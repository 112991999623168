import React from 'react';
import LoadingPreviewImage from '../../preview-loading.svg';
import HandPointUp from '../../hand-point-up.svg';
import styles from './preview.module.css';

export default function EmptyPreview() {
  return (
    <div>
      <img src={LoadingPreviewImage} alt="Preview loading..." height={360} className={styles.loadingImage} />
      <div className={styles.loadingTitle}>Your design changes have been captured.</div>
      <div className={styles.loadingDesc}>
        To preview the same, please enter the <br /> slug/ URL of any story in the field provided above.
        <img src={HandPointUp} alt="Hand point up logo" className={styles.handsUp} />
      </div>
    </div>
  );
}
