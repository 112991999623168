import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import {
  PAGEBUILDER_BUILDER_UPDATE_CONFIG,
  PAGEBUILDER_CREATE_NEW_LANDING_PAGE,
  PAGEBUILDER_CREATE_NEW_STORY_PAGE,
  PAGEBUILDER_DARK_MODE_PB_PREVIEW,
  PAGEBUILDER_GENERAL_ADD_MAGAZINE_CONFIG,
  PAGEBUILDER_GENERAL_AMP,
  PAGEBUILDER_GENERAL_PREMIUM_STORY_ICON_STYLE,
  PAGEBUILDER_GENERAL_STORY_DEMARCATION_STYLE,
  PAGEBUILDER_GENERAL_SPONSORED_STORY_STYLE,
  PAGEBUILDER_GENERAL_ROW_SECTION_TAG_STYLE,
  PAGEBUILDER_GENERAL_UPDATE_ACCESSTYPE_CONFIG,
  PAGEBUILDER_GENERAL_UPDATE_NOTIFICATIONS,
  PAGEBUILDER_GENERAL_UPDATE_SPA,
  PAGEBUILDER_GENERAL_UPDATE_NEXT_HEADER_CONFIG,
  PAGEBUILDER_GENERAL_UPDATE_OTHER_CONFIG,
  PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE,
  PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE_ARROW,
  PAGEBUILDER_GENERAL_UPDATE_SINGLE_SIGN_ON,
  PAGEBUILDER_GENERAL_UPDATE_THIRD_PARTY_CONFIG,
  PAGEBUILDER_GENERAL_WIDGET,
  PAGEBUILDER_HAVE_LAYOUT_UPDATES,
  PAGEBUILDER_HAVE_UNSAVED_CHANGES,
  PAGEBUILDER_SEARCH_ENABLE_ADVANCED_SEARCH,
  PAGEBUILDER_SEARCH_UPDATE_FILTER_OPTIONS,
  PAGEBUILDER_SEARCH_UPDATE_OTHER_SETTINGS,
  PAGEBUILDER_SET_DOMAIN_LIST,
  PAGEBUILDER_SET_PAGEID,
  PAGEBUILDER_SET_PAGETYPE,
  PAGEBUILDER_SET_SUBPAGETYPE,
  PAGEBUILDER_SET_UPDATED_PAGES,
  PAGEBUILDER_UPDATE_ACTION_BUTTON_STATUS,
  PAGEBUILDER_UPDATE_CONFIG_VERSION,
  PAGEBUILDER_UPDATE_LANDING_PAGE_DETAILS,
  PAGEBUILDER_UPDATE_PAGE_SETTINGS,
  PAGEBUILDER_UPDATE_METERED_PAYWALL_SETTINGS,
  PAGEBUILDER_STORY_GENERAL_SETTINGS,
  PAGEBUILDER_PERFORMANCE_BOT_MOD
} from '../reducers/builder/configReducer/actions';
import { IConfig, IDomainList, IGeneralConfig, IHistoryActionStatus, IUpdatePageConfig } from '../utils/interfaces';

export const setAdvancedSearch = (toggleAdvancedSearch: boolean) => ({
  type: PAGEBUILDER_SEARCH_ENABLE_ADVANCED_SEARCH,
  payload: { enableAdvancedSearch: toggleAdvancedSearch ? true : false }
});

export const updateSearchPageSettings = (setting: string, enable: boolean, settingType: string) => {
  switch (settingType) {
    case 'other':
      return {
        type: PAGEBUILDER_SEARCH_UPDATE_OTHER_SETTINGS,
        payload: { [setting]: enable }
      };
    case 'filter':
      return {
        type: PAGEBUILDER_SEARCH_UPDATE_FILTER_OPTIONS,
        payload: { [setting]: enable }
      };
  }
};

export const updatePBConfig = (config: IConfig) => ({
  type: PAGEBUILDER_BUILDER_UPDATE_CONFIG,
  payload: { ...config }
});

export const updateBackupConfig = (payload: { config: IConfig }) => ({
  type: 'PAGEBUILDER.BACKUP.UPDATE',
  payload: {
    ...payload,
    cancelQTConfigMerge: true
  }
});

export const updateConfigVersion = (version: number) => ({
  type: PAGEBUILDER_UPDATE_CONFIG_VERSION,
  payload: version
});

export const setBuilderLoadingState = (loading: boolean) => ({
  type: 'PAGEBUILDER.APP_LOADING',
  payload: loading
});

export const updateHaveUnsavedChanges = (payload: boolean) => ({
  type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
  payload
});

export const updateHaveLayoutUpdates = (payload: boolean) => ({
  type: PAGEBUILDER_HAVE_LAYOUT_UPDATES,
  payload
});

interface IKeyValue {
  [key: string]: string;
}

interface IDispatchGeneralRowSettingsArg {
  type: string;
  payload: IKeyValue | string | boolean;
}

type IDispatchGeneralRowSettings = (arg: IDispatchGeneralRowSettingsArg) => void;

const getArrowRowTitleName = (type: string) => {
  // Arrow and ahead rows have different config values for row title.
  const arrowAheadPropMap: IKeyValue = {
    default: 'borderBottomFull',
    'style-1': 'default',
    'style-2': 'crossLine',
    'style-3': 'borderLeft',
    'style-4': 'borderBottom'
  };
  return arrowAheadPropMap[type];
};

export const updateRowTitleStyle = (payload: IKeyValue) => (dispatch: IDispatchGeneralRowSettings) => {
  const arrowRowTitle = getArrowRowTitleName(payload.type);
  dispatch({
    type: PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE_ARROW,
    payload: arrowRowTitle
  });
  dispatch({
    type: PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE,
    payload
  });
  dispatch({
    type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
    payload: true
  });
};

export const premiumIconStyle = (payload: any) => (dispatch: any) => {
  dispatch({
    type: PAGEBUILDER_GENERAL_PREMIUM_STORY_ICON_STYLE,
    payload
  });
  dispatch({
    type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
    payload: true
  });
};

export const storyDemarcationStyle = (payload: any) => (dispatch: any) => {
  dispatch({
    type: PAGEBUILDER_GENERAL_STORY_DEMARCATION_STYLE,
    payload
  });
  dispatch({
    type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
    payload: true
  });
};

export function sponsoredStoryStyle(payload: any) {
  return (dispatch: any) => {
    dispatch({
      type: PAGEBUILDER_GENERAL_SPONSORED_STORY_STYLE,
      payload
    });
    dispatch({
      type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
      payload: true
    });
  };
}

export const updateSectionTag = (payload: IKeyValue) => ({
  type: PAGEBUILDER_GENERAL_ROW_SECTION_TAG_STYLE,
  payload
});

export const setPageType = (pageType: string) => ({
  type: PAGEBUILDER_SET_PAGETYPE,
  payload: pageType
});

export const updateSubPageType = (payload: string) => ({
  type: PAGEBUILDER_SET_SUBPAGETYPE,
  payload
});

export const setPageId = (pageId: string) => ({
  type: PAGEBUILDER_SET_PAGEID,
  payload: pageId
});

export const updateHistoryActionStatus = (historyActionStatus: IHistoryActionStatus) => ({
  type: PAGEBUILDER_UPDATE_ACTION_BUTTON_STATUS,
  payload: historyActionStatus
});

export const createNewLandingPage = (landingPageName: string, landingPageId: string) => ({
  type: PAGEBUILDER_CREATE_NEW_LANDING_PAGE,
  payload: { landingPageName, landingPageId }
});

export const createNewStoryPage = (storyPageName: string, storyType: string, templateName: string) => ({
  type: PAGEBUILDER_CREATE_NEW_STORY_PAGE,
  payload: { storyPageName: `${camelCase(storyPageName)}-story`, storyType, templateName }
});

type IUpdatePageSettingsDispatch = (args: IUpdatePageConfig) => void;

export const updateStoryPageGeneralSettings = (configToUpdate: object) => ({
  type: PAGEBUILDER_STORY_GENERAL_SETTINGS,
  payload: configToUpdate
});

export const updatePageSettings = (configToUpdate: object, moduleType?: string) => {
  return (dispatch: IUpdatePageSettingsDispatch, getState: any) => {
    const pageType = get(getState(), ['builder', 'pageType'], '');
    const pageId = get(getState(), ['builder', 'pageId'], '');
    const payload = { config: configToUpdate, moduleType: moduleType || pageType, pageId };
    dispatch({ type: PAGEBUILDER_UPDATE_PAGE_SETTINGS, payload });
  };
};

export const updateMeterSettings = (configToUpdate: object) => ({
  type: PAGEBUILDER_UPDATE_METERED_PAYWALL_SETTINGS,
  payload: configToUpdate
});

interface IPageDetails {
  [key: string]: string | boolean;
}

interface IPageDetailsPayload {
  pageDetails: IPageDetails;
  pageId: string;
}

interface IPageDetailsDispatchArgs {
  type: string;
  payload: IPageDetailsPayload;
}

type IPageDetailsDispatch = (args: IPageDetailsDispatchArgs) => void;

export const updatePageDetails = (pageDetails: IPageDetails) => (dispatch: IPageDetailsDispatch, getState: any) => {
  const pageId = get(getState(), ['builder', 'pageId'], '');
  const payload = { pageDetails, pageId };
  dispatch({
    type: PAGEBUILDER_UPDATE_LANDING_PAGE_DETAILS,
    payload
  });
};

interface IPBGeneralConfig {
  type: string;
  config: IGeneralConfig;
}

interface ISpa {
  disableCache?: boolean;
}

export const updateThirdPartyConfig = (payload: IPBGeneralConfig) => {
  return {
    type: PAGEBUILDER_GENERAL_UPDATE_THIRD_PARTY_CONFIG,
    payload
  };
};
export const updateNotificationsConfig = (payload: IPBGeneralConfig) => {
  return {
    type: PAGEBUILDER_GENERAL_UPDATE_NOTIFICATIONS,
    payload
  };
};

export const updateSpaConfig = (payload: ISpa) => {
  return {
    type: PAGEBUILDER_GENERAL_UPDATE_SPA,
    payload
  };
};

interface INextHeaderConfig {
  [key: string]: string | boolean;
}

export const updateNextConfig = (payload: INextHeaderConfig) => (dispatch: any) => {
  dispatch({
    type: PAGEBUILDER_GENERAL_UPDATE_NEXT_HEADER_CONFIG,
    payload
  });
  dispatch({
    type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
    payload: true
  });
};

export const ampSetting = (payload: any) => {
  return {
    type: PAGEBUILDER_GENERAL_AMP,
    payload
  };
};
export const updateAccesstypeConfig = (payload: IGeneralConfig) => {
  return {
    type: PAGEBUILDER_GENERAL_UPDATE_ACCESSTYPE_CONFIG,
    payload
  };
};

export const setDomainList = (payload: IDomainList[]) => ({
  type: PAGEBUILDER_SET_DOMAIN_LIST,
  payload
});

export const otherConfig = (payload: any) => {
  return {
    type: PAGEBUILDER_GENERAL_UPDATE_OTHER_CONFIG,
    payload
  };
};

export const updateSingleSignOnConfig = (payload: any) => {
  return {
    type: PAGEBUILDER_GENERAL_UPDATE_SINGLE_SIGN_ON,
    payload
  };
};

export const setUpdatedPages = (payload: Array<string>) => {
  return {
    type: PAGEBUILDER_SET_UPDATED_PAGES,
    payload
  };
};

export const updateWidgetSetting = (payload: any) => {
  return {
    type: PAGEBUILDER_GENERAL_WIDGET,
    payload
  };
};

export const updateDarkModePbPreview = (payload: boolean) => {
  return {
    type: PAGEBUILDER_DARK_MODE_PB_PREVIEW,
    payload
  };
};

export const addMagazineConfig = (payload: any) => {
  return {
    type: PAGEBUILDER_GENERAL_ADD_MAGAZINE_CONFIG,
    payload
  };
};

interface TogglePerformanceBotsConfig {
  [key: string]: boolean;
}

export const togglePerformanceBots = (type: string, payload: TogglePerformanceBotsConfig) => ({
  type: PAGEBUILDER_PERFORMANCE_BOT_MOD,
  payload
});
