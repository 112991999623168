import { Button } from '@quintype/em/components/button';
import Select from '@quintype/em/components/select';
import { TextField } from '@quintype/em/components/text-field';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createNewLandingPage, createNewStoryPage, updateHaveLayoutUpdates } from '../../actions/builder-action';
import { closePopup } from '../../actions/popup-action';
import StoryLayoutSelector from '../../containers/story/story-layout-selector';
import { defaultStoryTypes } from '../../containers/story/story-page';
import { IDropdownOption, IStoryTemplates } from '../../utils/interfaces';
import { getStoryName, getStoryTemplates } from '../../utils/utils';
import styles from './styles.module.css';

const CreateNewLayoutPagePopup = ({ meta }: { meta: { layoutType: string } }) => {
  const [layoutPageName, setLayoutPageName] = useState('');
  const [storyTemplateOptions, setStoryTemplateOptions] = useState([] as object[]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [selectStoryPageType, setSelectStoryPageType] = useState('text');
  const [showLayoutSelector, toggleLayoutSelector] = useState(false);
  const [creatingLayoutPage, setCreatingLayoutPage] = useState(false);
  const { publisherId = '', domain = '' } = useParams<{ publisherId: string; domain: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const isSaving = useSelector(state => get(state, ['builder', 'haveLayoutUpdates'], false));
  const customStoryConfig = useSelector(state => get(state, ['builder', 'config', 'customStory'], {}));

  const closeModal = () => {
    dispatch(closePopup());
  };

  const changeSelectStoryType = (type: string) => {
    setSelectStoryPageType(type);
  };

  const onChangeHandler = (value: string) => {
    setLayoutPageName(value);
  };

  const newLandingPage = () => {
    dispatch(updateHaveLayoutUpdates(true));
    // Assigning an ID for the page layout.
    const layoutPageId = new Date().valueOf().toString();
    dispatch(createNewLandingPage(layoutPageName.trim(), layoutPageId));
    setCreatingLayoutPage(true);
    mayNavigateToLayoutPage(`/accounts/${publisherId}/${domain}/${meta.layoutType}-page?layoutId=${layoutPageId}`);
  };

  const newStoryPage = (storyType: string, templateName: string) => {
    dispatch(createNewStoryPage(layoutPageName.trim(), storyType, templateName));
    dispatch(updateHaveLayoutUpdates(true));
    setCreatingLayoutPage(true);
    mayNavigateToLayoutPage(`/accounts/${publisherId}/${domain}/story/${encodeURIComponent(layoutPageName.trim())}`);
  };

  const mayNavigateToLayoutPage = (url: string) => {
    const checkSaved = setInterval(() => {
      if (!isSaving) {
        clearInterval(checkSaved);
        closeModal();
        history.push(url);
      }
    }, 500);
  };

  useEffect(() => {
    const setCreateStoryTemplateDropdownOptions = async () => {
      const result = await getStoryTemplates(publisherId);
      const storyTemplates = result
        .filter(
          (template: IStoryTemplates) =>
            !defaultStoryTypes.includes(`${camelCase(template.slug)}-story`) && template.slug !== 'news-elsewhere'
        )
        .map((template: IStoryTemplates) => ({
          label: template.name,
          value: template.slug
        }));
      const storyTypesInConfig = Object.keys(customStoryConfig).map((item: string) => getStoryName(item));
      const storyTypesOptionsToShow = storyTemplates.filter(
        (item: IDropdownOption) => !storyTypesInConfig.includes(camelCase(item.value))
      );
      setStoryTemplateOptions(storyTypesOptionsToShow);
      setIsLoadingOptions(false);
    };
    setCreateStoryTemplateDropdownOptions();
  }, [customStoryConfig, publisherId]);

  switch (meta.layoutType) {
    case 'landing':
      return (
        <form onSubmit={newLandingPage} data-test-id="new-landing-page-form">
          <div className={styles.inputWrapper}>
            <TextField
              label="Layout Name"
              onChange={onChangeHandler}
              value={layoutPageName}
              hint="New name for section layout"
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              onClick={newLandingPage}
              type="primary"
              disabled={creatingLayoutPage || !layoutPageName.trim().length}
            >
              {isSaving ? 'Creating...' : 'Create'}
            </Button>
            <Button onClick={closeModal}>Cancel</Button>
          </div>
        </form>
      );
    case 'story':
      return (
        <>
          {showLayoutSelector && (
            <StoryLayoutSelector
              type={selectStoryPageType}
              toggleLayoutSelector={toggleLayoutSelector}
              customStoryType={{
                customStoryTypeName: layoutPageName,
                changeStoryType: changeSelectStoryType,
                selectStoryTemplate: newStoryPage
              }}
            />
          )}
          {storyTemplateOptions.length ? (
            <div data-test-id="new-story-page-form">
              <div className={styles.inputWrapper}>
                <Select
                  label="Story Template Name"
                  helpText="Choose from the dropdown"
                  options={storyTemplateOptions}
                  onChange={({ value }: { value: string }) => setLayoutPageName(value)}
                />
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  onClick={() => toggleLayoutSelector(true)}
                  type="primary"
                  disabled={creatingLayoutPage || !layoutPageName.trim().length}
                >
                  {isSaving ? 'Creating...' : 'Create'}
                </Button>
                <Button onClick={closeModal}>Cancel</Button>
              </div>
            </div>
          ) : (
            <div className={styles.dialogText} data-test-id="no-new-story-templates">
              {isLoadingOptions
                ? `Loading..`
                : `You have no new custom story templates left to configure. Create a new story template in Bold and it will
              appear here.`}
            </div>
          )}
        </>
      );
    default:
      return null;
  }
};

export default CreateNewLayoutPagePopup;
