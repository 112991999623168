import React from 'react';
import { IsvgData } from '../../utils/interfaces';
import styles from './undo.module.css';

interface Iprops extends IsvgData {
  redo?: boolean;
}

const Undo: React.FunctionComponent<Iprops> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
  redo = false
}) => {
  const undo = redo ? `${styles.redo}` : '';
  return (
    <svg className={undo} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fill-rule="evenodd"
        d="M12 18.531a6.573 6.573 0 003.39-.916 6.886 6.886 0 002.475-2.46c.61-1.03.916-2.16.916-3.391 0-1.23-.3-2.366-.902-3.405a6.746 6.746 0 00-2.461-2.474 6.598 6.598 0 00-3.404-.916c-1.23 0-2.366.3-3.405.902a6.642 6.642 0 00-2.46 2.461V5.297a.316.316 0 00-.096-.233.316.316 0 00-.233-.095h-.273a.316.316 0 00-.233.095.316.316 0 00-.095.233v4.375c0 .091.032.169.095.232a.316.316 0 00.233.096h4.375a.316.316 0 00.232-.096.316.316 0 00.096-.232v-.274a.316.316 0 00-.096-.232.316.316 0 00-.232-.096H6.805a5.722 5.722 0 012.132-2.31A5.716 5.716 0 0112 5.898c1.057 0 2.037.265 2.94.793a5.837 5.837 0 012.132 2.133c.52.893.78 1.869.78 2.926a5.713 5.713 0 01-.793 2.94 5.837 5.837 0 01-2.133 2.132c-.893.52-1.869.78-2.926.78a5.687 5.687 0 01-2.12-.397 5.954 5.954 0 01-1.79-1.107.38.38 0 00-.233-.082.327.327 0 00-.232.109l-.191.191c-.055.055-.082.133-.082.233s.036.178.109.232a6.746 6.746 0 002.078 1.285c.784.31 1.604.465 2.461.465z"
      />
    </svg>
  );
};
export { Undo };
