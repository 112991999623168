import { Checkbox } from '@quintype/em/components/checkbox';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAdvancedSearch, updateHaveUnsavedChanges } from './../actions/builder-action';

export const EnableAdvancedSearch = () => {
  const enableAdvancedSearch = useSelector((state: any) =>
    get(state, ['builder', 'config', 'search', 'pageSetting', 'enableAdvancedSearch'], false)
  );
  const dispatch = useDispatch();
  return (
    <Checkbox
      id="enableAdvancedSearch"
      label="Enable Filter"
      checked={enableAdvancedSearch}
      onChange={(value: boolean) => {
        dispatch(setAdvancedSearch(value));
        dispatch(updateHaveUnsavedChanges(true));
      }}
    />
  );
};
