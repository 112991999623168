import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDarkModePbPreview, updateHaveLayoutUpdates } from '../../../actions/builder-action';
import { updateAheadDarkModePreview } from '../../../actions/update-qt-action';
import styles from './dark-mode-preview.module.css';
import { InfoTooltip } from '../../../molecules/info-tooltip';

export const DarkModePreview = () => {
  const config = useSelector((state: any) => get(state, ['builder', 'config'], {}));
  const { enableDarkModePreview = false } = config;

  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.wrapper}>
        Mode
        <input
          type="checkbox"
          name="switch-button"
          id="switch-checkbox-darkmode"
          data-test-id="switch-checkbox-button"
          className={styles.switchInput}
          checked={enableDarkModePreview}
          onChange={e => {
            dispatch(updateDarkModePbPreview(e.target.checked));
            dispatch(updateAheadDarkModePreview(e.target.checked));
            dispatch(updateHaveLayoutUpdates(true));
          }}
        />
        <label htmlFor={'switch-checkbox-darkmode'} className={styles.switchLabel} />
        <div className={styles.iconWrapper} data-for="mode" data-tip={true}>
          <InfoTooltip
            width={20}
            height={20}
            tooltipDescription="This toggle lets you preview how your site looks in Light/Dark Mode. To turn on Dark mode for your site, go to Header Settings > Dark mode > Select “Enable Dark Mode”"
            tooltipId="mode"
            tooltipPlace="bottom"
          />
        </div>
      </div>
    </>
  );
};
