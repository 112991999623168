import { Button } from '@quintype/em/components/button';
import React from 'react';
import { useDispatch } from 'react-redux';

import { showNavigationPopup } from '../../actions/popup-action';

const ViewEditor = ({ toggleConfirmationPopup, text, navigationPath }: any) => {
  const dispatch = useDispatch();

  const updateNavigationPopup = () => {
    toggleConfirmationPopup && toggleConfirmationPopup(true);
    dispatch(
      showNavigationPopup({
        saveButton: 'Save & Continue',
        discardButton: 'Discard & Continue',
        navigationPath
      })
    );
  };

  return <Button onClick={updateNavigationPopup}>{text}</Button>;
};

export default ViewEditor;
