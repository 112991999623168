import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import returnNull from '../../atoms/null';
import { subscriptionsWithSwitchablePlansData, getSubscriptionData } from '../../static-data/manage-subscriptions-data';

interface IProps {
  pageType?: string;
}

const doNothing = () => null;
const doNothingAsync = async () => null;

export const ManageSubscriptionAndPlans = ({ pageType }: IProps) => {
  let Component;
  let props;
  const enableDarkMode = useSelector(state => get(state, ['header', 'isDarkModeEnabled'], false));
  const mode = enableDarkMode ? 'dark' : 'light';
  const initAccessType = async (asyncCallback: any) => {
    asyncCallback();
  };
  const getSubscriptionsWithSwitchablePlans = async () => {
    return subscriptionsWithSwitchablePlansData;
  };
  const getSubscription = async () => {
    return getSubscriptionData;
  };

  switch (pageType) {
    case 'manage-subscriptions':
      Component = get(window, ['QT_COMPONENTS', 'ManageSubscriptions']);
      if (!Component) throw new Error(`"ManageSubscriptions" component not found`);
      break;
    case 'available-plans':
      props = {
        getSubscriptionsWithSwitchablePlans,
        subscriptionId: '333203',
        planId: '68762',
        enableAccesstype: true,
        initAccessType,
        isAtGlobalSet: true,
        mode,
        getSubscription
      };
      Component = get(window, ['QT_COMPONENTS', 'ShowAvailablePlans']);
      if (!Component) throw new Error(`"ShowAvailablePlans" component not found`);
      break;
    case 'plan-change-overview':
      props = {
        getSubscription,
        getSubscriptionsWithSwitchablePlans,
        initAccessType,
        initRazorPayPayment: doNothingAsync,
        initPaypalPayment: doNothingAsync,
        initOmisePayment: doNothingAsync,
        initPaytrailPayment: doNothingAsync,
        initAdyenPayment: doNothingAsync,
        initStripePayment: doNothingAsync,
        enableAccesstype: true,
        isAtGlobalSet: true,
        fromPlan: '68761',
        toPlan: '68767',
        subscriptionId: '333203',
        setShowLoader: doNothing,
        mode,
        switchType: 'upgrade'
      };
      Component = get(window, ['QT_COMPONENTS', 'ShowPlanChangeOverview']);
      if (!Component) throw new Error(`"ShowPlanChangeOverview" component not found`);
      break;

    default:
      Component = returnNull;
      break;
  }
  return <Component {...props} target="pagebuilder" />;
};
