import { Checkbox } from '@quintype/em/components/checkbox';
import Select from '@quintype/em/components/select';
import { TextArea } from '@quintype/em/components/text-area';
import { TextField } from '@quintype/em/components/text-field';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import { PAGEBUILDER_GENERAL_UPDATE_LOCALIZATION } from '../../reducers/builder/configReducer/actions';
import { CopyIcon } from './copy';
import { localizedElementList, localizedSubElementList } from './localization-elements-data';
import { copyTextToClipboard } from '../row-inspector/utils';
import styles from './localization.module.css';

interface IlocalizedSubElementList {
  [key: string]: object[];
}

export const Localization = () => {
  const dispatch = useDispatch();
  const [elementTextValue, setElementTextValue] = useState('');
  const [selectedElement, setSelectedElement] = useState({
    label: 'Select',
    value: '',
    dateTimeOptions: null,
    paywallOptions: null,
    errorMessageOptions: null,
    profileOptions: null,
    searchFilters: null,
    signInSignUpOptions: null,
    optionLabel: ''
  });
  const [selectedDateTime, setSelectedDateTime] = useState({ label: 'AM/PM', value: 'meridiem' });
  const [selectedPaywall, setSelectedPaywall] = useState({ label: 'Login Access', value: 'loginAccess' });
  const { enableLocalization = false, localizedElements = {} } = useSelector(state =>
    get(state, ['builder', 'config', 'general', 'localization'], {})
  );

  const [selectErrorMessageOption, setSelectedErrorMessageOption] = useState({
    label: '404 Page',
    value: '404Page'
  });

  const [selectProfileOption, setSelectedProfileOption] = useState({
    label: 'Profile',
    value: 'myProfile'
  });

  const [selectSearchOption, setSelectedSearchOption] = useState({
    label: 'Search',
    value: 'searchLabels'
  });

  const [selectSigninSignUpOptions, setSelectedSigninSignUpOption] = useState({
    label: 'Sign In/Sign Up',
    value: 'signInSignUp'
  });

  const updateUnsaved = (value: boolean) => {
    dispatch(updateHaveUnsavedChanges(value));
  };

  const updateElementTextValue = (key: string, value: boolean | string, parentKey?: string | undefined) => {
    const payload = parentKey ? { [parentKey]: { [key]: value } } : { [key]: value };
    return dispatch({ type: PAGEBUILDER_GENERAL_UPDATE_LOCALIZATION, payload });
  };

  const toggleLocalizedElementSettings = () => {
    updateElementTextValue('enableLocalization', !enableLocalization);
    updateUnsaved(true);
  };

  const onSelectElement = (option: any) => {
    const value = get(localizedElements, [option.value], '');
    setElementTextValue(value);
    setSelectedElement(option);
  };

  const delayDispatch = debounce(
    (updatedText, selectedElementValue, selectedParentValue) => {
      updateElementTextValue(selectedElementValue, updatedText, selectedParentValue);
      updateUnsaved(true);
    },
    300,
    {
      leading: false,
      trailing: true
    }
  );
  const onChangeElementText = (updatedText: string, selectedElementValue: string, selectedParentValue: string) => {
    setElementTextValue(updatedText);
    delayDispatch(updatedText, selectedElementValue, selectedParentValue);
  };

  const onChangeSubElementText = (updatedText: string, selectedElementValue: string, selectedParentValue: string) => {
    updateElementTextValue(selectedElementValue, updatedText, selectedParentValue);
    updateUnsaved(true);
  };

  const subDropdownComponent = (selectedOption: { [key: string]: any }) => {
    const supportTextFieldInRow = ['meridiem', 'months', 'publishTime', 'buttonLabels'];
    let wrapperStyle;
    if (supportTextFieldInRow.includes(selectedOption.value)) {
      wrapperStyle = styles.textFieldWrapper;
    } else if (selectedOption.value === 'shippingAddress') {
      wrapperStyle = styles.customTextFieldWrapper;
    } else {
      wrapperStyle = '';
    }
    return (
      selectedOption.value && (
        <div className={wrapperStyle}>
          {(localizedSubElementList as IlocalizedSubElementList)[selectedOption.value].map((item: any, index) => {
            const { type = '', label = '', placeholder = '', value = '', hint = '', shortCode = [] } = item;
            if (type === 'textField') {
              return (
                <TextField
                  value={get(localizedElements, [selectedOption.value, value])}
                  label={label}
                  key={`${index}-${selectedOption.value}`}
                  placeholder={placeholder}
                  onChange={(updateText: string) => onChangeSubElementText(updateText, value, selectedOption.value)}
                />
              );
            } else if (type === 'textArea') {
              return (
                <>
                  <TextArea
                    onChange={(updateText: string) => onChangeSubElementText(updateText, value, selectedOption.value)}
                    value={get(localizedElements, [selectedOption.value, value]) || ''}
                    hint={hint}
                    label={label}
                    placeholder={placeholder}
                  />
                  {shortCode.map((shortCodeItem: any, shortCodeIndex: any) => {
                    return (
                      <div className={styles.copyWrapper} key={shortCodeIndex}>
                        <span>{shortCodeItem}</span>
                        <button className={styles.copyButton} onClick={() => copyTextToClipboard(shortCodeItem)}>
                          <CopyIcon />
                          <span>Copy Shortcode</span>
                        </button>
                      </div>
                    );
                  })}
                </>
              );
            }
          })}
        </div>
      )
    );
  };

  const unsupportedElements = [
    'dateTime',
    'paywall',
    'shippingAddress',
    'buttonLabels',
    'errorsMessage',
    'profilePage',
    'signInSignUp',
    'searchFilter'
  ];

  const subElements = ['shippingAddress', 'buttonLabels'];

  return (
    <>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          label="Enable Custom Labels"
          id="enableLocalization"
          onChange={toggleLocalizedElementSettings}
          checked={enableLocalization}
        />
      </div>
      {enableLocalization && (
        <>
          <Select
            label="Choose Element to Customise Text"
            options={localizedElementList}
            onChange={onSelectElement}
            id={selectedElement.value}
            value={selectedElement}
          />
          {selectedElement.value && !unsupportedElements.includes(selectedElement.value) && (
            <TextField
              value={elementTextValue}
              label="Element Text"
              onChange={(updateText: string) => onChangeElementText(updateText, selectedElement.value, '')}
              placeholder={selectedElement.label}
            />
          )}
          {selectedElement.dateTimeOptions && (
            <>
              <Select
                label={selectedElement.optionLabel}
                options={selectedElement.dateTimeOptions}
                onChange={(options: any) => setSelectedDateTime(options)}
                id={selectedDateTime.value}
                value={selectedDateTime}
                defaultValue={selectedDateTime}
              />
              {subDropdownComponent(selectedDateTime)}
            </>
          )}
          {selectedElement.paywallOptions && (
            <>
              <Select
                label={selectedElement.optionLabel}
                options={selectedElement.paywallOptions}
                onChange={(options: any) => setSelectedPaywall(options)}
                id={selectedPaywall.value}
                value={selectedPaywall}
                defaultValue={selectedPaywall}
              />
              {subDropdownComponent(selectedPaywall)}
            </>
          )}
          {subElements.includes(selectedElement.value) && subDropdownComponent(selectedElement)}
          {selectedElement.errorMessageOptions && (
            <>
              <Select
                label={selectedElement.optionLabel}
                options={selectedElement.errorMessageOptions}
                onChange={(options: any) => setSelectedErrorMessageOption(options)}
                id={selectErrorMessageOption.value}
                value={selectErrorMessageOption}
                defaultValue={selectErrorMessageOption}
              />
              {subDropdownComponent(selectErrorMessageOption)}
            </>
          )}
          {selectedElement.profileOptions && (
            <>
              <Select
                label={selectedElement.optionLabel}
                options={selectedElement.profileOptions}
                onChange={(options: any) => setSelectedProfileOption(options)}
                id={selectProfileOption.value}
                value={selectProfileOption}
                defaultValue={selectProfileOption}
              />
              {subDropdownComponent(selectProfileOption)}
            </>
          )}
          {selectedElement.signInSignUpOptions && (
            <>
              <Select
                label={selectedElement.optionLabel}
                options={selectedElement.signInSignUpOptions}
                onChange={(options: any) => setSelectedSigninSignUpOption(options)}
                id={selectSigninSignUpOptions.value}
                value={selectSigninSignUpOptions}
                defaultValue={selectSigninSignUpOptions}
              />
              {subDropdownComponent(selectSigninSignUpOptions)}
            </>
          )}
          {selectedElement.searchFilters && (
            <>
              <Select
                label={selectedElement.optionLabel}
                options={selectedElement.searchFilters}
                onChange={(options: any) => setSelectedSearchOption(options)}
                id={selectSearchOption.value}
                value={selectSearchOption}
                defaultValue={selectSearchOption}
              />
              {subDropdownComponent(selectSearchOption)}
            </>
          )}
        </>
      )}
    </>
  );
};
