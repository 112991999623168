import Select from '@quintype/em/components/select';
import { TextField } from '@quintype/em/components/text-field';
import Axios from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Desktop } from '../../atoms/desktop-svg';
import { Globe } from '../../atoms/globe-svg';
import { Phone } from '../../atoms/mobile-svg';
import { useQuery } from '../../hooks/useQuery';
import { ILandingPageLayout, IPBState, ISectionMeta } from '../../utils/interfaces';
import styles from './preview.module.css';
import EmptyPreview from './EmptyPreview';

interface IDimensions {
  [key: string]: string[];
}

interface IPreviewArea {
  baseUrl: string;
  page: string;
  layoutId: string;
  layoutDetails: ILandingPageLayout;
  publisherId: string;
  subpage: string;
}

const PreviewArea = ({ baseUrl, page, layoutId, layoutDetails, publisherId, subpage }: IPreviewArea) => {
  const selectedOptionDefault = { slug: '', label: '', value: '', sectionId: 0 };
  const [screen, updateScreen] = useState('desktop');
  const [path, setPath] = useState('');
  const [sectionDetails, updateSectionDetails] = useState(selectedOptionDefault);
  const [sectionsList, updateSectionsList] = useState([selectedOptionDefault]);
  const [isLoaded, updateLoadingStatus] = useState(false);
  const dimensions: IDimensions = {
    mobile: ['362', '678'],
    desktop: ['100%', '100%']
  };
  const [width, height] = dimensions[screen];
  const currentTimeStamp = new Date().getTime();
  const addSlashBefore = (slug: string[] | null) => {
    if (slug === null) return '';
    return `/${slug.pop()}`;
  };
  const getSlug = (url: string = '') => addSlashBefore(url.match(/[^/]+/g));
  const getHeading = (pageType: string) => {
    switch (pageType) {
      case 'story':
        const storyType = subpage ? subpage : 'text';
        return `${startCase(storyType)} Story`;
      case 'tag':
      case 'author':
      case 'magazine-landing':
      case 'archive':
      case 'issue-landing':
      case 'search':
        return `${startCase(page)} Page`;
      case 'home':
        return 'Home';
      case 'section':
        return 'Section';
      case 'landingPage':
        return layoutDetails.layoutName;
      default:
        return '';
    }
  };
  const getSections = async () => {
    try {
      const config = await Axios.get(`/api/v1/publisher/${publisherId}/bold-config`);
      const { sections } = config.data;
      const sectionsData = sections.map((section: { [x: string]: any }) => {
        return {
          value: section['display-name'],
          label: section['display-name'],
          slug: section.slug,
          sectionId: section.id
        };
      });
      updateSectionsList(sectionsData);
      updateSectionDetails(sectionsData[0]);
      updateLoadingStatus(true);
    } catch (err) {
      console.log('Error: ', err);
    }
  };
  const selectedOptions = get(layoutDetails, ['pageSetting', 'selectedOptions'], [selectedOptionDefault]);

  if (page === 'section' && !isLoaded) getSections();

  useEffect(() => {
    if (page === 'landingPage') {
      updateSectionDetails(selectedOptions[0]);
      updateSectionsList(selectedOptions);
    }
  }, [layoutDetails, page, selectedOptions]);

  const heading = getHeading(page);
  const getSource = (pageType: string) => {
    switch (pageType) {
      case 'home':
        return `${baseUrl}?preview=true&${currentTimeStamp}`;
      case 'section':
      case 'landingPage':
        const { slug, sectionId } = sectionDetails;
        return slug && sectionId
          ? `${baseUrl}section/${slug}?preview=true&builderPageType=section&sectionId=${sectionId}&layoutId=${layoutId}&${currentTimeStamp}`
          : '';
      default:
        return `${baseUrl}${page}${getSlug(path)}?preview=true&${currentTimeStamp}`;
    }
  };

  const onChangeHandler = (section: ISectionMeta) => {
    updateSectionDetails(section);
  };

  const previewPages = ['tag', 'author', 'magazine-landing', 'archive', 'issue-landing', 'search', 'story'];
  const showEmptyPreview = baseUrl && previewPages.includes(page) && path === '';
  return (
    <>
      <div className={styles.headerWrapper}>
        {previewPages.includes(page) && (
          <>
            <p>{heading}</p>
            <div className={styles.slugInputWrapper}>
              <TextField
                classname={styles.storySlugWrapper}
                label=""
                placeholder={`Enter slug/url`}
                value={path}
                onChange={setPath}
              />
              <div className={styles.globeIconWrapper}>
                <Globe />
              </div>
            </div>
          </>
        )}
        {(page === 'landingPage' || page === 'section') && (
          <div className={styles.sectionWrapper}>
            <p className={styles.previewTextHead}>Preview As: </p>
            <div className={styles.dropdown}>
              <Select placeholder="Choose section to preview" options={sectionsList} onChange={onChangeHandler} />
            </div>
          </div>
        )}
        <div className={styles.actionButtonsWrapper}>
          <div
            className={`${styles.iconWrapper} ${screen === 'desktop' && styles.activeIcon}`}
            onClick={() => {
              updateScreen('desktop');
            }}
          >
            <Desktop />
          </div>{' '}
          <div
            className={`${styles.iconWrapper} ${screen === 'mobile' && styles.activeIcon}`}
            onClick={() => {
              updateScreen('mobile');
            }}
          >
            <Phone />
          </div>
        </div>
      </div>
      <div
        className={`${styles.iframeWrapper} ${
          screen === 'mobile' ? styles.iframeWrapperMobile : styles.iframeWrapperDesktop
        }`}
      >
        {showEmptyPreview && <EmptyPreview />}
        {baseUrl && !showEmptyPreview && (
          <iframe title="Preview Area" src={getSource(page)} width={width} height={height} />
        )}
      </div>
    </>
  );
};

const Preview = () => {
  const { publisherId = '', page = '', subpage = '' } = useParams() as {
    publisherId: string;
    page: string;
    subpage: string;
  };
  const [HOST, updateHost] = useState('');
  const queryParams = useQuery();
  const layoutId = queryParams.get('layoutId') || '';

  const layoutDetails = useSelector(
    (state: IPBState) => get(state, ['builder', 'config', 'landingPage', 'layouts', layoutId], {}),
    shallowEqual
  );

  useEffect(() => {
    const getHost = async () => {
      const {
        data: { host }
      } = await Axios.get(`/getHost/${publisherId}`);
      const addSlash = (hostName: string = '') => (hostName.endsWith('/') ? hostName : `${hostName}/`);
      host && updateHost(addSlash(host));
    };
    getHost();
  }, [publisherId]);
  let src = HOST;
  if (page !== 'home') {
    src = HOST ? `${HOST}pb-preview/` : '';
  }

  if (page === 'landingPage' && isEmpty(layoutDetails)) return null;

  const getPreviewArea = () => {
    if (src) {
      return (
        <div>
          <PreviewArea
            baseUrl={src}
            page={page}
            layoutId={layoutId}
            layoutDetails={layoutDetails}
            publisherId={publisherId}
            subpage={subpage}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return getPreviewArea();
};

export default Preview;
