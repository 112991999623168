const loadingReducer = (
  state: boolean = false,
  action: {
    payload: boolean;
    type: string;
  }
) => {
  switch (action.type) {
    case 'PAGEBUILDER.APP_LOADING':
      return action.payload;
    default:
      return state;
  }
};

export { loadingReducer };
