import React from 'react';
import { AccordionSection } from '@quintype/em/components/accordion';
import { updateMeterWallSettings, meteringOnShortCodes, renderAccordionSection } from '../../payWallUtils';
import { Separator } from '../../../../atoms/separator';
import get from 'lodash/get';

// BehindLoginAnonymousUsers
function behindLogin(modules: object, formats: any, pageSetting: any, dispatch: any) {
  const config = [
    {
      type: 'fieldLabel',
      label: 'STORIES BEHIND LOGIN'
    },
    {
      type: 'hintText',
      text: 'Use this if you have Turned ON metering for stories that are behind login..'
    },
    {
      type: 'quillEditor',
      label: 'Registration wall title',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'login', 'wallTitle']) || '',
      placeholder: 'Sign up for free to gain access to this article.',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'login', '', 'wallTitle', value, dispatch)
    },
    {
      type: 'quillEditor',
      label: 'Registration wall description',
      placeholder: 'You have exhausted your free articles for the day. To read more articles, please sign up with us.',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'login', 'wallDescription']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'login', '', 'wallDescription', value, dispatch)
    },
    {
      type: 'text',
      label: 'Sign up',
      placeholder: 'Sign up',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'login', 'buttonTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'login', '', 'buttonTxt', value, dispatch)
    },
    {
      type: 'text',
      label: 'Sign in',
      placeholder: 'Sign in',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'login', 'signInTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'login', '', 'signInTxt', value, dispatch)
    },
    {
      type: 'text',
      label: 'Already have an account? Sign in',
      placeholder: 'Already have an account? [Sign in]',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'login', 'bottomTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'login', '', 'bottomTxt', value, dispatch)
    },
    {
      type: 'toggleButton',
      label: 'Metering message',
      toggleName: 'MeteringIndicator',
      id: 'MeteringIndicator',
      updateToggleState: get(pageSetting, ['meteringOn', 'anonymous', 'login', 'MeteringIndicator'], true),
      updateToggleButton: (name: string, value: boolean | string) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'login', '', name, value, dispatch)
    },
    {
      type: 'hintText',
      text: 'Turn off this feature if you prefer not to inform your readers about the remaining number of articles.'
    },
    {
      type: 'text',
      label: `You can still access 'x' more articles for free`,
      placeholder: 'You can still access [number-of-stories] more articles for free',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'login', 'meteringMsgPlural']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'login', '', 'meteringMsgPlural', value, dispatch)
    },
    {
      type: 'text',
      label: 'You can still access 1 more article for free',
      placeholder: 'You can still access [number-of-stories] more article for free',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'login', 'meteringMsgSingular']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'login', '', 'meteringMsgSingular', value, dispatch)
    }
  ];

  return renderAccordionSection(config, modules, formats);
}

// behindSubscriptionAnonymousUsers
function behindSubscription(modules: object, formats: any, pageSetting: any, dispatch: any) {
  const selectedWallType = get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'AT_wallType'], 'payWall');
  const config = [
    {
      type: 'fieldLabel',
      label: 'STORIES BEHIND SUBSCRIPTION'
    },
    {
      type: 'hintText',
      text: 'Use this if you have Turned ON metering for stories that are behind subscription.'
    },
    {
      type: 'fieldLabel',
      label:
        'Please select which type of wall have you chosen to display for subscription-based stories to anonymous users in AccessType?'
    },
    {
      type: 'radio',
      name: 'AT_wallType',
      selected: selectedWallType,
      onChange: (e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) =>
        updateMeterWallSettings(
          'meteringOn',
          'anonymous',
          'subscription',
          '',
          'AT_wallType',
          (e.target as HTMLInputElement).value,
          dispatch
        )
    }
  ];

  return (
    <>
      {renderAccordionSection(config, modules, formats)}
      {selectedWallType === 'payWall'
        ? payWallSettings(modules, formats, pageSetting, dispatch)
        : loginWallSettings(modules, formats, pageSetting, dispatch)}
      {meteringOnShortCodes()}
    </>
  );
}

// behindSubscriptionAnonymousUsers with AT_wallType = payWall
function payWallSettings(modules: object, formats: any, pageSetting: any, dispatch: any) {
  const config = [
    {
      type: 'quillEditor',
      label: 'Paywall title',
      placeholder: 'Subscribe to gain access to this premium article',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'payWall', 'wallTitle']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'payWall', 'wallTitle', value, dispatch)
    },
    {
      type: 'quillEditor',
      label: 'Paywall description',
      placeholder:
        'You have exhausted your free premium articles for the day. To access more premium content and enjoy uninterrupted reading experience, please consider subscribing to one of our plans.',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'payWall', 'wallDescription']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings(
          'meteringOn',
          'anonymous',
          'subscription',
          'payWall',
          'wallDescription',
          value,
          dispatch
        )
    },
    {
      type: 'text',
      label: 'View all plans',
      placeholder: 'View all plans',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'payWall', 'buttonTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'payWall', 'buttonTxt', value, dispatch)
    },
    {
      type: 'text',
      label: 'Sign in',
      placeholder: 'Sign in',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'payWall', 'signInTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'payWall', 'signInTxt', value, dispatch)
    },
    {
      type: 'text',
      label: 'Already a subscriber?',
      placeholder: 'Already a subscriber? [Sign in]',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'payWall', 'bottomTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'payWall', 'bottomTxt', value, dispatch)
    },
    {
      type: 'toggleButton',
      label: 'Metering message',
      toggleName: 'MeteringIndicator',
      id: 'MeteringIndicator-payWall',
      updateToggleState: get(
        pageSetting,
        ['meteringOn', 'anonymous', 'subscription', 'payWall', 'MeteringIndicator'],
        true
      ),
      updateToggleButton: (name: string, value: boolean | string) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'payWall', name, value, dispatch)
    },
    {
      type: 'hintText',
      text: 'Turn off this feature if you prefer not to inform your readers about the remaining number of articles.'
    },
    {
      type: 'text',
      label: `You can still access 'x' more articles for free`,
      placeholder: 'You can still access [number-of-stories] more articles for free',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'payWall', 'meteringMsgPlural']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings(
          'meteringOn',
          'anonymous',
          'subscription',
          'payWall',
          'meteringMsgPlural',
          value,
          dispatch
        )
    },
    {
      type: 'text',
      label: `You can still access 1 more article for free`,
      placeholder: 'You can still access [number-of-stories] more article for free',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'payWall', 'meteringMsgSingular']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings(
          'meteringOn',
          'anonymous',
          'subscription',
          'payWall',
          'meteringMsgSingular',
          value,
          dispatch
        )
    },
    {
      type: 'fieldLabel',
      label: 'Available shortcodes'
    }
  ];

  return renderAccordionSection(config, modules, formats);
}

// behindSubscriptionAnonymousUsers with AT_wallType = loginWall
function loginWallSettings(modules: object, formats: any, pageSetting: any, dispatch: any) {
  const config = [
    {
      type: 'quillEditor',
      label: 'Registration wall title',
      placeholder: 'Sign up to gain access to this premium article.',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'loginWall', 'wallTitle']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'loginWall', 'wallTitle', value, dispatch)
    },
    {
      type: 'quillEditor',
      label: 'Registration wall description',
      placeholder:
        'You have exhausted your free premium articles for the day. To access more premium content and enjoy uninterrupted reading experience, please sign up with us.',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'loginWall', 'wallDescription']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings(
          'meteringOn',
          'anonymous',
          'subscription',
          'loginWall',
          'wallDescription',
          value,
          dispatch
        )
    },
    {
      type: 'text',
      label: 'Sign up',
      placeholder: 'Sign up',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'loginWall', 'buttonTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'loginWall', 'buttonTxt', value, dispatch)
    },
    {
      type: 'text',
      label: 'Sign in',
      placeholder: 'Sign in',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'loginWall', 'signInTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'loginWall', 'signInTxt', value, dispatch)
    },
    {
      type: 'text',
      label: 'Already a user?',
      placeholder: 'Already a user? [Sign in]',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'loginWall', 'bottomTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'loginWall', 'bottomTxt', value, dispatch)
    },
    {
      type: 'toggleButton',
      label: 'Metering message',
      toggleName: 'MeteringIndicator',
      id: 'MeteringIndicator-loginWall',
      updateToggleState: get(
        pageSetting,
        ['meteringOn', 'anonymous', 'subscription', 'loginWall', 'MeteringIndicator'],
        true
      ),
      updateToggleButton: (name: string, value: boolean | string) =>
        updateMeterWallSettings('meteringOn', 'anonymous', 'subscription', 'loginWall', name, value, dispatch)
    },
    {
      type: 'hintText',
      text: 'Turn off this feature if you prefer not to inform your readers about the remaining number of articles.'
    },
    {
      type: 'text',
      label: `You can still access 'x' more articles for free`,
      placeholder: 'You can still access [number-of-stories] more articles for free',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'loginWall', 'meteringMsgPlural']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings(
          'meteringOn',
          'anonymous',
          'subscription',
          'loginWall',
          'meteringMsgPlural',
          value,
          dispatch
        )
    },
    {
      type: 'text',
      label: `You can still access 1 more article for free`,
      placeholder: 'You can still access [number-of-stories] more article for free',
      value: get(pageSetting, ['meteringOn', 'anonymous', 'subscription', 'loginWall', 'meteringMsgSingular']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings(
          'meteringOn',
          'anonymous',
          'subscription',
          'loginWall',
          'meteringMsgSingular',
          value,
          dispatch
        )
    },
    {
      type: 'fieldLabel',
      label: 'Available shortcodes'
    }
  ];

  return renderAccordionSection(config, modules, formats);
}

// anonymous users settings
export function anonymousUsers(modules: object, formats: any, pageSetting: any, dispatch: any) {
  return (
    <AccordionSection label="anonymous users" isLabelUpperCase index={0}>
      {behindLogin(modules, formats, pageSetting, dispatch)}
      <Separator />
      {behindSubscription(modules, formats, pageSetting, dispatch)}
    </AccordionSection>
  );
}
