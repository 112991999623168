import { combineReducers } from 'redux';
import { componentMetaDataReducer } from './component-meta';
import { libraryLoaderReducer } from './library-loader';
import { storyComponentMetaDataReducer } from './story-components-meta';

// Ahead reducer is used to store all the data which is coming from ahead and it's related info.
// For example. the componentsMetadata coming from ahead is stored here.

export default combineReducers({
  libraryLoaded: libraryLoaderReducer,
  componentMetaData: componentMetaDataReducer,
  storyComponentMetaData: storyComponentMetaDataReducer
});
