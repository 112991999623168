import { BOLD_UPDATE_LANGUAGE } from '../reducers/bold';
import { AHEAD_DARK_MODE_PREVIEW } from '../reducers/header';
import { ILanguage } from '../utils/interfaces';

interface IUpdateLanguageAction {
  type: string;
  payload: ILanguage;
}

export function updateLanguage(payload: ILanguage): IUpdateLanguageAction {
  return {
    type: BOLD_UPDATE_LANGUAGE,
    payload
  };
}

interface IUpdateStateHeader {
  type: string;
  payload: boolean;
}

// require this to update certain components
export function updateAheadDarkModePreview(payload: boolean): IUpdateStateHeader {
  return {
    type: AHEAD_DARK_MODE_PREVIEW,
    payload
  };
}
