import get from 'lodash/get';
import React from 'react';
import returnNull from '../../atoms/null';

interface IProps {
  subscriptionConfig?: object;
  data?: {
    lavish?: object;
    concrete?: object;
  };
}

export const Subscription = ({ subscriptionConfig, data }: IProps) => {
  const layout = get(subscriptionConfig, ['subscription', 'rows', 0, 'layout'], 'Lavish');
  let SubscriptionComponent;
  let updatedData;

  switch (layout) {
    case 'Concrete':
      SubscriptionComponent = window?.QT_COMPONENTS?.Concrete || returnNull;
      updatedData = data?.concrete;
      break;
    case 'Minimalist':
      SubscriptionComponent = window?.QT_COMPONENTS?.Minimalist || returnNull;
      updatedData = data?.concrete;
      break;
    default:
      SubscriptionComponent = window?.QT_COMPONENTS?.Lavish || returnNull;
      updatedData = data?.lavish;
      break;
  }
  const props = {
    ...updatedData,
    config: { 'pagebuilder-config': { subscription: get(subscriptionConfig, ['subscription'], {}) } },
    enableDarkMode: get(subscriptionConfig, ['enableDarkModePreview'], false),
  };

  return <SubscriptionComponent {...props} />;
};
