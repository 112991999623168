import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const MoreVertical: React.FunctionComponent<IsvgData> = ({
  width = '24px',
  height = '24px',
  color = 'currentColor'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" />
    </svg>
  );
};

export { MoreVertical };
