import {
  PAGEBUILDER_GENERAL_UPDATE_CUSTOM_FONTS,
  PAGEBUILDER_HAVE_UNSAVED_CHANGES
} from '../reducers/builder/configReducer/actions';
import { addFontLinkTag } from '../utils/utils';

interface IUpdateFontsAction {
  type: string;
  payload: IUpdateFontsPayload;
}

interface IUpdateFontsPayload {
  primaryFont?: string;
  secondaryFont?: string;
}

type IDispatch = (args: IUpdateFontsAction) => void;

export default function updateFonts(action: IUpdateFontsAction) {
  const fontsName = action.payload.primaryFont || action.payload.secondaryFont || '';

  return (dispatch: IDispatch) => {
    addFontLinkTag(fontsName).then(() => dispatch(action));
  };
}

export const updateCustomFonts = (payload: any) => (dispatch: any) => {
  dispatch({ type: PAGEBUILDER_GENERAL_UPDATE_CUSTOM_FONTS, payload });
  dispatch({ type: PAGEBUILDER_HAVE_UNSAVED_CHANGES, payload: true });
};
