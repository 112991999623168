import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { TextField } from '@quintype/em/components/text-field';
import { TextArea } from '@quintype/em/components/text-area';
import { updateHaveUnsavedChanges, updatePageDetails } from '../../actions/builder-action';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';

interface IPageDetails {
  [key: string]: string;
}

export const PageDetails = () => {
  const dispatch = useDispatch();
  const configPath = useConfigPathFromStore();
  const { layoutName = '', description = '' } = useSelector(state => {
    return get(state, ['builder', 'config', ...configPath], {});
  });
  const handlePageDetails = (pageDetails: IPageDetails) => {
    dispatch(updatePageDetails(pageDetails));
    dispatch(updateHaveUnsavedChanges(true));
  };
  return (
    <div>
      <TextField
        label=""
        placeholder="Enter the template name..."
        value={layoutName}
        onChange={(value: string) => handlePageDetails({ layoutName: value })}
        hint="Name for this template"
      />
      <TextArea
        label="Page Description"
        placeholder="Enter the template description..."
        value={description}
        onChange={(value: string) => handlePageDetails({ description: value })}
        hint="Caption"
      />
    </div>
  );
};
