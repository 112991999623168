import { Button } from '@quintype/em/components/button';
import { Loader } from '@quintype/em/components/loader';
import { OutsideClickHandler } from '@quintype/em/components/outside-click-handler';
import { Eye } from '@quintype/em/icons/eye';
import get from 'lodash/get';
import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { setUpdatedPages, updateBackupConfig, updateHistoryActionStatus } from '../../actions/builder-action';
import { resetEditing } from '../../actions/edit-control-actions';
import { MoreVertical } from '../../atoms/action-svg';
import { Setting } from '../../atoms/setting-svg';
import { Dropdown } from '../../molecules/dropdown';
import HeaderActionDropdown from '../../molecules/header-action-dropdown';
import { HeaderDropdown } from '../../molecules/header-dropdown';
import { IConfig, IDomainList, IHistoryActionStatus, IMatch } from '../../utils/interfaces';
import { publishChanges } from '../../utils/utils';
import AutoSave from './auto-save';
import { DarkModePreview } from './dark-mode-preview/index';
import DomainMenu from './domain-menu';
import { generateOptions, getCurrentRouteLabel } from './header-utils';
import styles from './header.module.css';

enum ButtonElement {
  ButtonLabel = 'Publish'
}

interface HeaderProps extends RouteComponentProps {
  buttonLabel?: string;
  config: IConfig;
  updateBackupConfig: (config: IConfig) => void;
  updateHistoryActionStatus: (historyActionStatus: IHistoryActionStatus) => void;
  setUpdatedPages: (updatedPages: Array<string>) => void;
  publisherId: string;
  pageId: string;
  pageType: string;
  domainList: IDomainList[];
  hasEditAccess: boolean;
  resetEditing: (publisherId: string) => Promise<void>;
  match: IMatch;
}

interface IDropdownOption {
  label: string;
  link?: string;
  title?: string;
  pageType?: string;
  options?: IDropdownOption[];
}
interface Istate {
  isLoading: boolean;
  isNavigationMenuOpen: boolean;
  activeOption: string;
  isDropDownOpen: boolean;
  isActionDropDownOpen: boolean;
  showListItems: boolean;
  showErrorToaster: boolean;
}

interface IPrevProps {
  pageId?: string;
}

class HeaderBase extends React.Component<HeaderProps, Istate> {
  state: Istate = {
    isLoading: false,
    isNavigationMenuOpen: false,
    activeOption: getCurrentRouteLabel(this.props.match),
    isDropDownOpen: false,
    isActionDropDownOpen: false,
    showListItems: false,
    showErrorToaster: false
  };

  closeActionDropdown = () => {
    this.setState({ isActionDropDownOpen: false });
  };

  handleDropDown = () => {
    this.setState({ isDropDownOpen: false, showListItems: false });
  };

  partialCloseDropdown = () => {
    this.setState({ showListItems: false });
  };

  openDropDown = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.updateBackupConfig(this.props.config);
    this.setState(prevState => ({
      isDropDownOpen: !prevState.isDropDownOpen,
      showListItems: true
    }));
  };

  openActionDropDown = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState(prevState => ({ isActionDropDownOpen: !prevState.isActionDropDownOpen }));
  };

  logoutHandler = async () => {
    const { history } = this.props;
    await fetch('/logout');
    history.push('/login');
  };

  publishHandler = async () => {
    this.setState({ isLoading: true });
    const publisherId = get(this.props, ['match', 'params', 'publisherId']);
    const domain = get(this.props, ['match', 'params', 'domain']);
    const response = await publishChanges(publisherId, this.props.config, domain);

    if (response?.actionButtonStatus) {
      const actionButtonStatus = response?.actionButtonStatus;
      this.props.updateHistoryActionStatus(actionButtonStatus);
    } else {
      this.setState({ showErrorToaster: true });
      setTimeout(() => this.setState({ showErrorToaster: false }), 4000);
    }
    this.props.setUpdatedPages([]);
    setTimeout(() => this.setState({ isLoading: false }), 4000);
  };

  toggleNavigationMenu = (shouldOpen?: boolean) => {
    this.setState({
      isNavigationMenuOpen: shouldOpen || !this.state.isNavigationMenuOpen
    });
  };
  setActiveOption = (option: IDropdownOption, e: MouseEvent) => {
    e.preventDefault();
    this.setState(
      {
        activeOption: option.label,
        isNavigationMenuOpen: false
      },
      () => {
        if (option.link) {
          this.props.history.push(option.link);
        }
      }
    );
  };
  componentDidUpdate(prevProps: IPrevProps) {
    if (this.props.pageType === 'landingPage' && (this.props.pageId !== prevProps.pageId || !this.state.activeOption)) {
      const label = getCurrentRouteLabel(
        this.props.match,
        get(this.props.config, ['landingPage', 'layouts'], {}),
        this.props.pageId
      );
      if (label) {
        this.setState({
          activeOption: label
        });
      }
    }
  }

  render() {
    const { buttonLabel = ButtonElement.ButtonLabel, publisherId, config, domainList, hasEditAccess } = this.props;
    const magazineToggle = get(config, ['magazine', 'enableMagazine'], false);
    const featureToggle = { ...get(config, ['featureToggle'], {}), magazinePage: magazineToggle };
    const domain = get(this.props, ['match', 'params', 'domain']);
    const landingPagesLayouts = get(config, ['landingPage', 'layouts']);
    const customStoryTemplates = get(config, ['customStory']);
    const dropdownConfig = {
      title: 'Templates',
      label: 'Page',
      options: generateOptions(publisherId, featureToggle, landingPagesLayouts, customStoryTemplates, domain),
      active: this.state.activeOption,
      setActive: this.setActiveOption,
      isDropdownOpen: this.state.isNavigationMenuOpen,
      toggleDropdown: this.toggleNavigationMenu,
      pageType: this.props.pageType
    };

    const queryParams = this.props.pageType === 'landingPage' ? `?layoutId=${this.props.pageId}` : '';
    const storyType = get(this.props, ['match', 'params', 'storyType']);
    const previewSubPage = storyType ? `/${storyType}` : '';
    const previewLink = `/accounts/${publisherId}/${domain}/preview/${this.props.pageType}${previewSubPage}${queryParams}`;
    // Hiding preview icon from header
    const hidePreviewCases = [
      'subscription',
      'sign-in',
      'sign-up',
      'forgot-password',
      'email-otp',
      'mobile-otp',
      'reset-password',
      'enter-password',
      'meteredPaywall',
      'manage-subscriptions',
      'available-plans',
      'plan-change-overview',
      'profile-page',
      'purchased-story-page'
    ];
    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.navigation}>
              <OutsideClickHandler
                onOutsideClick={() =>
                  dropdownConfig.isDropdownOpen && dropdownConfig.toggleDropdown(!dropdownConfig.isDropdownOpen)
                }
              >
                <Dropdown config={dropdownConfig} />
              </OutsideClickHandler>
              <DarkModePreview />
            </div>
            <div className={styles.iconWrapper}>
              <DomainMenu publisherId={publisherId} domain={domain} />
              {domainList.length > 1 ? <div className={styles.divider} /> : null}
              {!hidePreviewCases.includes(this.props.pageType) && (
                <div className={styles.previewIconWrapper}>
                  <Link target="_blank" to={previewLink}>
                    <Eye />
                  </Link>
                </div>
              )}

              {hasEditAccess && (
                <div
                  onClick={event => this.openDropDown(event)}
                  className={
                    !this.state.isDropDownOpen
                      ? styles.settingsWrapper
                      : `${styles.settingColor} ${styles.settingsWrapper}`
                  }
                >
                  <Setting />
                </div>
              )}
              {this.state.isDropDownOpen && (
                // Commented OutsideClickHandler as it overrides all the button clicks inside it's inspector
                // In this case delete landing page feature stops working
                // Please add a proper fix
                <div className={this.state.showListItems ? styles.sidebar : styles.hideDropdown}>
                  <HeaderDropdown
                    handleDropdown={this.handleDropDown}
                    showListItems={this.state.showListItems}
                    partialCloseDropdown={this.partialCloseDropdown}
                  />
                </div>
              )}
              {hasEditAccess && (
                <OutsideClickHandler onOutsideClick={this.closeActionDropdown}>
                  <div
                    onClick={event => this.openActionDropDown(event)}
                    className={`${styles.moreVerticalWrapper} ${styles.settingsWrapper} ${this.state
                      .isActionDropDownOpen && styles.settingColor}`}
                  >
                    <MoreVertical />
                    {this.state.isActionDropDownOpen && (
                      <div className={styles.sidebar}>
                        <HeaderActionDropdown
                          closeDropdown={this.closeActionDropdown}
                          publisherId={this.props.publisherId}
                        />
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
              )}
              {hasEditAccess && (
                <div className={styles.buttonWrapper}>
                  <Button type="primary" variant="success" onClick={this.publishHandler}>
                    {this.state.isLoading ? (
                      <div className={styles.loaderWrapper}>
                        <Loader />
                      </div>
                    ) : (
                      buttonLabel
                    )}
                  </Button>
                </div>
              )}
              <div className={styles.buttonWrapper}>
                <Button onClick={this.logoutHandler}>Logout</Button>
              </div>
            </div>
            <AutoSave />
          </div>
        </div>
        {this.state.showErrorToaster && (
          <div className={styles.toastContainer}>
            <div className={styles.floatingBar}>
              Oops, server error countered! Changes made were not saved, please try again later!
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: { builder: { config: IConfig } }) => ({
  config: get(state, ['builder', 'config']),
  pageType: get(state, ['builder', 'pageType']),
  pageId: get(state, ['builder', 'pageId']),
  hasEditAccess: get(state, ['builder', 'editControl', 'access']),
  domainList: get(state, ['builder', 'domainList'])
});

const mapDispatchToProps = (dispatch: any) => ({
  updateBackupConfig: (config: IConfig) => dispatch(updateBackupConfig({ config })),
  updateHistoryActionStatus: (historyActionStatus: IHistoryActionStatus) =>
    dispatch(updateHistoryActionStatus(historyActionStatus)),
  setUpdatedPages: (updatedPages: Array<string>) => dispatch(setUpdatedPages(updatedPages)),
  resetEditing: (publisherId: string) => dispatch(resetEditing(publisherId))
});

const Header = connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderBase));
export { Header };
