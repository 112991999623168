import React from 'react';
import styles from './ad.module.css';
import { IAdConfig, ISelectedRow } from '../../utils/interfaces';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

interface IProps {
  currentRow: ISelectedRow;
}

const AdComponent = ({ currentRow }: IProps) => {
  const { layout, layoutLabel, config: { adType = '', targetingId = 'No value set' } = {} } = currentRow;
  const adConfig: IAdConfig = {
    Leaderboard: ['728', '90'],
    SuperLeaderboard: ['970', '90'],
    Billboard: ['970', '250'],
    Custom: ['970', '90'],
    'Story-Mrec': ['300', '250'],
    'Story-Lrec': ['336', '280']
  };
  const adDimensions = adConfig[layout] || {};
  let { pageType } = useSelector(state => get(state, 'builder'));

  return (
    <div data-test-id="ad" className={styles.adStyleWrapper}>
      <div className={styles.adStyle} style={{ width: `${adDimensions[0]}px`, height: `${adDimensions[1]}px` }}>
        <p className={styles.adProperties}>
          {layoutLabel === 'Custom' ? (
            'Custom Ad Script'
          ) : (
            <>
              adType: {adType || layoutLabel}
              <br />
              pageType: {pageType}
              <br />
              targetingId: {targetingId}
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default AdComponent;
