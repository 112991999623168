import {
  PAGEBUILDER_BREAKING_NEWS_UPDATE_THEME_ATTRIBUTES_KEY,
  PAGEBUILDER_HAVE_UNSAVED_CHANGES
} from '../reducers/builder/configReducer/actions';

interface IbreakingNewsThemeAttributes {
  [key: string]: string | boolean;
}

interface IDispatchbreakingNewsThemeArg {
  type: string;
  payload: IbreakingNewsThemeAttributes | boolean;
}

type IDispatchFooterThemeAttributes = (arg: IDispatchbreakingNewsThemeArg) => void;

export const updatebreakingNewsThemeAttributes = (payload: IbreakingNewsThemeAttributes | boolean) => (
  dispatch: IDispatchFooterThemeAttributes
) => {
  dispatch({ type: PAGEBUILDER_BREAKING_NEWS_UPDATE_THEME_ATTRIBUTES_KEY, payload });
  dispatch({ type: PAGEBUILDER_HAVE_UNSAVED_CHANGES, payload });
};
