import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBackupConfig } from '../../actions/builder-action';
import ActionButtons from '../../molecules/action-buttons';
import AdInspector from '../../molecules/ad-inspector';
import PBInspector from '../../molecules/inspector';
import RowInspector from '../../molecules/row-inspector';
import { SelectRow } from '../../molecules/select-row';
import WidgetInspector from '../../molecules/widget-inspector';
import { IComponentMeta, IConfig, ISelectedRow } from '../../utils/interfaces';
import RowWrapperActions from './row-wrapper-actions';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';
import styles from './row-wrapper.module.css';
import { defaultDarkTheme } from '../../constant';
import { openSelectRowInspector } from '../../actions/select-row-inspector-action';
import { updateHaveLayoutUpdates } from '../../actions/builder-action';
interface InspectorProps {
  currentRow: ISelectedRow;
  isHeaderRow?: boolean;
  componentMeta?: IComponentMeta[];
}

interface IProps {
  children: React.ReactNode;
  currentRow: ISelectedRow;
  type: string;
  index: number;
  inspectorChildren?: (args: InspectorProps) => void;
  inspectorTitle?: string;
  updateHaveUnsavedChange?: () => void;
  hideActionButtons?: boolean;
  isHeaderRow?: boolean;
  componentMeta?: IComponentMeta[];
  enableDarkModePreview?: boolean;
  hideSettingsBtn?: boolean;
}

interface IPBState {
  builder: {
    config: IConfig;
    haveUnsavedChanges: boolean;
    pageType: string;
    pageId: string;
  };
}

const RowWrapper = ({
  children,
  currentRow,
  type,
  index,
  inspectorTitle,
  hideActionButtons = false,
  isHeaderRow = false,
  componentMeta,
  enableDarkModePreview = false
}: IProps) => {
  const [showSettingsInspector, toggleSettingsInspector] = useState(false);
  const dispatch = useDispatch();
  const { config, haveUnsavedChanges, pageType } = useSelector((state: IPBState) => get(state, ['builder']));
  const configPath = useConfigPathFromStore();
  const backgroundColor = useSelector((state: IPBState) => {
    return enableDarkModePreview
      ? get(state, ['builder', 'config', ...configPath, 'pageSetting', 'darkBgColor'], defaultDarkTheme)
      : get(state, ['builder', 'config', ...configPath, 'pageSetting', 'bgColor'], '');
  });
  const rowSettingsHandler = () => {
    dispatch(updateBackupConfig({ config }));
    toggleSettingsInspector(true);
  };
  const getSelectedComponent = () => {
    switch (type) {
      case 'Ads':
        return <AdInspector {...currentRow} isHeaderRow={isHeaderRow} />;
      case 'widget':
        return <WidgetInspector {...currentRow} isHeaderRow={isHeaderRow} />;
      default:
        return <RowInspector {...currentRow} componentMeta={componentMeta} />;
    }
  };
  const plusButtonStyle = index === 0 ? styles.firstTopAddRowWrapper : styles.restTopAddRowWrapper;

  const hideReplaceBtnForLayoutLabels = ['Purchased Stories Layout'];
  const hideReplaceBtnForLayouts = [
    'ArrowPageIntroductionCard',
    'AuthorsListComponent',
    'SearchPage',
    'Concrete',
    'Lavish',
    'Minimalist',
    'SignIn',
    'ForgotPassword',
    'SignUp',
    'MobileOtp',
    'EmailOtp',
    'EnterPassword',
    'ResetPassword',
    'ManageSubscriptions',
    'AvailablePlans',
    'PlanChangeOverview',
    'UserProfilePage',
    'PurchasePageIntroductionCard'
  ];
  const editionPageRows = [
    'ArrowFourColGrid',
    'ArrowThreeColSevenStory',
    'ArrowMagazineHeaderCard',
    'ArrowMagazineEditions',
    'ArrowMagazineWidget',
    'ArrowPageIntroductionCard',
    'Concrete',
    'Lavish',
    'Minimalist',
    'SignIn',
    'ForgotPassword',
    'SignUp',
    'MobileOtp',
    'EmailOtp',
    'EnterPassword',
    'ResetPassword',
    'ManageSubscriptions',
    'AvailablePlans',
    'PlanChangeOverview',
    'UserProfilePage',
    'PurchasePageIntroductionCard'
  ];

  const hideReplaceBtnForComp =
    pageType === 'issue-landing' || pageType === 'magazine-landing' || pageType === 'archive'
      ? editionPageRows.includes(currentRow.layout)
      : hideReplaceBtnForLayouts.includes(currentRow.layout) ||
        hideReplaceBtnForLayoutLabels.includes(currentRow.layoutLabel);
  const canShowActionBtnForLayout = () => {
    // action button will be hidden on these layouts
    const layoutsToHideActionButton = ['ArrowAuthorIntroductionCard', 'ArrowTagIntroductionCard'];
    return !layoutsToHideActionButton.includes(currentRow.layout);
  };

  const getActionsList = () => {
    switch (pageType) {
      case 'subscription':
        return [
          {
            action: 'replace',
            handler: () => {
              dispatch(
                openSelectRowInspector({
                  type,
                  action: 'update',
                  rowId: currentRow.rowId,
                  isHeaderRow: isHeaderRow || false
                })
              );
              dispatch(updateHaveLayoutUpdates(true));
            }
          }
        ];
      default:
        return [
          {
            action: 'setting',
            handler: rowSettingsHandler
          }
        ];
    }
  };

  const defaultRowPages = [
    'issue-landing',
    'magazine-landing',
    'archive',
    'subscription',
    'manage-subscriptions',
    'available-plans',
    'plan-change-overview',
    'sign-in',
    'sign-up',
    'forgot-password',
    'email-otp',
    'mobile-otp',
    'reset-password',
    'enter-password',
    'profile-page',
    'purchased-story-page'
  ];

  return (
    <div className={styles.rowWrapper} style={{ backgroundColor }}>
      {defaultRowPages.includes(pageType) && editionPageRows.includes(currentRow.layout) ? (
        children
      ) : (
        <>
          <div className={plusButtonStyle}>
            <SelectRow rowId={currentRow.rowId} isHeaderRow={isHeaderRow} />
          </div>
          {children}
          <div className={styles.bottomAddRowWrapper}>
            <SelectRow rowId={currentRow.rowId} down isHeaderRow={isHeaderRow} />
          </div>
        </>
      )}
      {!hideActionButtons && canShowActionBtnForLayout() && (
        <>
          <div className={styles.buttonGroup}>
            <div className={styles.buttonGroupChild}>
              {!hideReplaceBtnForComp && (
                <RowWrapperActions currentRow={currentRow} type={type} index={index} isHeaderRow={isHeaderRow} />
              )}
              <ActionButtons actionList={getActionsList()} />
            </div>
            <div className={styles.hoverOverlay} />
          </div>
          <div className={styles.hoverOverlay} />
          {showSettingsInspector && (
            <PBInspector
              title={inspectorTitle}
              onActionButtonClick={() => toggleSettingsInspector(false)}
              isActive={showSettingsInspector}
              isActionButtonDisabled={!haveUnsavedChanges}
              onClose={() => toggleSettingsInspector(false)}
            >
              {getSelectedComponent()}
            </PBInspector>
          )}
        </>
      )}
    </div>
  );
};

export default RowWrapper;
