import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PBInspector from '../inspector';
import Manage from '../manage';
import Styling from '../styling';
import { Visual } from '../visual';
import { FixedTabList } from '@quintype/em/components/tabs';

enum TabElements {
  Visual = 'visual',
  Styling = 'styling',
  Manage = 'manage',
  VisualLabel = 'Visual',
  StylingLabel = 'Styling',
  ManageLabel = 'Manage'
}

const tabs = [
  {
    value: TabElements.Visual,
    label: TabElements.VisualLabel
  },
  {
    value: TabElements.Styling,
    label: TabElements.StylingLabel
  },
  {
    value: TabElements.Manage,
    label: TabElements.ManageLabel
  }
];

interface sidebarProps {
  isActive: boolean;
  title: string;
  onCloseHandler: () => void;
  haveUnsavedChanges?: boolean;
}

interface sidebarState {
  tabName: string;
  isSave: boolean;
  showConfirmationPopup: boolean;
}

class SidebarBase extends React.Component<sidebarProps, sidebarState> {
  constructor(props: sidebarProps) {
    super(props);
    this.state = {
      tabName: TabElements.Visual,
      isSave: false,
      showConfirmationPopup: false
    };
  }

  toggleConfirmationPopup = (value: boolean) => {
    this.setState({
      showConfirmationPopup: value
    });
  };

  onChangeHandler = (value: string) => {
    this.setState({
      tabName: value
    });
  };

  actionButtonHandler = () => {
    this.setState({
      isSave: true
    });
    this.props.onCloseHandler();
  };

  updatedCloseHandler = () => {
    this.props.onCloseHandler();
    this.setState({
      isSave: false
    });
  };

  tabListItems = () => {
    if (this.state.tabName === TabElements.Visual) {
      return <Visual />;
    }
    if (this.state.tabName === TabElements.Styling) {
      return <Styling />;
    }
    return <Manage toggleConfirmationPopup={this.toggleConfirmationPopup} />;
  };

  render() {
    const { title, isActive, haveUnsavedChanges } = this.props;

    return (
      <PBInspector
        title={title}
        isActive={isActive}
        onActionButtonClick={this.actionButtonHandler}
        isActionButtonDisabled={!haveUnsavedChanges}
        onClose={this.updatedCloseHandler}
        toggleConfirmationPopup={this.toggleConfirmationPopup}
        showConfirmationPopup={this.state.showConfirmationPopup}
      >
        <FixedTabList tabs={tabs} value={this.state.tabName} onChange={this.onChangeHandler} />
        {this.tabListItems()}
      </PBInspector>
    );
  }
}

interface IState {
  builder: {
    haveUnsavedChanges: boolean;
  };
}

const mapStateToProps = (state: IState) => ({
  haveUnsavedChanges: get(state, ['builder', 'haveUnsavedChanges'], false)
});

const Sidebar = connect(mapStateToProps)(SidebarBase);

export { Sidebar };
