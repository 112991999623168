export const backupConfigReducer = (state: any = {}, action: { type: string; payload: any }) => {
  switch (action.type) {
    case 'PAGEBUILDER.BACKUP.UPDATE':
      return { ...action.payload.config };
    case 'PAGEBUILDER.BACKUP.CLEAR':
      return {};
    default:
      return state;
  }
};
