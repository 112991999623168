import { PAGEBUILDER_SET_PAGETYPE } from '../configReducer/actions';
const pageTypeReducer = (
  state: string = '',
  action: {
    payload: string;
    type: string;
  }
) => {
  switch (action.type) {
    case PAGEBUILDER_SET_PAGETYPE:
      return action.payload;
    default:
      return state;
  }
};

export { pageTypeReducer };
