import { FieldLabel } from '@quintype/em/components/field-label';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import { IPBState } from '../../utils/interfaces';
import styles from './logo-navigator.module.css';

interface IProps {
  layout: string;
  updateStore: (key: string, value: string) => any;
}

export const LogoNavigator = ({ layout = '', updateStore }: IProps) => {
  const { navigateFooterLogo = 'homeUrl', navigateHeaderLogo = 'homeUrl', logoUrl = '' } = useSelector(
    (state: IPBState) => get(state, ['builder', 'config', layout]) || {}
  );
  const isHeaderLayout = layout === 'header';
  const selectedKey = isHeaderLayout ? 'navigateHeaderLogo' : 'navigateFooterLogo';
  const selectedValue = isHeaderLayout ? navigateHeaderLogo : navigateFooterLogo;
  return (
    <div className={styles.wrapper}>
      <FieldLabel label="On click go to" />
      <Radio
        name={selectedKey}
        align="vertical"
        selected={selectedValue}
        onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) => {
          updateStore(selectedKey, (e.target as HTMLInputElement).value);
        }}
      >
        <RadioOption id="1" value="homeUrl">
          Home Page
        </RadioOption>
        <RadioOption id="2" value="customUrl">
          A Custom URL
        </RadioOption>
      </Radio>
      {selectedValue === 'customUrl' && (
        <div className={styles.textFieldWrapper}>
          <TextField
            label="Add URL"
            placeholder="Custom URL here"
            value={logoUrl}
            onChange={(value: any) => updateStore('logoUrl', value)}
          />
        </div>
      )}
    </div>
  );
};
