import Select from '@quintype/em/components/select';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveLayoutUpdates } from '../../../actions/builder-action';
import { updateStoryAttribute } from '../../../actions/story-action';
import FullScreenModalWrapper from '../../../atoms/full-screen-modal-wrapper';
import MasonryLayouts from '../../../molecules/masonry-layouts';
import { IPBState } from '../../../utils/interfaces';
import styles from './story-layout-selector.module.css';

interface IStoryLayoutSelector {
  toggleLayoutSelector: Function;
  type: string;
  customStoryType?: {
    customStoryTypeName: string;
    customStoryTypeBaseName?: string;
    changeStoryType: (type: string) => void;
    selectStoryTemplate?: (storyType: string, templateName: string) => void;
  };
}

const storyTypeOptions = [
  { label: 'Text Story', value: 'text' },
  { label: 'Photo Story', value: 'photo' },
  { label: 'Video Story', value: 'video' },
  { label: 'Live Blog Story', value: 'liveBlog' },
  { label: 'Listicle Story', value: 'listicle' }
];

const StoryLayoutSelector = ({ toggleLayoutSelector, type, customStoryType }: IStoryLayoutSelector) => {
  const storyPageType = `${type}-story`;
  const storyLayouts = useSelector((state: IPBState) => get(state, ['ahead', 'storyComponentMetaData', storyPageType]));
  const dispatch = useDispatch();
  const layoutSelectHandler = (layoutName: string) => {
    toggleLayoutSelector(false);
    const data: { template: string; storyType?: string } = { template: layoutName };
    if (customStoryType) {
      data['storyType'] = type;
    }
    dispatch(
      updateStoryAttribute({
        type: customStoryType ? `${customStoryType.customStoryTypeName}-story` : storyPageType,
        data: data as any
      })
    );
    dispatch(updateHaveLayoutUpdates(true));
  };
  return (
    <FullScreenModalWrapper onClose={() => toggleLayoutSelector(false)}>
      <div className={styles.wrapper} data-test-id="story-layout-selector-modal">
        <div className={styles.sidebar} data-test-id="story-layout-selector-modal-side-controls">
          <form action="#">
            {customStoryType ? (
              <>
                <h3 className={styles.templatesHeading}>Story Templates</h3>
                <div
                  className={styles.templatesDescription}
                >{`Choose the story layout for '${customStoryType.customStoryTypeName}' story type from given designs`}</div>
                <Select
                  label="Story Type"
                  options={storyTypeOptions}
                  value={storyTypeOptions.find(option => option.value === type)}
                  onChange={({ value }: { value: string }) => customStoryType.changeStoryType(value)}
                />
              </>
            ) : (
              <h3 className={styles.templatesHeading}>{`${type} Story Templates`}</h3>
            )}
          </form>
        </div>
        <div className={styles.main} data-test-id="story-layout-selector-modal-pick-layout">
          <MasonryLayouts
            handleLayoutSelect={(type: string, componentName: string, componentLabel: string) => {
              if (customStoryType && customStoryType.selectStoryTemplate) {
                customStoryType.selectStoryTemplate(type, componentName);
              } else {
                layoutSelectHandler(componentName);
              }
            }}
            layoutsList={storyLayouts}
            type={type}
            customStoryTypeName={customStoryType ? customStoryType.customStoryTypeName : undefined}
            customStoryTypeBaseName={
              customStoryType && customStoryType.customStoryTypeBaseName
                ? customStoryType.customStoryTypeBaseName
                : undefined
            }
          />
        </div>
      </div>
    </FullScreenModalWrapper>
  );
};

export default StoryLayoutSelector;
