import React from 'react';

export const CopyIcon = () => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="M2.871 3.765a.884.884 0 0 0-.883.884v8.48c0 .486.396.883.883.883h8.479a.885.885 0 0 0 .884-.884V4.65a.885.885 0 0 0-.884-.884H2.87zm8.479 10.902H2.87a1.54 1.54 0 0 1-1.538-1.539V4.65c0-.848.69-1.538 1.538-1.538h8.479c.848 0 1.539.69 1.539 1.538v8.48a1.54 1.54 0 0 1-1.54 1.538zM3.11 6.564h8v-.786h-8v.786zm0 2.666h8v-.786h-8v.786zm0 2.667h3.556v-.786H3.11v.786zM13.116 1.333H4.57c-.677 0-1.249.44-1.459 1.046h.745a.877.877 0 0 1 .714-.386h8.546c.491 0 .89.4.89.89v8.543a.875.875 0 0 1-.398.722v.74a1.547 1.547 0 0 0 1.059-1.462V2.883c0-.855-.696-1.55-1.551-1.55"
          id="a"
        />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#5F6978" xlinkHref="#a" />
        <path mask="url(#b)" d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
};
