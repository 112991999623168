import '@quintype/em/global.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Theme } from '../src/molecules/theme';
import { App } from './containers/app';
import './index.css';
import configureStore from './store';
/* eslint import/no-webpack-loader-syntax: off */
/* tslint:disable */
require('expose-loader?React!react');
require('expose-loader?ReactDOM!react-dom');
require('expose-loader?Redux!redux');
require('expose-loader?ReactRedux!react-redux');
/* tslint:enable */

const configuredStore = configureStore();
ReactDOM.render(
  <Provider store={configuredStore}>
    <Theme />
    <App />
  </Provider>,
  document.getElementById('root')
);
