import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBackupConfig } from '../../actions/builder-action';
import returnNull from '../../atoms/null';
import { defaultDarkTheme } from '../../constant';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';
import ActionButtons from '../../molecules/action-buttons';
import PBInspector from '../../molecules/inspector';
import { IBreakingNews, IPBState, ISelectedRow, Window } from '../../utils/interfaces';
import styles from './ahead-breaking-news.module.css';
import { BreakingNewsSetting } from './breaking-news-settings';
import { Stories } from './breakingNews-data';

declare var window: Window;

interface IBuilder {
  selectedRows: ISelectedRow[];
}
interface IState {
  builder: IBuilder;
}

export const AheadBreakingNews = () => {
  const [showSettingsInspector, toggleSettingsInspector] = useState(false);
  const dispatch = useDispatch();

  const configPath = useConfigPathFromStore();
  const pickBreakingNews = window.QT_COMPONENTS && window.QT_COMPONENTS.BreakingNewsView;
  const ComponentName = (pickBreakingNews && pickBreakingNews) || returnNull;
  const { config, haveUnsavedChanges } = useSelector((state: IPBState) => get(state, ['builder']));
  const { language } = useSelector((state: IPBState) => {
    return get(state, ['qt', 'config']);
  });

  const { enableDarkModePreview = false, breakingNews } = useSelector((state: IPBState) =>
    get(state, ['builder', 'config'])
  );

  const { template = 'template1', scrollSpeed = '', title = 'Breaking News', delimiter = 'dot' }: IBreakingNews =
    breakingNews || {};

  const backgroundColor = useSelector((state: IState) => {
    return enableDarkModePreview
      ? get(state, ['builder', 'config', ...configPath, 'pageSetting', 'darkBgColor'], defaultDarkTheme)
      : get(state, ['builder', 'config', ...configPath, 'pageSetting', 'bgColor'], '');
  });

  const closeHandler = () => {
    toggleSettingsInspector(false);
  };

  const breakingnewsSettingsHandler = () => {
    dispatch(updateBackupConfig({ config }));
    toggleSettingsInspector(true);
  };

  return (
    <div className={styles.breakingNews} style={{ backgroundColor }}>
      <div className={styles.buttonGroup}>
        <ActionButtons
          actionList={[
            {
              action: 'setting',
              handler: breakingnewsSettingsHandler
            }
          ]}
        />
      </div>
      <PBInspector
        title="Breaking News Settings"
        onActionButtonClick={closeHandler}
        isActive={showSettingsInspector}
        isActionButtonDisabled={!haveUnsavedChanges}
        onClose={closeHandler}
      >
        <BreakingNewsSetting />
      </PBInspector>
      <ComponentName
        breakingNews={Stories}
        config={{
          'pagebuilder-config': {
            breakingNews: { template, scrollSpeed, title, delimiter }
          },
          language
        }}
      />
    </div>
  );
};
