import { Button } from '@quintype/em/components/button';
import { TextArea } from '@quintype/em/components/text-area';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { addUserToPublisher } from '../../utils/utils';

export const UsersAndRoles = () => {
  const [userEmail, setUserEmail] = useState('');
  const [hint, setHint] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { publisherId = '' } = useParams<{ publisherId: string }>();
  const onClick = async () => {
    try {
      const { message = '', error = '' } = await addUserToPublisher(userEmail, publisherId, 'member');
      setErrorMessage(error);
      setHint(message);
    } catch (err) {
      setErrorMessage('Unable to create user, request failed');
    }
  };
  return (
    <>
      <TextArea
        label="Add Users"
        value={userEmail}
        hint={hint}
        onChange={setUserEmail}
        errorMessage={errorMessage}
        placeholder="Enter user email id…"
      />
      <Button type="primary" onClick={onClick}>
        Add User
      </Button>
    </>
  );
};
