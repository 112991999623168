import React from 'react';
import styles from './iconButton.module.css';

interface IButton {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const IconButton = function({ children, className = '', onClick = () => null, ...props }: IButton) {
  return (
    <div className={styles.component}>
      <button {...props} className={`${className} ${styles.button}`} onClick={onClick}>
        {children}
      </button>
    </div>
  );
};

export default IconButton;
