import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Reset from '../../atoms/reset-svg';
import styles from './header-action-dropdown.module.css';
import { Undo } from '../../atoms/undo-svg';
import { changeConfigVersion } from '../../utils/utils';
import { useParams } from 'react-router';
import { updatePBConfig, updateHistoryActionStatus } from '../../actions/builder-action';
import { stopEditing } from '../../actions/edit-control-actions';
import Stop from '../../atoms/stop-svg';

interface IProps {
  closeDropdown: () => void;
  publisherId: string;
}

const HeaderActionDropdown = (props: IProps) => {
  const { publisherId = '', domain = '' } = useParams();
  const dispatch = useDispatch();
  const { undo, redo, reset } = useSelector((state: any) => get(state, ['builder', 'historyActionStatus']));

  const actionHandler = async (action: string) => {
    const { config, actionButtonStatus } = await changeConfigVersion(publisherId, domain, action);
    !isEmpty(config) && dispatch(updatePBConfig(config));
    !isEmpty(actionButtonStatus) && dispatch(updateHistoryActionStatus(actionButtonStatus));
  };

  return (
    <div className={styles.wrapper}>
      <ul className={styles.items}>
        {
          <li className={undo && styles.enable} onClick={() => undo && actionHandler('undo')}>
            <div className={styles.list}>
              <span className={styles.listIcon}>
                <Undo />
              </span>
              Undo
            </div>
          </li>
        }
        {
          <li className={redo && styles.enable} onClick={() => redo && actionHandler('redo')}>
            <div className={styles.list}>
              <span className={styles.listIcon}>
                <Undo redo />
              </span>
              Redo
            </div>
          </li>
        }
        <li className={reset && styles.enable} onClick={() => reset && actionHandler('reset')}>
          <div className={styles.list}>
            <span className={styles.listIcon}>
              <Reset />
            </span>
            Revert To Published
          </div>
        </li>
        <li className={styles.enable} onClick={() => dispatch(stopEditing(publisherId))}>
          <div className={styles.list}>
            <span className={styles.listIcon}>
              <Stop />
            </span>
            Stop Building
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HeaderActionDropdown;
