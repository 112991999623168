import { FixedTabList } from '@quintype/em/components/tabs';
import fetchIntercept from 'fetch-intercept';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateBackupConfig } from '../../../actions/builder-action';
import { defaultDarkTheme } from '../../../constant';
import ActionButtons from '../../../molecules/action-buttons';
import AdComponent from '../../../molecules/ad';
import PBInspector from '../../../molecules/inspector';
import StoryInspector from '../../../molecules/row-inspector/story-inspector';
import StorySlotInspector from '../../../molecules/row-inspector/story-slot-inspector';
import { SelectRow } from '../../../molecules/select-row';
import WidgetComponent from '../../../molecules/widget-component';
import { COLLECTION_DATA } from '../../../static-data/collection-data';
import { IConfig, Window } from '../../../utils/interfaces';
import StoryLayoutSelector from '../story-layout-selector';
import { addSlotsToStory } from '../utils';
import { authors, getDummyStoryData } from './dummy-data-utils';
import styles from './story-page.module.css';

enum TabElements {
  General = 'general',
  StorySlot = 'story slot'
}

const tabs = [
  {
    value: TabElements.General,
    label: TabElements.General.toUpperCase()
  },
  {
    value: TabElements.StorySlot,
    label: TabElements.StorySlot.toUpperCase()
  }
];

declare var window: Window;
interface IProps {
  type: string;
  enableDarkModePreview: boolean;
}
interface IPBState {
  builder: {
    config: IConfig;
    haveUnsavedChanges: boolean;
  };
  isAccordionActive: false;
}

export const defaultStoryTypes = ['text-story', 'video-story', 'photo-story', 'liveBlog-story', 'listicle-story'];

export const StoryPage = ({ type, enableDarkModePreview }: IProps) => {
  const [showLayoutSelector, toggleLayoutSelector] = useState(false);
  const [showSettingsInspector, toggleSettingsInspector] = useState(false);
  const { config, haveUnsavedChanges } = useSelector((state: IPBState) => get(state, ['builder']));
  const configRef = useRef(config);
  const typeRef = useRef(type);
  const storyPageType = `${type}-story`;
  const isCustomStoryType = !defaultStoryTypes.includes(storyPageType);

  const storyStoreField = isCustomStoryType ? 'customStory' : 'story';
  const dispatch = useDispatch();
  const customStoryTypeBaseName = get(config, ['customStory', storyPageType, 'storyType'], 'text');
  const [selectStoryPageType, setSelectStoryPageType] = useState(customStoryTypeBaseName);

  let data = getDummyStoryData(isCustomStoryType ? customStoryTypeBaseName : type, config);
  if (isCustomStoryType && type !== 'newsElsewhere' && data.story) {
    const customStoryName = type.replace(/([A-Z])/g, letter => `-${letter[0].toLowerCase()}`);
    data = { ...data, story: { ...data.story, 'story-template': customStoryName } };
  }
  const [tabName, handleTabName] = useState(TabElements.General);

  const storyWithSlots = addSlotsToStory(
    get(data, ['story', 'cards'], []),
    get(config, [storyStoreField, storyPageType, 'story-slots'], {}),
    data
  );

  useEffect(() => {
    configRef.current = config;
  }, [config]);

  useEffect(() => {
    typeRef.current = type;
  }, [type]);

  useEffect(() => {
    setSelectStoryPageType(customStoryTypeBaseName);
  }, [customStoryTypeBaseName]);

  useEffect(() => {
    const unregister = fetchIntercept.register({
      response(response) {
        // Modify the reponse object
        const { request, url } = response;

        if (url.includes('/api/v1/stories') && request.method.toLowerCase() === 'get') {
          const authorDetails = get(configRef.current, [
            'story',
            `${typeRef.current}-story`,
            'settings',
            'authorDetails'
          ]);
          const authorStyle = get(authorDetails, ['template'], 'default');
          const clonedResponse = response.clone();
          let json;
          if (authorStyle === 'centerAligned' || authorStyle === 'leftAligned') {
            json = () => clonedResponse.json().then(data => ({ story: { ...data.story, authors: [authors[0]] } }));
          } else {
            json = () => clonedResponse.json().then(data => ({ story: { ...data.story, authors } }));
          }

          response.json = json;
        }

        return response;
      }
    });

    return () => {
      unregister();
    };
  }, []);

  const changeSelectStoryType = (type: string) => {
    setSelectStoryPageType(type);
  };

  const onChangeHandler = (value: any) => {
    handleTabName(value);
  };

  const closeStorySettings = () => {
    toggleSettingsInspector(false);
  };

  const storySettingsHandler = () => {
    dispatch(updateBackupConfig({ config }));
    toggleSettingsInspector(true);
  };

  const storyRows = useSelector((state: IPBState) =>
    get(
      state,
      ['builder', 'config', storyStoreField, storyPageType, 'rows'],
      [
        {
          rowId: 1603877816292,
          type: 'story',
          config: {},
          pageType: 'story'
        }
      ]
    )
  );

  const StoryPageSettings = useSelector((state: any) => {
    const settings = get(state, ['builder', 'config', storyStoreField, storyPageType, 'settings'], {});
    const {
      showSection = true,
      showReadTime = false,
      enablePublishedTime = true,
      enableUpdatedTime = false,
      template: authorTemplate = 'default',
      showBio = false,
      showImage = true,
      showName = true,
      verticalShare = '',
      shareIconType = 'plain-color-svg',
      buttonText = '',
      theme = '',
      showGuestAuthorName = false,
      showLabels = false,
      showGuestAuthorImage = false,
      darkTheme = defaultDarkTheme
    } = settings;
    return {
      showSection,
      enablePublishedTime,
      enableUpdatedTime,
      authorTemplate,
      showBio,
      showImage,
      showName,
      verticalShare,
      shareIconType,
      buttonText,
      theme,
      showReadTime,
      darkTheme,
      showGuestAuthorImage,
      showGuestAuthorName,
      showLabels
    };
  });
  const defaultLayout = storyPageType === 'photo-story' ? 'hero-priority-left' : 'default';
  const selectedLayout = get(config, [storyStoreField, storyPageType, 'template'], defaultLayout);

  const tabListItems = () => {
    if (tabName === TabElements.General) {
      return (
        <StoryInspector
          layout={selectedLayout}
          config={StoryPageSettings}
          typeName={type}
          customStoryTypeBaseName={isCustomStoryType ? customStoryTypeBaseName : undefined}
        />
      );
    }
    return <StorySlotInspector storyType={storyPageType} storyTemplate={selectedLayout} />;
  };

  const renderStory = () => {
    if (window.QT_STORY_COMPONENTS) {
      const { asideSlots } = get(config, [storyStoreField, storyPageType], {});
      const StoryPageComponent = window.QT_STORY_COMPONENTS.StoryPage;
      const renderStoryType = (props: any) => {
        const templateConfig = {
          asideCollection: {
            config: {
              title: 'Related Stories',
              theme: enableDarkModePreview ? StoryPageSettings.darkTheme : StoryPageSettings.theme,
              showAuthor: true,
              showTime: true
            },
            data: data.staticRelatedStories,
            slots: asideSlots || [
              {
                type: 'ad',
                layout: 'Story-Mrec',
                layoutLabel: 'Story-Mrec',
                config: {
                  targetingId: 'story-mrec-aside-collections'
                }
              },
              {
                type: 'collection',
                collectionSlug: COLLECTION_DATA
              }
            ]
          },
          pageBuilderVisibleCards: 3
        };

        const storyComponentName = `${upperFirst(camelCase(isCustomStoryType ? customStoryTypeBaseName : type))}Story`;
        const Story = window.QT_STORY_COMPONENTS[storyComponentName];
        props = {
          ...props,
          templateConfig,
          adComponent: (currentRow: any) => {
            return <AdComponent currentRow={currentRow} />;
          },
          widgetComp: WidgetComponent,
          storyStoreField,
          storyPageType,
          isPbStoryView: true // added this to inform madrid that pb is using the component as it requires re-rendering
        };
        return <Story {...{ ...props, enableDarkMode: enableDarkModePreview }} />;
      };
      return (
        <StoryPageComponent
          data={storyWithSlots}
          config={config}
          renderStoryPageContent={renderStoryType}
          preview={true}
        />
      );
    }
    return null;
  };

  const areSlotsAddedAbove = storyRows[0].type === 'story';
  const areSlotsAddedBelow = storyRows[storyRows.length - 1].type === 'story';

  return (
    <div
      className={[
        areSlotsAddedAbove ? styles.slotPaddingTop : '',
        areSlotsAddedBelow ? styles.slotPaddingBottom : ''
      ].join(' ')}
    >
      {areSlotsAddedAbove && <SelectRow />}
      <div className={styles.storyWrapper}>
        <div className={styles.buttonGroup}>
          <ActionButtons
            actionList={[
              {
                action: 'replace',
                handler: () => toggleLayoutSelector(true)
              },
              {
                action: 'setting',
                handler: storySettingsHandler
              }
            ]}
          />
        </div>

        {showLayoutSelector &&
          (isCustomStoryType ? (
            <StoryLayoutSelector
              type={selectStoryPageType}
              toggleLayoutSelector={toggleLayoutSelector}
              customStoryType={{
                customStoryTypeName: type,
                customStoryTypeBaseName,
                changeStoryType: changeSelectStoryType
              }}
            />
          ) : (
            <StoryLayoutSelector type={type} toggleLayoutSelector={toggleLayoutSelector} />
          ))}
        <PBInspector
          title={`${type} Story Settings`}
          onActionButtonClick={closeStorySettings}
          isActive={showSettingsInspector}
          isActionButtonDisabled={!haveUnsavedChanges}
          onClose={closeStorySettings}
        >
          <FixedTabList tabs={tabs} value={tabName} onChange={onChangeHandler} />
          {tabListItems()}
        </PBInspector>
        <div>{renderStory()}</div>
      </div>
      {areSlotsAddedBelow && <SelectRow down={true} rowId={storyRows ? storyRows[storyRows.length - 1].rowId : 0} />}
    </div>
  );
};
