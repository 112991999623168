import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updateThirdPartyConfig } from '../../actions/builder-action';

interface IStore {
  builder: {
    config: {
      general: {
        thirdPartyIntegrations: {
          gaId?: string;
          gtmId?: string;
          widgetCode?: string;
          manifestLogoSrc?: string;
          customMetadata?: {
            'google-site-verification'?: { name: string; content: string };
            'yandex-verification'?: { name: string; content: string };
            'facebook-verification'?: { property: string; content: string };
          };
          adsenseClientId?: string;
        };
      };
    };
  };
}

export const ThirdPartyIntegrations = () => {
  const {
    gaId = '',
    gtmId = '',
    widgetCode = '',
    adsenseClientId = '',
    customMetadata = {},
    manifestLogoSrc = ''
  } = useSelector((state: IStore) => {
    const {
      builder: {
        config: {
          general: { thirdPartyIntegrations = {} }
        }
      }
    } = state;
    return thirdPartyIntegrations;
  });

  const {
    'google-site-verification': googleSiteVerification,
    'yandex-verification': yandexVerification,
    'facebook-verification': facebookVerification
  } = customMetadata;

  const googleContent = get(googleSiteVerification, ['content'], '');
  const yandexContent = get(yandexVerification, ['content'], '');
  const fbContent = get(facebookVerification, ['content'], '');
  const dispatch = useDispatch();

  const updateSettings = (setting: string, key: string, value: any) => {
    dispatch(updateThirdPartyConfig({ type: setting, config: { [key]: value } }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const data = [
    {
      label: 'GA ID',
      placeholder: 'Enter your GA Tracking Id',
      value: gaId,
      onChange: { setting: '', keyName: 'gaId' }
    },
    { label: 'GTM ID', value: gtmId, onChange: { setting: '', keyName: 'gtmId' } },
    { label: 'Widget Code', value: widgetCode, onChange: { setting: '', keyName: 'widgetCode' } },
    {
      label: 'Google Site Verification Tag',
      value: googleContent,
      onChange: { setting: 'google-site-verification', keyName: 'content' }
    },
    {
      label: 'Yandex Site Verification Tag',
      value: yandexContent,
      onChange: { setting: 'yandex-verification', keyName: 'content' }
    },
    {
      label: 'Facebook Article Verification Tag',
      value: fbContent,
      onChange: { setting: 'facebook-verification', keyName: 'content' }
    },
    {
      label: 'Adsense Client ID',
      value: adsenseClientId,
      onChange: { setting: '', keyName: 'adsenseClientId' }
    },
    {
      label: 'Manifest Logo Source',
      placeholder: 'Enter your logo source url for manifest icons',
      value: manifestLogoSrc,
      onChange: { setting: '', keyName: 'manifestLogoSrc' }
    }
  ];

  return (
    <>
      {data.map((item, index) => {
        const { label, placeholder = '', value, onChange } = item;
        return (
          <TextField
            label={label}
            placeholder={placeholder}
            value={value}
            key={index}
            onChange={(keyValue: any) => onChange && updateSettings(onChange.setting, onChange.keyName, keyValue)}
          />
        );
      })}
    </>
  );
};
