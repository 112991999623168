import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { Callout } from '@quintype/em/components/callout';
import { TextArea } from '@quintype/em/components/text-area';
import { TextField } from '@quintype/em/components/text-field';
import { Checkbox } from '@quintype/em/components/checkbox';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import { updateHeaderRowsList } from '../../actions/header-action';
import { updateRowsList } from '../../actions/row-action';
import { ISelectedRow } from '../../utils/interfaces';
import { checkValidEncodedCustomCode, encodeCustomCode } from '../../utils/utils';
import { Separator } from '../../atoms/separator';
import styles from './widget-inspector.module.css';

const WidgetInspector = ({
  rowId,
  config: { customCode = '', targetingId = '', aheadNxtWidgetPlaceholderHeight = '', centreAlignWidget = true } = {},
  isHeaderRow = false
}: ISelectedRow) => {
  const dispatch = useDispatch();
  const aheadNxtEnabled = useSelector((state: any) =>
    get(state, ['builder', 'config', 'general', 'aheadNxtEnabled'], false)
  );

  const updatewidgetRowSettings = (key: string, value: string) => {
    const updateRowsListFunc = isHeaderRow ? updateHeaderRowsList : updateRowsList;
    dispatch(
      updateRowsListFunc({
        rowAction: 'update_settings',
        rowActionPayload: { selectedRowId: rowId, row: { [key]: value } }
      })
    );
    dispatch(updateHaveUnsavedChanges(true));
  };
  return (
    <>
      <div className={styles.warningMessage}>
        <Callout
          variant="warning"
          title="Warning"
          content="The code may contain malicious script or may negatively affect the performance. Be cautious before pasting your code."
        />
      </div>
      <TextArea
        value={checkValidEncodedCustomCode(customCode)}
        onChange={(value: string) => updatewidgetRowSettings('customCode', encodeCustomCode(value))}
        hint="Paste any valid HTML code here. The code will be inserted to the widget row. Please do preview your changes before publish."
        label="Custom Code"
        placeholder="Paste custom code"
      />
      <TextField
        label="Targeting Id"
        value={targetingId}
        onChange={(value: string) => updatewidgetRowSettings('targetingId', value)}
        placeholder="No value set"
      />
      {aheadNxtEnabled && (
        <>
          <Separator />
          <div className={styles.aheadNxtBanner}>Ahead Next</div>
          <div className={styles.centreAlignChkboxWrapper}>
            <Checkbox
              label="Centre Align Widget"
              id={`checkbox-centre-align-widget-${rowId}`}
              checked={centreAlignWidget}
              onChange={(value: any) => updatewidgetRowSettings('centreAlignWidget', value)}
            />
          </div>
          <TextField
            label="Placeholder Height (in pixels)"
            value={aheadNxtWidgetPlaceholderHeight}
            onChange={(value: string) => updatewidgetRowSettings('aheadNxtWidgetPlaceholderHeight', value)}
            placeholder="50"
          />
        </>
      )}
    </>
  );
};

export default WidgetInspector;
