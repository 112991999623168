import { Button } from '@quintype/em/components/button';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import { Trash } from '../../atoms/trash';
import {
  PAGEBUILDER_GENERAL_ADD_LOGO,
  PAGEBUILDER_GENERAL_DELETE_LOGO
} from '../../reducers/builder/configReducer/actions';
import { IPBState } from '../../utils/interfaces';
import styles from './upload-logo.module.css';

interface IPropsDelete {
  logos: string[];
  removeSelectedLogo: (item: string) => void;
}

const DeleteLogo: React.FunctionComponent<IPropsDelete> = ({ logos, removeSelectedLogo }) => {
  return (
    <ul className={styles.deleteLogoWrapper}>
      {logos.map((item, index) => (
        <li className={styles.logoList} key={index}>
          <img className={styles.logos} src={item} alt="logo" />
          <div
            className={styles.trashWrapper}
            onClick={() => {
              removeSelectedLogo(item);
            }}
          >
            <Trash />
          </div>
        </li>
      ))}
    </ul>
  );
};

interface IProps {
  description?: string;
}

export const UploadLogo = (props: IProps) => {
  const [input, setInput] = useState('');
  const logos = useSelector((state: IPBState) => get(state, ['builder', 'config', 'general', 'logos'], []));
  const dispatch = useDispatch();

  const addLogoToArray = (logoArray: string[], logo: string) => {
    dispatch({
      type: PAGEBUILDER_GENERAL_ADD_LOGO,
      payload: [...logoArray, logo]
    });
    dispatch(updateHaveUnsavedChanges(true));
  };

  const removeSelectedLogo = (logo: string) => {
    dispatch({
      type: PAGEBUILDER_GENERAL_DELETE_LOGO,
      payload: logo
    });
    dispatch(updateHaveUnsavedChanges(true));
  };

  const handleChange = (value: any) => {
    setInput(value);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    addLogoToArray(logos, input);
    setInput('');
  };

  return (
    <div>
      <TextField
        placeholder="Enter the url..."
        label="Add Logos"
        onChange={(value: any) => handleChange(value)}
        value={input}
      />
      <div className={styles.buttonWrapper}>
        <Button type="primary" onClick={onSubmit}>
          Add Logo
        </Button>
      </div>
      <DeleteLogo logos={logos} removeSelectedLogo={removeSelectedLogo} />
      {props.description && <p className={styles.description}>{props.description}</p>}
    </div>
  );
};
