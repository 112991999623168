import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import { renderComponent } from '@quintype/framework/client/start';
import { FieldLabel } from '@quintype/em/components/field-label';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React, { useEffect, useState, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { updateBackupConfig, updateHaveLayoutUpdates, updateHaveUnsavedChanges } from '../../actions/builder-action';
import { changeHeaderLayout, updateHeaderSettings } from '../../actions/header-action';
import FullScreenModalWrapper from '../../atoms/full-screen-modal-wrapper';
import returnNull from '../../atoms/null';
import ActionButtons from '../../molecules/action-buttons';
import PBInspector from '../../molecules/inspector';
import { LogoNavigator } from '../../molecules/logo-navigator';
import { LogoPicker } from '../../molecules/logo-picker';
import LowFidelityLayouts from '../../molecules/low-fidelity-layouts';
import { SocialFollowStyle } from '../../molecules/social-link-style';
import { PAGEBUILDER_HEADER_UPDATE_SOCIAL_LINK_STYLE } from '../../reducers/builder/configReducer/actions';
import { IComponentMeta, IConfig, Window } from '../../utils/interfaces';
import { aheadHeaderMap } from '../header-layouts/aheadHeaderMap';
import styles from './ahead-header.module.css';
import {
  AheadNxtColorPicker,
  DarkModeSettings,
  DateTimeSettings,
  HamburgerAndMegaMenuSettings,
  PrimaryColorPicker,
  SecondaryColorPicker
} from './color-picker-header-panel';
import { HeaderAppDownloadLinks } from './header-app-download-links';
import { HeaderLoginSubscription } from './header-login-subscription';
import { Sticky } from './sticky';

declare var window: Window;

interface IPBState {
  builder: {
    config: IConfig;
    haveUnsavedChanges: boolean;
  };
  isAccordionActive: false;
}
interface IHeaderPBConfig {
  isDarkModeEnabled?: boolean;
}
interface IProps {
  logoStored: string | undefined;
  darkLogoStored: string | undefined;
  config: IConfig;
  haveUnsavedChanges: boolean;
  qt: any;
  headerType: string;
  enableAppDownloadLinksButton: boolean;
  enableSSO: boolean;
  googlePlayDownloadLink?: string;
  appStoreDownloadLink?: string;
  enableDate?: boolean;
  enableTime?: boolean;
  showDarkModeToggle?: boolean;
  aheadNxtEnabled: boolean;
  header: IHeaderPBConfig;
  desktopLogoWidth: string;
  desktopLogoHeight: string;
  mobileLogoWidth: string;
  mobileLogoHeight: string;
}

const AheadHeaderBase = (props: IProps) => {
  const { config, haveUnsavedChanges, headerType, aheadNxtEnabled } = props;
  const [showLayoutSelector, toggleLayoutSelector] = useState(false);
  const [showSettingsInspector, toggleSettingsInspector] = useState(false);
  const dispatch = useDispatch();
  const aheadState = { getState: () => props, subscribe: () => null, state: { ...props.qt, header: props.header } };

  useEffect(() => {
    if ((window.QT_COMPONENTS && headerType === 'header_5') || (window.QT_COMPONENTS && headerType === 'header_6')) {
      renderComponent(window.QT_COMPONENTS.HeaderSocialFollow, 'header-social-follow', aheadState, { hydrate: true });
      renderComponent(window.QT_COMPONENTS.NewHamburgerMenu, 'new-hamburger-menu', aheadState, { hydrate: true });
      renderComponent(window.QT_COMPONENTS.NewSearch, 'new-search', aheadState, { hydrate: true });
      renderComponent(window.QT_COMPONENTS.MegaMenu, 'mega-menu', aheadState, { hydrate: true });
      renderComponent(window.QT_COMPONENTS.PrimaryMenu, 'new-primary-menu', aheadState, { hydrate: true });
      renderComponent(window.QT_COMPONENTS.PrimaryMenu, 'mobile-primary-menu', aheadState, { hydrate: true });
      if (props.enableSSO) {
        renderComponent(window.QT_COMPONENTS.UserProfile, 'header-login', aheadState, { hydrate: true });
      }
      if (props.enableAppDownloadLinksButton) {
        renderComponent(window.QT_COMPONENTS.AppDownloadLinks, 'app-download-link', aheadState, { hydrate: true });
      }
      if (props.enableDate || props.enableTime) {
        renderComponent(window.QT_COMPONENTS.CurrentDateTime, 'current-date-time', aheadState, { hydrate: true });
      }
    }

    if (window.QT_COMPONENTS && props.showDarkModeToggle) {
      renderComponent(window.QT_COMPONENTS.DarkModeToggle, 'dark-mode-toggle', aheadState, { hydrate: true });
    }

    if (window.QT_COMPONENTS && document.getElementById('header')) {
      renderComponent(window.QT_COMPONENTS.HeaderSocialFollow, 'header-social-follow', aheadState, { hydrate: true });
      renderComponent(window.QT_COMPONENTS.HeaderSidebar, 'header-sidebar', aheadState, { hydrate: true });
      renderComponent(window.QT_COMPONENTS.HeaderSearch, 'header-search', aheadState, { hydrate: true });
      renderComponent(window.QT_COMPONENTS.DefaultMenu, 'header-default-menu', aheadState, { hydrate: true });
      props.enableSSO &&
        renderComponent(window.QT_COMPONENTS.UserProfile, 'header-login', aheadState, { hydrate: true });
      renderComponent(window.QT_COMPONENTS.HeaderSticky, 'header-sticky', aheadState, { hydrate: true });

      if (headerType) {
        if (!headerType.includes('single-layer') || headerType.includes('single-layer-header_5')) {
          renderComponent(window.QT_COMPONENTS.HeaderSearch, 'header-search-mobile', aheadState, { hydrate: true });
        }
        if (!headerType.includes('single-layer')) {
          renderComponent(window.QT_COMPONENTS.HeaderSidebar, 'header-sidebar-mobile', aheadState, { hydrate: true });
          renderComponent(window.QT_COMPONENTS.SecondaryMenu, 'header-secondary-menu', aheadState, { hydrate: true });

          if (props.enableAppDownloadLinksButton) {
            renderComponent(window.QT_COMPONENTS.MobileDownloadLinks, 'header-mobile-download-links', aheadState, {
              hydrate: true
            });
          } else {
            renderComponent(() => <div />, 'header-mobile-download-links', aheadState, { hydrate: true });
          }
        }
      }
    }
  }, [
    props.headerType,
    props.enableAppDownloadLinksButton,
    aheadState,
    headerType,
    props.enableSSO,
    props.enableDate,
    props.enableTime,
    props.showDarkModeToggle
  ]);

  const updateHeaderStyles = (socialFollowHeaderStyle: string) => {
    dispatch({ type: PAGEBUILDER_HEADER_UPDATE_SOCIAL_LINK_STYLE, payload: socialFollowHeaderStyle });
    dispatch(updateHaveUnsavedChanges(true));
  };
  const updateHeaderData = (key: string, value: string) => dispatch(updateHeaderSettings({ [key]: value }));

  const pickHeaderFn = window.QT_COMPONENTS && window.QT_COMPONENTS.pickHeader;

  const ComponentName = (pickHeaderFn && pickHeaderFn(props.headerType, true)) || returnNull;

  const handleLayoutSelect = (headerLayout: IComponentMeta) => {
    toggleLayoutSelector(false);
    dispatch(changeHeaderLayout(headerLayout.componentName));
    dispatch(updateHaveLayoutUpdates(true));
  };

  const closeHeaderSettings = () => {
    // TODO: Alert user for losing unsaved changes before closing the inspector
    toggleSettingsInspector(false);
  };

  const headerSettingsHandler = () => {
    dispatch(updateBackupConfig({ config }));
    toggleSettingsInspector(true);
  };

  const onClickHandler = () => {
    return (prevState: { isAccordionActive: boolean }) => ({ isAccordionActive: !prevState.isAccordionActive });
  };
  const socialLinkDescription = 'Select icon for social icons in header';

  const bigHeaderTypes = headerType === 'header_5' || headerType === 'header_6';
  let logoMobileWidthPlaceholder;
  if (headerType === 'header_3') {
    logoMobileWidthPlaceholder = 190;
  } else if (bigHeaderTypes) {
    logoMobileWidthPlaceholder = '';
  } else {
    logoMobileWidthPlaceholder = 122;
  }
  const logoDesktopWidthPlaceholder = (bigHeaderTypes) ? '' : 300;
  const logoDesktopHeightPlaceholder = (bigHeaderTypes) ? 104 : 56;
  const logoMobileHeightPlaceholder = (bigHeaderTypes) ? 64 : 32;

  return (
    <>
      <div id="preview-placeholder">
        <Sticky />
      </div>
      <div className={styles.header} data-test-id="ahead-header" id="header-preview">
        <div className={styles.buttonGroup}>
          <ActionButtons
            actionList={[
              {
                action: 'replace',
                handler: () => toggleLayoutSelector(true)
              },
              {
                action: 'setting',
                handler: headerSettingsHandler
              }
            ]}
          />
        </div>
        {showLayoutSelector ? (
          <FullScreenModalWrapper onClose={() => toggleLayoutSelector(false)}>
            <LowFidelityLayouts handleLayoutSelect={handleLayoutSelect} layoutsList={aheadHeaderMap} />
          </FullScreenModalWrapper>
        ) : null}
        <PBInspector
          title="Header Settings"
          onActionButtonClick={closeHeaderSettings}
          isActive={showSettingsInspector}
          isActionButtonDisabled={!haveUnsavedChanges}
          onClose={closeHeaderSettings}
        >
          <Accordion hasSingleActivePanel onToggle={() => onClickHandler}>
            <AccordionSection label="Logo" isLabelUpperCase index={0}>
              <LogoPicker
                updateTheStore={updateHeaderData}
                updateLogoKey="logo"
                logoStored={props.logoStored}
                darkLogoStored={props.darkLogoStored}
              />
              <LogoNavigator layout="header" updateStore={updateHeaderData} />
              <FieldLabel label="Logo Size for Desktop" />
              <TextField
                label="Width (in px)"
                placeholder={`${logoDesktopWidthPlaceholder}`}
                value={props.desktopLogoWidth}
                onChange={(value: any) => {
                  updateHeaderData('desktopLogoWidth', value);
                }}
              />
              <TextField
                label="Height (in px)"
                placeholder={`${logoDesktopHeightPlaceholder}`}
                value={props.desktopLogoHeight}
                onChange={(value: any) => {
                  updateHeaderData('desktopLogoHeight', value);
                }}
              />
              <FieldLabel label="Logo Size for Mobile" />
              <TextField
                label="Width (in px)"
                placeholder={`${logoMobileWidthPlaceholder}`}
                value={props.mobileLogoWidth}
                onChange={(value: any) => {
                  updateHeaderData('mobileLogoWidth', value);
                }}
              />
              <TextField
                label="Height (in px)"
                placeholder={`${logoMobileHeightPlaceholder}`}
                value={props.mobileLogoHeight}
                onChange={(value: any) => {
                  updateHeaderData('mobileLogoHeight', value);
                }}
              />
            </AccordionSection>
            <AccordionSection label="Social Icons" isLabelUpperCase index={1}>
              <SocialFollowStyle
                layout="Header"
                socialLinkDescription={socialLinkDescription}
                updateStyles={updateHeaderStyles}
              />
            </AccordionSection>
            <AccordionSection label="Primary Header Color" isLabelUpperCase index={2}>
              <PrimaryColorPicker />
            </AccordionSection>
            <AccordionSection label="Secondary Header Color" isLabelUpperCase index={3}>
              <SecondaryColorPicker />
            </AccordionSection>
            {aheadNxtEnabled ? (
              <AccordionSection label="Ahead Next Header Color" isLabelUpperCase index={4}>
                <AheadNxtColorPicker />
              </AccordionSection>
            ) : (
              <Fragment />
            )}
            <AccordionSection label="Buttons and Links" isLabelUpperCase index={5}>
              <HeaderLoginSubscription />
              <HeaderAppDownloadLinks />
            </AccordionSection>
            <AccordionSection label="Hamburger and Megamenu" isLabelUpperCase index={6}>
              <HamburgerAndMegaMenuSettings />
            </AccordionSection>
            {headerType === 'header_5' || headerType === 'header_6' ? (
              <AccordionSection label="Date and time" isLabelUpperCase index={7}>
                <DateTimeSettings />
              </AccordionSection>
            ) : (
              <div />
            )}
            <AccordionSection label="Dark Mode" isLabelUpperCase index={7}>
              <DarkModeSettings />
            </AccordionSection>
          </Accordion>
        </PBInspector>
        <ComponentName />
      </div>
    </>
  );
};

const mapStateToProps = (state: IPBState) => {
  return {
    config: get(state, ['builder', 'config']),
    haveUnsavedChanges: get(state, ['builder', 'haveUnsavedChanges']),
    headerType: get(state, ['builder', 'config', 'header', 'headerStyle'], 'header_1'),
    qt: get(state, ['qt']),
    enableAppDownloadLinksButton: get(state, [
      'qt',
      'config',
      'pagebuilder-config',
      'header',
      'enableAppDownloadLinksButton'
    ]),
    enableSSO: get(state, ['qt', 'config', 'pagebuilder-config', 'header', 'enableSSO']),
    enableDate: get(state, ['qt', 'config', 'pagebuilder-config', 'header', 'dateTime', 'enableDate'], false),
    enableTime: get(state, ['qt', 'config', 'pagebuilder-config', 'header', 'dateTime', 'enableTime'], false),
    showDarkModeToggle: get(state, ['qt', 'config', 'pagebuilder-config', 'header', 'showDarkModeToggle'], false),
    logoStored: get(state, ['builder', 'config', 'header', 'logo']),
    darkLogoStored: get(state, ['builder', 'config', 'header', 'darkLogo']),
    header: get(state, ['header'], {}),
    aheadNxtEnabled: get(state, ['builder', 'config', 'general', 'aheadNxtEnabled'], false),
    desktopLogoWidth: get(state, ['qt', 'config', 'pagebuilder-config', 'header', 'desktopLogoWidth'], ""),
    desktopLogoHeight: get(state, ['qt', 'config', 'pagebuilder-config', 'header', 'desktopLogoHeight'], ""),
    mobileLogoWidth: get(state, ['qt', 'config', 'pagebuilder-config', 'header', 'mobileLogoWidth'], ""),
    mobileLogoHeight: get(state, ['qt', 'config', 'pagebuilder-config', 'header', 'mobileLogoHeight'], ""),
  };
};

export const AheadHeader = connect(mapStateToProps)(AheadHeaderBase);
export default AheadHeader;
