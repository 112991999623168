import { Checkbox } from '@quintype/em/components/checkbox';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../../actions/builder-action';
import { PAGEBUILDER_FOOTER_UPDATE_THEME_ATTRIBUTES_KEY } from '../../../reducers/builder/configReducer/actions';

interface IProps {
  updateAppDownloadLinks: (keyName: string, keyValue: boolean) => void;
  enableAppDownloadLinksButton: boolean;
}

class FooterAppDownloadLinksBase extends React.Component<IProps> {
  render() {
    const { updateAppDownloadLinks, enableAppDownloadLinksButton } = this.props;
    return (
      <div data-test-id="footer-enable-app-download-link">
        <Checkbox
          label="App download links"
          id="footerAppDownloadLinks"
          checked={enableAppDownloadLinksButton}
          onChange={(value: any) => updateAppDownloadLinks('enableAppDownloadLinksButton', value)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: {}) => ({
  enableAppDownloadLinksButton: get(state, ['builder', 'config', 'footer', 'enableAppDownloadLinksButton'], false)
});

interface IActionType {
  type: string;
  payload: object | boolean;
}

const mapDispatchToProps = (dispatch: React.Dispatch<IActionType>) => ({
  updateAppDownloadLinks: (keyName: string, keyValue: boolean) => {
    dispatch({ type: PAGEBUILDER_FOOTER_UPDATE_THEME_ATTRIBUTES_KEY, payload: { [keyName]: keyValue } });
    dispatch(updateHaveUnsavedChanges(true));
  }
});

export const FooterAppDownloadLinks = connect(mapStateToProps, mapDispatchToProps)(FooterAppDownloadLinksBase);
