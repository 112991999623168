import Logo1 from './low-fidelity-header-svgs/1.svg';
import Logo2 from './low-fidelity-header-svgs/2.svg';
import Logo3 from './low-fidelity-header-svgs/3.svg';
import Logo4 from './low-fidelity-header-svgs/4.svg';
import Logo5 from './low-fidelity-header-svgs/5.svg';
import Logo6 from './low-fidelity-header-svgs/6.svg';
import Logo7 from './low-fidelity-header-svgs/7.svg';
import Logo8 from './low-fidelity-header-svgs/8.svg';
import Logo9 from './low-fidelity-header-svgs/9.svg';
import Logo10 from './low-fidelity-header-svgs/10.svg';
import Logo11 from './low-fidelity-header-svgs/11.svg';

export const aheadHeaderMap = [
  {
    componentName: 'header_1',
    componentLabel: 'Header 1',
    image: Logo3
  },
  {
    componentName: 'header_2',
    componentLabel: 'Header 2',
    image: Logo2
  },
  {
    componentName: 'header_3',
    componentLabel: 'Header 3',
    image: Logo1
  },
  {
    componentName: 'header_4',
    componentLabel: 'Header 4',
    image: Logo4
  },
  {
    componentName: 'header_5',
    componentLabel: 'Header 5',
    image: Logo10
  },
  {
    componentName: 'header_6',
    componentLabel: 'Header 6',
    image: Logo11
  },
  {
    componentName: 'single-layer-header_1',
    componentLabel: 'Single Layer - Header 1',
    image: Logo5
  },
  {
    componentName: 'single-layer-header_2',
    componentLabel: 'Single Layer - Header 2',
    image: Logo6
  },
  {
    componentName: 'single-layer-header_3',
    componentLabel: 'Single Layer - Header 3',
    image: Logo7
  },
  {
    componentName: 'single-layer-header_4',
    componentLabel: 'Single Layer - Header 4',
    image: Logo8
  },
  {
    componentName: 'single-layer-header_5',
    componentLabel: 'Single Layer - Header 5',
    image: Logo9
  }
];
