import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Stop: React.FunctionComponent<IsvgData> = ({ width = '24px', height = '24px', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fill-rule="evenodd"
        d="M12 18.531c1.221 0 2.352-.305 3.39-.916 1.04-.61 1.865-1.435 2.475-2.474.61-1.04.916-2.17.916-3.391s-.305-2.352-.916-3.39c-.61-1.04-1.435-1.865-2.474-2.475-1.04-.61-2.17-.916-3.391-.916s-2.352.305-3.39.916c-1.04.61-1.865 1.435-2.475 2.474-.61 1.04-.916 2.17-.916 3.391s.305 2.352.916 3.39c.61 1.04 1.435 1.865 2.474 2.475 1.04.61 2.17.916 3.391.916zm4.484-2.926l-8.34-8.34c.766-.656 1.637-1.089 2.612-1.298.975-.21 1.941-.173 2.898.11.957.282 1.796.783 2.516 1.503.72.72 1.221 1.559 1.504 2.516.282.957.319 1.923.11 2.898-.21.975-.643 1.846-1.3 2.611zm-3.24 1.928c-.975.21-1.941.173-2.898-.11-.957-.282-1.8-.778-2.53-1.49-.71-.729-1.207-1.572-1.49-2.529-.282-.957-.319-1.923-.11-2.898.21-.975.643-1.846 1.3-2.611l8.34 8.34c-.766.656-1.637 1.089-2.612 1.298z"
      />
    </svg>
  );
};

export default Stop;
