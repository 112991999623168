import React, { useEffect, useRef } from 'react';

// This component is needed because even though the ahead-header is sticky, the preview action buttons for the header also needs to be sticky

export const Sticky = () => {
  const stickyRef = useRef(null);

  const makeSticky = (entries: any[]) => {
    const fixedClassName = 'sticky';
    const headerDoc = document.getElementById('header-preview');
    const headerClassList = headerDoc && headerDoc.classList;
    const placeholderDoc = document.getElementById('preview-placeholder');

    const aheadHeader = document.getElementById('header');
    const aheadHeaderHeight = aheadHeader && aheadHeader.offsetHeight;

    entries.forEach(entry => {
      if (entry.rootBounds) {
        if (entry.isIntersecting) {
          headerClassList && headerClassList.remove(fixedClassName);
          if (placeholderDoc) {
            placeholderDoc.style.height = '0px';
          }
        } else {
          headerClassList && headerClassList.add(fixedClassName);
          if (placeholderDoc) {
            // Adding the height here to prevent page jump, but as PB doesnot know what the header height is, adding it manually
            placeholderDoc.style.height = `${aheadHeaderHeight}px`;
          }
        }
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(makeSticky, {});
    if (stickyRef.current) {
      observer.observe(stickyRef.current as any);
    }
    return () => {
      if (stickyRef.current) {
        observer.unobserve(stickyRef.current as any);
      }
    };
  }, []);

  return <div ref={stickyRef} />;
};
