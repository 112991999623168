import {
  PAGEBUILDER_GENERAL_ADD_FALLBACK_IMAGE,
  PAGEBUILDER_GENERAL_UPDATE_AD_SETTINGS,
  PAGEBUILDER_GENERAL_UPDATE_BREAKING_NEWS,
  PAGEBUILDER_GENERAL_UPDATE_COMMENT_PLUGIN_SETTINGS,
  PAGEBUILDER_GENERAL_UPDATE_CONFIG,
  PAGEBUILDER_HAVE_UNSAVED_CHANGES
} from '../reducers/builder/configReducer/actions';
import {
  IFallbackImage,
  IGeneralBreakingNews,
  IGeneralCommentSettings,
  IGeneralCommentSettingsPayload,
  IGeneralSettingsPayload
} from '../utils/interfaces';

interface IArgs {
  type: string;
  payload: IGeneralSettingsPayload;
}
type IDispatch = (args: IArgs) => void;

interface ICommentArgs {
  type: string;
  payload: IGeneralCommentSettingsPayload;
}
type IDispatchComments = (args: ICommentArgs) => void;

const updateAdsSetting = (payload: IGeneralSettingsPayload) => (dispatch: IDispatch) => {
  dispatch({ type: PAGEBUILDER_GENERAL_UPDATE_AD_SETTINGS, payload });
};

export const updateGeneralConfig = (payload: IGeneralCommentSettingsPayload) => (dispatch: IDispatchComments) => {
  dispatch({ type: PAGEBUILDER_GENERAL_UPDATE_CONFIG, payload });
  dispatch({ type: PAGEBUILDER_HAVE_UNSAVED_CHANGES, payload });
};

interface IFallbackArgs {
  type: string;
  payload: IFallbackImage;
}
type IDispatchFallback = (args: IFallbackArgs) => void;

export const fallbackImage = (payload: IFallbackImage) => (dispatch: IDispatchFallback) => {
  dispatch({ type: PAGEBUILDER_GENERAL_ADD_FALLBACK_IMAGE, payload });
  dispatch({ type: PAGEBUILDER_HAVE_UNSAVED_CHANGES, payload });
};

interface IGeneralBreakingNewsArgs {
  type: string;
  payload: IGeneralBreakingNews;
}
type IDispatchGeneralBreakingNews = (args: IGeneralBreakingNewsArgs) => void;

export const updateGeneralBreakingNews = (payload: IGeneralBreakingNews) => (
  dispatch: IDispatchGeneralBreakingNews
) => {
  dispatch({ type: PAGEBUILDER_GENERAL_UPDATE_BREAKING_NEWS, payload });
  dispatch({ type: PAGEBUILDER_HAVE_UNSAVED_CHANGES, payload });
};

export const commentSettings = (payload: IGeneralCommentSettings) => {
  return {
    type: PAGEBUILDER_GENERAL_UPDATE_COMMENT_PLUGIN_SETTINGS,
    payload
  };
};

export default updateAdsSetting;
