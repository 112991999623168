import { IEditControl } from '../../../utils/interfaces';
import {
  PAGEBUILDER_EDIT_CONTROL_START_EDITING,
  PAGEBUILDER_EDIT_CONTROL_STOP_EDITING,
  PAGEBUILDER_EDIT_CONTROL_SET_CURRENT_EDITOR,
  PAGEBUILDER_EDIT_CONTROL_RESET_EDITING
} from './actions';

const initialState = {
  access: false,
  currentEditor: null,
  isEditAccessLocked: Number(localStorage.getItem('lockedAccessTime')) > Date.now()
};

const editControlReducer = (
  state: IEditControl = initialState,
  action: {
    type: string;
    payload: string | null;
  }
) => {
  switch (action.type) {
    case PAGEBUILDER_EDIT_CONTROL_SET_CURRENT_EDITOR:
      return {
        ...state,
        access: false,
        currentEditor: action.payload
      };
    case PAGEBUILDER_EDIT_CONTROL_START_EDITING:
      return {
        ...state,
        access: true,
        currentEditor: action.payload
      };
    case PAGEBUILDER_EDIT_CONTROL_RESET_EDITING:
      return {
        ...state,
        access: false,
        currentEditor: null
      };
    case PAGEBUILDER_EDIT_CONTROL_STOP_EDITING:
      return {
        ...state,
        isEditAccessLocked: true
      };
    default:
      return state;
  }
};

export { editControlReducer };
