import { FieldLabel } from '@quintype/em/components/field-label';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import Select from '@quintype/em/components/select';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateGeneralConfig } from '../../actions/general-settings-action';
import timezones from 'timezones-list';
import styles from './time-formatter.module.css';
import { Separator } from '../../atoms/separator';
import { ToggleButton } from '../toggle-button';

interface StoryCardTimeInterface {
  liveBlog?: 'published' | 'updated';
  rest?: 'published' | 'updated';
}

interface StoryCardOption {
  label: 'Published Time' | 'Updated Time';
  value: 'published' | 'updated';
}

const storyCardPublishedOption: StoryCardOption = {
  label: 'Published Time',
  value: 'published'
};

const storyCardUpdatedOption: StoryCardOption = {
  label: 'Updated Time',
  value: 'updated'
};

const storyCardTimeOptions = [storyCardPublishedOption, storyCardUpdatedOption];

const TimeFormatter = () => {
  const dispatch = useDispatch();
  const timezonesObj = timezones.map(({ tzCode: value, ...rest }) => ({
    value,
    ...rest
  }));

  const {
    timeFormat = '12hours',
    timeZone = '',
    storyCardTime = {},
    dateFormat = 'dd-mon-yyyy',
    enableTimeTranslation = false,
    enableLocalization = false
  }: {
    timeFormat: any;
    timeZone: any;
    dateFormat: string;
    enableTimeTranslation: boolean;
    enableLocalization: boolean;
    storyCardTime: StoryCardTimeInterface;
  } = useSelector(state => get(state, ['builder', 'config', 'general'], {}));

  const updateSettings = (key: string, value: string | boolean | StoryCardTimeInterface) => {
    return dispatch(updateGeneralConfig({ [key]: value }));
  };

  const getDefaultValue = () => {
    const result = timezonesObj.filter(item => item.value === timeZone);
    return result && result[0];
  };

  const getStoryCardDefaultTime = () => {
    const defaultValue: {
      liveBlog?: StoryCardOption;
      rest?: StoryCardOption;
    } = {};
    const storyTemplates: readonly ['liveBlog', 'rest'] = ['liveBlog', 'rest'];
    for (const template of storyTemplates) {
      if (storyCardTime[template]) {
        defaultValue[template] =
          storyCardTime[template] === 'published' ? storyCardPublishedOption : storyCardUpdatedOption;
      } else {
        defaultValue[template] = storyCardPublishedOption;
      }
    }
    return defaultValue;
  };

  const dateFormatOptions = [
    { label: '01 Jun 2023', value: 'dd-mon-yyyy' },
    { label: 'Jun 01, 2023', value: 'mon-dd-yyyy' }
  ];

  const getDefaultDateFormat = () => {
    const result = dateFormatOptions.filter(item => item.value === dateFormat);
    return result?.[0] || dateFormatOptions[0];
  };

  const handleToggleUpdate = (key: string, value: boolean) => {
    dispatch(updateGeneralConfig({ [key]: value }));
  };

  return (
    <>
      <div className={styles.wrapper}>
        <FieldLabel label="Date Format" />
        <Select
          label=""
          options={dateFormatOptions}
          defaultValue={getDefaultDateFormat()}
          onChange={(key: { value: string }) => updateSettings('dateFormat', key.value)}
        />
      </div>
      <div className={styles.wrapper}>
        <FieldLabel label="Time Format" />
        <Radio
          name="timeFormat"
          align="horizontal"
          selected={timeFormat}
          onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) => {
            updateSettings('timeFormat', (e.target as HTMLInputElement).value);
          }}
        >
          <RadioOption id="1" value="12hours">
            12-hour
          </RadioOption>
          <RadioOption id="2" value="24hours">
            24-hour
          </RadioOption>
        </Radio>
      </div>
      <Separator />

      <div className={styles.toggleWrapper}>
        <FieldLabel label="Automatic Time Translation" />
        <ToggleButton
          label=""
          toggleName="enableTimeTranslation"
          id="enableTimeTranslation"
          updateToggleState={enableTimeTranslation}
          updateToggleButton={handleToggleUpdate}
        />
      </div>
      <p className={styles.info}>
        {
          'Enable this feature to automatically translate the timestamp on story cards (for timestamp < 24h). The translation will be to the front-end language set in BOLD. To check the languages we currently support please refer to our help '
        }
        <a
          className={styles.linkWrapper}
          target="_blank"
          href="https://help.quintype.com/en_US/general-settings/automatic-time-translation"
          rel="noopener noreferrer"
        >
          documentation
        </a>
        {'.'}
      </p>
      <Separator />

      <FieldLabel label="Time to show on Story Cards" />
      <p className={styles.info}>
        Choose what time you need to display on story cards. This applies to all the pages where the stories are listed.
      </p>

      <Select
        label="Live Blog"
        options={storyCardTimeOptions}
        defaultValue={getStoryCardDefaultTime().liveBlog}
        helpText="We recommend using Updated Time for Live Blogs"
        onChange={(selectedOption: StoryCardOption) => {
          updateSettings('storyCardTime', {
            ...storyCardTime,
            liveBlog: selectedOption.value
          });
        }}
      />

      <Select
        label="All other story types"
        options={storyCardTimeOptions}
        defaultValue={getStoryCardDefaultTime().rest}
        onChange={(selectedOption: StoryCardOption) => {
          updateSettings('storyCardTime', {
            ...storyCardTime,
            rest: selectedOption.value
          });
        }}
      />

      <Separator />

      <Select
        label="Structured Data Time Zone"
        options={timezonesObj}
        defaultValue={getDefaultValue()}
        onChange={(key: { value: string }) => updateSettings('timeZone', key.value)}
      />
    </>
  );
};

export default TimeFormatter;
