import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveLayoutUpdates } from '../../actions/builder-action';
import { updateHeaderRowsList } from '../../actions/header-action';
import { moveRow, updateRowsList } from '../../actions/row-action';
import { openSelectRowInspector } from '../../actions/select-row-inspector-action';
import { MoreVertical } from '../../atoms/action-svg';
import { Replace } from '../../atoms/Replace-svg';
import IconButton from '../../atoms/iconButton';
import { Trash } from '../../atoms/trash';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';
import { ActionDropdownItem, ActionDropdownList, ActionDropdownMenu } from '../../molecules/action-dropdown';
import { IComponentMeta, IConfig, ISelectedRow } from '../../utils/interfaces';
import styles from './row-wrapper.module.css';
import { Chevron } from '@quintype/em/icons/chevron';

interface InspectorProps {
  currentRow: ISelectedRow;
  isHeaderRow?: boolean;
  componentMeta?: IComponentMeta[];
}

interface IProps {
  currentRow: ISelectedRow;
  type: string;
  index: number;
  inspectorChildren?: (args: InspectorProps) => void;
  inspectorTitle?: string;
  updateHaveUnsavedChange?: () => void;
  hideActionButtons?: boolean;
  isHeaderRow?: boolean;
  componentMeta?: IComponentMeta[];
}

interface IPBState {
  builder: {
    config: IConfig;
    haveUnsavedChanges: boolean;
    pageType: string;
    subPageType: string;
    pageId: string;
  };
}

const RowWrapperActions = ({ currentRow, type, index, isHeaderRow = false }: IProps) => {
  const hasEditAccess = useSelector(state => get(state, ['builder', 'editControl', 'access']));
  const dispatch = useDispatch();
  const { config, pageType, pageId, subPageType } = useSelector((state: IPBState) => get(state, ['builder']));
  const moduleType = (isHeaderRow && 'header') || pageType;
  const configPathPart = useConfigPathFromStore();
  const path = isHeaderRow ? ['header'] : configPathPart;
  const noOfRows = get(config, [...path, 'rows', 'length'], 0);
  const renderMoveUpButton = () => index > 0;
  const renderMoveDownButton = () => index < noOfRows - 1;

  const deleteRow = () => {
    const updateRowsListFunc = isHeaderRow ? updateHeaderRowsList : updateRowsList;
    dispatch(
      updateRowsListFunc({
        rowAction: 'delete_row',
        rowActionPayload: { selectedRowId: currentRow.rowId, row: {}, pageId }
      })
    );
    dispatch(updateHaveLayoutUpdates(true));
  };

  const handleLayout = () => {
    dispatch(
      openSelectRowInspector({ type, action: 'update', rowId: currentRow.rowId, isHeaderRow: isHeaderRow || false })
    );
    // dispatch(updateHaveLayoutUpdates(true));
  };

  const dropdown = () => {
    if (!hasEditAccess) return null;
    return (
      <ActionDropdownMenu alignTo="left">
        <div className={styles.rotate90}>
          <MoreVertical />
        </div>
        <ActionDropdownList>
          <ActionDropdownItem actionHandler={handleLayout} actionName="Replace Row">
            <Replace />
          </ActionDropdownItem>
          {renderMoveUpButton() && (
            <ActionDropdownItem
              actionHandler={() => dispatch(moveRow(index, moduleType, pageId, -1, subPageType))}
              actionName="MoveUp"
            >
              <Chevron variant="up" width={22} height={18} />
            </ActionDropdownItem>
          )}
          {renderMoveDownButton() && (
            <ActionDropdownItem
              actionHandler={() => dispatch(moveRow(index, moduleType, pageId, 1, subPageType))}
              actionName="Move Down"
            >
              <Chevron variant="down" width={22} height={18} />
            </ActionDropdownItem>
          )}
          <ActionDropdownItem actionHandler={deleteRow} actionName="Delete">
            <Trash />
          </ActionDropdownItem>
        </ActionDropdownList>
      </ActionDropdownMenu>
    );
  };

  const showReplaceDropDown = () => {
    return (
      <IconButton onClick={handleLayout}>
        <Replace />
      </IconButton>
    );
  };

  const renderDropDown = currentRow.type === 'collections' ? showReplaceDropDown() : dropdown();
  const actionDropDown = ['tag', 'author'].includes(pageType) ? renderDropDown : dropdown();

  return (
    <>
      <div className={styles.buttonGroupChild}>{actionDropDown}</div>
    </>
  );
};

export default RowWrapperActions;
