import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import { setBuilderLoadingState, updateHistoryActionStatus, updatePBConfig } from '../../actions/builder-action';
import { updateAheadDarkModePreview, updateLanguage } from '../../actions/update-qt-action';
import Chameleon from '../../molecules/chameleon';
import CustomCodeGeneralSettings from '../../molecules/custom-code-general-settings';
import AmpCustomCode from '../../molecules/amp-custom-code';
import EditControlSupervisor from '../../molecules/edit-control-supervisor';
import ErrorBoundary from '../../molecules/error-boundary';
import SetupSubdomain from '../../molecules/setup-subdomain';
import SharedPopupGenerator from '../../molecules/SharedPopupGenerator';
import { IParams } from '../../utils/interfaces';
import { getBoldConfig, getDomainLangauge, getSavedConfig, setPageDirection } from '../../utils/utils';
import withAuth from '../../utils/withAuth';
import Author from '../author';
import Subscription from '../subscription';
import ManageSubscriptions from '../manage-subscriptions';
import AvailablePlans from '../available-plans';
import PlanChangeOverview from '../plan-change-overview';
import Authors from '../authors';
import Login from '../login';
import Home from '../home';
import IssueLanding from '../issue-landing';
import LandingPage from '../landing-page';
import MagazineArchive from '../magazine-archive';
import MagazinePage from '../magazine-page';
import Metering from '../metered-wall';
import Profile from '../profile';
import PurchasedStory from '../purchased-story';
import Preview from '../preview';
import { Redirects } from '../redirects';
import Search from '../search';
import SecretMode from '../secret-mode';
import Story from '../story';
import Tag from '../tag';
import InactiveScreenLogout from './inactive-screen-logout';
import './page-builder.module.css';

export const PageBuilder = () => {
  const { publisherId = '', domain } = useParams<IParams>();
  const dispatch = useDispatch();
  const [pageAction, setPageAction] = useState('');
  const [mainDomainSlug, setMainDomainSlug] = useState('');

  useEffect(() => {
    let boldConfig: any;
    function handleError() {
      const { 'publisher-name': publisherName = '', domains: subdomains = [] } = boldConfig;
      const result = subdomains.find((item: { slug: string }) => item.slug === domain);
      if (result) {
        setPageAction('setup-subdomain');
        setMainDomainSlug(publisherName);
      } else {
        setPageAction('not-found');
      }
    }

    async function fetchConfig() {
      dispatch(setBuilderLoadingState(true));

      try {
        const { config, actionButtonStatus } = await getSavedConfig(publisherId, domain);
        if (config) {
          dispatch(updateAheadDarkModePreview(config.enableDarkModePreview));
        }
        boldConfig = await getBoldConfig(publisherId);
        // TODO: Move populating initial state to login and store it inside the localstorage and preload it from there.
        const language = getDomainLangauge(boldConfig, domain);
        dispatch(updateLanguage(language));
        const direction = get(language, ['direction'], 'ltr') || 'ltr';
        setPageDirection(direction);
        dispatch(updatePBConfig(config));
        dispatch(updateHistoryActionStatus(actionButtonStatus));
        dispatch(setBuilderLoadingState(false));
      } catch (error) {
        if (boldConfig) {
          handleError();
        } else {
          try {
            boldConfig = await getBoldConfig(publisherId);
            handleError();
          } catch (error) {
            setPageAction('not-found');
          }
        }
      }
    }
    fetchConfig();
  }, [publisherId, domain, dispatch]);

  // TODO: create page not found component with better UI.
  if (pageAction === 'not-found') return <h2>Page not found.</h2>;
  if (pageAction === 'setup-subdomain') {
    return <SetupSubdomain mainDomainSlug={mainDomainSlug} publisherId={publisherId} domain={domain} />;
  }

  return (
    <>
      <ErrorBoundary>
        <Chameleon />
      </ErrorBoundary>
      <InactiveScreenLogout publisherId={publisherId} />
      <EditControlSupervisor publisherId={publisherId} />
      <SharedPopupGenerator />
      <Switch>
        <Route path="/accounts/:publisherId/:domain/custom-code" component={withAuth(CustomCodeGeneralSettings)} />
        <Route path="/accounts/:publisherId/:domain/redirects" component={withAuth(Redirects)} />
        <Route path="/accounts/:publisherId/:domain/magazine-archive" component={withAuth(MagazineArchive)} />
        <Route path="/accounts/:publisherId/:domain/issue-landing" component={withAuth(IssueLanding)} />
        <Route path="/accounts/:publisherId/:domain/magazine-landing" component={withAuth(MagazinePage)} />
        <Route path="/accounts/:publisherId/:domain/tag" component={withAuth(Tag)} />
        <Route path="/accounts/:publisherId/:domain/author" component={withAuth(Author)} />
        <Route path="/accounts/:publisherId/:domain/authors" component={withAuth(Authors)} />
        <Route path="/accounts/:publisherId/:domain/search" component={withAuth(Search)} />
        <Route path="/accounts/:publisherId/:domain/section" component={withAuth(LandingPage)} />
        <Route path="/accounts/:publisherId/:domain/landing-page" component={withAuth(LandingPage)} />
        <Route path="/accounts/:publisherId/:domain/secret-mode" component={withAuth(SecretMode)} />
        <Route path="/accounts/:publisherId/:domain/preview/:page/:subpage?" component={withAuth(Preview)} />
        <Route exact path="/accounts/:publisherId/:domain/story/:storyType" component={withAuth(Story)} />
        <Route exact path="/accounts/:publisherId/:domain" component={withAuth(Home)} />
        <Route path="/accounts/:publisherId/:domain/subscription" component={withAuth(Subscription)} />
        <Route path="/accounts/:publisherId/:domain/manage-subscriptions" component={withAuth(ManageSubscriptions)} />
        <Route path="/accounts/:publisherId/:domain/available-plans" component={withAuth(AvailablePlans)} />
        <Route path="/accounts/:publisherId/:domain/plan-change-overview" component={withAuth(PlanChangeOverview)} />
        <Route path="/accounts/:publisherId/:domain/sign-in" component={withAuth(Login)} />
        <Route path="/accounts/:publisherId/:domain/sign-up" component={withAuth(Login)} />
        <Route path="/accounts/:publisherId/:domain/forgot-password" component={withAuth(Login)} />
        <Route path="/accounts/:publisherId/:domain/reset-password" component={withAuth(Login)} />
        <Route path="/accounts/:publisherId/:domain/email-otp" component={withAuth(Login)} />
        <Route path="/accounts/:publisherId/:domain/mobile-otp" component={withAuth(Login)} />
        <Route path="/accounts/:publisherId/:domain/enter-password" component={withAuth(Login)} />
        <Route path="/accounts/:publisherId/:domain/metering" component={withAuth(Metering)} />
        <Route path="/accounts/:publisherId/:domain/user-details" component={withAuth(Profile)} />
        <Route path="/accounts/:publisherId/:domain/purchased-stories" component={withAuth(PurchasedStory)} />
        <Route path="/accounts/:publisherId/:domain/amp-custom-code" component={withAuth(AmpCustomCode)} />
      </Switch>
    </>
  );
};
