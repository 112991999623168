import { Button } from '@quintype/em/components/button';
import { Loader } from '@quintype/em/components/loader';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setupSubdomain } from '../../utils/utils';
import styles from './setup-subdomain.module.css';

interface IDefaultContentBlock {
  domain: string;
  handleSetupClick: () => {};
  onCancel: () => void;
}

const DefaultContentBlock = ({ domain, handleSetupClick, onCancel }: IDefaultContentBlock) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        Set up the subdomain <span className={styles.highlight}>`{domain}`</span>
      </h2>
      <div className={styles.description}>
        Click on ’Start Now’ to begin configuring your subdomain. By clicking on ‘Go Back’ you'll be redirected back to
        the main domain.
      </div>
      <div className={styles.actionButtons}>
        <Button classname={styles.buttonItem} onClick={() => onCancel()}>
          Go Back
        </Button>
        <Button classname={styles.buttonItem} type="primary" onClick={handleSetupClick}>
          Start Now
        </Button>
      </div>
    </div>
  );
};

const LoadingMessage = (props: { domain: string }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Loader />
        <div className={styles.subTitle}>
          The subdomain <span className={styles.highlight}>`{props.domain}`</span> is being configured.
        </div>
      </div>
    </div>
  );
};

const RedirectingMessage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.subTitle}>You are being redirected to main domain...</div>
      </div>
    </div>
  );
};

const ErrorMessage = (props: { handleSetupClick: () => {}; onCancel: () => void }) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Oops!</h2>
      <div className={styles.subTitle}>
        Looks like there is an error. You could try again or go back to your home page.
      </div>
      <div className={styles.actionButtons}>
        <Button classname={styles.buttonItem} onClick={() => props.onCancel()}>
          Go Back
        </Button>
        <Button classname={styles.buttonItem} type="primary" onClick={props.handleSetupClick}>
          Try Again
        </Button>
      </div>
    </div>
  );
};

const SuccessMessage = (props: { domain: string; onCancel: () => void }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.subTitle}>
        The subdomain <span className={styles.highlight}>`{props.domain}`</span> has been configured successfully. You
        can now start customizing your pages.
      </div>
      <div className={styles.actionButtons}>
        <Button classname={styles.buttonItem} onClick={() => props.onCancel()}>
          I’ll do it later
        </Button>
        <Button classname={styles.buttonItem} type="primary" onClick={() => window.location.reload()}>
          Customize now
        </Button>
      </div>
    </div>
  );
};

interface ISetupSubdomainProps {
  publisherId: string;
  domain: string;
  mainDomainSlug: string;
}

const SetupSubdomain = ({ publisherId, domain, mainDomainSlug }: ISetupSubdomainProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const returnLink = `/accounts/${publisherId}/${mainDomainSlug}`;
  const history = useHistory();
  const handleCancel = () => {
    setRedirecting(true);
    history.replace(returnLink);
    window.location.reload();
  };

  const handleSetupClick = async () => {
    try {
      setLoading(true);
      const result = await setupSubdomain(publisherId, domain);
      if (result) {
        setLoading(false);
        setSuccess(true);
        setError(false);
      }
    } catch (er) {
      setLoading(false);
      setError(true);
    }
  };

  if (loading) return <LoadingMessage domain={domain} />;
  if (redirecting) return <RedirectingMessage />;

  return (
    <div className={styles.container}>
      {success && <SuccessMessage domain={domain} onCancel={handleCancel} />}
      {error && <ErrorMessage handleSetupClick={handleSetupClick} onCancel={handleCancel} />}
      {!error && !success && (
        <DefaultContentBlock onCancel={handleCancel} handleSetupClick={handleSetupClick} domain={domain} />
      )}
    </div>
  );
};

export default SetupSubdomain;
