import { Checkbox } from '@quintype/em/components/checkbox';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHeaderSettings } from '../../../actions/header-action';
import { IPBState } from '../../../utils/interfaces';
import styles from './header-login-subscription.module.css';

export const HeaderLoginSubscription = () => {
  const dispatch = useDispatch();
  const { enableSubscriptionButton = false, enableSSO = false } = useSelector((state: IPBState) =>
    get(state, ['builder', 'config', 'header'])
  );
  const updateLoginSubscription = (keyName: string, keyValue: boolean) =>
    dispatch(updateHeaderSettings({ [keyName]: keyValue }));

  return (
    <>
      <div data-test-id="header-subscribe-button" className={styles.checkboxWrapper}>
        <Checkbox
          label="Subscribe Button"
          id="headerAccesstype"
          checked={enableSubscriptionButton}
          onChange={(value: any) => updateLoginSubscription('enableSubscriptionButton', value)}
        />
      </div>
      <div data-test-id="header-login-button" className={styles.checkboxWrapper}>
        <Checkbox
          label="Login Button"
          id="sso"
          checked={enableSSO}
          onChange={(value: any) => updateLoginSubscription('enableSSO', value)}
        />
      </div>
    </>
  );
};

