import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import StorySettings from './story-settings';

const StoryInspector = ({ config = {}, layout = '', typeName = '', customStoryTypeBaseName }: any) => {
  const type = customStoryTypeBaseName || typeName;
  const storyPageType = `${type}-story`;
  const storyComponentMeta = useSelector((state: any) => {
    return get(state, ['ahead', 'storyComponentMetaData', storyPageType]);
  });

  const enableDarkModePreview = useSelector((state: any) => {
    return get(state, ['builder', 'config', 'enableDarkModePreview'], false);
  });

  const generateSettings = (item: any, index: number) => {
    const { showHideSettings, styleSettings, authorStylesSetting } = item;
    const hideSettings = type === 'liveBlog' ? 'imageRender' : 'buttonText';
    const filteredItems = showHideSettings.filter((item: any) => item.keyName !== hideSettings);

    return (
      <>
        <Accordion hasSingleActivePanel onToggle={() => {}}>
          {filteredItems && (
            <AccordionSection label="Layout Settings" isLabelUpperCase index={index}>
              <StorySettings
                config={config}
                data={filteredItems}
                storyType={typeName}
                customStoryTypeBaseName={customStoryTypeBaseName}
              />
            </AccordionSection>
          )}
          {styleSettings && (
            <AccordionSection label="Story Color" isLabelUpperCase index={index}>
              <StorySettings
                config={config}
                data={styleSettings}
                storyType={typeName}
                customStoryTypeBaseName={customStoryTypeBaseName}
                enableDarkModePreview={enableDarkModePreview}
              />
            </AccordionSection>
          )}
          {authorStylesSetting && (
            <AccordionSection label="Author Styles" isLabelUpperCase index={index}>
              <StorySettings
                config={config}
                data={authorStylesSetting}
                storyType={typeName}
                customStoryTypeBaseName={customStoryTypeBaseName}
              />
            </AccordionSection>
          )}
        </Accordion>
      </>
    );
  };
  return (
    <>
      {storyComponentMeta &&
        storyComponentMeta.map((item: any, index: number) => {
          if (item.componentName === layout) {
            return generateSettings(item.settings, index);
          }
        })}
    </>
  );
};

export default StoryInspector;
