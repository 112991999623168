import { PAGEBUILDER_UPDATE_ACTION_BUTTON_STATUS } from '../configReducer/actions';
import { IHistoryActionStatus } from '../../../utils/interfaces';

const historyActionStatusReducer = (
  state: IHistoryActionStatus = { undo: false, redo: false, reset: false },
  action: {
    type: string;
    payload: IHistoryActionStatus;
  }
) => {
  switch (action.type) {
    case PAGEBUILDER_UPDATE_ACTION_BUTTON_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export { historyActionStatusReducer };
