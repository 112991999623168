import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Trash: React.FunctionComponent<IsvgData> = ({ width = '24px', height = '24px', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M15.898 19.5c.372 0 .689-.122.953-.366a1.42 1.42 0 00.454-.923l.966-10.899h.616c.039 0 .078-.019.117-.058.039-.04.058-.078.058-.117v-.059a.678.678 0 00-.205-.498.678.678 0 00-.498-.205h-3.047l-.996-1.318a1.31 1.31 0 00-1.113-.557H10.86c-.468 0-.84.186-1.113.557L8.75 6.375H5.703a.678.678 0 00-.498.205.678.678 0 00-.205.498v.059c0 .039.02.078.059.117.039.039.078.058.117.058h.615l.967 10.899c.039.371.19.679.454.923s.581.366.952.366h7.734zM14.141 6.375H9.92l.558-.762a.472.472 0 01.38-.176h2.344c.156 0 .283.06.381.176l.557.762zm1.757 12.187H8.164a.449.449 0 01-.308-.117.51.51 0 01-.16-.322l-.967-10.81h10.605l-.967 10.81a.51.51 0 01-.161.322.449.449 0 01-.308.117z"
      ></path>
    </svg>
  );
};

export { Trash };
