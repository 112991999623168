import { ColorSwatch } from '@quintype/em/components/color-swatch';
import { FieldLabel } from '@quintype/em/components/field-label';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import { PAGEBUILDER_GENERAL_UPDATE_COLORS_IN_PALETTE } from '../../reducers/builder/configReducer/actions';
import { IPBState } from '../../utils/interfaces';
import styles from './color-palette.module.css';

interface IProps {
  description?: string;
}

export const Palette = (props: IProps) => {
  const dispatch = useDispatch();
  const colors = useSelector((state: IPBState) => get(state, ['builder', 'config', 'general', 'colors'], []));

  const onArrayOfColorsUpdate = (colorsList: string[]) => {
    dispatch({ type: PAGEBUILDER_GENERAL_UPDATE_COLORS_IN_PALETTE, payload: colorsList });
    dispatch(updateHaveUnsavedChanges(true));
  };

  return (
    <div className={styles.colorPaletteWrapper}>
      <FieldLabel label="Choose colours" classname={styles.label} />
      <ColorSwatch
        colors={colors}
        onChange={(colorsList: string[]) => onArrayOfColorsUpdate(colorsList)}
        onDelete={(colorsList: string[]) => onArrayOfColorsUpdate(colorsList)}
        showAddColorButton={colors.length >= 8 ? false : true}
      />
      {props.description && <p className={styles.description}>{props.description}</p>}
    </div>
  );
};
