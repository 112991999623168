import { Checkbox } from '@quintype/em/components/checkbox';
import { TextField } from '@quintype/em/components/text-field';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import React, { useState } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import updateAdsSetting from '../../actions/general-settings-action';
import styles from './ads-general-settings.module.css';
import { ToggleButton } from '../toggle-button';
import { AD_PROVIDERS } from '../../constant';
import { Separator } from '../../atoms/separator';

interface IStore {
  builder: {
    config: {
      general: {
        ads: {
          dfpNetworkId?: string;
          lazyLoadDfp?: boolean;
          googleAdsenseClientId?: string;
          adProvider?: string;
        };
      };
    };
  };
}

const AdsGeneralSettings = () => {
  const dispatch = useDispatch();
  const { dfpNetworkId, lazyLoadDfp = false, googleAdsenseClientId, adProvider: stateAdProvider } = useSelector(
    (state: IStore) => {
      const {
        builder: {
          config: {
            general: { ads = {} }
          }
        }
      } = state;
      return ads;
    }
  );
  const [adProvider, setAdProvider] = useState(stateAdProvider || AD_PROVIDERS.NONE);
  const updateSettings = (key: string, value: string) => {
    dispatch(updateAdsSetting({ [key]: value }));
    dispatch(updateHaveUnsavedChanges(true));
  };
  const adProviderOnchange = (e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) => {
    const provider = (e.target as HTMLInputElement).value;
    dispatch(updateAdsSetting({ adProvider: provider }));
    dispatch(updateHaveUnsavedChanges(true));
    setAdProvider(provider);
  };

  const aheadNxtEnabled = useSelector((state: any) =>
    get(state, ['builder', 'config', 'general', 'aheadNxtEnabled'], false)
  );

  const dfpAdSettings = (
    <>
      <TextField
        label="DFP Network Id"
        placeholder="DFP netword id..."
        value={dfpNetworkId || ''}
        onChange={(value: string) => updateSettings('dfpNetworkId', value)}
      />
      <Checkbox
        label="Lazy Load DFP Ads"
        id="lazyLoadDfp"
        checked={lazyLoadDfp}
        onChange={(value: any) => updateSettings('lazyLoadDfp', value)}
      />
    </>
  );
  const aheadNxtAdSettings = (
    <>
      <div className={styles.marginBottomXs}>
        <div className={styles.marginBottomXs}>
          <ToggleButton
            label="Show Ads"
            toggleName="showAds"
            id="showAds"
            updateToggleState={adProvider !== AD_PROVIDERS.NONE}
            updateToggleButton={(name: string, value: boolean) => {
              if (value) {
                dispatch(updateAdsSetting({ adProvider: AD_PROVIDERS.GOOGLE_ADSENSE }));
                setAdProvider(AD_PROVIDERS.GOOGLE_ADSENSE);
              } else {
                dispatch(updateAdsSetting({ adProvider: AD_PROVIDERS.NONE }));
                setAdProvider(AD_PROVIDERS.NONE);
              }
              dispatch(updateHaveUnsavedChanges(true));
            }}
          />
        </div>
        {adProvider !== AD_PROVIDERS.NONE && (
          <Radio name="adProvider" label="Select Ad Provider" selected={adProvider} onChange={adProviderOnchange}>
            <RadioOption value={AD_PROVIDERS.GOOGLE_ADSENSE} checked={stateAdProvider === AD_PROVIDERS.GOOGLE_ADSENSE}>
              Google AdSense
            </RadioOption>
            <RadioOption
              value={AD_PROVIDERS.GOOGLE_AD_MANAGER}
              checked={stateAdProvider === AD_PROVIDERS.GOOGLE_AD_MANAGER}
            >
              Google Ad Manager
            </RadioOption>
          </Radio>
        )}
      </div>
      {adProvider === AD_PROVIDERS.GOOGLE_AD_MANAGER && <em>Note: DFP Network ID is same as ahead</em>}
      {adProvider === AD_PROVIDERS.GOOGLE_ADSENSE && (
        <TextField
          value={googleAdsenseClientId || ''}
          label="Client ID"
          placeholder="ca-pub-0000000000000000"
          onChange={(adsenseClientId: string) => updateSettings('googleAdsenseClientId', adsenseClientId)}
        />
      )}
    </>
  );

  return (
    <div className={styles.adsGeneralSettingsWrapper}>
      <div>{dfpAdSettings}</div>
      {aheadNxtEnabled && (
        <div>
          <Separator />
          <b>Ahead Next</b>
          {aheadNxtAdSettings}
        </div>
      )}
    </div>
  );
};

export default AdsGeneralSettings;
