import defaultLowFidelity from './svg/default.svg';
import style1LowFidelity from './svg/style-1.svg';
import style2LowFidelity from './svg/style-2.svg';
import style3LowFidelity from './svg/style-3.svg';
import style4LowFidelity from './svg/style-4.svg';

const rowTitleData = [
  { name: 'default', svg: defaultLowFidelity },
  { name: 'style-1', svg: style1LowFidelity },
  { name: 'style-2', svg: style2LowFidelity },
  { name: 'style-3', svg: style3LowFidelity },
  { name: 'style-4', svg: style4LowFidelity }
];

export default rowTitleData;
