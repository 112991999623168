import { Checkbox } from '@quintype/em/components/checkbox';
import { FieldLabel } from '@quintype/em/components/field-label';
import { TextField } from '@quintype/em/components/text-field';
import { InfoCircle } from '@quintype/em/icons/info-circle';
import drop from 'lodash/drop';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateCustomFonts } from '../../actions/update-fonts-action';
import { Trash } from '../../atoms/trash';
import { IPBState } from '../../utils/interfaces';
import styles from './font-uploader.module.css';

const FileUploadPreview = ({ updateFont, rootFileType, customFonts }: any) => {
  const onRemoveFile = (fileName: string) => {
    const updatedFontList = customFonts[rootFileType].filter((item: any) => item.name !== fileName);
    updateFont(rootFileType, updatedFontList);
  };

  if (!customFonts[rootFileType]) return null;

  return (
    <>
      {customFonts[rootFileType].length > 0 &&
        customFonts[rootFileType].map((newfile: any) => (
          <div key={newfile.name} className={styles.previewWrapper}>
            <div className={styles.previewBtn}>{newfile.name}</div>
            <div onClick={() => onRemoveFile(newfile.name)} className={styles.trashBtn}>
              <Trash />
            </div>
          </div>
        ))}
    </>
  );
};

const getUploadButton = (customFonts: any, props: any) => {
  if (!customFonts[props.font.value] || (customFonts[props.font.value] && customFonts[props.font.value].length < 5)) {
    return (
      <button className={styles.buttonWrapper}>
        Add new
        <input id="upload-file-btn" type="file" {...props} multiple className={styles.uploader} />
      </button>
    );
  }
  return null;
};

const FileUploadButton = ({ updateFont, customFonts, ...props }: any) => (
  <>
    <FieldLabel label={props.font.key} />
    <FileUploadPreview
      updateFont={updateFont}
      rootFileType={props.font.value}
      showLoader={props.showLoader}
      customFonts={customFonts}
    />
    {props.showLoader && props.currentItem === props.font.value && <div className={styles.loader}>Uploading...</div>}
    {getUploadButton(customFonts, props)}
  </>
);

const CustomFontUploader = () => {
  const dispatch = useDispatch();
  const { domain } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const customFonts = useSelector((state: IPBState) =>
    get(state, ['builder', 'config', 'general', 'fonts', 'customFonts'], {})
  );
  const { primaryFont = '', primary400 = [], primary700 = [], isPrimaryData = false } = customFonts;

  let { secondary400 = [], secondary700 = [], secondaryFont = '' } = customFonts;

  const updateFont = (key: string, value: any) => dispatch(updateCustomFonts({ [key]: value }));

  const fontType = (font: string) => {
    switch (font) {
      case 'primary400':
        return primary400;
      case 'primary700':
        return primary700;
      case 'secondary400':
        return secondary400;
      case 'secondary700':
        return secondary700;
    }
  };

  const removeDuplicates = (arr: any) => {
    return arr.reduce((acc: any, current: any) => {
      const fontExists = acc.find((item: { name: string }) => item.name === current.name);
      if (!fontExists) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  };

  const uploadFile = async (file: any, font: string) => {
    try {
      const { url, ASSET_HOST = '' } = await (await fetch(`/upload/s3-url/${domain}/${file.name}`)).json();
      const response = await fetch(url, { method: 'PUT', body: file });
      const uploadedUrl = get(response, ['url']);
      const s3PathArr = new URL(uploadedUrl).pathname.split('/'); /* Drop environment from url path */
      const updatedPath = drop(s3PathArr, 2);
      let currentFontArr = fontType(font);
      const fontUrl = `${ASSET_HOST}/`.concat(updatedPath.join('/'));
      currentFontArr = [...currentFontArr, { url: fontUrl, name: file.name }];
      currentFontArr = removeDuplicates(currentFontArr);
      return [...currentFontArr];
    } catch (e) {
      console.log('err', e);
    }
  };

  const handleFile = (e: any, font: string, customFont: any) => {
    handleLoader(font, true);
    let totalFontFiles = Array.from(e.target.files);

    if (customFont[font] && customFont[font].length) {
      totalFontFiles = [...customFont[font], ...e.target.files];
    }

    if (totalFontFiles.length > 5) {
      alert('Only 5 files accepted');
      return handleLoader(null, false);
    }
    let updatedFontList: any = [];
    totalFontFiles.forEach(async file => {
      const uploadedFont: any = await uploadFile(file, font);
      updatedFontList = [...updatedFontList, ...uploadedFont];
      updatedFontList = removeDuplicates(updatedFontList);
      updateFont(font, updatedFontList);
      handleLoader(null, false);
    });
  };

  const handleLoader = (font: any, loader: boolean) => {
    setShowLoader(loader);
    setCurrentItem(font);
  };

  const secondaryFontList = [
    { key: 'Regular *', value: 'secondary400' },
    { key: 'Bold *', value: 'secondary700' }
  ];

  const primaryFontList = [
    { key: 'Regular *', value: 'primary400' },
    { key: 'Bold *', value: 'primary700' }
  ];

  useEffect(() => {
    if (isPrimaryData) {
      secondaryFont = updateFont('secondaryFont', secondaryFont.replace(secondaryFont, primaryFont));
      secondary400 = updateFont(
        'secondary400',
        primary400.map((item: object) => item)
      );
      secondary700 = updateFont(
        'secondary700',
        primary700.map((item: object) => item)
      );
    }
  }, [isPrimaryData, primary400, primary700]);

  const fieldSet = (fontValue: any, fontName: string, fontList: any) => (
    <>
      <TextField
        label="Font Name *"
        placeholder="Enter font family"
        value={fontValue}
        onChange={(value: string) => updateFont(fontName, value)}
      />
      {fontList.map((font: { value: string }, index: number) => (
        <FileUploadButton
          onChange={(e: any) => handleFile(e, font.value, customFonts)}
          key={`${font}-${index}`}
          font={font}
          updateFont={updateFont}
          showLoader={showLoader}
          currentItem={currentItem}
          customFonts={customFonts}
        />
      ))}
    </>
  );

  const fontsBlock = (label: string, info: string, fontName: string, fontValue: string, fontList: any) => (
    <>
      <div className={styles.heading}>
        <label className={styles.title}>{label}</label>
        <InfoCircle width={16} height={16} label={info} />
      </div>
      {fontName === 'secondaryFont' && (
        <>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label="Same as Title Font *"
              id="isPrimaryData"
              checked={isPrimaryData}
              onChange={(value: boolean) => updateFont('isPrimaryData', value)}
            />
          </div>
          {!isPrimaryData && fieldSet(fontValue, fontName, fontList)}
        </>
      )}
      {fontName === 'primaryFont' && fieldSet(fontValue, fontName, fontList)}
    </>
  );

  return (
    <>
      {fontsBlock(
        'Title Font',
        'applies to section titles, story titles etc.',
        'primaryFont',
        primaryFont,
        primaryFontList
      )}
      {fontsBlock(
        'Content Font',
        'applies to content of stories etc',
        'secondaryFont',
        secondaryFont,
        secondaryFontList
      )}
    </>
  );
};

export default CustomFontUploader;
