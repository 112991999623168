import { FieldLabel } from '@quintype/em/components/field-label';
import Select from '@quintype/em/components/select';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatebreakingNewsThemeAttributes } from '../../../actions/breaking-news-action';
import { defaultTheme } from '../../../constant';
import { ThemeSetter } from '../../../molecules/theme-setter';
import { IBreakingNews, IPBState } from '../../../utils/interfaces';

const selectOptions = [
  { label: 'Within Container', value: 'template1' },
  { label: 'Full Bleed', value: 'template2' }
];

const selectOptionsForseparator = [
  { label: 'Dotted', value: 'dot' },
  { label: 'Pipe', value: 'pipe' }
];

export const BreakingNewsSetting = () => {
  const getDefaultValueforTemplate = (style: string) => {
    switch (style) {
      case 'template1':
        return { label: 'Within Container', value: 'template1' };
      default:
        return { label: 'Full Bleed', value: 'template2' };
    }
  };
  const getDefaultValueforSeparator = (style: string) => {
    switch (style) {
      case 'pipe':
        return { label: 'Pipe', value: 'pipe' };
      default:
        return { label: 'Dotted', value: 'dot' };
    }
  };
  const { enableDarkModePreview = false, breakingNews = {} } = useSelector((state: IPBState) =>
    get(state, ['builder', 'config'])
  );
  const {
    backgroundColor = '',
    darkBackgroundColor = '',
    title = '',
    scrollSpeed = '',
    darkTextColor = '',
    textColor = ''
  }: IBreakingNews = breakingNews;

  const dispatch = useDispatch();

  const updateBreakingNews = (key: string, value: string) =>
    dispatch(updatebreakingNewsThemeAttributes({ [key]: value }));

  return (
    <>
      <FieldLabel label="Breaking News Style" />
      <Select
        defaultValue={getDefaultValueforTemplate('template1')}
        options={selectOptions}
        onChange={(obj: any) => updateBreakingNews('template', obj.value)}
      />
      <FieldLabel label="News Separator" />
      <Select
        defaultValue={getDefaultValueforSeparator('dot')}
        options={selectOptionsForseparator}
        onChange={(obj: any) => updateBreakingNews('delimiter', obj.value)}
      />
      <TextField
        label="Breaking News Title"
        placeholder="Enter Title"
        value={title}
        onChange={(value: any) => updateBreakingNews('title', value)}
      />
      <TextField
        label="Breaking News Speed"
        value={scrollSpeed}
        onChange={(value: any) => updateBreakingNews('scrollSpeed', value)}
        hint="Enter single digit number"
      />

      <ThemeSetter
        label="Breaking News Background Color"
        updateTheStore={updateBreakingNews}
        lightColorExtract={backgroundColor}
        lightUpdateStoreKey="backgroundColor"
        darkColorExtract={darkBackgroundColor}
        darkUpdateStoreKey="darkBackgroundColor"
        lightDefaultColor="#EFEFEF"
        darkDefaultColor="#2d2d2d"
        enableDarkModePreview={enableDarkModePreview}
      />
      <ThemeSetter
        label="Breaking News Text Color"
        updateTheStore={updateBreakingNews}
        lightColorExtract={textColor}
        lightUpdateStoreKey="textColor"
        darkColorExtract={darkTextColor}
        darkUpdateStoreKey="darkTextColor"
        lightDefaultColor="#333"
        darkDefaultColor={defaultTheme}
        enableDarkModePreview={enableDarkModePreview}
      />
    </>
  );
};
