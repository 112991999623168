import Layout1 from './low-fidelity-footer-layouts/footer-layout-1.svg';
import Layout2 from './low-fidelity-footer-layouts/footer-layout-2.svg';
import Layout3 from './low-fidelity-footer-layouts/footer-layout-3.svg';

export const aheadFooterMap = [
  {
    componentName: 'footer_1',
    componentLabel: 'Footer 1',
    image: Layout1
  },
  {
    componentName: 'footer_2',
    componentLabel: 'Footer 2',
    image: Layout2
  },
  {
    componentName: 'footer_3',
    componentLabel: 'Footer 3',
    image: Layout3
  }
];
