export const STORY_DATA = {
  'updated-at': 1564650013782,
  seo: {
    'claim-reviews': {
      story: null
    }
  },
  'assignee-id': 123981,
  'author-name': 'Ravigopal Kesari',
  tags: [
    {
      id: 163455,
      name: 'politics',
      'meta-description': null,
      slug: 'politics',
      'tag-type': 'Tag'
    },
    {
      id: 169510,
      name: 'review',
      'meta-description': null,
      slug: 'review',
      'tag-type': 'Tag'
    }
  ],
  headline: 'Ready Player One review \u2013 Spielberg\u00A0',
  'storyline-id': null,
  votes: {},
  'story-content-id': 'a3561065-11ce-4281-9d86-325934aa2146',
  slug: 'recent-stories/news/ready-player-one-review-spielberg-spins-a-dizzying-vr-yarn',
  'linked-stories': {
    '2d0008f7-768f-4667-822f-cb531d9627f4': {
      'author-name': 'Feed Migrator',
      headline: 'How is the coronavirus impacting people with disabilities?',
      'story-content-id': '2d0008f7-768f-4667-822f-cb531d9627f4',
      slug: 'coronavirus/how-is-the-coronavirus-impacting-people-with-disabilities',
      sections: [
        {
          'domain-slug': null,
          slug: 'coronavirus',
          name: 'Coronavirus',
          'section-url': 'https://ace-web.qtstage.io/anything/coronavirus',
          id: 42239,
          'parent-id': null,
          'display-name': 'Coronavirus',
          collection: {
            slug: 'coronavirus',
            name: 'Coronavirus',
            id: 92836
          },
          data: null
        }
      ],
      'hero-image-metadata': {
        'original-url': 'https://www.aljazeera.com/mritems/Images/2020/4/19/f90b71cdf10141ff9913b72bcfd29768_18.jpg'
      },
      'hero-image-s3-key': 'ace/2020-04/9adec2ac-d37c-496f-abcc-98309a4bb356/f90b71cdf10141ff9913b72bcfd29768_18.jpg',
      url: 'https://ace-web.qtstage.io/anything/coronavirus/how-is-the-coronavirus-impacting-people-with-disabilities',
      'content-updated-at': 1587491154824,
      'author-id': 934395,
      'first-published-at': 1587405627183,
      authors: [
        {
          id: 934395,
          name: 'Feed Migrator',
          slug: 'feed-migrator',
          'avatar-url': null,
          'avatar-s3-key': null,
          'twitter-handle': null,
          bio: null
        }
      ]
    }
  },
  'last-published-at': 1564650017054,
  subheadline:
    'This spectacular gaming ride whizzes through a limitless futurescape – while also taking a puzzling detour to the shiny',
  alternative: {
    home: {
      default: {
        headline: 'Movie Review: Why Ready Player One is Amazing',
        'hero-image': {
          'hero-image-metadata': {
            width: 1080,
            height: 1330,
            'focus-point': [525, 207]
          },
          'hero-image-attribution': 'attribution',
          'hero-image-s3-key':
            'ace/2018-01/7f028fc9-48fa-4e6c-b2b3-8baf1e6e8928/22520141_862918130543643_474831853139210771_o.jpg',
          'hero-image-caption': '',
          'hero-image-url':
            'https://thumbor-stg.assettype.com/ace/2018-01/7f028fc9-48fa-4e6c-b2b3-8baf1e6e8928/22520141_862918130543643_474831853139210771_o.jpg',
          'temporary-hero-image-key': null
        }
      }
    }
  },
  sections: [
    {
      'domain-slug': null,
      slug: 'news',
      name: 'News',
      'section-url': 'https://ace-web.qtstage.io/section/news',
      id: 5670,
      'parent-id': 5773,
      'display-name': 'News ',
      collection: {
        slug: 'news',
        name: 'News',
        id: 3795
      },
      data: null
    },
    {
      'domain-slug': null,
      slug: 'recent-stories',
      name: 'Recent stories',
      'section-url': 'https://ace-web.qtstage.io/anything/recent-stories',
      id: 5773,
      'parent-id': null,
      'display-name': 'Recent stories',
      collection: {
        slug: 'recent',
        name: 'Recent stories',
        id: 2760
      },
      data: null
    },
    {
      'domain-slug': null,
      slug: 'film',
      name: 'Film',
      'section-url': 'https://ace-web.qtstage.io/section/film',
      id: 5749,
      'parent-id': 5670,
      'display-name': 'Film Display name',
      collection: {
        slug: 'film-manual-collection',
        name: 'Film',
        id: 2725
      },
      data: null
    }
  ],
  'story-audio': {
    's3-key': 'story-audio/ace/2019-08/a3561065-11ce-4281-9d86-325934aa2146/12132634-d337-471b-9e92-1ce7b4d15bfb.mp3'
  },
  'read-time': 2,
  'access-level-value': 23,
  'content-created-at': 1524115986915,
  'owner-name': 'Ravigopal Kesari',
  'custom-slug': null,
  'push-notification': null,
  'publisher-id': 97,
  'hero-image-metadata': {
    width: 1920,
    height: 1080,
    'mime-type': 'image/png',
    'file-size': 587085,
    'file-name': 'ready-player-one-hd-wallpapers-70749-6537851.png',
    'focus-point': [883, 411]
  },
  comments: null,
  'word-count': 323,
  entities: {},
  'published-at': 1564650017054,
  'is-live-blog': false,
  'breaking-news-linked-story-id': null,
  'storyline-title': null,
  summary:
    'Passport is authentication middleware for Node. It is designed to serve a singular purpose: authenticate requests. When writing modules, encapsulation is a virtue, so Passport delegates all other functionality to the application. This separation of concerns keeps code clean and maintainable, and makes Passport extremely easy to integrate into an application.',
  'push-notification-title': null,
  'external-id': null,
  'canonical-url': null,
  'is-amp-supported': false,
  autotags: [],
  'linked-entities': [],
  status: 'published',
  'hero-image-attribution': 'Quis Nostrud',
  'bullet-type': '123',
  'hero-image-s3-key':
    'ace/2018-01/7f028fc9-48fa-4e6c-b2b3-8baf1e6e8928/22520141_862918130543643_474831853139210771_o.jpg',
  contributors: [],
  'associated-series-collection-ids': [],
  cards: [
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/c529557b-a4f5-4363-ba3f-7ac0eb14f299',
          type: 'text',
          'family-id': '2b7c81f0-98f6-416c-9692-90ba504c9385',
          title: '',
          id: 'c529557b-a4f5-4363-ba3f-7ac0eb14f299',
          metadata: {},
          subtype: null,
          text:
            '<p><a href="https://www.theguardian.com/technology/virtual-reality">Virtual Reality</a> is the air guitar solo of modern cinema: a frenetic imagined activity in a made-up world that exists one level below the already made-up world of the story.</p>'
        },
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/4abd5e1d-4f02-433a-af48-2733a63682b9',
          type: 'composite',
          'family-id': 'aa26b0a8-f7e2-4466-a1ee-75cfc54c69d1',
          'story-elements': [
            {
              description: '',
              title: '',
              subtype: null,
              metadata: {
                description:
                  'In the year 2045, people can escape their harsh reality in the OASIS, an immersive virtual world where you can go anywhere, do anything, be anyone-the only limits are your own imagination. OASIS creator James Halliday left his immense fortune and control of the Oasis to the winner of a contest designed to find a worthy heir. When unlikely hero Wade Watts conquers the first challenge of the reality-bending treasure hunt, he and his friends-known as the High Five-are hurled into a fantastical universe of discovery and danger to save the OASIS and their world.',
                url: 'https://www.rottentomatoes.com/m/ready_player_one/',
                name: 'Ready player One review from rotten tomatoes'
              },
              text:
                '<div><p>Ready player One review from rotten tomatoes</p><p><a href="https://www.rottentomatoes.com/m/ready_player_one/">In the year 2045, people can escape their harsh reality in the OASIS, an immersive virtual world where you can go anywhere, do anything, be anyone-the only limits are your own imagination. OASIS creator James Halliday left his immense fortune and control of the Oasis to the winner of a contest designed to find a worthy heir. When unlikely hero Wade Watts conquers the first challenge of the reality-bending treasure hunt, he and his friends-known as the High Five-are hurled into a fantastical universe of discovery and danger to save the OASIS and their world.</a></p></div>',
              id: '47afb0c6-aa50-446c-8e55-c10c604c5246',
              'family-id': '6b236f36-ce8a-4f9a-b70e-844367f13af0',
              type: 'text'
            }
          ],
          title: '',
          id: '4abd5e1d-4f02-433a-af48-2733a63682b9',
          metadata: {},
          subtype: 'references'
        },
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/80f39709-fdf6-40b4-9d8c-a2db549ce621',
          type: 'text',
          'family-id': '338160c1-cb62-4328-b866-abbb13cf0e1b',
          title: '',
          id: '80f39709-fdf6-40b4-9d8c-a2db549ce621',
          metadata: {
            content:
              'After the death of James Halliday, the creator of the virtual reality world, his pre-recorded message reveals the hidden fortune, which makes Wade Watts, a teenager, embark on a quest.',
            attribution: 'Player'
          },
          subtype: 'quote',
          text:
            '<div><blockquote>After the death of James Halliday, the creator of the virtual reality world, his pre-recorded message reveals the hidden fortune, which makes Wade Watts, a teenager, embark on a quest.</blockquote><span class="attribution">Player</span></div>'
        },
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/0e1e9777-e0e6-45f9-b212-264db2d8eeb5',
          type: 'text',
          'family-id': '7be340e2-8e00-4bba-b640-cdbd57ba2719',
          title: '',
          id: '0e1e9777-e0e6-45f9-b212-264db2d8eeb5',
          metadata: {
            content:
              "Although the many story changes might be hard for book purists to accept, Steven Spielberg has lovingly captured the zeitgeist of '80s nostalgia in this adventure."
          },
          subtype: 'blurb',
          text:
            '<blockquote>Although the many story changes might be hard for book purists to accept, <a href="https://www.rottentomatoes.com/m/ready_player_one/">Steven Spielberg</a> has lovingly captured the zeitgeist of 80s nostalgia in this adventure.</blockquote>'
        },
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/48546269-f61b-42f3-945c-5358d14f3429',
          type: 'text',
          'family-id': '33c7c7c1-1001-46f1-b77d-0553805ded84',
          title: '',
          id: '48546269-f61b-42f3-945c-5358d14f3429',
          metadata: {
            content:
              'After the death of James Halliday, the creator of the virtual reality world, his pre-recorded message reveals the hidden fortune, which makes Wade Watts, a teenager, embark on a quest.',
            attribution: 'Player'
          },
          subtype: 'blockquote',
          text:
            '<div><blockquote>After the death of James Halliday, the creator of the virtual reality world, his pre-recorded message reveals the hidden fortune, which makes Wade Watts, a teenager, embark on a quest.</blockquote><span class="attribution">Player</span></div>'
        },
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/0e1e9777-e0e6-45f9-b212-264db2d8eeb5',
          type: 'text',
          'family-id': '7be340e2-8e00-4bba-b640-cdbd57ba2719',
          title: '',
          id: '0e1e9777-e0e6-45f9-b212-264db2d8eeb5',
          metadata: {
            content:
              "Although the many story changes might be hard for book purists to accept, Steven Spielberg has lovingly captured the zeitgeist of '80s nostalgia in this adventure."
          },
          subtype: 'blurb',
          text:
            '<blockquote>Although the many story changes might be hard for book purists to accept, <a href="https://www.rottentomatoes.com/m/ready_player_one/">Steven Spielberg</a> has lovingly captured the zeitgeist of 80s nostalgia in this adventure.</blockquote>'
        },
        {
          description: '',
          'page-url': '/story/aa1b48a8-e6ae-4bfd-922a-be27f7dd1177/element/8a18c4b5-7aa6-4780-8626-b26d10e480eb',
          type: 'text',
          'family-id': '4e7cbe61-85c5-4f15-8131-6da5cfbb14b9',
          title: '',
          id: '8a18c4b5-7aa6-4780-8626-b26d10e480eb',
          metadata: {},
          subtype: 'summary',
          text:
            '<p>The International House of Pancakes or as we know it, IHOP is one of our favourite places to grab a satiating breakfast at. The never ending variety of pancakes, eggs and waffles have had us hooked from day 1 and add fresh juice, fried chicken, toasty breads and coffee to the mix and you have the penultimate breakfast that is the best thing ever.</p>'
        },
        {
          description: '',
          'page-url': '/story/a9068be5-70ce-4d55-86d0-687546f921ea/element/62393c55-50ed-4310-81c2-01cc0ef17446',
          type: 'file',
          'family-id': '591f90c3-e98e-43dc-8509-9f11f5335af6',
          title: '',
          id: '62393c55-50ed-4310-81c2-01cc0ef17446',
          'file-name': 'Attached PDF',
          url: 'https://thumbor-stg.assettype.com/ace/2019-07/6dcf2021-615b-43e6-85f3-21acb8953cea/resume_samples.pdf',
          's3-key': 'ace/2019-07/6dcf2021-615b-43e6-85f3-21acb8953cea/resume_samples.pdf',
          'content-type': 'application/pdf',
          metadata: {
            'file-size': 301808
          },
          subtype: 'attachment'
        },
        {
          description: '',
          'image-metadata': {
            width: 720,
            height: 720,
            'focus-point': [384, 356]
          },
          'page-url': '/story/3f332160-263d-422c-b186-bb26e8f1fd44/element/f47da1f1-177a-4e61-8976-6b7ea099cad8',
          type: 'image',
          'family-id': '38cec79b-f806-4fc1-a442-0bd300b657a4',
          'image-attribution': 'File image ',
          title: 'Filmmaker and cinematographer Amar Maibam from Manipur',
          id: 'f47da1f1-177a-4e61-8976-6b7ea099cad8',
          'image-s3-key':
            'ace/2018-01/7f028fc9-48fa-4e6c-b2b3-8baf1e6e8928/22520141_862918130543643_474831853139210771_o.jpg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/5ff364ee-2d20-4ebd-b58d-d077d6fb149f/element/9597681c-2569-46b0-8943-b309bf79ef03',
          type: 'text',
          'family-id': '027139a8-1c3e-479f-a0a4-4ea9b7a960f2',
          title: '',
          id: '9597681c-2569-46b0-8943-b309bf79ef03',
          metadata: {
            'linked-story-id': 'a22d009f-b308-4074-b7db-32d28118d702',
            'linked-story': {
              headline: 'How long does the coronavirus last inside the body?',
              'story-content-id': 'a22d009f-b308-4074-b7db-32d28118d702',
              'highlighted-text': '',
              id: 'a22d009f-b308-4074-b7db-32d28118d702',
              'highlighted-headline': null
            }
          },
          subtype: 'also-read',
          text: 'How long does the coronavirus last inside the body?'
        },
        {
          description: '',
          'page-url': '/story/1c0c6370-8424-4925-b1b3-d957aad2e0d8/element/e42058bc-c106-4dc2-832c-2089e074d34d',
          type: 'text',
          'family-id': 'd711e90f-f892-4c4e-8840-582688733b6d',
          title: '',
          id: 'e42058bc-c106-4dc2-832c-2089e074d34d',
          metadata: {
            content: 'Environment',
            attribution:
              'Environment is a place where different things are such as a swampy or hot environment. It can be living (biotic) or non-living (abiotic) things. It includes physical, chemical and other natural forces. Living things live in their environment.'
          },
          subtype: 'bigfact',
          text:
            '<div><div class="bigfact-title"><strong>Environment</strong></div><div class="bigfact-description"><em>Environment is a place where different things are such as a swampy or hot environment. It can be living (biotic) or non-living (abiotic) things. It includes physical, chemical and other natural forces. Living things live in their environment.</em></div></div>'
        },
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/a0078928-ebb8-4464-a8e6-62e77ca7f502',
          type: 'text',
          'family-id': 'bc29c3e2-9b21-444b-9e42-359968da256a',
          title: '',
          id: 'a0078928-ebb8-4464-a8e6-62e77ca7f502',
          metadata: {
            'linked-story-id': '2d0008f7-768f-4667-822f-cb531d9627f4',
            'linked-story': {
              headline: 'How is the coronavirus impacting people with disabilities?',
              'story-content-id': '2d0008f7-768f-4667-822f-cb531d9627f4',
              'highlighted-text': '',
              id: '2d0008f7-768f-4667-822f-cb531d9627f4',
              'highlighted-headline': null
            }
          },
          subtype: 'also-read',
          text: 'How is the coronavirus impacting people with disabilities?'
        },
        // q-and-a
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/b474c23d-d782-49f1-a615-fa03081c46b1',
          type: 'text',
          'family-id': '77f6332d-73fb-4255-91bb-e2d714981c82',
          title: '',
          id: 'b474c23d-d782-49f1-a615-fa03081c46b1',
          metadata: {
            question: '<p>Is Ready Player One book better than the movie?</p>',
            answer:
              "<p>Ready Player One is the latest example. I read Earnest Cline's book a couple months before watching Steven Spielberg's movie. ... The book generally fares better with reviewers, averaging 4.6 out of 5 stars on Amazon, while the movie scores 73% on Rotten Tomatoes.</p>",
            interviewer: {
              'author-collection-id': null,
              'updated-at': 1565606538341,
              slug: 'reena-singh-2',
              'last-name': null,
              'publisher-id': 97,
              name: 'Reena Singh',
              'avatar-url':
                'https://thumbor-stg.assettype.com/ace/2019-08/565bba26-4f05-4e63-b619-511a2a2bd5b7/images.jpeg',
              settings: null,
              source: null,
              'first-name': null,
              'communication-email': null,
              bio: null,
              id: 300199,
              'avatar-s3-key': 'ace/2019-08/565bba26-4f05-4e63-b619-511a2a2bd5b7/images.jpeg',
              'twitter-handle': null,
              'created-at': 1551268188983,
              metadata: {}
            },
            interviewee: {
              'author-collection-id': null,
              'updated-at': 1565606538341,
              slug: 'reena-singh-2',
              'last-name': null,
              'publisher-id': 97,
              name: 'Reena Singh',
              'avatar-url':
                'https://thumbor-stg.assettype.com/ace/2019-08/565bba26-4f05-4e63-b619-511a2a2bd5b7/images.jpeg',
              settings: null,
              source: null,
              'first-name': null,
              'communication-email': null,
              bio: null,
              id: 300199,
              'avatar-s3-key': 'ace/2019-08/565bba26-4f05-4e63-b619-511a2a2bd5b7/images.jpeg',
              'twitter-handle': null,
              'created-at': 1551268188983,
              metadata: {}
            }
          },
          subtype: 'q-and-a',
          text:
            '<div><div class="question"><p>Is Ready Player One book<a href="https://www.jbsimmons.com/blog/books-better-than-movies-ready-player-one"> better </a>than the movie?</p></div><div class="answer"><p><strong>Ready Player One</strong> is the latest example. I read Earnest Cline\'s <strong><a href="https://www.jbsimmons.com/blog/books-better-than-movies-ready-player-one">book</a></strong> a couple months before watching Steven Spielberg\'s <strong>movie</strong>. ... The <strong>book</strong> generally fares <strong>better</strong> with reviewers, averaging 4.6 out of 5 stars on Amazon, while the <strong>movie</strong> scores 73% on Rotten Tomatoes.</p></div></div>'
        },
        // question
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/b474c23d-d782-49f1-a615-fa03081c46b1',
          type: 'text',
          'family-id': '77f6332d-73fb-4255-91bb-e2d714981c82',
          title: '',
          id: 'b474c23d-d782-49f1-a615-fa03081c46b1',
          metadata: {
            interviewer: {
              'author-collection-id': null,
              'updated-at': 1565606538341,
              slug: 'reena-singh-2',
              'last-name': null,
              'publisher-id': 97,
              name: 'Reena Singh',
              'avatar-url':
                'https://thumbor-stg.assettype.com/ace/2019-08/565bba26-4f05-4e63-b619-511a2a2bd5b7/images.jpeg',
              settings: null,
              source: null,
              'first-name': null,
              'communication-email': null,
              bio: null,
              id: 300199,
              'avatar-s3-key': 'ace/2019-08/565bba26-4f05-4e63-b619-511a2a2bd5b7/images.jpeg',
              'twitter-handle': null,
              'created-at': 1551268188983,
              metadata: {}
            }
          },
          subtype: 'question',
          text:
            '<p>How does the expansion of the universe influence the observed redshift of distant galaxies and the concept of cosmic microwave background radiation?</p>'
        },
        //answer
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/b474c23d-d782-49f1-a615-fa03081c46b1',
          type: 'text',
          'family-id': '77f6332d-73fb-4255-91bb-e2d714981c82',
          title: '',
          id: 'b474c23d-d782-49f1-a615-fa03081c46b1',
          metadata: {
            interviewee: {
              'author-collection-id': null,
              'updated-at': 1565606538341,
              slug: 'reena-singh-2',
              'last-name': null,
              'publisher-id': 97,
              name: 'Reena Singh',
              'avatar-url':
                'https://thumbor-stg.assettype.com/ace/2019-08/565bba26-4f05-4e63-b619-511a2a2bd5b7/images.jpeg',
              settings: null,
              source: null,
              'first-name': null,
              'communication-email': null,
              bio: null,
              id: 300199,
              'avatar-s3-key': 'ace/2019-08/565bba26-4f05-4e63-b619-511a2a2bd5b7/images.jpeg',
              'twitter-handle': null,
              'created-at': 1551268188983,
              metadata: {}
            }
          },
          subtype: 'answer',
          text:
            '<p>Autonomous vehicles rely on AI-powered sensors, mapping, and decision algorithms to deftly navigate intricate roadways, ensuring safe and seamless journeys.</p>'
        }
      ],
      'card-updated-at': 1587559563683,
      'content-version-id': '11beb6bf-bcb3-4ea2-8764-7355fc40811b',
      'card-added-at': 1524115986932,
      status: 'draft',
      id: '954331a6-4e5e-4d96-8204-74f56ce1f837',
      'content-id': '954331a6-4e5e-4d96-8204-74f56ce1f837',
      version: 32,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Ready Player One review – Spielberg ',
          message: 'Ready Player One review – Spielberg spins a dizzying VR yarn',
          image: {
            key: 'ace/2019-08/e3103795-ebb4-4c6c-93f0-2e829037e008/ready_player_one_hd_wallpapers_70749_6537851.png',
            url: null,
            attribution: null,
            caption: null,
            metadata: {
              width: 1920,
              height: 1080,
              'mime-type': 'image/png',
              'file-size': 587085,
              'file-name': 'ready-player-one-hd-wallpapers-70749-6537851.png',
              'focus-point': [883, 411]
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/a3561065-11ce-4281-9d86-325934aa2146/element/d42210d4-4c37-475c-ac6e-414821ad2e57',
          type: 'youtube-video',
          'family-id': '43f1bf4c-2144-4f86-b36e-d910ff76eb8d',
          title: '',
          id: 'd42210d4-4c37-475c-ac6e-414821ad2e57',
          url: 'https://www.youtube.com/watch?v=cSp1dM2Vj48&feature=youtu.be',
          'embed-url': 'https://www.youtube.com/embed/cSp1dM2Vj48',
          metadata: {},
          subtype: null
        },
        {
          id: '00000000-0000-0000-0000-000000000000',
          description: '',
          title: '',
          subtype: null,
          type: 'text',
          text: '<p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
          metadata: {
            'promotional-message': true
          }
        }
      ],
      'card-updated-at': 1524116209278,
      'content-version-id': 'f1e7f3ea-21f2-4801-b6d7-2022ee079478',
      'card-added-at': 1524116147733,
      status: 'draft',
      id: '464e6388-3a0d-49ca-aea7-bce0893259ba',
      'content-id': '464e6388-3a0d-49ca-aea7-bce0893259ba',
      version: 3,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Ready Player One review – Spielberg ',
          message: 'Ready Player One review – Spielberg spins a dizzying VR yarn',
          image: {
            key: 'ace/2019-08/e3103795-ebb4-4c6c-93f0-2e829037e008/ready_player_one_hd_wallpapers_70749_6537851.png',
            url: null,
            attribution: null,
            caption: null,
            metadata: {
              width: 1920,
              height: 1080,
              'mime-type': 'image/png',
              'file-size': 587085,
              'file-name': 'ready-player-one-hd-wallpapers-70749-6537851.png',
              'focus-point': [883, 411]
            }
          }
        }
      }
    }
  ],
  url:
    'https://ace-web.qtstage.io/anything/recent-stories/news/ready-player-one-review-spielberg-spins-a-dizzying-vr-yarn',
  'story-version-id': '28c7b47d-3b37-49f6-a0ba-2886d14182e4',
  'content-type': 'story',
  'content-updated-at': 1564650017530,
  'author-id': 123981,
  'owner-id': 123981,
  'linked-story-ids': [],
  access: 'subscription',
  'promotional-message': '<p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
  'asana-project-id': null,
  'first-published-at': 1524116216298,
  'hero-image-caption': 'hero image caption',
  version: 47,
  'story-template': 'review',
  'sequence-no': null,
  'created-at': 1564650011136,
  authors: [
    {
      id: 94980,
      name: 'Kuruvilla Choolackal',
      slug: 'kuruvilla-choolackal-2',
      'avatar-url': null,
      'avatar-s3-key': null,
      'twitter-handle': null,
      bio: null,
      'contributor-role': {
        id: 873,
        name: 'Author'
      }
    },
    {
      id: 123981,
      name: 'Ravigopal Kesari',
      slug: 'ravigopal-kesari',
      'avatar-url':
        'https://lh5.googleusercontent.com/-NhNrHEp1w4M/AAAAAAAAAAI/AAAAAAAAAAs/lzYwVY1BQdQ/photo.jpg?sz=50',
      'avatar-s3-key': null,
      'twitter-handle': null,
      bio: null,
      'contributor-role': {
        id: 873,
        name: 'Author'
      }
    }
  ],
  metadata: {
    'review-title': 'Ready Player One',
    'review-rating': {
      label: '1',
      value: '1'
    },
    'sponsored-by': 'quint',
    'card-share': {
      shareable: false
    }
  },
  'publish-at': null,
  'assignee-name': 'Ravigopal Kesari'
};
