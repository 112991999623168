import { produce } from 'immer';
import get from 'lodash/get';
import { getStoryStoreField, updatePageType } from '../../../../utils/utils';
import { getModuleType, getRowIndexToSlice, getRowsList } from '../row-helper';
import { getConfigPathFromStore } from './../../../../hooks/useConfigPathFromStore';
import { IRowDetails, ISelectedRow } from './../../../../utils/interfaces';

export const addRow = (state: any, action: any, position = 'up') =>
  produce(state, (draft: any) => {
    const page = getModuleType(action);
    const storeField = page === 'story' ? getStoryStoreField(action.payload.subPageType) : page;
    draft[storeField] = draft[storeField] || {};
    const rowsList = getRowsList(draft[storeField], page, action);
    const updateIndex = getRowIndexToSlice({
      rowsList: rowsList as ISelectedRow[],
      rowDetails: action.payload as IRowDetails,
      down: position === 'down'
    });
    const pageId = get(action, ['payload', 'pageId']);
    const configPathPart = getConfigPathFromStore(page, page === 'story' ? action.payload.subPageType : pageId);
    const updatedRows = [
      ...get(draft, [...configPathPart, 'rows'], []).slice(0, updateIndex),
      get(action.payload, 'row'),
      ...get(draft, [...configPathPart, 'rows'], []).slice(updateIndex)
    ];

    if (page === 'landingPage') {
      draft[page].layouts = {
        ...draft[page].layouts,
        [pageId]: {
          ...get(draft[page], ['layouts', pageId]),
          rows: updatedRows
        }
      };
    } else if (page === 'story') {
      draft[storeField][action.payload.subPageType].rows = updatedRows;
    } else {
      draft[page].rows = updatedRows;
    }
    draft.updatedPages = updatePageType(page, draft.updatedPages);
  });

export const deleteRow = (state: any, action: any) =>
  produce(state, (draft: any) => {
    const page = getModuleType(action);
    const storeField = page === 'story' ? getStoryStoreField(action.payload.subPageType) : page;
    const rowsList = getRowsList(draft[storeField], page, action);
    const updateIndex = getRowIndexToSlice({
      rowsList: rowsList as ISelectedRow[],
      rowDetails: action.payload as IRowDetails
    });
    const pageId = get(action, ['payload', 'pageId']);
    const configPathPart = getConfigPathFromStore(page, page === 'story' ? action.payload.subPageType : pageId);
    const updatedRows = [
      ...get(draft, [...configPathPart, 'rows'], []).slice(0, updateIndex),
      ...get(draft, [...configPathPart, 'rows'], []).slice(updateIndex + 1)
    ];
    if (page === 'landingPage') {
      draft[page].layouts = {
        ...draft[page].layouts,
        [pageId]: {
          ...get(draft[page], ['layouts', pageId]),
          rows: updatedRows
        }
      };
    } else if (page === 'story') {
      get(draft, [storeField, action.payload.subPageType, 'rows'], []).splice(updateIndex, 1);
      draft[storeField][action.payload.subPageType].rows = get(
        draft,
        [storeField, action.payload.subPageType, 'rows'],
        []
      );
    } else {
      get(draft, [page, 'rows'], []).splice(updateIndex, 1);
      draft[page].rows = get(draft, [page, 'rows'], []);
    }
    draft.updatedPages = updatePageType(page, draft.updatedPages);
  });

export const moveRow = (state: any, action: any) =>
  produce(state, (draft: any) => {
    const page = getModuleType(action);
    const pageId = get(action, ['payload', 'pageId']);
    const configPathPart = getConfigPathFromStore(page, page === 'story' ? action.payload.subPageType : pageId);
    const rows = get(draft, [...configPathPart, 'rows'], []);
    const tmpRow = rows[action.payload.index];
    rows[action.payload.index] = rows[action.payload.index + action.payload.relativePosition];
    rows[action.payload.index + action.payload.relativePosition] = tmpRow;
    draft.updatedPages = updatePageType(page, draft.updatedPages);
  });

export const updateSelectedRowLayout = (state: any, action: any) =>
  produce(state, (draft: any) => {
    const pageId = get(action, ['payload', 'pageId']);
    const page = getModuleType(action);
    const configPathPart = getConfigPathFromStore(page, page === 'story' ? action.payload.subPageType : pageId);
    const rows = get(draft, [...configPathPart, 'rows'], []);
    const selectedRowIndex = get(state, [...configPathPart, 'rows'], []).findIndex((rowData: ISelectedRow) => {
      return rowData.rowId === action.payload.selectedRowId;
    }, -1);
    rows[selectedRowIndex] = action.payload.row;
    draft.updatedPages = updatePageType(page, draft.updatedPages);
  });

export const updateSelectedRowSettings = (state: any, action: any) =>
  produce(state, (draft: any) => {
    const page = getModuleType(action);
    const pageId = get(action, ['payload', 'pageId']);
    const configPathPart = getConfigPathFromStore(page, page === 'story' ? action.payload.subPageType : pageId);
    const pageState = get(draft, [...configPathPart], {});
    pageState.rows = get(state, [...configPathPart, 'rows'], []).map((rowData: ISelectedRow) => {
      if (rowData.rowId === action.payload.selectedRowId) {
        return { ...rowData, config: { ...(rowData.config || {}), ...action.payload.row } };
      }
      return rowData;
    });
    draft.updatedPages = updatePageType(page, draft.updatedPages);
  });

export const updatePageSettings = (state: any, action: any) =>
  produce(state, (draft: any) => {
    const page = getModuleType(action);
    const pageId = get(action, ['payload', 'pageId']);
    const configPathPart = getConfigPathFromStore(page, pageId);
    const pageState = get(draft, [...configPathPart], { rows: [] });
    const config = get(action, ['payload', 'config'], {});
    pageState.pageSetting = { ...pageState.pageSetting, ...(config as object) };
    draft.updatedPages = updatePageType(page, draft.updatedPages);
  });
