import { FieldLabel } from '@quintype/em/components/field-label';
import { OutsideClickHandler } from '@quintype/em/components/outside-click-handler';
import { Chevron } from '@quintype/em/icons/chevron';
import React from 'react';
import { GlobalSocialLinkIcon } from '../../atoms/global-social-link-icons';
import { CircularColorSocialLink } from '../../social-link-icons/circular-color/index';
import { CircularPlainSocialLink } from '../../social-link-icons/circular-plain/index';
import { PlainColorSocialLink } from '../../social-link-icons/plain-color/index';
import { PlainSocialLink } from '../../social-link-icons/plain/index';
import { SquareSocialLink } from '../../social-link-icons/square/index';
import styles from './social-link-icons.module.css';

interface IProps {
  socialLinkDescription: string;
  layout?: string;
  updateStyles: any;
  supportPlainSvg?: boolean;
  defaultLayout?: string;
}
interface IState {
  showDropdown: boolean;
}
class SocialFollowStyleBase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showDropdown: false
    };
  }

  updateState = () => {
    return this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  };

  showIcons = () => {
    return (
      <OutsideClickHandler onOutsideClick={() => this.updateState()}>
        {!this.props.supportPlainSvg ? (
          <ul className={styles.socialLinkWrapper} onClick={this.updateState}>
            <li className={styles.socialLinkInnerWrapper} onClick={() => this.props.updateStyles('circular-color-svg')}>
              <CircularColorSocialLink />
            </li>
            <li className={styles.socialLinkInnerWrapper} onClick={() => this.props.updateStyles('circular-plain-svg')}>
              <CircularPlainSocialLink />
            </li>
            <li className={styles.socialLinkInnerWrapper} onClick={() => this.props.updateStyles('plain-color-svg')}>
              <PlainColorSocialLink />
            </li>
            <li className={styles.socialLinkInnerWrapper} onClick={() => this.props.updateStyles('plain-svg')}>
              <PlainSocialLink />
            </li>
            <li className={styles.socialLinkInnerWrapper} onClick={() => this.props.updateStyles('square-svg')}>
              <SquareSocialLink />
            </li>
          </ul>
        ) : (
          <ul className={styles.socialLinkWrapper} onClick={this.updateState}>
            <li
              data-test-id="social-color-icon"
              className={styles.socialLinkInnerWrapper}
              onClick={() => this.props.updateStyles('plain-color-svg')}
            >
              <PlainColorSocialLink />
            </li>
            <li
              data-test-id="social-plain-icon"
              className={styles.socialLinkInnerWrapper}
              onClick={() => this.props.updateStyles('plain-svg')}
            >
              <PlainSocialLink />
            </li>
          </ul>
        )}
      </OutsideClickHandler>
    );
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <FieldLabel label="Social Icon Style" classname={styles.label} />
        <div
          className={styles.wrapperSvg}
          onClick={event => {
            event.stopPropagation();
            this.updateState();
          }}
        >
          <GlobalSocialLinkIcon layout={this.props.layout} defaultLayout={this.props.defaultLayout} />
          <div className={styles.chevron}>
            <Chevron variant={this.state.showDropdown ? 'up' : 'down'} width={16} height={16} />
          </div>
        </div>
        {this.state.showDropdown && this.showIcons()}
        <p className={styles.socialLinkDescription}>{this.props.socialLinkDescription}</p>
      </div>
    );
  }
}

export const SocialFollowStyle = SocialFollowStyleBase;
