import defaultLowFidelity from './svg/default.svg';
import style1LowFidelity from './svg/style-1.svg';
import style2LowFidelity from './svg/style-2.svg';
import style3LowFidelity from './svg/style-3.svg';

const sectionTagData = [
  { name: '', svg: defaultLowFidelity },
  { name: 'borderBottomSml', svg: style1LowFidelity },
  { name: 'solid', svg: style2LowFidelity },
  { name: 'borderLeft', svg: style3LowFidelity }
];

export default sectionTagData;
