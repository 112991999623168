import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import { defaultDarkTheme, defaultTheme } from '../../constant';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';
import { loaderSwitch } from '../../molecules/loader-switch';
import { SelectRow } from '../../molecules/select-row';
import { ISelectedRow } from '../../utils/interfaces';
import { getStoryStoreField } from '../../utils/utils';
import styles from './component-loader.module.css';
interface IBuilder {
  selectedRows: ISelectedRow[];
}
interface IState {
  builder: IBuilder;
}
export const RowLoader = () => {
  const configPath = useConfigPathFromStore();
  const pageType = configPath?.[0] === 'login' ? configPath?.[1] : configPath?.[0];
  const subPageType = useSelector((state: IState) => {
    return get(state, ['builder', 'subPageType'], '');
  });
  const builderConfig = useSelector((state: IState) => {
    return get(state, ['builder', 'config'], {});
  });

  const componentMeta = useSelector((state: IState) => {
    return get(state, ['ahead', 'componentMetaData']);
  });

  const selectedRows = get(builderConfig, [...configPath, 'rows'], []);
  const rowsGeneralConfig = get(builderConfig, ['general', 'rows'], {});
  const enableDarkModePreview = get(builderConfig, ['enableDarkModePreview'], false);
  const pageSetting = get(builderConfig, [...configPath, 'pageSetting'], {});
  const storyPageSetting = get(builderConfig, [getStoryStoreField(subPageType), subPageType, 'settings'], {});
  // Hack for arrow prop :( TODO: Once arrow have a proper config structure, remove this
  const { iconStyle = 'star', enablePremiumStoryIcon = false } = get(rowsGeneralConfig, ['premiumStoryIcon'], {});
  let borderColor = get(rowsGeneralConfig, ['sectionTag', 'color'], '');
  let collectionNameBorderColor = get(rowsGeneralConfig, ['titleStyle', 'underLineColor'], '');
  let iconColor = get(rowsGeneralConfig, ['premiumStoryIcon', 'iconColor'], '#F7B500');
  let backgroundColor =
    pageType === 'story' || pageType === 'customStory'
      ? get(storyPageSetting, ['theme'], defaultTheme)
      : get(pageSetting, ['bgColor'], defaultTheme);

  if (enableDarkModePreview) {
    borderColor = get(rowsGeneralConfig, ['sectionTag', 'darkColor'], '#4860BC');
    collectionNameBorderColor = get(rowsGeneralConfig, ['titleStyle', 'darkUnderLineColor'], '#4860BC');
    iconColor = get(rowsGeneralConfig, ['premiumStoryIcon', 'darkIconColor'], '#F7B500');
    backgroundColor =
      pageType === 'story' || pageType === 'customStory'
        ? get(storyPageSetting, ['darkTheme'], defaultDarkTheme)
        : get(pageSetting, ['darkBgColor'], defaultDarkTheme);
  }

  const updatedRowsGeneralConfig = {
    ...rowsGeneralConfig,
    borderColor,
    sectionTagTemplate: get(rowsGeneralConfig, ['sectionTag', 'template'], ''),
    collectionNameBorderColor,
    iconStyle,
    iconColor,
    enablePremiumStoryIcon
  };

  if (selectedRows.length === 0) {
    return (
      <section className={`componentLoader ${styles.sectionWrapper}`}>
        <SelectRow />
      </section>
    );
  }

  return (
    <section className={`componentLoader ${styles.sectionWrapper}`} style={{ backgroundColor }}>
      {selectedRows.map((currentRow: ISelectedRow, index: number) =>
        loaderSwitch({
          currentRow,
          index,
          rowsGeneralConfig: updatedRowsGeneralConfig,
          componentMeta,
          backgroundColor,
          pageType,
          subPageType,
          builderConfig,
          configPath,
          enableDarkModePreview
        })
      )}
    </section>
  );
};
