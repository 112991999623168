import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Setting: React.FunctionComponent<IsvgData> = ({ width = '24', height = '24', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.906 13.094l1.313.781a.34.34 0 01.172.188.412.412 0 01.015.25 7.757 7.757 0 01-1.719 2.937.464.464 0 01-.218.125.353.353 0 01-.25-.031l-1.313-.781A5.984 5.984 0 0114 17.656v1.531a.396.396 0 01-.078.235.347.347 0 01-.203.14 7.971 7.971 0 01-3.438 0 .347.347 0 01-.203-.14.396.396 0 01-.078-.235v-1.53a5.984 5.984 0 01-1.906-1.094l-1.313.78a.353.353 0 01-.25.032.464.464 0 01-.219-.125 7.757 7.757 0 01-1.718-2.937.412.412 0 01.015-.25.336.336 0 01.172-.188l1.313-.781a6.427 6.427 0 010-2.188l-1.313-.781a.336.336 0 01-.172-.187.412.412 0 01-.015-.25A7.757 7.757 0 016.312 6.75a.464.464 0 01.22-.125.353.353 0 01.25.031l1.312.782A5.984 5.984 0 0110 6.344V4.813c0-.084.026-.162.078-.235a.347.347 0 01.203-.14 7.971 7.971 0 013.438 0c.083.02.15.067.203.14a.396.396 0 01.078.235v1.53c.708.25 1.344.615 1.906 1.095l1.313-.782a.353.353 0 01.25-.031c.083.02.156.063.218.125a7.757 7.757 0 011.72 2.938.412.412 0 01-.016.25.336.336 0 01-.172.187l-1.313.781a6.43 6.43 0 010 2.188zm-1.101.508l.116-.677a5.427 5.427 0 000-1.85l-.116-.677.59-.351.889-.527a6.696 6.696 0 00-1.015-1.73l-1.466.873-.545-.464a4.984 4.984 0 00-1.59-.912L13 7.05V5.323a6.911 6.911 0 00-2-.002v1.73l-.667.236a4.984 4.984 0 00-1.59.912l-.546.464-.615-.366-.848-.507a6.711 6.711 0 00-1.02 1.726l1.481.882-.116.677a5.427 5.427 0 000 1.85l.116.677-.59.351-.889.527a6.696 6.696 0 001.015 1.73l1.466-.873.545.464c.47.4.997.703 1.59.912l.668.236v1.728a6.91 6.91 0 002 .002v-1.73l.667-.236a4.984 4.984 0 001.59-.912l.546-.464.615.366.848.507a6.711 6.711 0 001.02-1.726l-1.481-.882zM14.5 12a2.41 2.41 0 01-.734 1.766c-.49.49-1.079.734-1.766.734a2.407 2.407 0 01-1.766-.734A2.407 2.407 0 019.5 12c0-.687.245-1.276.734-1.766A2.407 2.407 0 0112 9.5c.687 0 1.276.245 1.766.734.49.49.734 1.079.734 1.766zm-1 0c0-.422-.14-.756-.442-1.058A1.41 1.41 0 0012 10.5c-.422 0-.756.14-1.059.442A1.41 1.41 0 0010.5 12c0 .422.14.756.441 1.059A1.41 1.41 0 0012 13.5c.422 0 .756-.14 1.058-.441A1.41 1.41 0 0013.5 12z"
      ></path>
    </svg>
  );
};

export { Setting };
