import React from 'react';
import { AccordionSection } from '@quintype/em/components/accordion';
import get from 'lodash/get';
import { updateMeterWallSettings, renderAccordionSection, meteringOffShortCodes } from '../payWallUtils';

// metering off storiesBehindSubscription
export function storiesBehindSubscription(modules: any, formats: any, pageSetting: any, dispatch: any): JSX.Element {
  const config = [
    {
      type: 'hintText',
      text:
        'Use this if you have Turned OFF metering for stories that are behind subscription for Anonymous or Logged In users.'
    },
    {
      type: 'quillEditor',
      label: 'Paywall title',
      placeholder: 'Subscribe to gain access to this premium article.',
      value: get(pageSetting, ['meteringOff', 'subscription', 'wallTitle']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOff', '', 'subscription', '', 'wallTitle', value, dispatch)
    },
    {
      type: 'quillEditor',
      label: 'Paywall description',
      placeholder:
        'To access our premium articles and enjoy an uninterrupted reading experience, please consider subscribing to one of our plans.',
      value: get(pageSetting, ['meteringOff', 'subscription', 'wallDescription']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOff', '', 'subscription', '', 'wallDescription', value, dispatch)
    },
    {
      type: 'text',
      label: 'View all plans button',
      placeholder: 'View all plans',
      value: get(pageSetting, ['meteringOff', 'subscription', 'buttonTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOff', '', 'subscription', '', 'buttonTxt', value, dispatch)
    },
    {
      type: 'text',
      label: 'Sign in',
      placeholder: 'Sign in',
      value: get(pageSetting, ['meteringOff', 'subscription', 'signInTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOff', '', 'subscription', '', 'signInTxt', value, dispatch)
    },
    {
      type: 'text',
      label: 'Already a subscriber?',
      placeholder: 'Already a subscriber? [Sign in]',
      value: get(pageSetting, ['meteringOff', 'subscription', 'bottomTxt']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOff', '', 'subscription', '', 'bottomTxt', value, dispatch)
    }
  ];

  return (
    <AccordionSection label={'stories behind subscription'} isLabelUpperCase index={0}>
      {renderAccordionSection(config, modules, formats)}
      {meteringOffShortCodes()}
    </AccordionSection>
  );
}
