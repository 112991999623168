import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserDetails } from '../utils/utils';

function useUserDetails() {
  const [userDetails, setUser] = useState({
    userId: '',
    email: ''
  });
  const { publisherId = '' } = useParams();
  useEffect(() => {
    // Using an IIFE
    (async () => {
      const response = await getUserDetails(publisherId);
      setUser(response);
    })();
  }, [publisherId]);
  return userDetails;
}

export default useUserDetails;
