import { Loader } from '@quintype/em/components/loader';
import axios from 'axios';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { setPageId } from '../../actions/builder-action';
import AuthorPage from '../../containers/author-page';
import AuthorsPage from '../../containers/authors-page';
import SubscriptionPage from '../../containers/subscription-page';
import PaywallBanner from '../../molecules/pay-wall-banner';
import { RowLoader } from '../../containers/component-loader';
import IssueLandingPage from '../../containers/issue-landing-page';
import MagazineArchivePage from '../../containers/magazine-archive-page';
import MagazineLandingPage from '../../containers/magazine-landing-page';
import SearchPage from '../../containers/search-page';
import { StorypageTab } from '../../containers/story/selection-tab';
import TagPage from '../../containers/tag-page';
import { useQuery } from '../../hooks/useQuery';
import { loaderSwitch } from '../../molecules/loader-switch';
import { AHEAD_UPDATE_COMPONENT_LIBRARY_META } from '../../reducers/ahead/component-meta';
import { AHEAD_COMPONENT_LIBRARY_LOADED } from '../../reducers/ahead/library-loader';
import {
  LOAD_DUMMY_MEMBER,
  LOAD_DUMMY_MEMBER_SUBSCRIPTIONS,
  DUMMY_MEMBER_SUBSCRIPTIONS_LOADED,
  DUMMY_MEMBER_LOADED
} from '../../reducers/member-and-subscriptions';
import { AHEAD_UPDATE_STORY_COMPONENT_LIBRARY_META } from '../../reducers/ahead/story-components-meta';
import { ISelectedRow } from '../../utils/interfaces';
import { getScript } from '../../utils/utils';
import { AheadBreakingNews } from '../ahead-breaking-news';
import AheadFontLoader from '../ahead-fontloader';
import { AheadFooter } from '../ahead-footer';
import styles from './styles.module.css';

type IActionCreator = (payload?: any) => object;

interface IProps {
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideBreakingNews?: boolean;
  updateClientComponentList: any;
  loadDummyMember: any;
  loadDummyMemberSubscriptions: any;
  updateClientStoryComponentList: any;
  dummyMemberSubscriptionsLoaded: any;
  dummyMemberLoaded: any;
  isAppLoading: boolean;
  notifyAheadComponentLibraryLoaded: IActionCreator;
  headerRowsList: ISelectedRow[];
  isBreakingNewsEnabled: boolean;
  enableDarkModePreview: boolean;
}

interface IState {
  svgSpriteData: string;
  svgSpriteLoaded: boolean;
  componentsLoaded: boolean;
}
class AheadPreviewBase extends React.Component<IProps, IState> {
  instance: any;
  constructor(props: IProps) {
    super(props);
    this.instance = null;
    this.state = {
      svgSpriteLoaded: false,
      svgSpriteData: '',
      componentsLoaded: false
    };
  }
  componentDidMount() {
    this.getSVGSpriteContents();
    this.executeComponentScript();
    this.setDummyMemberData();
  }

  getSVGSpriteContents() {
    axios.get(`/ahead/svg-sprite.svg`).then(res => {
      this.setState({
        svgSpriteLoaded: true,
        svgSpriteData: res.data || ''
      });
    });
  }

  setDummyMemberData = () => {
    this.props.loadDummyMember();
    this.props.dummyMemberLoaded();
    this.props.loadDummyMemberSubscriptions();
    this.props.dummyMemberSubscriptionsLoaded();
  };

  executeComponentScript = () => {
    // We nest the calls here so that we can control what happens only when all scripts are loaded
    // Feel free to refactor this into a linear promise chain if you're adding more scripts here
    getScript('/ahead/components.js', this.instance, () => {
      getScript('/ahead/story-components.js', this.instance, this.setData);
    });
  };

  public setData = () => {
    this.setState({ componentsLoaded: true });
    this.props.notifyAheadComponentLibraryLoaded();
    axios.get(`/ahead/components-metadata`).then(res => {
      this.props.updateClientComponentList(res.data);
    });
    axios.get(`/ahead/story-components-metadata`).then(res => {
      this.props.updateClientStoryComponentList(res.data);
    });
  };

  render() {
    const isBreakingNewsVisibled = !this.props.hideBreakingNews && this.props.isBreakingNewsEnabled;

    const routesData = [
      {
        path: '/accounts/:publisherId/:domain/section',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/landing-page',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/story/:storyType',
        component: StorypageTab
      },
      {
        path: '/accounts/:publisherId/:domain/issue-landing',
        component: IssueLandingPage
      },
      {
        path: '/accounts/:publisherId/:domain/magazine-landing',
        component: MagazineLandingPage
      },
      {
        path: '/accounts/:publisherId/:domain/magazine-archive',
        component: MagazineArchivePage
      },
      {
        path: '/accounts/:publisherId/:domain/tag',
        component: TagPage
      },
      {
        path: '/accounts/:publisherId/:domain/author',
        component: AuthorPage
      },
      {
        path: '/accounts/:publisherId/:domain/authors',
        component: AuthorsPage
      },
      {
        path: '/accounts/:publisherId/:domain/subscription',
        component: SubscriptionPage
      },
      {
        path: '/accounts/:publisherId/:domain/manage-subscriptions',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/available-plans',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/plan-change-overview',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/search',
        component: SearchPage
      },
      {
        path: '/accounts/:publisherId/:domain',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/sign-in',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/sign-up',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/forgot-password',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/reset-password',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/email-otp',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/mobile-otp',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/enter-password',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/metering',
        component: PaywallBanner
      },
      {
        path: '/accounts/:publisherId/:domain/user-details',
        component: RowLoader
      },
      {
        path: '/accounts/:publisherId/:domain/purchased-stories',
        component: RowLoader
      }
    ];

    return (
      <div id="ahead-preview" className={styles.aheadPreview}>
        {/* To Inject script and style tags */}
        <div ref={ref => (this.instance = ref)} />
        {/* To inject SVG sprite data */}
        <div
          id="svg-container"
          className={styles.svgContainer}
          dangerouslySetInnerHTML={{ __html: this.state.svgSpriteData }}
        />
        {this.state.componentsLoaded && (
          <>
            {!this.props.hideHeader &&
              this.props.headerRowsList.map((currentRow: ISelectedRow, index: number) =>
                loaderSwitch({
                  currentRow,
                  index,
                  isHeaderRow: true,
                  enableDarkModePreview: this.props.enableDarkModePreview
                })
              )}
            {isBreakingNewsVisibled && <AheadBreakingNews />}
            <SetPageId />
            <AheadFontLoader />
            {this.props.isAppLoading ? <Loader /> : null}
            <Switch>
              {routesData.map(route => (
                <Route exact path={route?.path} component={route?.component} />
              ))}
              <Redirect to="/accounts/:publisherId/:domain" />
            </Switch>
            {!this.props.hideFooter && <AheadFooter />}
          </>
        )}
      </div>
    );
  }
}

const SetPageId = () => {
  const queryParams = useQuery();
  const dispatch = useDispatch();
  const layoutId = queryParams.get('layoutId') || '';
  useEffect(() => {
    dispatch(setPageId(layoutId));
  }, [dispatch, layoutId]);
  return null;
};

interface IComponent {
  componentName: string;
  image: string;
}

const updateClientComponentList = (componentList: IComponent[]): object => {
  return {
    type: AHEAD_UPDATE_COMPONENT_LIBRARY_META,
    payload: componentList
  };
};
const updateClientStoryComponentList = (storycomponentList: IComponent[]): object => {
  return {
    type: AHEAD_UPDATE_STORY_COMPONENT_LIBRARY_META,
    payload: storycomponentList
  };
};
const notifyAheadComponentLibraryLoaded = () => {
  return {
    type: AHEAD_COMPONENT_LIBRARY_LOADED,
    loaded: true
  };
};
const loadDummyMember = () => {
  return {
    type: LOAD_DUMMY_MEMBER,
    loaded: true
  };
};
const dummyMemberLoaded = () => {
  return {
    type: DUMMY_MEMBER_LOADED,
    loaded: true
  };
};
const loadDummyMemberSubscriptions = () => {
  return {
    type: LOAD_DUMMY_MEMBER_SUBSCRIPTIONS,
    loaded: true
  };
};
const dummyMemberSubscriptionsLoaded = () => {
  return {
    type: DUMMY_MEMBER_SUBSCRIPTIONS_LOADED,
    loaded: true
  };
};

const mapStateToProps = (state: any) => {
  return {
    isAppLoading: get(state, ['builder', 'appLoading']),
    headerRowsList: get(state, ['builder', 'config', 'header', 'rows'], []),
    isBreakingNewsEnabled: get(state, ['builder', 'config', 'general', 'breakingNews', 'isEnabled'], true),
    enableDarkModePreview: get(state, ['builder', 'config', 'enableDarkModePreview'], false)
  };
};

export const AheadPreview = connect(mapStateToProps, {
  updateClientComponentList,
  updateClientStoryComponentList,
  notifyAheadComponentLibraryLoaded,
  loadDummyMember,
  loadDummyMemberSubscriptions,
  dummyMemberSubscriptionsLoaded,
  dummyMemberLoaded
})(AheadPreviewBase);

export default AheadPreview;
