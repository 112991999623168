import {
  PAGEBUILDER_HAVE_UNSAVED_CHANGES,
  PAGEBUILDER_INFINITE_SCROLL
} from '../reducers/builder/configReducer/actions';

interface IConfigInfiniteScroll {
  [key: string]: string | boolean;
}

interface IDispatchInfiniteScroll {
  type: string;
  payload: IConfigInfiniteScroll;
}

type IDispatchFooterThemeAttributes = (arg: IDispatchInfiniteScroll) => void;

export const updateInfiniteScroll = (payload: IConfigInfiniteScroll) => (dispatch: IDispatchFooterThemeAttributes) => {
  dispatch({ type: PAGEBUILDER_INFINITE_SCROLL, payload });
  dispatch({ type: PAGEBUILDER_HAVE_UNSAVED_CHANGES, payload });
};
