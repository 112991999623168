import { FieldLabel } from '@quintype/em/components/field-label';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import Select from '@quintype/em/components/select';
import { TextField } from '@quintype/em/components/text-field';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Separator } from '../../atoms/separator';
import { ContentSorter } from '../../molecules/content-sorter';
import { SocialFollowStyle } from '../../molecules/social-link-style';
import { ThemeSetter } from '../../molecules/theme-setter';
import { ToggleButton } from '../../molecules/toggle-button';
import styles from './setting-components.module.css';
import { IconSelector } from '../../molecules/icon-selector';

interface IRadioOption {
  name: string;
  value: string;
}

interface IOption {
  label: string;
  value: string;
}

interface IIconOption {
  value: string;
  svg: string;
}

interface IDataItems {
  type: string;
  label?: string;
  key?: string;
  defaultValue?: any;
  description?: string;
  placeHolder?: string;
  darkKey?: string;
  onChange?: (key: string, value: any) => void;
  radioOptions?: IRadioOption[];
  options?: IOption[];
  marginSize?: string;
  iconOptions?: IIconOption[];
}

interface IProps {
  configOptions: IDataItems[];
}

export const SettingsElements = ({ configOptions }: IProps) => {
  return (
    <>
      {configOptions.map((item: IDataItems) => {
        const {
          type,
          label = '',
          key = '',
          defaultValue = '',
          placeHolder,
          darkKey = '',
          onChange = () => null,
          radioOptions = [],
          description = '',
          options = [],
          marginSize,
          iconOptions
        } = item || {};

        switch (type) {
          case 'iconSelector':
            return (
              <IconSelector
                label={label}
                keyName={key}
                options={iconOptions}
                defaultValue={defaultValue}
                onChange={onChange}
              />
            );
          case 'iconArrayEditor':
            return (
              <ContentSorter
                label={label}
                keyName={key}
                options={options}
                defaultValue={defaultValue}
                onChange={onChange}
              />
            );
          case 'dropdown':
            const defaultOption = options.filter(item => item.value === defaultValue);
            return (
              <>
                <FieldLabel label={label} />
                <Select
                  defaultValue={defaultOption}
                  placeholder={placeHolder}
                  options={options}
                  onChange={(option: { value: any }) => onChange(key, option.value)}
                />
              </>
            );
          case 'quillEditor':
            const modules = {
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }]
              ]
            };

            const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet'];
            return (
              <div className={styles.quillWrapper}>
                <FieldLabel label={label} />
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={defaultValue}
                  onChange={(value: any) => onChange(key, value)}
                  placeholder=" "
                />
              </div>
            );
          case 'radioButton':
            return (
              <div className={styles.radioButtonWrapper}>
                <Radio
                  label={label}
                  name={defaultValue}
                  align="vertical"
                  selected={defaultValue}
                  onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) => {
                    onChange(key, (e.target as HTMLInputElement).value);
                  }}
                >
                  {radioOptions?.map(({ name, value }: { name: string; value: string }, index: number) => {
                    return (
                      <RadioOption value={value} id={value + index} key={value + index}>
                        {name}
                      </RadioOption>
                    );
                  })}
                </Radio>
              </div>
            );
          case 'socialShareIcons':
            return (
              <SocialFollowStyle
                defaultLayout={defaultValue}
                socialLinkDescription={description}
                updateStyles={(value: any) => onChange(key, value)}
              />
            );
          case 'textField':
            return (
              <TextField
                label={label}
                placeholder={placeHolder}
                value={defaultValue}
                onChange={(value: any) => onChange(key, value)}
              />
            );
          case 'toggle':
            return (
              <div className={styles.toggleContainer}>
                <ToggleButton
                  label={label}
                  toggleName={key}
                  id={key}
                  updateToggleState={defaultValue}
                  updateToggleButton={(name: string, value: boolean) => onChange(name, value)}
                />
              </div>
            );
          case 'colorPicker':
            return (
              <div className={styles.colorPickerContainer}>
                <ThemeSetter
                  label={label}
                  updateTheStore={onChange}
                  lightColorExtract={defaultValue}
                  lightUpdateStoreKey={key}
                  darkUpdateStoreKey={darkKey}
                  lightDefaultColor="#4860BC"
                  darkDefaultColor="#4860BC"
                />
              </div>
            );
          case 'separator':
            return <Separator marginSize={marginSize} />;
          case 'fieldLabel':
            return (
              <div className={styles.fieldLabel}>
                <FieldLabel label={label} />
                {description && <div className={styles['description']}>{description}</div>}
              </div>
            );
        }
      })}
    </>
  );
};
