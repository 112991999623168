import React from 'react';
import get from 'lodash/get';
import { TextField } from '@quintype/em/components/text-field';
import { useDispatch, useSelector } from 'react-redux';
import { fallbackImage } from '../../actions/general-settings-action';

const FallbackImage = () => {
  const fallbackImageurl = useSelector(state =>
    get(state, ['builder', 'config', 'general', 'fallbackImage', 'url'], '')
  );
  const dispatch = useDispatch();

  const addLogoToArray = (key: string, fallback: string) => {
    dispatch(fallbackImage({ [key]: fallback }));
  };

  return (
    <TextField
      placeholder="Enter the image url here"
      label="Add a Fallback Image"
      onChange={(value: any) => addLogoToArray('url', value)}
      value={fallbackImageurl}
      hint="Default image to be used for stories without hero image."
    />
  );
};

export default FallbackImage;
