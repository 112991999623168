import { FieldLabel } from '@quintype/em/components/field-label';
import Select from '@quintype/em/components/select';
import React, { useState } from 'react';
import { PlusCircle } from '../../atoms/circle-with-plus-svg';
import { Trash } from '../../atoms/trash';
import styles from './content-sorter.module.css';

interface IOption {
  label: string;
  value: string;
}

interface IProps {
  label: string;
  options?: IOption[];
  onChange: (key: string, value: any) => void;
  defaultValue: string[];
  keyName: string;
}

export const ContentSorter = ({ label, options, onChange, keyName, defaultValue }: IProps) => {
  const getDropdownOptions = (visibleRowsArray: string[]) =>
    options?.map((item: { value: any }) => (!visibleRowsArray.includes(item.value) ? item : null)).filter(item => item);
  const [rows, setRows] = useState(defaultValue);
  const [dropdownOptions, setDropdownOptions] = useState(getDropdownOptions(defaultValue));
  const updateRow = (newValue: string, newValueIndex: number) => {
    const newArray = [...rows];
    newArray[newValueIndex] = newValue;
    const updatedDropdownOptions = getDropdownOptions(newArray);
    onChange(keyName, newArray);
    setRows(newArray);
    setDropdownOptions(updatedDropdownOptions);
  };

  const deleteRow = (index: number) => {
    const newArray = [...rows];
    newArray.splice(index, 1);
    const updatedDropdownOptions = getDropdownOptions(newArray);
    onChange(keyName, newArray);
    setRows(newArray);
    setDropdownOptions(updatedDropdownOptions);
  };

  const addRow = () => {
    const newArray = [...rows];
    newArray.push('');
    setRows(newArray);
  };

  const conditionToDisplayNewRow = rows?.length === options?.length;

  const getCurrentSelectedRow = (row: string) => {
    if (row) {
      const selectedOptionIndex = (options || []).findIndex(option => option.value === row);
      return options?.[selectedOptionIndex];
    }
    return null;
  };
  return (
    <div>
      <FieldLabel label={label} />
      {rows.map((item: any, index: number) => {
        return (
          <div className={styles['icon-seletion-wrapper']} key={item + index}>
            <Select
              defaultValue={getCurrentSelectedRow(item)}
              options={dropdownOptions}
              onChange={(option: { value: any }) => updateRow(option.value, index)}
            />
            <div onClick={() => deleteRow(index)}>
              <Trash color="#000" />
            </div>
          </div>
        );
      })}
      {conditionToDisplayNewRow ? null : (
        <div onClick={() => addRow()}>
          <PlusCircle />
        </div>
      )}
    </div>
  );
};
