import { Checkbox } from '@quintype/em/components/checkbox';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { togglePerformanceBots, updateHaveUnsavedChanges } from '../../actions/builder-action';
import { InfoTooltip } from '../../molecules/info-tooltip';
import styles from './bots-settings.module.css';

const BotsSettings = () => {
  const dispatch = useDispatch();
  const { bots = {} } = useSelector((state: any) => get(state, ['builder', 'config', 'performance'], {}));

  const { gtm = false, adSense = false } = bots;

  const updateBotConfig = (key: string, value: any) => {
    dispatch(togglePerformanceBots(key, { [key]: value }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  return (
    <>
      <div className={styles.checkboxWrapper}>
        <div className={styles.tooltipWrapper}>
          <Checkbox
            label="Google Tag Manager"
            id="bots_gtm"
            checked={gtm}
            onChange={(value: boolean) => updateBotConfig('gtm', value)}
          />
          <InfoTooltip
            width={20}
            height={20}
            tooltipDescription="We recommend disabling GTM for bots"
            tooltipId="bots_gtm_tooltip"
            tooltipPlace="bottom"
          />
        </div>
        <div className={styles.tooltipWrapper}>
          <Checkbox
            label="Google AD Sense"
            id="bots_adSense"
            checked={adSense}
            onChange={(value: boolean) => updateBotConfig('adSense', value)}
          />
          <InfoTooltip
            width={20}
            height={20}
            tooltipDescription="We recommend disabling AdSense for bots"
            tooltipId="bots_gtm_adsense_tooltip"
            tooltipPlace="bottom"
          />
        </div>
      </div>
    </>
  );
};

export default BotsSettings;
