import get from 'lodash/get';
import React, { Component, ComponentClass, FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { BASE_API_URL } from './utils';

interface IProps {
  [key: string]: object;
}

interface IState {
  loading: boolean;
  redirect: boolean;
}

type IComponent = string | FunctionComponent<string> | ComponentClass<string, any>;

const withAuth = (ComponentToProtect: IComponent) => {
  return class extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = {
        loading: true,
        redirect: false
      };
    }

    componentDidMount = async () => {
      const publisherId = get(this.props, ['match', 'params', 'publisherId'], null);
      try {
        const res = await fetch(`${BASE_API_URL}/${publisherId}/checkToken`);
        const responseData = await res.json();
        if (res.status === 200 && get(responseData, ['status']) === 'success') this.setState({ loading: false });
        else throw new Error(res.statusText);
      } catch (err) {
        console.log(err);
        this.setState({ loading: false, redirect: true });
      }
    };

    render = () => {
      const { loading, redirect } = this.state;
      if (loading) return null;
      if (redirect) return <Redirect to="/login" />;
      return React.createElement(ComponentToProtect);
    };
  };
};

export default withAuth;
