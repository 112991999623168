import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import Select from '@quintype/em/components/select';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import updateFonts from '../../actions/update-fonts-action';
import { PAGEBUILDER_GENERAL_UPDATE_FONTS } from '../../reducers/builder/configReducer/actions';
import { Compare } from '../../utils/utils';
import { dataList, selectFontList } from './data.fixtures';
import CustomFontUploader from './font-uploader';
import styles from './font-uploader.module.css';
import { FontFamilies, FontLanguage, fontsList } from './fonts-list';

interface FontsProps {
  fonts: any;
  updateFonts: (fonts: any, value: string) => void;
  updateHaveUnsavedChanges: (value: boolean) => void;
  onClickHandler: any;
  fontType?: any;
}
interface FontsData {
  label: string;
  [value: string]: string;
}

interface FontsState {
  defaultFont: string;
  defaultLanguage: { label: string; value: string };
  languageBasedFontsList: FontsData[];
}

interface IFontItem {
  'option-name': string;
  'variable-name': string;
  label: string;
  options: FontsData[];
}

class LanguageFontsBase extends React.Component<FontsProps, FontsState> {
  constructor(props: FontsProps) {
    super(props);
    const languageScript = get(this.props.fonts, ['languageScript'], 'English(Latin)');
    this.state = {
      defaultFont: FontFamilies.Roboto,
      defaultLanguage: { label: FontLanguage.Latin, value: FontLanguage.Latin },
      languageBasedFontsList: fontsList[languageScript]
    };
  }

  getFontList = (fontList: { 'variable-name': string; options: FontsData[] }) => {
    const { 'variable-name': variableName, options = [] } = fontList;
    const updatedFonts = variableName === 'languageScript' ? options : this.state.languageBasedFontsList;
    return updatedFonts && updatedFonts.sort(Compare);
  };

  fontsHandler(value: string, dataItem: IFontItem, isLanguageScript?: boolean) {
    this.props.updateHaveUnsavedChanges(true);
    this.props.updateFonts(dataItem['variable-name'], value);
    if (isLanguageScript) {
      this.setState(
        {
          ...this.state,
          languageBasedFontsList: fontsList[value]
        },
        () => {
          // Whenever language changes, change the primary and secondary fonts to the first font of selected lanugage.
          this.props.updateFonts('primaryFont', get(this.state.languageBasedFontsList, [0, 'label'], ''));
          this.props.updateFonts('secondaryFont', get(this.state.languageBasedFontsList, [0, 'label'], ''));
        }
      );
      return;
    }
  }

  getStoreValue(val: string = 'languageScript') {
    return {
      label: get(this.props.fonts, [val]),
      value: get(this.props.fonts, [val])
    };
  }

  render() {
    return (
      <>
        <div className={styles.radioWrapper}>
          <Radio
            name="fontType"
            selected={this.props.fontType}
            onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) => {
              this.props.updateFonts('fontType', (e.target as HTMLInputElement).value);
              this.props.updateHaveUnsavedChanges(true);
            }}
          >
            <RadioOption id="1" value="googleFont">
              Google Font
            </RadioOption>
            <RadioOption id="2" value="customFont">
              Custom Fonts
            </RadioOption>
          </Radio>
        </div>
        {this.props.fontType === 'customFont' ? (
          <CustomFontUploader />
        ) : (
          <>
            {dataList.data.map((dataItem: any, index: number) => (
              <Select
                label="Language Script"
                value={
                  this.getStoreValue('languageScript').value
                    ? this.getStoreValue('languageScript')
                    : this.state.defaultLanguage
                }
                defaultValue={this.state.defaultLanguage}
                options={this.getFontList(dataItem)}
                onChange={(fontObject: any) => {
                  this.fontsHandler(fontObject.value, dataItem, true);
                }}
                key={index}
                customStyles={{ color: 'blue' }}
                placeholder={this.state.defaultLanguage}
              />
            ))}
            {selectFontList.data.map((dataItem: IFontItem, index: number) => (
              <Select
                value={this.getStoreValue(dataItem.label)}
                label={dataItem['option-name']}
                options={this.getFontList(dataItem)}
                onChange={(fontObject: any) => {
                  this.fontsHandler(fontObject.value, dataItem);
                }}
                key={dataItem.label + '-' + index}
                customStyles={{ color: 'blue' }}
                placeholder={this.state.defaultFont}
              />
            ))}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: {}) => ({
  fonts: get(state, ['builder', 'config', 'general', 'fonts'], {}),
  fontType: get(state, ['builder', 'config', 'general', 'fonts', 'fontType'], 'googleFont')
});

const mapDispatchToProps = (dispatch: any) => ({
  updateFonts: (fontType: any, fontValue: string) => {
    return dispatch(updateFonts({ type: PAGEBUILDER_GENERAL_UPDATE_FONTS, payload: { [fontType]: fontValue } }));
  },
  updateHaveUnsavedChanges: (value: boolean) => {
    dispatch(updateHaveUnsavedChanges(value));
  }
});

export const LanguageFonts = connect(mapStateToProps, mapDispatchToProps)(LanguageFontsBase);
