import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openSelectRowInspector } from '../../actions/select-row-inspector-action';
import { Plus } from '../../atoms/plus-svg';
import styles from './styles.module.css';

interface IProps {
  rowId?: number;
  down?: boolean;
  isHeaderRow?: boolean;
}

export const SelectRow = ({ rowId = 0, down, isHeaderRow = false }: IProps) => {
  const getConfig = useSelector(state => get(state, ['builder'], ''));
  const hasEditAccess = get(getConfig, ['editControl', 'access'], '');
  const pageType = getConfig.pageType;
  const supportedPages = ['tag', 'author', 'authors', 'search', 'magazine', 'story'];
  const isSupportedPages = supportedPages.includes(pageType);

  const dispatch = useDispatch();
  const handleRowList = () => {
    dispatch(
      openSelectRowInspector({
        type: isHeaderRow || isSupportedPages ? 'Ads' : 'Collections',
        action: down ? 'add_row_bottom' : 'add_row_top',
        rowId,
        isHeaderRow
      })
    );
  };

  if (!hasEditAccess) return null;
  return (
    <div className={styles.buttonWrapper}>
      <div className={`${styles.button}`} onClick={handleRowList}>
        <div data-test-id="ad-row" className={styles.addRowIcon}>
          <Plus />
        </div>
      </div>
      <div className={styles.dashedLine} />
    </div>
  );
};
