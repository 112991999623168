import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPageType } from '../../actions/builder-action';
import AheadPreview from '../../ahead/ahead-preview';
import { Header } from '../../row/header';

export default function PurchasedStory() {
  const { publisherId = '' } = useParams();
  const dispatch = useDispatch();
  dispatch(setPageType('purchased-story-page'));
  return (
    <>
      <Header publisherId={publisherId} />
      <AheadPreview />
    </>
  );
}
