import get from 'lodash/get';
import { IStorySlot, IStorySlotConfig } from '../../../utils/interfaces';

export interface IAdOptionsProps {
  slotId: string;
  slotDetails: IStorySlotConfig;
  setAdSize: (slotId: string, key: string, value: string) => void;
  selected?: string;
}

export interface IStorySlotInspectorProps {
  storyType: string;
  storyTemplate: string;
}

export interface ISlotMenu {
  slotPosition: string;
  item: IStorySlot;
  slotId: string;
  slotDetails: IStorySlotConfig;
}

export const getComponentType = (position: string | undefined, slotId: string, item: IStorySlot) => {
  switch (position) {
    case 'aside':
      return get(item, [slotId, 'component-type'], 'collection');
    case 'story':
      if (get(item, [slotId, 'component-type']) === 'collection') {
        return 'ad';
      }
      return get(item, [slotId, 'component-type'], 'ad');
    default:
      return get(item, [slotId, 'component-type'], 'ad');
  }
};

// slots are stored as { "slotId": IStorySlotConfig }
export const getSlotId = (slot: object) => Object.keys(slot)[0];

export const asideSlotsSupportedStoryTemplates = ['default', 'headline-priority', 'hero-priority-left'];

export const selectSlotType = {
  label: 'Slot Position',
  options: (showAllOptions: boolean) => {
    if (showAllOptions) {
      return [
        { label: 'Sidebar', value: 'aside' },
        { label: 'Story', value: 'story' }
      ];
    }
    return [{ label: 'Story', value: 'story' }];
  }
};

export const placement = {
  label: 'Placement',
  options: [
    { label: 'Before', value: 'before' },
    { label: 'After', value: 'after' }
  ]
};

export const selectCard = (slotConfig: IStorySlotConfig) => {
  const adPlacement = get(slotConfig, ['placement']);
  const cards = {
    label: 'Select Card',
    options: [
      { label: 'Card 1', value: 'card-1' },
      { label: 'Card 2', value: 'card-2' },
      { label: 'Card 3', value: 'card-3' },
      { label: 'Card 4', value: 'card-4' },
      { label: 'Card 5', value: 'card-5' }
    ]
  };

  if (adPlacement === 'before') {
    return cards;
  } else {
    cards.options.push({ label: 'Story', value: 'story' });
    return cards;
  }
};

export const componentType = {
  label: 'Component Type',
  options: (position: string) =>
    position === 'story'
      ? [
          { label: 'Ad', value: 'ad' },
          { label: 'Widget', value: 'widget' }
        ]
      : [
          { label: 'Story Collection', value: 'collection' },
          { label: 'Ad', value: 'ad' },
          { label: 'Widget', value: 'widget' }
        ]
};

export const radioBtn = {
  label: 'Ad Size',
  type: 'Ad Size',
  keyName: 'ad-size',
  radioStoryOptions: [
    { name: '728*90', value: '728*90' },
    { name: '300*250', value: '300*250' },
    { name: '336*280', value: '336*280' }
  ],
  radioAsideOptions: [
    { name: '300*250', value: '300*250' },
    { name: '336*280', value: '336*280' }
  ]
};

export const getComponentMeta = (item: any, slotId: string) => {
  const slotPosition = get(item, [slotId, 'slot-position']);
  const type = get(item, [slotId, 'component-type']);
  if (slotPosition === 'story') {
    if (type === 'widget') {
      return { label: 'Widget', value: 'widget' };
    }
    return { label: 'Ad', value: 'ad' };
  }
  switch (type) {
    case 'ad':
      return { label: 'Ad', value: 'ad' };
    case 'widget':
      return { label: 'Widget', value: 'widget' };
    case 'collection':
    default:
      return { label: 'Story Collection', value: 'collection' };
  }
};

export const getDefaultValueforSlotPosition = (item: any, slotId: any) => {
  const slotPosition = get(item, [slotId, 'slot-position']);

  switch (slotPosition) {
    case 'aside':
      return { label: 'Sidebar', value: 'aside' };
    case 'story':
      return { label: 'Story', value: 'story' };
    default:
      return { label: 'Sidebar', value: 'aside' };
  }
};

export const getPlacementOfStoryAd = (slotConfig: IStorySlotConfig) => {
  const adPlacement = get(slotConfig, ['placement']);

  switch (adPlacement) {
    case 'before':
      return placement.options[0];
    case 'after':
      return placement.options[1];
  }
};

export const getCardOption = (slotConfig: IStorySlotConfig) => {
  const card = get(slotConfig, ['select-card']);
  if (!card) return null;
  if (card === 'story') {
    return { label: 'Story', value: 'story' };
  } else {
    const cardNumber = card.slice(5);
    return { label: `Card ${cardNumber}`, value: `card-${cardNumber}` };
  }
};

export const dummyAsideSlotConfig = {
  'slot-name': 'Untitled Slot',
  'slot-position': 'aside',
  'component-type': 'collection',
  config: {}
};

export const dummyStorySlotConfig = {
  'slot-name': 'Untitled Slot',
  'slot-position': 'story',
  'component-type': 'ad',
  'ad-size': '728*90'
};

export const copyTextToClipboard = (text: string | null) => {
  const copyFrom = document.createElement('textarea');
  copyFrom.textContent = text;
  document.body.appendChild(copyFrom);
  copyFrom.select();
  document.execCommand('copy');
  copyFrom.blur();
  document.body.removeChild(copyFrom);
};
