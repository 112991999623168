import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { closePopup } from '../../actions/popup-action';
import CloseIcon from '../../atoms/close-icon';
import styles from './styles.module.css';
const popupRoot = document.getElementById('popup-root');

interface IProps {
  children: ReactNode;
  title: string;
  dispatch: Dispatch;
}

export class ModalWrapper extends React.Component<IProps> {
  el: HTMLDivElement;
  constructor(props: IProps) {
    super(props);
    this.el = document.createElement('div');
    this.el.classList.add(styles.popupModal);
    this.el.classList.add(styles['popupModal--hidden']);
  }
  componentDidMount() {
    if (popupRoot) {
      popupRoot.innerHTML = '';
      popupRoot.appendChild(this.el);
      setTimeout(() => {
        this.el.classList.remove(styles['popupModal--hidden']);
      }, 100);
    }
  }
  componentWillUnmount() {
    if (popupRoot) {
      popupRoot.removeChild(this.el);
    }
  }
  closeModal = () => {
    this.el.classList.add(styles['popupModal--hidden']);
    setTimeout(() => {
      this.props.dispatch(closePopup());
    }, 500);
  };
  render() {
    return ReactDOM.createPortal(
      <div className={styles.popupOverlay}>
        <div className={styles.popupWrapper}>
          <CloseIcon onCloseClick={this.closeModal} className={styles.closeBtn} />
          <div className={styles.popupheader}>{this.props.title}</div>
          {this.props.children}
        </div>
      </div>,
      this.el
    );
  }
}
