export const defaultPremiumIconColor = '#F7B500';
export const defaultTheme = '#ffffff';
export const defaultDarkTheme = '#1d1d1d';

export const AD_PROVIDERS = Object.freeze({
  GOOGLE_ADSENSE: 'googleAdsense',
  GOOGLE_AD_MANAGER: 'googleAdManager',
  MGID: 'mgid',
  NONE: 'none'
});

// quillEditor configs
export const richTxtFormats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet'];
export const richTxtModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }]
  ]
};

// short Code text
export const shortCodeText = 'Click on any of the shortcodes below to copy, and then paste it wherever it is needed';

export const QUILL_EMPTY_STRING = '<p><br></p>';
