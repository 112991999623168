import { FontLanguage } from './fonts-list';

export const dataList = {
  label: 'Language',
  key: '1',
  data: [
    {
      'option-name': 'Primary Language',
      'variable-name': 'languageScript',
      options: [
        {
          label: FontLanguage.Latin,
          value: FontLanguage.Latin
        },
        {
          label: FontLanguage.Devanagiri,
          value: FontLanguage.Devanagiri
        },
        {
          label: FontLanguage.Tamil,
          value: FontLanguage.Tamil
        },
        {
          label: FontLanguage.Gujarati,
          value: FontLanguage.Gujarati
        },
        {
          label: FontLanguage.Bengali,
          value: FontLanguage.Bengali
        },
        {
          label: FontLanguage.Arabic,
          value: FontLanguage.Arabic
        },
        {
          label: FontLanguage.Cyrillic,
          value: FontLanguage.Cyrillic
        },
        {
          label: FontLanguage.Greek,
          value: FontLanguage.Greek
        },
        {
          label: FontLanguage.Gurmukhi,
          value: FontLanguage.Gurmukhi
        },
        {
          label: FontLanguage.Hebrew,
          value: FontLanguage.Hebrew
        },
        {
          label: FontLanguage.Kannada,
          value: FontLanguage.Kannada
        },
        {
          label: FontLanguage.Malayalam,
          value: FontLanguage.Malayalam
        },
        {
          label: FontLanguage.Oriya,
          value: FontLanguage.Oriya
        },
        {
          label: FontLanguage.Sinhala,
          value: FontLanguage.Sinhala
        },
        {
          label: FontLanguage.Telugu,
          value: FontLanguage.Telugu
        },
        {
          label: FontLanguage.Thai,
          value: FontLanguage.Thai
        },
        {
          label: FontLanguage.Vietnamese,
          value: FontLanguage.Vietnamese
        }
      ]
    }
  ]
};
export const selectFontList = {
  label: 'Fonts',
  key: '1',
  data: [
    {
      'option-name': 'Title Font',
      'variable-name': 'primaryFont',
      label: 'primaryFont',
      options: []
    },
    {
      'option-name': 'Content Font',
      'variable-name': 'secondaryFont',
      label: 'secondaryFont',
      options: []
    }
  ]
};
