import {
  PAGEBUILDER_HAVE_UNSAVED_CHANGES,
  PAGEBUILDER_HEADER_ADD_ROW_BOTTOM,
  PAGEBUILDER_HEADER_ADD_ROW_TOP,
  PAGEBUILDER_HEADER_DELETE_ROW,
  PAGEBUILDER_HEADER_DATE_TIME_SETTINGS,
  PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW,
  PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW_SETTINGS,
  PAGEBUILDER_HEADER_UPDATE_STYLE,
  PAGEBUILDER_HEADER_UPDATE_THEME_ATTRIBUTES_KEY
} from '../reducers/builder/configReducer/actions';
import { IRowAction, IRowActionPayload } from '../utils/interfaces';

export const changeHeaderLayout = (style: string = 'header_2') => ({
  type: PAGEBUILDER_HEADER_UPDATE_STYLE,
  payload: style
});

interface IHeaderSettingsPayload {
  [key: string]: string | boolean;
}

interface IDispatchHeaderSettingsArg {
  type: string;
  payload: IHeaderSettingsPayload | boolean;
}

type IDispatchHeaderSettings = (arg: IDispatchHeaderSettingsArg) => void;

export const updateHeaderSettings = (payload: IHeaderSettingsPayload | boolean) => (
  dispatch: IDispatchHeaderSettings
) => {
  dispatch({
    type: PAGEBUILDER_HEADER_UPDATE_THEME_ATTRIBUTES_KEY,
    payload
  });
  dispatch({
    type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
    payload: true
  });
};

interface IArgs {
  type: string;
  payload: IRowActionPayload;
}
type IDispatch = (args: IArgs) => void;

export const updateHeaderRowsList = ({ rowAction, rowActionPayload }: IRowAction) => {
  return (dispatch: IDispatch) => {
    switch (rowAction) {
      case 'update':
        dispatch({ type: PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW, payload: rowActionPayload });
        return;
      case 'delete_row':
        dispatch({ type: PAGEBUILDER_HEADER_DELETE_ROW, payload: rowActionPayload });
        return;
      case 'add_row_top':
        dispatch({ type: PAGEBUILDER_HEADER_ADD_ROW_TOP, payload: rowActionPayload });
        return;
      case 'add_row_bottom':
        dispatch({ type: PAGEBUILDER_HEADER_ADD_ROW_BOTTOM, payload: rowActionPayload });
        return;
      case 'update_settings':
        dispatch({ type: PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW_SETTINGS, payload: rowActionPayload });
    }
  };
};

export const headerSettings = (payload: any) => (dispatch: any) => {
  dispatch({ type: PAGEBUILDER_HEADER_DATE_TIME_SETTINGS, payload });
  dispatch({ type: PAGEBUILDER_HAVE_UNSAVED_CHANGES, payload: true });
};
