import { combineReducers } from 'redux';
import { backupConfigReducer } from './backupConfigReducer';
import { configReducer } from './configReducer';
import { domainListReducer } from './domainListReducer';
import { editControlReducer } from './editControlReducer';
import { historyActionStatusReducer } from './historyActionStatusReducer';
import { haveLayoutUpdatesReducer } from './layoutUpdateReducer';
import { loadingReducer } from './loadingReducer';
import { pageIdReducer } from './pageIdReducer';
import { pageTypeReducer } from './pageTypeReducer';
import { selectRowInspectorReducer } from './selectRow';
import { haveUnsavedChangesReducer } from './shouldSave';
import { navigationPopupReducer, showPopupReducer } from './showPopupReducer';
import { subPageTypeReducer } from './subPageTypeReducer';

export default combineReducers({
  config: configReducer,
  backupConfig: backupConfigReducer,
  appLoading: loadingReducer,
  haveUnsavedChanges: haveUnsavedChangesReducer,
  haveLayoutUpdates: haveLayoutUpdatesReducer,
  selectRowInspector: selectRowInspectorReducer,
  pageType: pageTypeReducer,
  pageId: pageIdReducer,
  editControl: editControlReducer,
  historyActionStatus: historyActionStatusReducer,
  popup: showPopupReducer,
  subPageType: subPageTypeReducer,
  domainList: domainListReducer,
  navigationPopup: navigationPopupReducer
});
