import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const OptimisedIcon: React.FunctionComponent<IsvgData> = ({ width = '24px', height = '24px', color = '#4860BC' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fill-rule="evenodd"
        d="M10.629 22L18.114 10.125 13.124 10.125 14.371 3 6.886 13.688 11.876 13.688z"
      />
    </svg>
  );
};

export { OptimisedIcon };
