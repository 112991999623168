import { PAGEBUILDER_HAVE_UNSAVED_CHANGES } from '../configReducer/actions';

const haveUnsavedChangesReducer = (
  state: boolean = false,
  action: {
    type: string;
    payload: boolean;
  }
) => {
  switch (action.type) {
    case PAGEBUILDER_HAVE_UNSAVED_CHANGES:
      return action.payload;
    default:
      return state;
  }
};

export { haveUnsavedChangesReducer };
