/* eslint-disable */
// @ts-ignore
export const initChameleon = function(t, n: any, o) {
  var a = 'chmln',
    e = 'adminPreview',
    c = 'setup identify alias track clear set show on off custom help _data'.split(' ');
  if ((n[a] || (n[a] = {}), n[a][e] && (n[a][e] = !1), !n[a].root)) {
    (n[a].accountToken = o), (n[a].location = n.location.href.toString()), (n[a].now = new Date());
    for (var s = 0; s < c.length; s++)
      // @ts-ignore
      !(function() {
        var t = (n[a][c[s] + '_a'] = []);
        n[a][c[s]] = function() {
          // @ts-ignore
          t.push(arguments);
        };
      })();
    var i = t.createElement('script');
    (i.src = 'https://fast.trychameleon.com/messo/' + o + '/messo.min.js'), (i.async = !0), t.head.appendChild(i);
  }
};
export const chameleonId = 'SfDhNzWubfgGqBAvRGNKKbTnP1HYsquqTuudYEd8fBRFuZ-1EXR5E-Av382t7djdCKvZO6';
