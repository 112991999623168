import React from 'react';
import facebook from './svg/facebook.svg';
import youtube from './svg/youtube.svg';
import instagram from './svg/instagram.svg';
import linkedin from './svg/linkedin.svg';
import twitter from './svg/twitter.svg';
import telegram from './svg/telegram.svg';
import { SocialIconGroup } from '../social-icon-group/index';

export const PlainSocialLink = () => {
  return <SocialIconGroup icons={[facebook, youtube, instagram, linkedin, twitter, telegram]} />;
};
