import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextArea } from '@quintype/em/components/text-area';
import updateAppDownloadLinksGeneralSettings from '../../actions/app-download-links-action';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';

interface IStore {
  builder: {
    config: {
      general: {
        appDownloadLinks: {
          appStoreDownloadLink?: string;
          googlePlayDownloadLink?: string;
        };
      };
    };
  };
}

const AppDownloadLinksGeneralSettings = () => {
  const dispatch = useDispatch();
  const { appStoreDownloadLink = '', googlePlayDownloadLink = '' } = useSelector((state: IStore) => {
    const {
      builder: {
        config: {
          general: { appDownloadLinks = {} }
        }
      }
    } = state;
    return appDownloadLinks;
  });
  const updateSettings = (key: string, value: string) => {
    updateAppDownloadLinksGeneralSettings(dispatch, { [key]: value });
    dispatch(updateHaveUnsavedChanges(true));
  };

  return (
    <div>
      <TextArea
        label="iOS App Link"
        placeholder="iOS App Link"
        value={appStoreDownloadLink}
        onChange={(value: string) => updateSettings('appStoreDownloadLink', value)}
      />
      <TextArea
        label="Android App Link"
        placeholder="Android App Link"
        value={googlePlayDownloadLink}
        onChange={(value: string) => updateSettings('googlePlayDownloadLink', value)}
      />
    </div>
  );
};

export default AppDownloadLinksGeneralSettings;
