import { PAGEBUILDER_GENERAL_OTHER_ADS_UPDATE_SCRIPTS } from '../reducers/builder/configReducer/actions';

interface IOtherAdsScriptsPayload {
  [key: string]: string;
}

const updateOtherAdsScripts = (payload: IOtherAdsScriptsPayload) => ({
  type: PAGEBUILDER_GENERAL_OTHER_ADS_UPDATE_SCRIPTS,
  payload
});

export default updateOtherAdsScripts;
