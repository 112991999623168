import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { Checkbox } from '@quintype/em/components/checkbox';
import { FieldLabel } from '@quintype/em/components/field-label';
import styles from './story-demarcation.module.css';
import { visualStoryIconsData } from './icons';
import { storyDemarcationStyle } from '../../actions/builder-action';

export function VisualStoryDemarcation() {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dispatch = useDispatch();
  const demarcationType = 'visualStoryDemarcationIcon';

  const { enableVisualStoryDemarcationIcon } = useSelector((state: any) =>
    get(state, ['builder', 'config', 'general', 'rows', 'visualStoryDemarcationIcon'], {})
  );

  const updateIconHandler = (name: string, index: number) => {
    setSelectedIndex(index);
    dispatch(storyDemarcationStyle({ demarcationType, iconStyle: name }));
  };

  return (
    <>
      <div className={styles.checkBoxWrapper} data-test-id="visual-story-demarcation-checkbox">
        <Checkbox
          label="Visual Story Demarcation"
          id="visual-story-demarcation-Icon"
          checked={enableVisualStoryDemarcationIcon}
          onChange={(value: any) =>
            dispatch(storyDemarcationStyle({ demarcationType, enableVisualStoryDemarcationIcon: value }))
          }
        />
      </div>
      <FieldLabel label="Choose Icon style" />
      <div className={styles.iconWrapper}>
        {visualStoryIconsData.map((item: { name: string; svg: string }, index: number) => (
          <div
            onClick={() => updateIconHandler(item.name, index)}
            className={selectedIndex === index ? styles.selectedImgWrapper : styles.imageWrapper}
            data-test-id={item.name}
            key={`visual-icon-${item.name}`}
          >
            <img src={item.svg} className={styles.image} alt="Visual Story Demarcation Icon style" />
          </div>
        ))}
      </div>
    </>
  );
}
