import { FieldLabel } from '@quintype/em/components/field-label';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import CheckSvg from './check.svg';
import styles from './colors.module.css';

interface IProps {
  colors: string[];
  label: string;
  updateTheStore: (key: string, value: string, pageType: string) => void;
  colorExtract: string;
  description?: string;
  updateStoreKey: any;
  pageType: string;
}

class ColorPickerBase extends React.Component<IProps> {
  render() {
    return (
      <div className={styles.colorPicker}>
        <FieldLabel label={this.props.label} classname={styles.label} />
        {this.props.colors.map((color, index) => (
          <div
            key={`color-${index}`}
            className={styles.colorContainer}
            style={{ backgroundColor: color }}
            onClick={() => {
              this.props.updateTheStore(this.props.updateStoreKey, color, this.props.pageType);
            }}
          >
            {color === this.props.colorExtract && <img src={CheckSvg} className={styles.visibleCheckImg} alt="color" />}
          </div>
        ))}
        {this.props.description && <p className={styles.description}>{this.props.description}</p>}
      </div>
    );
  }
}
interface IStoreData {
  builder: { config: { colors: string[] } };
}

const mapStateToProps = (state: IStoreData) => ({
  colors: get(state, ['builder', 'config', 'general', 'colors'], []),
  pageType: get(state, ['builder', 'pageType'])
});

export const ColorPicker = connect(mapStateToProps)(ColorPickerBase);
