import { Checkbox } from '@quintype/em/components/checkbox';
import Select from '@quintype/em/components/select';
import { TextField } from '@quintype/em/components/text-field';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAccesstypeConfig, updateHaveUnsavedChanges } from '../../actions/builder-action';
import styles from './accesstype-integration.module.css';

interface IStore {
  builder: {
    config: {
      general: {
        accesstypeIntegration: {
          enableAccesstype?: boolean;
          bridgeKeeperIntegrationId?: string;
          accesstypeKey?: string;
          planPageTitle?: string;
          planPageDescription?: string;
          accesstypeMode?: string;
          subscriptionColor?: string;
          darkSubscriptionColor?: string;
          disableMetering?: boolean;
          enablePayPerAsset?: boolean;
          captureShippingAddress?: boolean;
          locale?: string;
        };
      };
    };
  };
}

interface IStoryTemplate {
  label: string;
  value: any;
  type: string | boolean;
  onChange: any;
  options: any;
}

export const AccesstypeIntegration = () => {
  const {
    enableAccesstype = false,
    bridgeKeeperIntegrationId = '',
    accesstypeKey = '',
    accesstypeMode = '',
    subscriptionColor = '',
    darkSubscriptionColor = '',
    disableMetering = true,
    enablePayPerAsset = false,
    captureShippingAddress = false,
    locale = ''
  } = useSelector((state: IStore) => {
    const {
      builder: {
        config: {
          general: { accesstypeIntegration = {} }
        }
      }
    } = state;
    return accesstypeIntegration;
  });
  const dispatch = useDispatch();

  const updateSettings = (key: string, value: any) => {
    dispatch(updateAccesstypeConfig({ [key]: value }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const enabledAccesstypeData = [
    {
      label: 'BridgeKeeper Integration ID',
      value: bridgeKeeperIntegrationId,
      onChange: { keyName: 'bridgeKeeperIntegrationId' },
      type: 'textField'
    },
    { label: 'Accesstype Key', value: accesstypeKey, onChange: { keyName: 'accesstypeKey' }, type: 'textField' },
    {
      label: 'Accesstype Mode',
      value: accesstypeMode,
      onChange: { keyName: 'accesstypeMode' },
      type: 'dropDown',
      options: [
        { label: 'Staging', value: 'staging' },
        { label: 'Production', value: 'production' }
      ]
    },
    {
      label: 'Enable Metering',
      value: disableMetering,
      onChange: { keyName: 'disableMetering' },
      // doing this to support secret mode config
      type: 'meteringCheckbox'
    },
    {
      label: 'Enable Pay Per Asset',
      value: enablePayPerAsset,
      onChange: { keyName: 'enablePayPerAsset' },
      type: 'checkbox'
    },
    {
      label: 'locale (optional)',
      value: locale,
      onChange: { keyName: 'locale' },
      type: 'textField'
    },
    {
      label: 'Capture Shipping Address',
      value: captureShippingAddress,
      onChange: { keyName: 'captureShippingAddress' },
      type: 'checkbox'
    }
  ];

  const getUpdatedValue = (value: string) => {
    switch (value) {
      case 'staging':
        return { label: 'Staging', value: 'staging' };
      case 'production':
        return { label: 'Production', value: 'production' };
      case 'gridView':
        return { label: 'Grid View', value: 'grid-view' };
      case 'listView':
        return { label: 'List View', value: 'list-view' };
    }
  };

  return (
    <>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          label="Enable AccessType"
          id="enableAccessType"
          checked={enableAccesstype}
          onChange={(keyValue: any) => updateSettings('enableAccesstype', keyValue)}
        />
      </div>
      {enableAccesstype &&
        enabledAccesstypeData.map((item, index) => {
          const { label, type, value, onChange, options } = item;

          switch (type) {
            case 'meteringCheckbox':
              return (
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    label={label}
                    id={onChange.keyName}
                    checked={!value as boolean}
                    onChange={(keyValue: any) => updateSettings(onChange.keyName, !keyValue)}
                  />
                </div>
              );
            case 'checkbox':
              return (
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    label={label}
                    id={onChange.keyName}
                    checked={value as boolean}
                    onChange={(keyValue: any) => updateSettings(onChange.keyName, keyValue)}
                  />
                </div>
              );
            case 'textField':
              return (
                <>
                  <TextField
                    label={label}
                    value={value as string}
                    key={index}
                    onChange={(keyValue: any) => updateSettings(onChange.keyName, keyValue)}
                  />
                  {onChange.keyName === 'locale' && (
                    <div className={styles.hintWrapper}>
                      help documentation :&nbsp;
                      <a
                        className={styles.docWrapper}
                        target="_blank"
                        href="https://docs.adyen.com/online-payments/web-drop-in/customization#language-and-localization"
                        rel="noopener noreferrer"
                      >
                        adyen docs
                      </a>
                    </div>
                  )}
                </>
              );
            case 'dropDown':
              return (
                <Select
                  label={label}
                  options={options}
                  id={onChange.keyName}
                  value={getUpdatedValue(value as string)}
                  onChange={(obj: any) => updateSettings(onChange.keyName, obj.value)}
                />
              );
          }
        })}
    </>
  );
};
