import get from 'lodash/get';
import {
  PAGEBUILDER_GENERATE_AD_SLOTS,
  PAGEBUILDER_HAVE_UNSAVED_CHANGES,
  PAGEBUILDER_STORY_ELEMENTS_UPDATE_ATTRIBUTE,
  PAGEBUILDER_STORY_ELEMENTS_UPDATE_SETTINGS,
  PAGEBUILDER_STORY_PAGE_SETTINGS,
  PAGEBUILDER_STORY_SLOT_SETTING,
  PAGEBUILDER_STORY_UPDATE_ATTRIBUTE,
  PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD,
  PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD_OPTS,
  PAGEBUILDER_STORY_UPDATE_AUTHOR_STYLE,
  PAGEBUILDER_STORY_UPDATE_DATE_TIME,
  PAGEBUILDER_STORY_UPDATE_SLOTS,
  PAGEBUILDER_STORY_UPDATE_STYLES
} from '../reducers/builder/configReducer/actions';
import { IStorySlot } from '../utils/interfaces';

interface IPayload {
  type: string;
  data: {
    [key: string]: string;
  };
}

interface IDispatchArg {
  type: string;
  payload: IPayload;
}

type IDispatch = (arg: IDispatchArg) => void;

export const updateStoryAttribute = (payload: IPayload) => {
  return (dispatch: IDispatch) =>
    dispatch({
      type: PAGEBUILDER_STORY_UPDATE_ATTRIBUTE,
      payload
    });
};

export const updateStorySettings = (payload: any) => {
  return (dispatch: IDispatch) =>
    dispatch({
      type: PAGEBUILDER_STORY_UPDATE_STYLES,
      payload
    });
};
export const updateStoryPageSettings = (payload: any) => {
  return (dispatch: IDispatch) =>
    dispatch({
      type: PAGEBUILDER_STORY_PAGE_SETTINGS,
      payload
    });
};

export const updateDateTime = (payload: any) => {
  return (dispatch: IDispatch) =>
    dispatch({
      type: PAGEBUILDER_STORY_UPDATE_DATE_TIME,
      payload
    });
};

export const updateAuthorcard = (payload: any) => {
  return (dispatch: IDispatch) => {
    dispatch({
      type: PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD,
      payload
    });
  };
};
export const updateAuthorcardOpts = (payload: any) => {
  return (dispatch: IDispatch) => {
    dispatch({
      type: PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD,
      payload
    });
    dispatch({
      type: PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD_OPTS,
      payload
    });
  };
};

interface IElementAttributePayload {
  type: string;
  data: {
    [key: string]: string;
  };
}

interface IElementAttributeDispatchArg {
  type: string;
  payload: IElementAttributePayload;
}

type IElementAttributeDispatch = (arg: IElementAttributeDispatchArg) => void;

export const updateStoryElementAttribute = (payload: IElementAttributePayload) => (
  dispatch: IElementAttributeDispatch
) =>
  dispatch({
    type: PAGEBUILDER_STORY_ELEMENTS_UPDATE_ATTRIBUTE,
    payload
  });

interface IElementSettingsPayload {
  type: string;
  settingsType: string;
  data: {
    [key: string]: string;
  };
}

interface IElementSettingsDispatchArg {
  type: string;
  payload: IElementSettingsPayload;
}

type IElementSettingsDispatch = (arg: IElementSettingsDispatchArg) => void;

export const updateStoryElementSettings = (payload: IElementSettingsPayload) => (dispatch: IElementSettingsDispatch) =>
  dispatch({
    type: PAGEBUILDER_STORY_ELEMENTS_UPDATE_SETTINGS,
    payload
  });

type IAuthorSettingsPayload = {
  [key: string]: string | boolean;
};

interface IDispatchAuthorSettingsArg {
  type: string;
  payload: IAuthorSettingsPayload | boolean;
}

type IDispatchAuthorSettings = (arg: IDispatchAuthorSettingsArg) => void;

export const updateAuthorSettings = (payload: IAuthorSettingsPayload | boolean) => (
  dispatch: IDispatchAuthorSettings
) => {
  dispatch({
    type: PAGEBUILDER_STORY_UPDATE_AUTHOR_STYLE,
    payload
  });
  dispatch({
    type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
    payload: true
  });
};

interface IUpdateStorySlotsPayload {
  type: string;
  slots: IStorySlot[];
}

interface IUpdateStorySlotsDispatchArgs {
  type: string;
  payload: IUpdateStorySlotsPayload | boolean;
}

type IUpdateStorySlotsDispatch = (arg: IUpdateStorySlotsDispatchArgs) => void;

export const updateStorySlots = (payload: IUpdateStorySlotsPayload) => (dispatch: IUpdateStorySlotsDispatch) => {
  dispatch({
    type: PAGEBUILDER_STORY_UPDATE_SLOTS,
    payload
  });
  dispatch({
    type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
    payload: true
  });
};

interface IStorySlotSettingPayload {
  type: string;
  id: string;
  key: string;
  // value can be string, boolean or an object, based on the option
  value: any;
}

interface IStorySlotSettingsDispatchArgs {
  type: string;
  payload: IStorySlotSettingPayload | boolean;
}

type IStorySlotSettingsDispatch = (arg: IStorySlotSettingsDispatchArgs) => void;

export const storySlotSettings = (payload: IStorySlotSettingPayload) => (dispatch: IStorySlotSettingsDispatch) => {
  dispatch({
    type: PAGEBUILDER_STORY_SLOT_SETTING,
    payload
  });
  dispatch({
    type: PAGEBUILDER_HAVE_UNSAVED_CHANGES,
    payload: true
  });
};

export const generateSlots = (payload: { type: string; storyStoreField: string }) => (dispatch: any, getState: any) => {
  const slots = get(getState(), ['builder', 'config', payload.storyStoreField, payload.type, 'slots'], []);
  const getSlotId = (slot: object) => Object.keys(slot)[0];
  const storySlots = slots.filter(
    (slot: object) =>
      get(slot, [getSlotId(slot), 'slot-position']) === 'story' && get(slot, [getSlotId(slot), 'isSlotEnable'])
  );
  const asideSlots = slots.filter(
    (slot: object) =>
      get(slot, [getSlotId(slot), 'slot-position']) === 'aside' && get(slot, [getSlotId(slot), 'isSlotEnable'])
  );

  const adSizeLayoutMap = {
    '728*90': 'Leaderboard',
    '300*250': 'Story-Mrec',
    '336*280': 'Story-Lrec'
  };

  const storySlotsConfig = storySlots.reduce((accSlotConfig: object, slot: IStorySlot) => {
    const slotConfig = get(slot, [getSlotId(slot)]);
    const componentType = get(slotConfig, ['component-type']);
    const selectedCard = get(slot, [getSlotId(slot), 'select-card'], '');
    const cardNumber = selectedCard === 'story' ? selectedCard : selectedCard.slice(5);
    const currentSlotConfig = get(accSlotConfig, [cardNumber], { before: [], after: [] });
    const placement = get(slot, [getSlotId(slot), 'placement'], 'before');
    const adType = get(slot, [getSlotId(slot), 'ad-size'], '728*90');
    const layout = get(adSizeLayoutMap, [adType], 'Leaderboard');
    switch (componentType) {
      case 'widget':
        const updatedWidgetSlotConfig = {
          ...currentSlotConfig,
          [placement]: [
            ...get(currentSlotConfig, [placement], []),
            {
              type: 'widget',
              layout: 'widget',
              layoutLabel: 'Custom Widget',
              widgetCode: get(slotConfig, ['story-widget'], ''),
              targetId: get(slotConfig, ['target-id'], ''),
              centreAlignWidget: get(slotConfig, ['centre-align-widget'], true),
              config: {
                customCode: get(slotConfig, ['story-widget'], '')
              },
              aheadNext: {
                placeholderHeight: get(slotConfig, 'ahead-nxt-widget-placeholder-height', ''),
                widgetCode: get(slotConfig, ['ahead-nxt-story-widget'], ''),
                targetId: get(slotConfig, ['ahead-nxt-target-id'], '')
              }
            }
          ]
        };
        accSlotConfig = { ...accSlotConfig, [cardNumber]: updatedWidgetSlotConfig };
        return accSlotConfig;
      default:
        const updatedAdSlotConfig = {
          ...currentSlotConfig,
          [placement]: [
            ...get(currentSlotConfig, [placement], []),
            {
              type: 'ad',
              layout,
              layoutLabel: layout,
              config: {
                targetingId: get(slot, [getSlotId(slot), 'target-id'], '')
              }
            }
          ]
        };
        accSlotConfig = { ...accSlotConfig, [cardNumber]: updatedAdSlotConfig };
        return accSlotConfig;
    }
  }, {});

  const asideSlotsConfig = asideSlots.map((slot: IStorySlot) => {
    const slotConfig = get(slot, [getSlotId(slot)]);
    const componentType = get(slotConfig, ['component-type']);
    const adSize = get(slotConfig, ['ad-size'], '');
    const adType = get(adSizeLayoutMap, [adSize], 'Story-Mrec');
    switch (componentType) {
      case 'ad':
        return {
          type: get(slotConfig, ['component-type'], ''),
          layout: adType,
          layoutLabel: adType,
          config: {
            targetingId: get(slotConfig, ['aside-target-id'], '')
          }
        };

      case 'widget':
        return {
          type: 'widget',
          layout: 'widget',
          layoutLabel: 'Custom Widget',
          pageType: 'story',
          widgetCode: get(slotConfig, ['aside-widget'], ''),
          targetId: get(slotConfig, ['target-id'], ''),
          centreAlignWidget: get(slotConfig, ['centre-align-widget'], true),
          config: {
            customCode: get(slotConfig, ['aside-widget'], '')
          },
          aheadNext: {
            placeholderHeight: get(slotConfig, 'ahead-nxt-widget-placeholder-height', ''),
            widgetCode: get(slotConfig, ['ahead-nxt-story-widget'], '')
          }
        };

      default:
        return {
          type: 'collection',
          collectionSlug: get(slotConfig, ['collection-slug'], ''),
          title: get(slotConfig, ['collection-title'], '')
        };
    }
  });

  dispatch({
    type: PAGEBUILDER_GENERATE_AD_SLOTS,
    payload: { type: payload.type, value: { 'story-slots': storySlotsConfig, asideSlots: asideSlotsConfig } }
  });
  dispatch({ type: PAGEBUILDER_HAVE_UNSAVED_CHANGES, payload: true });
};
