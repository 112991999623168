import React from 'react';
import { AccordionSection } from '@quintype/em/components/accordion';
import { updateMeterWallSettings, renderAccordionSection } from '../../payWallUtils';
import { copyTextToClipboard } from '../../../row-inspector/utils';
import get from 'lodash/get';
import styles from '../../metering.module.css';

// logged in users settings
export function loggedInUsers(modules: object, formats: any, pageSetting: any, dispatch: any) {
  const config = [
    {
      type: 'fieldLabel',
      label: 'STORIES BEHIND SUBSCRIPTION'
    },
    {
      type: 'hintText',
      text: 'Use this if you have Turned ON metering for stories that are behind subscription for Logged In users.'
    },
    {
      type: 'quillEditor',
      label: 'Paywall title',
      placeholder: 'Subscribe to gain access to this premium articles.',
      value: get(pageSetting, ['meteringOn', 'loggedIn', 'wallTitle']) || '',
      onChange: (value: any) => updateMeterWallSettings('meteringOn', 'loggedIn', '', '', 'wallTitle', value, dispatch)
    },
    {
      type: 'quillEditor',
      label: 'Paywall description',
      placeholder:
        'You have exhausted your free premium articles for the day. To access more premium content and enjoy uninterrupted reading experience, please consider subscribing to one of our plans.',
      value: get(pageSetting, ['meteringOn', 'loggedIn', 'wallDescription']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'loggedIn', '', '', 'wallDescription', value, dispatch)
    },
    {
      type: 'text',
      label: 'View all plans button',
      placeholder: 'View all plans',
      value: get(pageSetting, ['meteringOn', 'loggedIn', 'buttonTxt']) || '',
      onChange: (value: any) => updateMeterWallSettings('meteringOn', 'loggedIn', '', '', 'buttonTxt', value, dispatch)
    },
    {
      type: 'toggleButton',
      label: 'Metering message',
      toggleName: 'MeteringIndicator',
      id: 'MeteringIndicator-loggedIn',
      updateToggleState: get(pageSetting, ['meteringOn', 'loggedIn', 'MeteringIndicator'], true),
      updateToggleButton: (name: string, value: boolean | string) =>
        updateMeterWallSettings('meteringOn', 'loggedIn', '', '', name, value, dispatch)
    },
    {
      type: 'text',
      label: `You can still access 'x' more articles for free`,
      placeholder: 'You can still access [number-of-stories] more articles for free',
      value: get(pageSetting, ['meteringOn', 'loggedIn', 'meteringMsgPlural']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'loggedIn', '', '', 'meteringMsgPlural', value, dispatch)
    },
    {
      type: 'text',
      label: `You can still access 1 more article for free`,
      placeholder: 'You can still access [number-of-stories] more article for free',
      value: get(pageSetting, ['meteringOn', 'loggedIn', 'meteringMsgSingular']) || '',
      onChange: (value: any) =>
        updateMeterWallSettings('meteringOn', 'loggedIn', '', '', 'meteringMsgSingular', value, dispatch)
    },
    {
      type: 'fieldLabel',
      label: 'Available shortcodes'
    },
    {
      type: 'hintText',
      text: 'Click on any of the shortcodes below to copy, and then paste it wherever it is needed.'
    }
  ];

  return (
    <AccordionSection label="logged in users" isLabelUpperCase index={1}>
      {renderAccordionSection(config, modules, formats)}
      <div className={styles.shortCodeWrapper}>
        <span className={styles.shortCode} onClick={() => copyTextToClipboard('[number-of-stories]')}>
          Number of stories
        </span>
      </div>
    </AccordionSection>
  );
}
