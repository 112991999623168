import { FieldLabel } from '@quintype/em/components/field-label';
import { Switch } from '@quintype/em/components/switch';
import React from 'react';
import styles from './toggle-button.module.css';

interface IProps {
  label: string;
  toggleName: string;
  id: any;
  updateToggleState: boolean;
  updateToggleButton: (toggleName: string, checked: boolean) => void;
}

export const ToggleButton = ({ label, toggleName, id, updateToggleState, updateToggleButton }: IProps) => {
  return (
    <div className={styles.toggleButtonContainer}>
      <FieldLabel label={label} classname={styles.labelContainer} />
      <Switch checked={updateToggleState} id={id} onChange={() => updateToggleButton(toggleName, !updateToggleState)} />
    </div>
  );
};
