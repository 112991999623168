import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import { defaultDarkTheme, defaultTheme } from '../../constant';
import { getConfigPathFromStore } from '../../hooks/useConfigPathFromStore';
import { ThemeSetter } from '../../molecules/theme-setter';
import { PAGEBUILDER_UPDATE_PAGE_SETTINGS } from '../../reducers/builder/configReducer/actions';

interface IProps {
  pageSetting: PageSettings;
  pageId: string;
  updatePageSettings: (key: string, value: string, pageType: string, pageId: string) => void;
  pageType?: string;
  enableDarkModePreview: boolean;
}

interface PageSettings {
  bgColor: string;
  darkBgColor: string;
}

class ColorPickerPageBackgroundColorBase extends React.Component<IProps> {
  render() {
    const { bgColor, darkBgColor } = this.props.pageSetting;
    return (
      <>
        <ThemeSetter
          label="Choose background color"
          updateTheStore={(key, value, pageType) =>
            this.props.updatePageSettings(key, value, pageType, this.props.pageId)
          }
          lightColorExtract={bgColor}
          lightUpdateStoreKey="bgColor"
          darkColorExtract={darkBgColor}
          darkUpdateStoreKey="darkBgColor"
          lightDefaultColor={defaultTheme}
          darkDefaultColor={defaultDarkTheme}
          pageType={this.props.pageType}
          enableDarkModePreview={this.props.enableDarkModePreview}
        />
      </>
    );
  }
}

const mapStateToProps = (state: {}) => {
  const { pageType, pageId } = get(state, ['builder'], {});
  const configPathPart = getConfigPathFromStore(pageType, pageId);

  return {
    pageSetting: get(state, ['builder', 'config', ...configPathPart, 'pageSetting'], {}),
    pageId,
    pageType,
    enableDarkModePreview: get(state, ['builder', 'config', 'enableDarkModePreview'], false)
  };
};

interface IActionType {
  type: string;
  payload: {};
}

const mapDispatchToProps = (dispatch: React.Dispatch<IActionType>) => ({
  updatePageSettings: (key: string, value: string, moduleType: string, pageId: string) => {
    dispatch({
      type: PAGEBUILDER_UPDATE_PAGE_SETTINGS,
      payload: {
        config: {
          [key]: value
        },
        moduleType,
        pageId
      }
    });
    dispatch(updateHaveUnsavedChanges(true));
  }
});

export const ColorPickerPageBackgroundColor = connect(
  mapStateToProps,
  mapDispatchToProps
)(ColorPickerPageBackgroundColorBase);
