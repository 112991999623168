import { Switch } from '@quintype/em/components/switch';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updateSingleSignOnConfig } from '../../actions/builder-action';
import styles from './single-sign-on.module.css';

export const SingleSignOn = () => {
  const dispatch = useDispatch();
  const { enableSingleSignOn = false, bridgeKeeperClientId = '' } = useSelector(state =>
    get(state, ['builder', 'config', 'general', 'sso'], {})
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div>Enable SSO</div>
        <Switch
          id="singleSignOn"
          onChange={(checked: boolean) => {
            dispatch(updateSingleSignOnConfig({ enableSingleSignOn: checked }));
            dispatch(updateHaveUnsavedChanges(true));
          }}
          checked={enableSingleSignOn}
        />
      </div>
      {enableSingleSignOn && (
        <TextField
          label="BridgeKeeper Client ID"
          value={bridgeKeeperClientId}
          onChange={(keyValue: any) => {
            dispatch(updateSingleSignOnConfig({ bridgeKeeperClientId: keyValue }));
            dispatch(updateHaveUnsavedChanges(true));
          }}
        />
      )}
    </>
  );
};
