import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Replace: React.FunctionComponent<IsvgData> = ({ width = '24px', height = '24px', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd" stroke={color}>
        <g>
          <path d="M15.5 6.5v-6h-6v6h6zM6.5 6.5v-6h-6v6h6z" transform="translate(4 13)" />
        </g>
        <path d="M9 0H15V15H9z" transform="rotate(90 12 7.5)" />
      </g>
    </svg>
  );
};

export { Replace };
