// blurb
import blurbBorder from './svgs/blurbBorder.svg';
import blurbDefault from './svgs/blurbDefault.svg';
// blockquote
import Default from './svgs/default.svg';
import WithBackground from './svgs/withBackground.svg';
import WithBorder from './svgs/withBorder.svg';
// quote
import quoteDefault from './svgs/quoteDefault.svg';
import quoteBorderLeft from './svgs/quoteBorderLeft.svg';
import quoteBorderSml from './svgs/quoteBorderSml.svg';
// summary
import summaryDefault from './svgs/summaryDefault.svg';
import summaryHeader from './svgs/summaryHeader.svg';
import summaryBorder from './svgs/summaryBorder.svg';
// Also Read
import alsoReadDefault from './svgs/alsoReadDefault.svg';
import alsoReadImageRight from './svgs/alsoReadImageRight.svg';
import alsoReadTextLeft from './svgs/alsoReadTextLeft.svg';
// Question Answer
import questionAnswerDefault from './svgs/questionAnswerDefault.svg';
import questionAnswerAuthorImage from './svgs/questionAnswerAuthorImage.svg';
// Question
import questionDefault from './svgs/questionDefault.svg';
import questionAuthorImage from './svgs/questionAuthorImage.svg';
//Answer
import answerDefault from './svgs/answerDefault.svg';
import answerAuthorImage from './svgs/answerAuthorImage.svg';

export const QuestionAnswerTemplateList = [
  {
    componentLabel: 'Default',
    componentName: 'default',
    image: questionAnswerDefault
  },
  {
    componentLabel: 'With Author Image',
    componentName: 'withAuthorImage',
    image: questionAnswerAuthorImage
  }
];

export const QuestionTemplateList = [
  {
    componentLabel: 'Default',
    componentName: 'default',
    image: questionDefault
  },
  {
    componentLabel: 'With Author Image',
    componentName: 'withAuthorImage',
    image: questionAuthorImage
  }
];

export const AnswerTemplateList = [
  {
    componentLabel: 'Default',
    componentName: 'default',
    image: answerDefault
  },
  {
    componentLabel: 'With Author Image',
    componentName: 'withAuthorImage',
    image: answerAuthorImage
  }
];

export const BlockQuoteTemplateList = [
  {
    componentLabel: 'Default',
    componentName: 'default',
    image: Default
  },
  {
    componentLabel: 'With Border',
    componentName: 'withBorder',
    image: WithBorder
  },
  {
    componentLabel: 'With Background',
    componentName: 'withBackground',
    image: WithBackground
  }
];

export const BlurbTemplateList = [
  {
    componentLabel: 'Default',
    componentName: 'default',
    image: blurbDefault
  },
  {
    componentLabel: 'With Border',
    componentName: 'withBorder',
    image: blurbBorder
  }
];

export const QuoteTemplateList = [
  {
    componentLabel: 'Default',
    componentName: 'borderNone',
    image: quoteDefault
  },
  {
    componentLabel: 'With Border',
    componentName: 'borderLeft',
    image: quoteBorderLeft
  },
  {
    componentLabel: 'Border Top',
    componentName: 'borderTopSmall',
    image: quoteBorderSml
  }
];

export const SummaryTemplateList = [
  {
    componentLabel: 'Default',
    componentName: 'default',
    image: summaryDefault
  },
  {
    componentLabel: 'With Header',
    componentName: 'header',
    image: summaryHeader
  },
  {
    componentLabel: 'With Border',
    componentName: 'border',
    image: summaryBorder
  }
];

export const AlsoReadTemplateList = [
  {
    componentLabel: 'Default',
    componentName: 'default',
    image: alsoReadDefault
  },
  {
    componentLabel: 'Image Right Align',
    componentName: 'imageRightAlign',
    image: alsoReadImageRight
  },
  {
    componentLabel: 'Text Left Align',
    componentName: 'textLeftAlign',
    image: alsoReadTextLeft
  }
];
