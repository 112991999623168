import { Button } from '@quintype/em/components/button';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import { TextField } from '@quintype/em/components/text-field';
import React from 'react';
import { IRedirectItem } from '../../utils/interfaces';
import { IErrorMessageData } from './index';
import styles from './edit-redirect.module.css';

interface IEditRedirectProps {
  title: string;
  type: 'add' | 'edit';
  redirectData?: IRedirectItem | {};
  onAdd: (type: 'add' | 'edit', id: string) => void;
  onCancel: () => void;
  onChange: (data: IRedirectItem) => void;
  error?: IErrorMessageData;
}

export function EditRedirect({
  title = 'New Redirection',
  type = 'add',
  redirectData = {},
  onAdd,
  onCancel,
  onChange,
  error
}: IEditRedirectProps) {
  const { sourceUrl, destinationUrl, statusCode, id } = redirectData as IRedirectItem;

  function localoOnChange(type: string, value: string | number) {
    const newData = {
      ...(redirectData as IRedirectItem),
      [type]: value
    };
    onChange(newData);
  }

  function getErrorMessage(type: keyof Omit<IRedirectItem, 'id'>) {
    const isErrorPresent = error && error.id === id;
    const messages = error && error.message && error.message[type];
    if (!isErrorPresent || !messages) return '';
    if (Array.isArray(messages)) {
      return messages.join(' , ');
    }
  }

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles['links_container']}>
        <TextField
          value={sourceUrl}
          label="Source Path"
          onChange={text => localoOnChange('sourceUrl', text)}
          placeholder="/category/india"
          errorMessage={getErrorMessage('sourceUrl')}
        />
        <TextField
          value={destinationUrl}
          label="Destination Path"
          onChange={text => localoOnChange('destinationUrl', text)}
          placeholder="/india"
          errorMessage={getErrorMessage('destinationUrl')}
        />
      </div>
      <div>
        <Radio
          name="to"
          label="Redirection Type"
          selected={statusCode.toString()}
          onChange={e => localoOnChange('statusCode', parseInt((e.target as HTMLInputElement).value, 10))}
        >
          <RadioOption value="301">Permanent 301</RadioOption>
          <RadioOption value="302"> Temporary 302</RadioOption>
        </Radio>
        {getErrorMessage('statusCode') && (
          <span className={styles['error-message']}>{getErrorMessage('statusCode')}</span>
        )}
      </div>

      <div className={styles['button_container']}>
        <Button classname={'edit_redirect_save_button'} type="primary" onClick={() => onAdd(type, id)}>
          {type === 'add' ? 'Add Redirection' : 'Update Redirection'}
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  );
}
