import { STORY_DATA } from './story-data';
import get from 'lodash/get';

export const generateStoryElementData = (elementType: string) => {
  const storyElements: object[] = get(STORY_DATA, ['cards', 0, 'story-elements'], []);
  return storyElements.find((element: any) => {
    const { subtype, type } = element;
    if (subtype) return subtype === elementType;
    return type === elementType;
  });
};
