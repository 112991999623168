import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPageType } from '../../actions/builder-action';
import AheadPreview from '../../ahead/ahead-preview';
import { SelectRowInspector } from '../../molecules/select-row-inspector';
import { Header } from '../../row/header';
import styles from './style.module.css';

const Home = () => {
  const { publisherId = '' } = useParams();
  const dispatch = useDispatch();
  dispatch(setPageType('home'));
  return (
    <div className={styles.componentWrapper}>
      <Header publisherId={publisherId} />
      <AheadPreview />
      <SelectRowInspector publisherId={publisherId} />
    </div>
  );
};

export default Home;
