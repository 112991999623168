import React, { useEffect } from 'react';
import style from './edit-control-supervisor.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { initEditControl } from '../../actions/edit-control-actions';
import get from 'lodash/get';
import useUserDetails from '../../hooks/user';

interface IProps {
  publisherId: string;
}
const EditControlSupervisor = ({ publisherId }: IProps) => {
  const userDetails = useUserDetails();
  const { currentEditor, isEditAccessLocked } = useSelector(state => get(state, ['builder', 'editControl']));
  const dispatch = useDispatch();
  const isSameUser = currentEditor === userDetails.email;

  useEffect(() => {
    userDetails.email && dispatch(initEditControl(publisherId, userDetails.email, isEditAccessLocked));
  }, [publisherId, userDetails.email, isEditAccessLocked, dispatch]);
  if (!userDetails.email) return null;
  return (
    <>
      {currentEditor && !isSameUser && (
        <div className={style.container}>
          <div className={style.floatingBar}>You are in read only mode. {currentEditor} is using Page Builder.</div>
        </div>
      )}
      {!currentEditor && isEditAccessLocked && (
        <div className={style.container}>
          <div className={style.floatingBar}>
            You have stopped building. You can regain access only after 2 minutes.
          </div>
        </div>
      )}
    </>
  );
};

export default EditControlSupervisor;
