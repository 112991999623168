import {
  PAGEBUILDER_FOOTER_UPDATE_STYLE,
  PAGEBUILDER_FOOTER_UPDATE_THEME_ATTRIBUTES_KEY,
  PAGEBUILDER_HAVE_UNSAVED_CHANGES
} from '../reducers/builder/configReducer/actions';

export const changeFooterLayout = (style: string = 'footer_2') => ({
  type: PAGEBUILDER_FOOTER_UPDATE_STYLE,
  payload: style
});

interface IFooterThemeAttributes {
  [key: string]: string | boolean;
}

interface IDispatchFooterThemeArg {
  type: string;
  payload: IFooterThemeAttributes | boolean;
}

type IDispatchFooterThemeAttributes = (arg: IDispatchFooterThemeArg) => void;

export const updateFooterThemeAttributes = (payload: IFooterThemeAttributes | boolean) => (
  dispatch: IDispatchFooterThemeAttributes
) => {
  dispatch({ type: PAGEBUILDER_FOOTER_UPDATE_THEME_ATTRIBUTES_KEY, payload });
  dispatch({ type: PAGEBUILDER_HAVE_UNSAVED_CHANGES, payload });
};
