import React from 'react';
import { Checkbox } from '@quintype/em/components/checkbox';
import Select from '@quintype/em/components/select';
import { TextArea } from '@quintype/em/components/text-area';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updateNotificationsConfig } from '../../actions/builder-action';
import styles from './notification-general-settings.module.css';
import { encodeCustomCode, checkValidEncodedCustomCode } from '../../utils/utils';

interface IStore {
  builder: {
    config: {
      general: {
        notifications: {
          enableNotifications: boolean;
          oneSignal?: { oneSignalAppId?: string; oneSignalAppleWebId?: string };
          fcm?: {
            serverKey?: string;
            messagingSenderId?: string;
            apiKey?: string;
            authDomain?: string;
            projectId?: string;
            storageBucket?: string;
            appId?: string;
            measurementId?: string;
            vapidKey?: string;
          };
          izooto?: { izootoKey?: string };
          others?: { embedCode?: string };
        };
      };
    };
  };
}

const NotificationGeneralSettings = () => {
  const options = [
    { label: 'One Signal', value: 'oneSignal' },
    { label: 'Izooto', value: 'izooto' },
    { label: 'FCM', value: 'fcm' },
    { label: 'Others', value: 'others' }
  ];

  const getDefaultValue = (type: string) => {
    switch (type) {
      case 'oneSignal':
        return { label: 'One Signal', value: 'oneSignal' };
      case 'izooto':
        return { label: 'Izooto', value: 'izooto' };
      case 'fcm':
        return { label: 'FCM', value: 'fcm' };
      default:
        return { label: 'Others', value: 'others' };
    }
  };

  const generalConfig = useSelector((state: IStore) => get(state, ['builder', 'config', 'general'], {}));
  const {
    enableNotifications = false,
    oneSignal = {},
    izooto = {},
    others = {},
    fcm = {},
    notificationType = 'others'
  } = get(generalConfig, ['notifications'], {});

  const dispatch = useDispatch();
  const updateSettings = (setting: string, key: string, value: any) => {
    dispatch(updateNotificationsConfig({ type: setting, config: { [key]: value } }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const getNotificationSettings = () => {
    const { oneSignalAppId = '', oneSignalAppleWebId = '' } = oneSignal;
    const {
      serverKey = '',
      messagingSenderId = '',
      apiKey = '',
      authDomain = '',
      projectId = '',
      storageBucket = '',
      appId = '',
      measurementId = '',
      vapidKey = ''
    } = fcm;
    const { izootoKey = '' } = izooto;
    const { embedCode = '' } = others;

    if (notificationType === 'oneSignal') {
      return (
        <>
          <TextField
            label="One signal app id"
            value={oneSignalAppId}
            placeholder="App Id"
            onChange={(value: string) => updateSettings('oneSignal', 'oneSignalAppId', value)}
          />
          <TextField
            label="One signal apple app id"
            value={oneSignalAppleWebId}
            placeholder="Apple App Id"
            onChange={(value: string) => updateSettings('oneSignal', 'oneSignalAppleWebId', value)}
          />
        </>
      );
    }
    if (notificationType === 'fcm') {
      const fcmNotification = [
        { label: 'Server Key', value: serverKey, placeholder: 'Server Key', key: 'serverKey' },
        { label: 'Sender Id', value: messagingSenderId, placeholder: 'Sender Id', key: 'messagingSenderId' },
        { label: 'Api Key', value: apiKey, placeholder: 'Api Key', key: 'apiKey' },
        { label: 'Auth Domain', value: authDomain, placeholder: 'Auth Domain', key: 'authDomain' },
        { label: 'Project Id', value: projectId, placeholder: 'Project Id', key: 'projectId' },
        { label: 'Storage Bucket', value: storageBucket, placeholder: 'Storage Bucket', key: 'storageBucket' },
        { label: 'App Id', value: appId, placeholder: 'App Id', key: 'appId' },
        {
          label: 'Measurement Id (Optional)',
          value: measurementId,
          placeholder: 'Measurement Id',
          key: 'measurementId'
        },
        { label: 'VAPID Key', value: vapidKey, placeholder: 'VAPID Key', key: 'vapidKey' }
      ];
      return (
        <div>
          {fcmNotification.map((item: { label: string; value: string; placeholder: string; key: string }) => {
            const { label = '', placeholder = '', value = '', key = '' } = item;
            return (
              <TextField
                key={`fcm-${key}`}
                label={label}
                value={value}
                placeholder={placeholder}
                onChange={(value: string) => updateSettings('fcm', key, value)}
              />
            );
          })}
        </div>
      );
    }
    if (notificationType === 'izooto') {
      return (
        <TextField
          label="Izooto Integration Key"
          value={izootoKey}
          placeholder="Enter Izooto Key"
          onChange={(value: string) => updateSettings('izooto', 'izootoKey', value)}
        />
      );
    }
    return (
      <TextArea
        label="Embed Code"
        value={checkValidEncodedCustomCode(embedCode)}
        placeholder="Paste code to be added in service worker."
        onChange={(value: string) => updateSettings('others', 'embedCode', encodeCustomCode(value))}
      />
    );
  };
  return (
    <>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          label="Enable Notification"
          id="pushNotification"
          checked={enableNotifications as boolean}
          onChange={(keyValue: boolean) => updateSettings('', 'enableNotifications', keyValue)}
        />
      </div>
      {enableNotifications && (
        <>
          <Select
            label="Choose Notification Type"
            options={options}
            defaultValue={getDefaultValue(notificationType)}
            onChange={(key: { value: string }) => updateSettings('', 'notificationType', key.value)}
          />
          {getNotificationSettings()}
        </>
      )}
    </>
  );
};

export default NotificationGeneralSettings;
