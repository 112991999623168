import React from 'react';
import { batch } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@quintype/em/components/checkbox';
import { TextField } from '@quintype/em/components/text-field';
import { commentSettings } from '../../actions/general-settings-action';
import styles from './comment-plugin.module.css';
import Select from '@quintype/em/components/select';
import { FieldLabel } from '@quintype/em/components/field-label';
import get from 'lodash/get';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';

interface IStore {
  config: {
    general: {
      commentPlugin?: {
        enableComments?: boolean;
        hideCommentsButton?: boolean;
        commentType?: string;
        meType?: {
          accountId?: string;
          host?: string;
          primaryColor?: string;
          fontColor?: string;
          className?: string;
          enableLiveFeed?: boolean;
        };
      };
    };
  };
}

const CommentPlugin = () => {
  const dispatch = useDispatch();

  const {
    enableComments = false,
    hideCommentsButton = true,
    meType = {},
    commentType = ''
  } = useSelector((state: IStore) => get(state, ['builder', 'config', 'general', 'commentPlugin'], {}));
  const {
    enableLiveFeed = false,
    accountId = '',
    host = '',
    primaryColor = '',
    fontColor = '',
    className = '',
    secondaryColor = '',
    metypeIntegrationId = ''
  } = meType;

  const updateSettings = (key: string, value: string | boolean, type: string) => {
    const payLoad = type ? { [type]: { [key]: value } } : { [key]: value };
    batch(() => {
      dispatch(commentSettings(payLoad));
      dispatch(updateHaveUnsavedChanges(true));
    });
  };

  const selectOptions = [
    { label: 'MeType', value: 'meType' },
    { label: 'Facebook', value: 'facebook' }
  ];

  const getDefaultCommentPlugin = (type: string) => {
    switch (type) {
      case 'facebook':
        return { label: 'Facebook', value: 'facebook' };
      default:
        return { label: 'MeType', value: 'meType' };
    }
  };

  const meTypeConfig = [
    {
      label: 'Account ID',
      placeholder: 'Enter Account ID...',
      value: accountId,
      onChange: { key: 'accountId' }
    },
    {
      label: 'Host',
      placeholder: 'Enter Host...',
      value: host,
      onChange: { key: 'host' }
    },
    {
      label: 'Primary color',
      placeholder: 'Enter Primary color...',
      value: primaryColor,
      onChange: { key: 'primaryColor' }
    },
    {
      label: 'Secondary color',
      placeholder: 'Enter Secondary color...',
      value: secondaryColor,
      onChange: { key: 'secondaryColor' }
    },
    {
      label: 'Font Color',
      placeholder: 'Enter Font Color...',
      value: fontColor,
      onChange: { key: 'fontColor' }
    },
    {
      label: 'Class Name',
      placeholder: 'Enter Class Name...',
      value: className,
      onChange: { key: 'className' }
    },
    {
      label: 'Integration Id for SSO ',
      placeholder: 'Enter Integration Id for SSO...',
      value: metypeIntegrationId,
      onChange: { key: 'metypeIntegrationId' }
    }
  ];

  return (
    <>
      <Checkbox
        label="Enable Comments"
        id="enableComments"
        checked={enableComments}
        onChange={(result: boolean) => updateSettings('enableComments', result, '')}
      />

      {enableComments && (
        <div className={styles.container}>
          <FieldLabel label="Comment Plugin" />
          <Select
            options={selectOptions}
            value={getDefaultCommentPlugin(commentType as string)}
            onChange={(obj: any) => updateSettings('commentType', obj.value, '')}
          />
          {commentType === 'meType' && (
            <>
              {meTypeConfig.map((item, index) => {
                const { label, value, onChange, placeholder } = item;
                return (
                  <TextField
                    key={`meType-${index}`}
                    label={label}
                    placeholder={placeholder}
                    value={value}
                    onChange={(keyValue: string) => updateSettings(onChange.key, keyValue, 'meType')}
                  />
                );
              })}
              <Checkbox
                label="Live Feed"
                id="enableLiveFeed"
                checked={enableLiveFeed}
                onChange={(value: boolean) => updateSettings('enableLiveFeed', value, 'meType')}
              />
            </>
          )}
          {commentType && (
            <div className={styles.checkboxContainer}>
              <Checkbox
                label="Button to show/hide comments"
                id="hideCommentsButton"
                checked={hideCommentsButton}
                onChange={(result: boolean) => updateSettings('hideCommentsButton', result, '')}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CommentPlugin;
