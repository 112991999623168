import { updateHaveUnsavedChanges, updateMeterSettings } from '../../actions/builder-action';
import React, { ReactElement } from 'react';
import { FieldLabel } from '@quintype/em/components/field-label';
import { TextField } from '@quintype/em/components/text-field';
import { ToggleButton } from '../toggle-button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './metering.module.css';
import './metering.module.css';
import { copyTextToClipboard } from '../row-inspector/utils';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import { shortCodeText, QUILL_EMPTY_STRING } from '../../constant';

export function updateMeterWallSettings(
  meterStatus: string,
  readerType: string,
  storyBehind: string,
  wallType: string,
  key: string,
  value: string | boolean,
  dispatch: any
) {
  const config = { meterStatus, readerType, storyBehind, wallType, key, value };
  dispatch(updateMeterSettings(config));
  dispatch(updateHaveUnsavedChanges(true));
}

interface configObject {
  type: string;
  text?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  onChange?: any;
  updateToggleState?: any;
  updateToggleButton?: any;
  toggleName?: any;
  id?: string;
  selected?: string;
}

export function renderAccordionSection(config: configObject[], modules: any, formats: any): ReactElement[] {
  return config.map((item, index) => {
    switch (item.type) {
      case 'hintText':
        return (
          <div className={styles.hintText} key={index}>
            {item.text}
          </div>
        );
      case 'quillEditor':
        return (
          <div className={styles.quillWrapper} key={item?.label || '' + index}>
            <FieldLabel label={item.label || ''} />
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={item.value}
              onChange={item.onChange}
              placeholder={item.placeholder || ''}
            />
          </div>
        );
      case 'text':
        return (
          <TextField
            label={item.label || ''}
            placeholder={item.placeholder}
            value={item.value || ''}
            onChange={(value: string) => {
              item.onChange(value);
            }}
          />
        );
      case 'fieldLabel':
        return (
          <div className={styles.meterOnAnonymous} key={index}>
            <FieldLabel label={item.label || ''} />
          </div>
        );
      case 'toggleButton':
        return (
          <ToggleButton
            label={item.label || ''}
            toggleName={item.toggleName}
            id={item.id || ''}
            updateToggleState={item.updateToggleState}
            updateToggleButton={(name: string, value: boolean | string) => item.updateToggleButton(name, value)}
          />
        );
      case 'radio':
        return (
          <div className={styles.radioToggle}>
            <Radio
              name="AT_wallType"
              selected={item.selected}
              onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) => item.onChange(e)}
            >
              <RadioOption id="1" value="payWall">
                Paywall
              </RadioOption>
              <RadioOption id="2" value="loginWall">
                Registration Wall
              </RadioOption>
            </Radio>
          </div>
        );
      default:
        return <></>;
    }
  });
}

export function meteringOffShortCodes() {
  return (
    <div className={styles.shortCodeWrapper}>
      <FieldLabel label={'Available shortcodes'} />
      <div className={styles.hintText}>{shortCodeText}</div>
      <span className={styles.shortCode} onClick={() => copyTextToClipboard('[Sign in]')}>
        Sign in
      </span>
    </div>
  );
}

export function meteringOnShortCodes() {
  return (
    <>
      <div className={styles.hintText}>{shortCodeText}</div>
      <div className={styles.shortCodeWrapper}>
        <span className={styles.shortCode} onClick={() => copyTextToClipboard('[number-of-stories]')}>
          Number of stories
        </span>
        <span className={styles.shortCode} onClick={() => copyTextToClipboard('[Sign in]')}>
          Sign in
        </span>
      </div>
    </>
  );
}

interface ISettings {
  wallTitle: string;
  wallDescription: string;
  signInTxt: string;
  buttonTxt: string;
  bottomTxt: string;
}

export function updateBannerDefaults(bannerName: string, bannerSettings: ISettings) {
  const updatedBannerSettings = Object.assign({}, bannerSettings);
  let { wallTitle, wallDescription, buttonTxt, bottomTxt } = bannerSettings;
  switch (bannerName) {
    case 'Stories behind Login': {
      if (wallTitle === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallTitle = '<p><strong>Sign up to gain access to this article.</strong></p>';

      if (wallDescription === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallDescription =
          '<p>To read more articles and enjoy an uninterrupted reading experience, please sign up with us.</p>';

      if (!buttonTxt) updatedBannerSettings.buttonTxt = 'Sign up';
      if (!bottomTxt) updatedBannerSettings.bottomTxt = 'Already have an account? [Sign in]';
      break;
    }
    case 'Stories behind Subscription': {
      if (wallTitle === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallTitle = '<p><strong>Subscribe to gain access to this premium article.</strong></p>';

      if (wallDescription === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallDescription =
          '<p>To access our premium articles and enjoy an uninterrupted reading experience, please consider subscribing to one of our plans.</p>';

      if (!buttonTxt) updatedBannerSettings.buttonTxt = 'View all plans';
      if (!bottomTxt) updatedBannerSettings.bottomTxt = 'Already a subscriber?  [Sign in]';
      break;
    }
    case 'Anonymous users - stories behind Login': {
      if (wallTitle === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallTitle = '<p><strong>Sign up for free to gain access to this article.</strong></p>';

      if (wallDescription === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallDescription =
          '<p>You have exhausted your free articles for the day. To read more articles, please sign up with us.</p>';

      if (!buttonTxt) updatedBannerSettings.buttonTxt = 'Sign up';
      if (!bottomTxt) updatedBannerSettings.bottomTxt = 'Already have an account? [Sign in]';
      break;
    }
    case 'Anonymous users - stories behind Subscription (Paywall)': {
      if (wallTitle === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallTitle = '<p><strong>Subscribe to gain access to this premium article</strong></p>';

      if (wallDescription === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallDescription =
          '<p>You have exhausted your free premium articles for the day. To access more premium content and enjoy uninterrupted reading experience, please consider subscribing to one of our plans.</p>';

      if (!buttonTxt) updatedBannerSettings.buttonTxt = 'View all plans';
      if (!bottomTxt) updatedBannerSettings.bottomTxt = 'Already a subscriber?  [Sign in]';
      break;
    }
    case 'Anonymous users - stories behind Subscription (Registration Wall)': {
      if (wallTitle === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallTitle = '<p><strong>Sign up to gain access to this premium article.</strong></p>';

      if (wallDescription === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallDescription =
          '<p>You have exhausted your free premium articles for the day. To access more premium content and enjoy uninterrupted reading experience, please sign up with us.</p>';

      if (!buttonTxt) updatedBannerSettings.buttonTxt = 'Sign up';
      if (!bottomTxt) updatedBannerSettings.bottomTxt = 'Already a user? [Sign in]';
      break;
    }
    case 'Logged in users - stories behind Subscription': {
      if (wallTitle === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallTitle = '<p><strong>Subscribe to gain access to this premium articles.</strong></p>';

      if (wallDescription === QUILL_EMPTY_STRING)
        updatedBannerSettings.wallDescription =
          '<p>You have exhausted your free premium articles for the day. To access more premium content and enjoy uninterrupted reading experience, please consider subscribing to one of our plans.</p>';

      if (!buttonTxt) updatedBannerSettings.buttonTxt = 'View all plans';
      break;
    }
  }
  return updatedBannerSettings;
}
