import { INavigationPopupReducer, IPopupReducerState } from '../../../utils/interfaces';
import { PAGEBUILDER_CLOSE_POPUP, PAGEBUILDER_NAVIGATION_CLOSE_POPUP, PAGEBUILDER_NAVIGATION_SHOW_POPUP, PAGEBUILDER_SHOW_POPUP } from './actions';
const showPopupReducer = (
  state: IPopupReducerState = { popupComponent: null, popupHeader: '', meta: {}, showPopup: false },
  action: {
    payload: any;
    type: string;
  }
) => {
  switch (action.type) {
    case PAGEBUILDER_SHOW_POPUP:
      return {
        popupComponent: action.payload.popupComponent,
        popupHeader: action.payload.popupHeader,
        meta: action.payload.meta,
        showPopup: true
      };
    case PAGEBUILDER_CLOSE_POPUP:
      return {
        popupComponent: null,
        popupHeader: '',
        meta: {},
        showPopup: false
      };
    default:
      return state;
  }
};


const navigationPopupReducer = (state: INavigationPopupReducer = { showPopup: false, saveButton: null, discardButton: null, navigationPath: null },
  action: {
    payload: any;
    type: string;
  }) => {
  switch (action.type) {
    case PAGEBUILDER_NAVIGATION_SHOW_POPUP:
      return {
        saveButton: action.payload.saveButton,
        discardButton: action.payload.discardButton,
        navigationPath: action.payload.navigationPath
      };
    case PAGEBUILDER_NAVIGATION_CLOSE_POPUP:
      return {
        saveButton: null,
        discardButton: null,
        navigationPath: null
      };
    default:
      return state;
  }
}

export { showPopupReducer, navigationPopupReducer };
