export const COLLECTION_DATA = {
  'associated-metadata': {
    initial_stories_load_count: 6,
    show_author_name: true,
    layout: 'ThreeColGrid',
    subsequent_stories_load_count: 8,
    show_section_tag: true,
    show_time_of_publish: true,
    enable_load_more_button: true,
    show_collection_name: true,
    enableDarkTheme: false,
    theme: 'light',
    button_text: 'Read more'
  },
  metadata: {
    'cover-image': {
      success: true,
      url: 'https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png',
      metadata: {
        width: 475,
        height: 654,
        'mime-type': 'image/png',
        'file-size': 569846,
        'file-name': 'Group 3.png',
        'focus-point': [263, 385]
      },
      'extracted-data': {
        artist: ''
      },
      attribution: '',
      'cover-image-url': 'https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png',
      'cover-image-s3-key': 'ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png'
    },
    entities: {
      collectionEntities: {
        magazine: [
          {
            id: 199563,
            name: 'New Magazine',
            type: 'magazine',
            'entity-type-id': 382
          }
        ]
      }
    }
  },
  id: '13675',
  items: [
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Plastic-free paradise: The Greek island eliminating plastic waste in three years',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline: 'Businesses on the Greek island of Paros have committed to reducing plastic waste',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Environment',
            id: 5751,
            'parent-id': null,
            'display-name': 'Environment',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Social enterprises outperform traditional business, says new research',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'The new report demonstrates the strength of social businesses, just as Social Enterprise UK launched a celebrity-backed campaign to get consumers supporting brands with purpose',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Lifestyle',
            id: 5751,
            'parent-id': null,
            'display-name': 'Lifestyle',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe',
        metadata: {
          'sponsored-by': 'quint'
        }
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Green party: how are festivals upping their environmental credentials?',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'This summer, festival organisers across Europe took steps to tackle their waste problem. We zoom in on events that are getting greener – and pioneering solutions to much bigger challenges',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Environment',
            id: 5751,
            'parent-id': null,
            'display-name': 'Environment',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Rooted in unity: Employee-owned businesses are doing well by doing good',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'Employee ownership is having a moment in the UK. Founder of Riverford, Guy Singh-Watson, explains why he handed the reins to his workforce',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Economics',
            id: 5751,
            'parent-id': null,
            'display-name': 'Economics',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Awareness of environmental issues is at its highest, research shows',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'New figures suggest people are increasingly motivated to make changes to reduce their carbon footprint',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Environment',
            id: 5751,
            'parent-id': null,
            'display-name': 'Environment',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Plastic-free paradise: The Greek island eliminating plastic waste in three years',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline: 'Businesses on the Greek island of Paros have committed to reducing plastic waste',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Environment',
            id: 5751,
            'parent-id': null,
            'display-name': 'Environment',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Social enterprises outperform traditional business, says new research',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'The new report demonstrates the strength of social businesses, just as Social Enterprise UK launched a celebrity-backed campaign to get consumers supporting brands with purpose',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Lifestyle',
            id: 5751,
            'parent-id': null,
            'display-name': 'Lifestyle',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Green party: how are festivals upping their environmental credentials?',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'This summer, festival organisers across Europe took steps to tackle their waste problem. We zoom in on events that are getting greener – and pioneering solutions to much bigger challenges',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Environment',
            id: 5751,
            'parent-id': null,
            'display-name': 'Environment',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Green party: how are festivals upping their environmental credentials?',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'This summer, festival organisers across Europe took steps to tackle their waste problem. We zoom in on events that are getting greener – and pioneering solutions to much bigger challenges',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Environment',
            id: 5751,
            'parent-id': null,
            'display-name': 'Environment',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Green party: how are festivals upping their environmental credentials?',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'This summer, festival organisers across Europe took steps to tackle their waste problem. We zoom in on events that are getting greener – and pioneering solutions to much bigger challenges',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Environment',
            id: 5751,
            'parent-id': null,
            'display-name': 'Environment',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Green party: how are festivals upping their environmental credentials?',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'This summer, festival organisers across Europe took steps to tackle their waste problem. We zoom in on events that are getting greener – and pioneering solutions to much bigger challenges',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Environment',
            id: 5751,
            'parent-id': null,
            'display-name': 'Environment',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    },
    {
      type: 'story',
      name: 'Sports',
      slug: 'test',
      template: 'default',
      metadata: {
        'cover-image': {}
      },
      id: '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
      story: {
        access: 'subscription',
        'updated-at': 1552543691683,
        'assignee-id': 296202,
        'author-name': 'Jane Doe',
        headline: 'Green party: how are festivals upping their environmental credentials?',
        'storyline-id': null,
        'story-content-id': '9fa8385f-0d10-4a65-9c60-50da5dd993e8',
        slug: 'lifestyle/2019/02/08/ph-regression',
        'last-published-at': 1552543694084,
        subheadline:
          'This summer, festival organisers across Europe took steps to tackle their waste problem. We zoom in on events that are getting greener – and pioneering solutions to much bigger challenges',
        sections: [
          {
            slug: 'lifestyle',
            name: 'Environment',
            id: 5751,
            'parent-id': null,
            'display-name': 'Environment',
            collection: { slug: 'lifestyle-updated-slug', name: 'Lifestyle', id: 2728 },
            data: null
          }
        ],
        'content-created-at': 1549615447375,
        'owner-name': 'Jane Doe',
        'publisher-id': 97,
        'read-time': 5,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/svg+xml',
          'file-size': 903,
          'file-name': 'Placeholder Image.svg'
        },
        'published-at': 1552543694084,
        'storyline-title': null,
        'hero-image-attribution': 'Credits: Parvathi Mohan',
        'hero-image-s3-key': '',
        'content-updated-at': 1552543694401,
        'author-id': 296202,
        'first-published-at': 1549615479950,
        'hero-image-caption': 'Along the way, he became a celebrity, adding to his allure with TV commercials',
        'created-at': 1552543688384,
        authors: [
          {
            id: 296202,
            name: 'Jane Doe',
            slug: 'sanjeev-kumar',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': null
          }
        ],
        'publish-at': null,
        'assignee-name': 'Jane Doe'
      }
    }
  ],
  name: 'Unearthed Axis',
  slug: 'test',
  summary:
    'How did the big-headed, wide-eyed, spindly-legged, green-skinned popular depiction of an alien become the universal representation of extraterrestrial species?',
  template: 'default',
  type: 'collection',
  'created-at': 1552543688384
};
