import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { defaultStoryTypes } from '../containers/story/story-page';
import { ISelectedRow } from '../utils/interfaces';
import { useQuery } from './useQuery';
interface IBuilder {
  selectedRows: ISelectedRow[];
}
interface IState {
  builder: IBuilder;
}
function useConfigPathFromStore() {
  const queryParams = useQuery();
  return useSelector((state: IState) => {
    const pageType = get(state, ['builder', 'pageType'], '');
    const subPageType = get(state, ['builder', 'subPageType'], '');
    const layoutId = queryParams.get('layoutId');
    return getConfigPathFromStore(pageType, pageType === 'story' ? subPageType : (layoutId as string));
  });
}

// A similar function to use in non-Fn Component which will read the pageType and pageId.
export const getConfigPathFromStore = (pageType: string, nestedId: string) => {
  switch (pageType) {
    case 'landingPage':
      return [pageType, 'layouts', nestedId];
    case 'story':
      // Check if story is default story type or custom story type
      if (defaultStoryTypes.includes(nestedId || '')) {
        return [pageType, nestedId];
      }
      return ['customStory', nestedId];
    case 'issue-landing':
    case 'magazine-landing':
    case 'archive':
      return ['magazine', pageType];
    case 'sign-in':
    case 'sign-up':
    case 'forgot-password':
    case 'email-otp':
    case 'mobile-otp':
    case 'reset-password':
    case 'enter-password':
      return ['login', pageType];
    case 'profile-page':
      return ['profilePage'];
    case 'purchased-story-page':
      return ['purchasedStoryPage'];
    default:
      return [pageType];
  }
};

export default useConfigPathFromStore;
