import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Close: React.FunctionComponent<IsvgData> = ({ width = '24px', height = '24px', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fill-rule="evenodd"
        d="M17.657 7.05l-4.773 4.772 4.773 4.774-.707.707-4.773-4.774-4.773 4.774-.707-.707 4.773-4.774L6.697 7.05l.707-.707 4.773 4.772 4.773-4.772.707.707z"
      />
    </svg>
  );
};

export { Close };
