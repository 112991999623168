import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { CircularColorSocialLink } from '../../social-link-icons/circular-color';
import { CircularPlainSocialLink } from '../../social-link-icons/circular-plain';
import { PlainSocialLink } from '../../social-link-icons/plain';
import { PlainColorSocialLink } from '../../social-link-icons/plain-color';
import { SquareSocialLink } from '../../social-link-icons/square';
import styles from './global-social-link-icons.module.css';

interface Iprops {
  SocialFollowHeaderStyle: string;
  SocialFollowFooterStyle: string;
  layout?: string | undefined;
  SocialFollowTextStoryStyle: string;
  SocialFollowPhotoStoryStyle: string;
  SocialFollowVideoStoryStyle: string;
  SocialFollowLiveBlogStoryStyle: string;
  defaultLayout?: string | undefined;
}
class GlobalSocialLinkIconBase extends React.Component<Iprops> {
  getSocialStyle = (style: string | undefined) => {
    switch (style) {
      case 'plain-svg':
        return <PlainSocialLink />;
      case 'square-svg':
        return <SquareSocialLink />;
      case 'circular-plain-svg':
        return <CircularPlainSocialLink />;
      case 'circular-color-svg':
        return <CircularColorSocialLink />;
      case 'plain-color-svg':
        return <PlainColorSocialLink />;
      default:
        return <PlainColorSocialLink />;
    }
  };

  getLayout = (layout: string | undefined) => {
    // need to clean this code by passing the values selected from the component itself
    switch (layout) {
      case 'Footer':
        return this.getSocialStyle(this.props.SocialFollowFooterStyle);
      case 'Header':
        return this.getSocialStyle(this.props.SocialFollowHeaderStyle);
      case 'text':
        return this.getSocialStyle(this.props.SocialFollowTextStoryStyle);
      case 'photo':
        return this.getSocialStyle(this.props.SocialFollowPhotoStoryStyle);
      case 'video':
        return this.getSocialStyle(this.props.SocialFollowVideoStoryStyle);
      case 'liveBlog':
        return this.getSocialStyle(this.props.SocialFollowLiveBlogStoryStyle);
      default:
        return this.getSocialStyle(this.props.defaultLayout);
    }
  };
  render() {
    return <div className={styles.globalSocialLinkWrapper}>{this.getLayout(this.props.layout)}</div>;
  }
}

const mapStateToProps = (state: any) => ({
  SocialFollowHeaderStyle: get(state, ['builder', 'config', 'header', 'socialFollowStyle']),
  SocialFollowFooterStyle: get(state, ['builder', 'config', 'footer', 'socialFollowStyle']),
  SocialFollowTextStoryStyle: get(
    state,
    ['builder', 'config', 'story', 'text-story', 'settings', 'shareIconType'],
    'plain-color-svg'
  ),
  SocialFollowPhotoStoryStyle: get(
    state,
    ['builder', 'config', 'story', 'photo-story', 'settings', 'shareIconType'],
    'plain-color-svg'
  ),
  SocialFollowVideoStoryStyle: get(
    state,
    ['builder', 'config', 'story', 'video-story', 'settings', 'shareIconType'],
    'plain-color-svg'
  ),
  SocialFollowLiveBlogStoryStyle: get(
    state,
    ['builder', 'config', 'story', 'liveBlog-story', 'settings', 'shareIconType'],
    'plain-color-svg'
  )
});

export const GlobalSocialLinkIcon = connect(mapStateToProps)(GlobalSocialLinkIconBase);
