import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import { Button } from '@quintype/em/components/button';
import { Callout } from '@quintype/em/components/callout';
import { Checkbox } from '@quintype/em/components/checkbox';
import { FieldLabel } from '@quintype/em/components/field-label';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import Select from '@quintype/em/components/select';
import { TextArea } from '@quintype/em/components/text-area';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateSlots, storySlotSettings, updateStorySlots } from '../../actions/story-action';
import { IDropdownOption, IStorySlot, IStorySlotConfig } from '../../utils/interfaces';
import { checkValidEncodedCustomCode, encodeCustomCode, getStoryStoreField } from '../../utils/utils';
import { Separator } from '../../atoms/separator';
import styles from './arrow-row-setting/setting.module.css';
import {
  asideSlotsSupportedStoryTemplates,
  componentType,
  dummyAsideSlotConfig,
  dummyStorySlotConfig,
  getCardOption,
  getComponentMeta,
  getComponentType,
  getDefaultValueforSlotPosition,
  getPlacementOfStoryAd,
  getSlotId,
  IAdOptionsProps,
  ISlotMenu,
  IStorySlotInspectorProps,
  placement,
  radioBtn,
  selectCard,
  selectSlotType
} from './utils';

const AdOptions = ({ slotId, slotDetails, setAdSize, selected }: IAdOptionsProps) => {
  const [selectedOption, setSelectedOption] = useState(selected);
  const radioOptions =
    slotDetails['slot-position'] === 'aside' ? radioBtn.radioAsideOptions : radioBtn.radioStoryOptions;
  return (
    // making this a form to avoid the input elements in RadioOptions are grouped sanely
    <form>
      <Radio
        label={radioBtn.label}
        name={radioBtn.keyName}
        selected={selectedOption}
        id={slotId}
        align="vertical"
        onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) => {
          setSelectedOption((e.target as HTMLInputElement).value);
          setAdSize(slotId, 'ad-size', (e.target as HTMLInputElement).value);
        }}
      >
        {radioOptions.map(({ name, value }: { name: string; value: string }, index: number) => {
          return (
            <RadioOption value={value} key={slotId + index}>
              {name}
            </RadioOption>
          );
        })}
      </Radio>
    </form>
  );
};

const StorySlotInspector = ({ storyType = '', storyTemplate = 'default' }: IStorySlotInspectorProps) => {
  const storyStoreField = getStoryStoreField(storyType);
  const slotArray = useSelector((state: any) =>
    get(state, ['builder', 'config', storyStoreField, storyType, 'slots'], [])
  );

  const aheadNxtEnabled = useSelector((state: any) =>
    get(state, ['builder', 'config', 'general', 'aheadNxtEnabled'], false)
  );
  const dispatch = useDispatch();

  const areAsideSlotsSupported = (storyTemplateName: string) =>
    asideSlotsSupportedStoryTemplates.includes(storyTemplateName);

  const deleteSlot = (slotId: string) => {
    const updatedSlots = slotArray.filter((slot: object) => slotId !== getSlotId(slot));
    dispatch(updateStorySlots({ type: storyType, slots: updatedSlots }));
    dispatch(generateSlots({ type: storyType, storyStoreField }));
  };

  const addSlot = (storyTemplateName: string) => {
    const slotId = new Date().getTime().toString();
    const updatedSlots = [
      ...slotArray,
      {
        [slotId]: areAsideSlotsSupported(storyTemplateName) ? dummyAsideSlotConfig : dummyStorySlotConfig
      }
    ];

    dispatch(updateStorySlots({ type: storyType, slots: updatedSlots }));
    dispatch(generateSlots({ type: storyType, storyStoreField }));
  };

  const updatedata = (id: string, key: string, value: any) => {
    dispatch(storySlotSettings({ type: storyType, id, key, value }));
    dispatch(generateSlots({ type: storyType, storyStoreField }));
  };

  const renderStoryOptions = (
    compType: string | undefined,
    slotId: string,
    slotDetails: IStorySlotConfig,
    item: IStorySlot
  ) => {
    const cardOptions = selectCard(slotDetails);
    switch (compType) {
      case 'ad':
        return (
          <>
            <div className={styles.checkboxWrapper}>
              <AdOptions
                slotId={slotId}
                slotDetails={slotDetails}
                selected={slotDetails['ad-size']}
                setAdSize={updatedata}
              />
            </div>
            <div className={styles.slotSetting}>
              <Select
                label={placement.label}
                options={placement.options}
                onChange={(option: IDropdownOption) => updatedata(slotId, 'placement', option.value)}
                value={getPlacementOfStoryAd(slotDetails)}
              />
              <Select
                label={cardOptions.label}
                options={cardOptions.options}
                onChange={(option: IDropdownOption) => updatedata(slotId, 'select-card', option.value)}
                value={getCardOption(slotDetails)}
              />
            </div>
            <TextField
              label="Targeting Id"
              placeholder="Enter targeting ID"
              value={get(item, [slotId, 'target-id'])}
              onChange={value => updatedata(slotId, 'target-id', value)}
            />
          </>
        );
      case 'widget':
        return (
          <>
            <div className={styles.warningMessage}>
              <Callout
                variant="warning"
                title="Warning"
                content="The code may contain malicious script or may negatively affect the performance. Be cautious before pasting your code."
              />
            </div>
            <FieldLabel label="Custom Code" />
            <div className={styles.widgetInputBox}>
              <TextArea
                label="widget"
                value={checkValidEncodedCustomCode(slotDetails['story-widget'] || '')}
                hint="Paste any valid HTML code here. The code will be inserted to the row."
                onChange={(value: string) => updatedata(slotId, 'story-widget', encodeCustomCode(value))}
              />
            </div>
            <TextField
              label="Targeting Id"
              placeholder="Enter targeting ID"
              value={get(item, [slotId, 'target-id'])}
              onChange={value => updatedata(slotId, 'target-id', value)}
            />
            {aheadNxtEnabled && (
              <>
                <Separator />
                <div className={styles.aheadNxtBanner}>Ahead Next</div>
                <div className={styles.centreAlignChkboxWrapper}>
                  <Checkbox
                    label="Centre Align Widget"
                    id={`checkbox-centre-align-widget-${slotId}`}
                    checked={get(slotDetails, ['centre-align-widget'], true)}
                    onChange={(value: any) => updatedata(slotId, 'centre-align-widget', value)}
                  />
                </div>
                <TextField
                  label="Targeting Id"
                  placeholder="Enter targeting ID"
                  value={get(item, [slotId, 'ahead-nxt-target-id'])}
                  onChange={value => updatedata(slotId, 'ahead-nxt-target-id', value)}
                />
                <TextField
                  label="Placeholder Height (in pixels)"
                  value={get(item, [slotId, 'ahead-nxt-widget-placeholder-height'])}
                  onChange={value => updatedata(slotId, 'ahead-nxt-widget-placeholder-height', value)}
                  placeholder="50"
                />
                <Separator />
              </>
            )}
            <div className={styles.slotSetting}>
              <Select
                label={placement.label}
                options={placement.options}
                onChange={(option: IDropdownOption) => updatedata(slotId, 'placement', option.value)}
                defaultValue={getPlacementOfStoryAd(slotDetails)}
              />
              <Select
                label={cardOptions.label}
                options={cardOptions.options}
                onChange={(option: IDropdownOption) => updatedata(slotId, 'select-card', option.value)}
                defaultValue={getCardOption(slotDetails)}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderAsideOptions = (compType: string | undefined, slotId: string, slotDetails: IStorySlotConfig) => {
    switch (compType) {
      case 'collection':
        return (
          <>
            <TextField
              label="Collection Slug"
              placeholder="Add collection slug from Bold"
              value={slotDetails['collection-slug'] || ''}
              onChange={value => updatedata(slotId, 'collection-slug', value)}
            />
            <TextField
              label="Collection Title"
              placeholder="Add collection title"
              value={slotDetails['collection-title'] || ''}
              onChange={value => updatedata(slotId, 'collection-title', value)}
            />
          </>
        );
      case 'ad':
        return (
          <>
            <div className={styles.checkboxWrapper}>
              <AdOptions
                slotDetails={slotDetails}
                slotId={slotId}
                selected={slotDetails['ad-size']}
                setAdSize={updatedata}
              />
            </div>
            <TextField
              label="Targeting Id"
              placeholder="Enter targeting ID"
              value={slotDetails['aside-target-id'] || ''}
              onChange={value => updatedata(slotId, 'aside-target-id', value)}
            />
          </>
        );

      case 'widget':
        return (
          <>
            <div className={styles.warningMessage}>
              <Callout
                variant="warning"
                title="Warning"
                content="The code may contain malicious script or may negatively affect the performance. Be cautious before pasting your code."
              />
            </div>
            <FieldLabel label="Custom Code" />
            <div className={styles.widgetInputBox}>
              <TextArea
                label="widget"
                value={checkValidEncodedCustomCode(slotDetails['aside-widget'] || '')}
                hint="Paste any valid HTML code here. The code will be inserted to the row."
                onChange={(value: string) => updatedata(slotId, 'aside-widget', encodeCustomCode(value))}
              />
            </div>
            <TextField
              label="Targeting Id"
              placeholder="Enter targeting ID"
              value={get(slotDetails, ['target-id'])}
              onChange={value => updatedata(slotId, 'target-id', value)}
            />
            {aheadNxtEnabled && (
              <>
                <Separator />
                <div className={styles.aheadNxtBanner}>Ahead Next</div>
                <div className={styles.centreAlignChkboxWrapper}>
                  <Checkbox
                    label="Centre Align Widget"
                    id={`checkbox-centre-align-widget-${slotId}`}
                    checked={get(slotDetails, ['centre-align-widget'], true)}
                    onChange={(value: any) => updatedata(slotId, 'centre-align-widget', value)}
                  />
                </div>
                <TextField
                  label="Placeholder Height (in pixels)"
                  value={slotDetails['ahead-nxt-widget-placeholder-height'] || ''}
                  onChange={value => updatedata(slotId, 'ahead-nxt-widget-placeholder-height', value)}
                  placeholder="50"
                  hint="Note: The Targeting ID for Ahead Next remains the same as Ahead"
                />
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const getSlotName = (slotDetails: IStorySlotConfig) => {
    return get(slotDetails, ['slot-name'], 'Untitled Slot');
  };
  const getSlotStatus = (slotDetails: IStorySlotConfig) => {
    return get(slotDetails, ['isSlotEnable'], false);
  };

  const renderOptions = (slotId: string, item: IStorySlot, slotDetails: IStorySlotConfig) => {
    const defaultSlotPosition = areAsideSlotsSupported(storyTemplate) ? 'aside' : 'story';
    const slotPosition = get(item, [slotId, 'slot-position'], defaultSlotPosition);
    switch (slotPosition) {
      case 'story':
        return renderStoryOptions(getComponentType(slotPosition, slotId, item), slotId, slotDetails, item);
      case 'aside':
        return renderAsideOptions(getComponentType(slotPosition, slotId, item), slotId, slotDetails);
      default:
        return null;
    }
  };

  const getSlotMenu = ({ slotPosition, item, slotId, slotDetails }: ISlotMenu) => {
    return (
      <>
        <Select
          label={selectSlotType.label}
          options={selectSlotType.options(areAsideSlotsSupported(storyTemplate))}
          onChange={(items: any) => updatedata(slotId, 'slot-position', items.value)}
          value={getDefaultValueforSlotPosition(item, slotId)}
        />
        <Select
          label={componentType.label}
          options={componentType.options(slotPosition)}
          value={getComponentMeta(item, slotId)}
          onChange={(items: any) => updatedata(slotId, 'component-type', items.value)}
        />
        {renderOptions(slotId, item, slotDetails)}
      </>
    );
  };

  return (
    <>
      <Accordion hasSingleActivePanel onToggle={() => false}>
        {(slotArray.length > 0 &&
          slotArray.map((item: IStorySlot, index: number) => {
            const slotId = Object.keys(item)[0];
            const defaultSlotPosition = areAsideSlotsSupported(storyTemplate) ? 'aside' : 'story';
            const slotPosition = get(item, [slotId, 'slot-position'], defaultSlotPosition);
            const slotDetails = get(item, [slotId], {}) as IStorySlotConfig;

            if (!areAsideSlotsSupported(storyTemplate) && slotPosition === 'aside') {
              return <></>;
            }
            const label = get(item, [slotId, 'slot-name'], 'Untitled Slot');
            return (
              <AccordionSection label={label || 'Untitled Slot'} isLabelUpperCase index={index} key={index}>
                <TextField
                  label="Slot Name"
                  placeholder="Slot name"
                  onChange={value => updatedata(slotId, 'slot-name', value)}
                  value={getSlotName(slotDetails)}
                />
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    label="Enable slot"
                    id={`toggle-${slotId}`}
                    checked={getSlotStatus(slotDetails)}
                    onChange={(value: any) => updatedata(slotId, 'isSlotEnable', value)}
                  />
                </div>

                {getSlotMenu({ slotPosition, item, slotId, slotDetails })}

                <Button type="primary" variant="danger" onClick={() => deleteSlot(slotId)}>
                  Delete Slot
                </Button>
              </AccordionSection>
            );
          })) || <></>}
      </Accordion>
      <div className={styles.addSlotButton}>
        <Button type="primary" onClick={() => addSlot(storyTemplate)}>
          Add Slot
        </Button>
      </div>
    </>
  );
};

export default StorySlotInspector;
