import Select from '@quintype/em/components/select';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateHaveLayoutUpdates } from '../../actions/builder-action';
import { updateHeaderRowsList } from '../../actions/header-action';
import { updateRowsList } from '../../actions/row-action';
import { closeSelectRowInspector } from '../../actions/select-row-inspector-action';
import FullScreenModalWrapper from '../../atoms/full-screen-modal-wrapper';
import { widgetMap } from '../../molecules/select-row-inspector/widgetMap';
import { adMap } from '../../row/ad/ad-layouts';
import { IComponentMeta, IConfig, IRowAction, ISelectedRow, ISelectRowInspector } from '../../utils/interfaces';
import { getDefaultValue, updateActionOption } from '../../utils/utils';
import LowFidelityLayouts from '../low-fidelity-layouts';
import MasonryLayouts from '../masonry-layouts';
import styles from './select-row-inspector.module.css';
import { Switch } from '@quintype/em/components/switch';
import get from 'lodash/get';

type rowFunction = (args: IRowAction) => void;
type saveFunction = (arg: boolean) => void;
interface IProps {
  updateRowsListFunction: rowFunction;
  updateHeaderRowsList: rowFunction;
  updateHaveLayoutUpdates: saveFunction;
  closeModal: () => void;
  componentMetaData: IComponentMeta[];
  selectedRow: ISelectedRow[];
  selectRowInspector: ISelectRowInspector;
  rowId?: number;
  currentRow?: ISelectedRow;
  config: IConfig;
  publisherId: string;
  pageType: string;
}

interface IState {
  selectedLayout: object | null;
  selectVal: string;
  optimisedOnly: boolean;
}

interface IComponentName {
  label: string;
  value: string;
}

interface IDefaultSettings {
  keyName: string;
  defaultValue: boolean | string;
}

interface IAcc {
  [key: string]: string | boolean;
}

class SelectRowInspectorBase extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedLayout: null,
      selectVal: '',
      optimisedOnly: false
    };
  }

  getRowId = () => {
    if (this.props.selectRowInspector.action === 'update') {
      return this.props.selectRowInspector.rowId;
    }
    return new Date().getTime();
  };

  handleSelectChange = (
    type: string,
    componentName: string,
    componentLabel: string,
    pageType: string,
    settings: object,
    componentContentType: string,
    storiesCount: number
  ) => {
    const aheadNextRow = get(settings, ['aheadNextRowMappingSettings', '0', 'defaultValue'], '');
    const showHideSettings = get(settings, ['showHideSettings'], []);
    const showHideDefaultConfig = showHideSettings.reduce((acc: IAcc, defaultSettings: IDefaultSettings) => {
      acc[defaultSettings.keyName] = defaultSettings.defaultValue;
      return acc;
    }, {});
    const sliderSettings = get(settings, ['sliderSettings'], null);
    let sliderDefaultConfig = {};
    if (sliderSettings) {
      sliderDefaultConfig = sliderSettings.reduce((acc: IAcc, defaultSettings: IDefaultSettings) => {
        acc[defaultSettings.keyName] = defaultSettings.defaultValue;
        return acc;
      }, {});
    }
    const config = { ...showHideDefaultConfig, ...sliderDefaultConfig, aheadNextRow };

    if (pageType === 'tag' || pageType === 'author' || pageType === 'purchased-story-page') {
      config.footerButton = 'SubsequentLoadCount';
    }

    this.setState(
      {
        selectedLayout: {
          rowId: this.getRowId(),
          type,
          layout: componentName,
          layoutLabel: componentLabel,
          config,
          pageType,
          componentContentType,
          storiesCount
        }
      },
      this.addRowHandler
    );
  };

  addRowHandler = () => {
    const { action, rowId, isHeaderRow } = this.props.selectRowInspector;
    const updateRowsListFunc = isHeaderRow ? this.props.updateHeaderRowsList : this.props.updateRowsListFunction;
    this.state.selectedLayout &&
      updateRowsListFunc({
        rowAction: action,
        rowActionPayload: {
          selectedRowId: rowId,
          row: this.state.selectedLayout,
          moduleType: this.props.pageType
        }
      });

    this.props.updateHaveLayoutUpdates(true);
    this.setState({ selectedLayout: null });
    this.props.closeModal();
  };

  onChangeHandler = (selectedOption: IComponentName) => {
    this.setState({ selectVal: selectedOption.value });
  };
  // TODO: Change any
  renderRowSelector(type: string, componentMetaData: any) {
    switch (this.state.selectVal || type) {
      case 'Ads':
        return (
          <LowFidelityLayouts
            handleLayoutSelect={comp =>
              this.handleSelectChange('ads', comp.componentName, comp.componentLabel, this.props.pageType, {}, '', 0)
            }
            layoutsList={adMap}
          />
        );
      case 'widget':
        return (
          <LowFidelityLayouts
            handleLayoutSelect={comp =>
              this.handleSelectChange('widget', 'widget', 'Custom Widget', this.props.pageType, {}, '', 0)
            }
            layoutsList={widgetMap}
          />
        );
      default:
        return (
          <MasonryLayouts
            handleLayoutSelect={(
              type: string,
              componentName: string,
              componentLabel: string,
              settings: object,
              componentContentType: string,
              storiesCount: number
            ) =>
              this.handleSelectChange(
                type,
                componentName,
                componentLabel,
                this.props.pageType,
                settings,
                componentContentType,
                storiesCount
              )
            }
            layoutsList={componentMetaData}
            type="collections"
            optimisedOnly={this.state.optimisedOnly}
          />
        );
    }
  }

  switchHandler = () => {
    this.setState(state => ({
      optimisedOnly: !state.optimisedOnly
    }));
  };

  componentDidUpdate({ selectRowInspector }: any) {
    if (selectRowInspector.isOpen !== this.props.selectRowInspector.isOpen) {
      const rowType = this.props.selectRowInspector.type;
      const selectVal = get(getDefaultValue(rowType), ['value'], '');
      this.setState({ selectVal: selectVal });
    }
  }

  render() {
    const {
      componentMetaData,
      selectRowInspector: { isOpen, type, isHeaderRow }
    } = this.props;
    const storiesOptionArr = !isHeaderRow ? [{ label: 'Stories', value: 'stories' }] : [];
    const layoutTypesList = [...storiesOptionArr, { label: 'Ads', value: 'Ads' }, { label: 'Widget', value: 'widget' }];

    const optionSelector = (props: IProps) => {
      const {
        selectRowInspector: { action, type },
        pageType
      } = props;
      switch (pageType) {
        case 'tag':
          return updateActionOption(action, type);
        case 'author':
          return updateActionOption(action, type);
        case 'authors':
          return updateActionOption(action, type);
        case 'search':
          return updateActionOption(action, type);
        case 'magazine':
          return updateActionOption(action, type);
        case 'story':
          return updateActionOption(action, type);
        case 'subscription':
          return updateActionOption(action, type);
        default:
          return layoutTypesList;
      }
    };

    const modalLayout = (
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          <div className={styles.heading}>Choose Layout</div>
          <p className={styles.description}>Select the layout option you want to display.</p>
          {this.props?.pageType !== 'subscription' && (
            <>
              <div className={styles.chooseLayout}>Layouts</div>
              <form action="#">
                <Select
                  defaultValue={getDefaultValue(type)}
                  onChange={this.onChangeHandler}
                  options={optionSelector(this.props)}
                />
              </form>
            </>
          )}
          {this.props?.pageType === 'home' && this.state.selectVal === 'stories' && (
            <div className={styles.switchWrapper}>
              <span className={styles.labelBold}>Optimised row templates</span>
              <Switch id="optimsed" onChange={this.switchHandler} checked={this.state.optimisedOnly} />
            </div>
          )}
        </div>
        <div className={styles.main}>{this.renderRowSelector(type, componentMetaData)}</div>
      </div>
    );

    return (
      <div className={styles.buttonWrapper}>
        {isOpen && (
          <FullScreenModalWrapper
            onClose={() => {
              this.setState({
                selectVal: ''
              });
              this.props.closeModal();
            }}
          >
            {modalLayout}
          </FullScreenModalWrapper>
        )}
      </div>
    );
  }
}

interface IAhead {
  componentMetaData: IComponentMeta[];
}

interface IBuilder {
  selectedRow: ISelectedRow[];
  selectRowInspector: ISelectRowInspector;
  config: IConfig;
  pageType: string;
}

interface IStateProps {
  ahead: IAhead;
  builder: IBuilder;
}

const mapStateToProps = ({ builder, ahead: { componentMetaData = [] } }: IStateProps) => {
  const { selectedRow = [], selectRowInspector, config, pageType } = builder;
  return {
    componentMetaData,
    selectedRow,
    config,
    selectRowInspector,
    pageType
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  updateRowsListFunction: (data: IRowAction) => dispatch(updateRowsList(data)),
  updateHeaderRowsList: (data: IRowAction) => dispatch(updateHeaderRowsList(data)),
  updateHaveLayoutUpdates: (update: boolean) => dispatch(updateHaveLayoutUpdates(update)),
  closeModal: () => dispatch(closeSelectRowInspector())
});

export const SelectRowInspector = connect(mapStateToProps, mapDispatchToProps)(SelectRowInspectorBase);
