// Builder actions.
// Actions which will effect whole config/ common to more than one module.
export const PAGEBUILDER_BUILDER_UPDATE_CONFIG = 'PAGEBUILDER.BUILDER.UPDATE_CONFIG';
export const PAGEBUILDER_ROW_UPDATE_SELECTED_ROW = 'PAGEBUILDER.BUILDER.ROW_UPDATE_SELECTED_ROW';
export const PAGEBUILDER_ROW_DELETE_ROW = 'PAGEBUILDER.BUILDER.ROW_DELETE_ROW';
export const PAGEBUILDER_ROW_ADD_ROW_BOTTOM = 'PAGEBUILDER.BUILDER.ROW_ADD_ROW_BOTTOM';
export const PAGEBUILDER_ROW_ADD_ROW_TOP = 'PAGEBUILDER.BUILDER.ROW_ADD_ROW_TOP';
export const PAGEBUILDER_ROW_UPDATE_SELECTED_ROW_SETTINGS = 'PAGEBUILDER.BUILDER.ROW_UPDATE_SELECTED_ROW_SETTINGS';
export const PAGEBUILDER_ROW_MOVE = 'PAGEBUILDER.BUILDER.ROW_MOVE';
export const PAGEBUILDER_UPDATE_PAGE_SETTINGS = 'PAGEBUILDER.BUILDER.UPDATE_PAGE_SETTINGS';

// meteredPaywall
export const PAGEBUILDER_UPDATE_METERED_PAYWALL_SETTINGS = 'PAGEBUILDER.BUILDER.UPDATE_METER_SETTINGS';

// dark mode PB preview
export const PAGEBUILDER_DARK_MODE_PB_PREVIEW = 'PAGEBUILDER.BUILDER.DARK_MODE_PB_PREVIEW';

// General
export const PAGEBUILDER_GENERAL_UPDATE_FONTS = 'PAGEBUILDER.GENERAL.UPDATE_FONTS';
export const PAGEBUILDER_GENERAL_UPDATE_CUSTOM_FONTS = 'PAGEBUILDER.GENERAL.UPDATE_CUSTOM_FONTS';
export const PAGEBUILDER_GENERAL_UPDATE_LOCALIZATION = 'PAGEBUILDER.GENERAL.UPDATE_LOCALIZATION';
export const PAGEBUILDER_GENERAL_UPDATE_COLORS_IN_PALETTE = 'PAGEBUILDER.GENERAL.UPDATE_COLORS_IN_PALETTE';
export const PAGEBUILDER_GENERAL_DELETE_LOGO = 'PAGEBUILDER.GENERAL.DELETE_LOGO';
export const PAGEBUILDER_GENERAL_ADD_LOGO = 'PAGEBUILDER.GENERAL.ADD_LOGO';
export const PAGEBUILDER_GENERAL_ADD_FALLBACK_IMAGE = 'PAGEBUILDER.GENERAL_ADD_FALLBACK_IMAGE';
export const PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE = 'PAGEBUILDER.GENERAL.UPDATE_ROW_TITLE_STYLE';
export const PAGEBUILDER_GENERAL_UPDATE_ROW_TITLE_STYLE_ARROW = 'PAGEBUILDER.GENERAL.UPDATE_ROW_TITLE_STYLE_ARROW';
export const PAGEBUILDER_GENERAL_ROW_SECTION_TAG_STYLE = 'PAGEBUILDER.GENERAL.ROW_SECTION_TAG_STYLE';
export const PAGEBUILDER_GENERAL_PREMIUM_STORY_ICON_STYLE = 'PAGEBUILDER.GENERAL.PREMIUM_STORY_ICON_STYLE';
export const PAGEBUILDER_GENERAL_STORY_DEMARCATION_STYLE = 'PAGEBUILDER.GENERAL.STORY_DEMARCATION_ICON_STYLE';
export const PAGEBUILDER_GENERAL_SPONSORED_STORY_STYLE = 'PAGEBUILDER.GENERAL.SPONSORED_STORY_STYLE';

export const PAGEBUILDER_GENERAL_UPDATE_CUSTOM_CODE_SETTINGS = 'PAGEBUILDER.GENERAL.UPDATE_CUSTOM_CODE_SETTINGS';
export const PAGEBUILDER_GENERAL_REDIRECT_URL = 'PAGEBUILDER.GENERAL.UPDATE_REDIRECT_URL';
export const PAGEBUILDER_GENERAL_UPDATE_AD_SETTINGS = 'PAGEBUILDER.GENERAL.UPDATE_AD_SETTINGS';
export const PAGEBUILDER_GENERAL_OTHER_ADS_UPDATE_SCRIPTS = 'PAGEBUILDER.GENERAL.OTHER_ADS_UPDATE_SCRIPTS';
export const PAGEBUILDER_GENERAL_UPDATE_APP_DOWNLOAD_LINKS_SETTINGS =
  'PAGEBUILDER.GENERAL.UPDATE_APP_DOWNLOAD_LINKS_SETTINGS';
export const PAGEBUILDER_GENERAL_UPDATE_THIRD_PARTY_CONFIG = 'PAGEBUILDER.GENERAL.UPDATE_THIRD_PARTY_CONFIG';
export const PAGEBUILDER_GENERAL_UPDATE_CONFIG = 'PAGEBUILDER.GENERAL.UPDATE_CONFIG';
export const PAGEBUILDER_GENERAL_UPDATE_NOTIFICATIONS = 'PAGEBUILDER.GENERAL.UPDATE_NOTIFICATIONS';
export const PAGEBUILDER_GENERAL_UPDATE_SPA = 'PAGEBUILDER.GENERAL.UPDATE_SPA';
export const PAGEBUILDER_GENERAL_UPDATE_NEXT_HEADER_CONFIG = 'PAGEBUILDER_GENERAL_UPDATE_NEXT_HEADER_CONFIG';
export const PAGEBUILDER_GENERAL_AMP = 'PAGEBUILDER.GENERAL_AMP';
export const PAGEBUILDER_GENERAL_WIDGET = 'PAGEBUILDER_GENERAL_WIDGET';
export const PAGEBUILDER_GENERAL_UPDATE_ACCESSTYPE_CONFIG = 'PAGEBUILDER.GENERAL_UPDATE_ACCESSTYPE_CONFIG';
export const PAGEBUILDER_GENERAL_UPDATE_OTHER_CONFIG = 'PAGEBUILDER.GENERAL_UPDATE_OTHER_CONFIG';
export const PAGEBUILDER_GENERAL_UPDATE_BREAKING_NEWS = 'PAGEBUILDER.GENERAL_UPDATE_BREAKING_NEWS';
export const PAGEBUILDER_GENERAL_UPDATE_SINGLE_SIGN_ON = 'PAGEBUILDER.GENERAL_UPDATE_SINGLE_SIGN_ON';
export const PAGEBUILDER_GENERAL_UPDATE_COMMENT_PLUGIN_SETTINGS = 'PAGEBUILDER.GENERAL_UPDATE_COMMENT_PLUGIN_SETTINGS';
// Header
export const PAGEBUILDER_HEADER_UPDATE_STYLE = 'PAGEBUILDER.HEADER.UPDATE_HEADER_STYLE';
export const PAGEBUILDER_HEADER_UPDATE_SOCIAL_LINK_STYLE = 'PAGEBUILDER.HEADER.UPDATE_HEADER_SOCIAL_LINK_STYLE';
export const PAGEBUILDER_HEADER_UPDATE_THEME_ATTRIBUTES_KEY = 'PAGEBUILDER.HEADER.UPDATE_HEADER_THEME_ATTRIBUTES_KEY';
export const PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW = 'PAGEBUILDER.HEADER.UPDATE_SELECTED_ROW';
export const PAGEBUILDER_HEADER_DELETE_ROW = 'PAGEBUILDER.HEADER.DELETE_ROW';
export const PAGEBUILDER_HEADER_ADD_ROW_TOP = 'PAGEBUILDER.HEADER.ADD_ROW_TOP';
export const PAGEBUILDER_HEADER_ADD_ROW_BOTTOM = 'PAGEBUILDER.HEADER.ADD_ROW_BOTTOM';
export const PAGEBUILDER_HEADER_UPDATE_SELECTED_ROW_SETTINGS = 'PAGEBUILDER.HEADER.UPDATE_SELECTED_ROW_SETTINGS';
export const PAGEBUILDER_HEADER_DATE_TIME_SETTINGS = 'PAGEBUILDER.HEADER.DATE_TIME_SETTINGS';

// Footer
export const PAGEBUILDER_FOOTER_UPDATE_THEME_ATTRIBUTES_KEY = 'PAGEBUILDER.FOOTER.UPDATE_THEME_ATTRIBUTES_KEY';
export const PAGEBUILDER_FOOTER_UPDATE_STYLE = 'PAGEBUILDER.UPDATE_FOOTER_STYLE';
export const PAGEBUILDER_FOOTER_UPDATE_SOCIAL_LINK_STYLE = 'PAGEBUILDER.UPDATE_FOOTER_SOCIAL_LINK_STYLE';

// breaking news

export const PAGEBUILDER_BREAKING_NEWS_UPDATE_THEME_ATTRIBUTES_KEY =
  'PAGEBUILDER.BREAKING_NEWS.UPDATE_THEME_ATTRIBUTES_KEY';

// Story
export const PAGEBUILDER_STORY_UPDATE_ATTRIBUTE = 'PAGEBUILDER.STORY.UPDATE_ATTRIBUTE';
export const PAGEBUILDER_STORY_UPDATE_STYLES = 'PAGEBUILDER.STORY.UPDATE_STYLES';
export const PAGEBUILDER_STORY_UPDATE_DATE_TIME = 'PAGEBUILDER.STORY.UPDATE_DATE_TIME';
export const PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD = 'PAGEBUILDER.STORY.UPDATE_AUTHOR_CARD';
export const PAGEBUILDER_STORY_UPDATE_AUTHOR_CARD_OPTS = 'PAGEBUILDER.STORY.UPDATE_AUTHOR_CARD_OPTS';
export const PAGEBUILDER_INFINITE_SCROLL = 'PAGEBUILDER.INFINITE_SCROLL';
export const PAGEBUILDER_STORY_UPDATE_AUTHOR_STYLE = 'PAGEBUILDER.STORY.UPDATE_AUTHOR_STYLE';
export const PAGEBUILDER_STORY_ELEMENTS_UPDATE_ATTRIBUTE = 'PAGEBUILDER.STORY.ELEMENTS.UPDATE.ATTRIBUTE';
export const PAGEBUILDER_STORY_ELEMENTS_UPDATE_SETTINGS = 'PAGEBUILDER.STORY.ELEMENTS.UPDATE.SETTINGS';
export const PAGEBUILDER_STORY_GENERAL_SETTINGS = 'PAGEBUILDER.STORY.GENERAL.SETTINGS';

// story ads

export const PAGEBUILDER_STORY_UPDATE_SLOTS = 'PAGEBUILDER.STORY.UPDATE.SLOTS';
export const PAGEBUILDER_STORY_SLOT_SETTING = 'PAGEBUILDER.STORY.SLOT.SETTING';
export const PAGEBUILDER_GENERATE_AD_SLOTS = 'PAGEBUILDER.STORY.SLOT.GENERATE';

export const PAGEBUILDER_STORY_PAGE_SETTINGS = 'PAGEBUILDER.STORY.PAGE_SETTINGS';
// Landing pages: All pages can be including home and section can be a landing page.
// Moving landing page concept out of the section so that it will get its own importance.
export const PAGEBUILDER_CREATE_NEW_LANDING_PAGE = 'PAGEBUILDER.LANDING_PAGE.CREATE_NEW';
export const PAGEBUILDER_CREATE_NEW_STORY_PAGE = 'PAGEBUILDER.STORY_PAGE.CREATE_NEW';
export const PAGEBUILDER_UPDATE_LANDING_PAGE_DETAILS = 'PAGEBUILDER.LANDING_PAGE.UPDATE_DETAILS';

// Search Page
export const PAGEBUILDER_SEARCH_ENABLE_ADVANCED_SEARCH = 'PAGEBUILDER.SEARCH_PAGE.ENABLE_ADVANCED_SEARCH';
export const PAGEBUILDER_SEARCH_UPDATE_FILTER_OPTIONS = 'PAGEBUILDER.SEARCH_PAGE.UPDATE_FILTER_OPTIONS';
export const PAGEBUILDER_SEARCH_UPDATE_OTHER_SETTINGS = 'PAGEBUILDER.SEARCH_PAGE.UPDATE_OTHER_SETTINGS';

// Other. All events in the format of `PAGEBUILDER.<SOMETHING>`. These actions won't trigger a merge config
// action.
export const PAGEBUILDER_HAVE_UNSAVED_CHANGES = 'PAGEBUILDER.HAVE_UNSAVED_CHANGES';
export const PAGEBUILDER_HAVE_LAYOUT_UPDATES = 'PAGEBUILDER.HAVE_LAYOUT_UPDATES';
export const PAGEBUILDER_UPDATE_CONFIG_VERSION = 'PAGEBUILDER.UPDATE_CONFIG_VERSION';
export const PAGEBUILDER_UPDATE_ACTION_BUTTON_STATUS = 'PAGEBUILDER.UPDATE_ACTION_BUTTON_STATUS';
export const PAGEBUILDER_SET_PAGETYPE = 'PAGEBUILDER.SET_PAGETYPE';
export const PAGEBUILDER_SET_PAGEID = 'PAGEBUILDER.SET_PAGEID';
export const PAGEBUILDER_SET_SUBPAGETYPE = 'PAGEBUILDER.SET_SUBPAGETYPE';
export const PAGEBUILDER_SET_UPDATED_PAGES = 'PAGEBUILDER.SET_UPDATED_PAGES';

// domains
export const PAGEBUILDER_SET_DOMAIN_LIST = 'PAGEBUILDER.SET_DOMAINLIST';

// magazine
export const PAGEBUILDER_GENERAL_ADD_MAGAZINE_CONFIG = 'PAGEBUILDER.GENERAL_ADD_MAGAZINE_CONFIG';

// performance
export const PAGEBUILDER_PERFORMANCE_BOT_MOD = 'PAGEBUILDER.PERFORMANCE.BOT.TOGGLE';
