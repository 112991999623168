import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { SSOLogin, UserSignup } from '../../molecules/sso-components';
import withAuth from '../../utils/withAuth';
import { PageBuilder } from '../page-builder';

export const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" title="Login" component={SSOLogin} />
        <Route path="/user/signup" component={UserSignup} />
        <Route path="/accounts/:publisherId/:domain" title="Publisher Account" component={withAuth(PageBuilder)} />
        <Redirect to="/login" />
      </Switch>
    </BrowserRouter>
  );
};
