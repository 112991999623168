// tslint:disable:no-console
import get from 'lodash/get';
import { AHEAD_MERGE_PAGEBUILDER_CONFIG_TO_QT_CONFIG } from '../reducers/bold';
export const mergePBConfigtoQT = (store: any) => (next: any) => (action: any) => {
  // We've to merge the PB config to QT config for each action of PB related to ahead components
  // , so that the static preview (not the actual data preview) will work as Ahead is reading the config from qt.config
  // For ex: we change sectionTagStyle we're changing inside builder, which will be consumed by ahead eventually.
  // But, the static preview won't reflect as that component is reading from qt.config.

  // using regex to emit action on events like PAGEBUILDER.GENERAL.UPDATE_KEY but not on PAGEBUILDER.HAVE_CHANGES or PAGEBUILDER.LOADING
  if (
    action.type !== AHEAD_MERGE_PAGEBUILDER_CONFIG_TO_QT_CONFIG &&
    action.type.match(/PAGEBUILDER\..+\..+/g) &&
    !(action.payload && action.payload.cancelQTConfigMerge)
  ) {
    next(action);
    return store.dispatch({
      type: AHEAD_MERGE_PAGEBUILDER_CONFIG_TO_QT_CONFIG,
      payload: get(store.getState(), ['builder', 'config'])
    });
  }
  const result = next(action);
  return result;
};
