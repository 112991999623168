export const localizedElementList = [
  {
    label: 'Date and Time',
    value: 'dateTime',
    optionLabel: 'Date and Time',
    dateTimeOptions: [
      { label: 'AM/PM', value: 'meridiem' },
      { label: 'Months', value: 'months' },
      { label: 'Time', value: 'publishTime' }
    ]
  },
  {
    label: 'Paywall',
    value: 'paywall',
    optionLabel: 'Paywall Method',
    paywallOptions: [
      { label: 'Login Access', value: 'loginAccess' },
      { label: 'Magazine Subscription', value: 'magazineSubscription' },
      { label: 'Metered Paywall', value: 'meteredPaywall' },
      { label: 'Pay Per Article', value: 'payPerArticle' },
      { label: 'Payment Failure', value: 'paymentFailure' },
      { label: 'Payment Processing', value: 'paymentProcessing' },
      { label: 'Payment Success', value: 'paymentSuccess' },
      { label: 'Subscription', value: 'normalSubscription' }
    ]
  },
  { label: 'Shipping Address', value: 'shippingAddress' },
  {
    label: 'Buttons and labels',
    value: 'buttonLabels'
  },
  {
    label: 'Errors and Messaging',
    value: 'errorsMessage',
    optionLabel: 'Errors and Messaging',
    errorMessageOptions: [
      { label: '404 Page', value: '404Page' },
      { label: 'Field Errors', value: 'fieldErrors' }
    ]
  },
  {
    label: 'Profile Page',
    value: 'profilePage',
    optionLabel: 'Tabs',
    profileOptions: [
      { label: 'Profile', value: 'myProfile' },
      { label: 'Subscription', value: 'mySubscriptions' },
      { label: 'Purchased Stories', value: 'purchasedStories' }
    ]
  },
  {
    label: 'Sign in/Sign up',
    value: 'signInSignUp',
    optionLabel: 'Templates',
    signInSignUpOptions: [
      { label: 'Sign in/Sign up', value: 'signInSignUp' },
      { label: 'Email Verification', value: 'emailVerification' },
      { label: 'Forgot Password', value: 'forgotPassword' }
    ]
  },
  {
    label: 'Search and Filter',
    value: 'searchFilter',
    optionLabel: 'Search and Filter',
    searchFilters: [
      { label: 'Search', value: 'searchLabels' },
      { label: 'Filter', value: 'searchFilters' }
    ]
  }
];

export const localizedSubElementList = {
  months: [
    { label: 'January', value: 'jan', type: 'textField' },
    { label: 'February', value: 'feb', type: 'textField' },
    { label: 'March', value: 'mar', type: 'textField' },
    { label: 'April', value: 'apr', type: 'textField' },
    { label: 'May', value: 'may', type: 'textField' },
    { label: 'June', value: 'jun', type: 'textField' },
    { label: 'July', value: 'jul', type: 'textField' },
    { label: 'August', value: 'aug', type: 'textField' },
    { label: 'September', value: 'sep', type: 'textField' },
    { label: 'October', value: 'oct', type: 'textField' },
    { label: 'November', value: 'nov', type: 'textField' },
    { label: 'December', value: 'dec', type: 'textField' }
  ],
  meridiem: [
    { label: 'AM', value: 'am', type: 'textField' },
    { label: 'PM', value: 'pm', type: 'textField' }
  ],
  publishTime: [
    { label: 'Ago', value: 'ago', type: 'textField' },
    { label: 'Hour', value: 'hour', type: 'textField' },
    { label: 'Hours', value: 'hours', type: 'textField' },
    { label: 'Minute', value: 'minute', type: 'textField' },
    { label: 'Minutes', value: 'minutes', type: 'textField' },
    { label: 'Seconds', value: 'seconds', type: 'textField' },
    { label: 'Days', value: 'days', type: 'textField' },
    { label: 'Weeks', value: 'weeks', type: 'textField' },
    { label: 'Month', value: 'month', type: 'textField' },
    { label: 'Months', value: 'months', type: 'textField' },
    { label: 'Year', value: 'year', type: 'textField' },
    { label: 'Years', value: 'years', type: 'textField' }
  ],
  normalSubscription: [
    { label: 'Title', value: 'title', type: 'textField', hint: '', placeholder: 'Want to read the full story?' },
    {
      label: 'Message',
      value: 'description',
      type: 'textArea',
      placeholder: 'We’re glad you’re enjoying this story. Subscribe to any of our plans to continue reading the story.'
    },
    { label: 'Button', value: 'viewPlans', type: 'textField', hint: '', placeholder: 'View All Plans' },
    {
      label: 'Already have an account?',
      value: 'accountAccess',
      type: 'textField',
      hint: '',
      placeholder: 'Already have an account?'
    }
  ],
  meteredPaywall: [
    {
      label: 'Message',
      value: 'description',
      type: 'textArea',
      hint: 'Add your own paywall text and copy-paste the shortcodes listed below to integrate the variables in text',
      placeholder:
        'You have [stories-left] more free stories left. Subscribe us to continue unlimited access to all our stories.',
      shortCode: ['[stories-left]']
    }
  ],
  payPerArticle: [
    { label: 'Title', value: 'title', type: 'textField', placeholder: 'Want to read the full story?' },
    {
      label: 'Message',
      value: 'description',
      type: 'textArea',
      hint: 'Add your own paywall text and copy-paste the shortcodes listed below to integrate the variables in text',
      placeholder:
        'We’re glad you’re enjoying this story. To continue reading this article, pay [currency] [price] OR subscribe to any of our plans to continue reading the story.',
      shortCode: ['[currency]', '[price]']
    },
    { label: 'Button 1 text', value: 'viewPlans', type: 'textField', placeholder: 'View All Plans' },
    { label: 'Button 2 text', value: 'buyArticle', type: 'textField', placeholder: 'Buy this Article' }
  ],
  magazineSubscription: [
    {
      label: 'Button',
      value: 'subscribe',
      type: 'textField',
      placeholder: 'Magazine Subscription'
    }
  ],
  loginAccess: [
    { label: 'Title', value: 'title', type: 'textField', hint: '', placeholder: 'Sign in to get access' },
    {
      label: 'Message',
      value: 'description',
      type: 'textArea',
      hint: 'Add your own paywall text and copy-paste the shortcodes listed below to integrate the variables in text',
      placeholder: `We're glad you're enjoying [publisher-title]. Sign in to continue reading this story.`,
      shortCode: ['[publisher-title]']
    }
  ],
  paymentSuccess: [
    { label: 'Title', value: 'title', type: 'textField', hint: '', placeholder: 'Thank you !' },
    {
      label: 'Message',
      value: 'description',
      type: 'textArea',
      hint: '',
      placeholder: 'Your payment was successful. Now you can continue reading the article'
    }
  ],
  paymentFailure: [
    { label: 'Title', value: 'title', type: 'textField', hint: '', placeholder: 'Oops! Something went wrong.' },
    {
      label: 'Message',
      value: 'description',
      type: 'textArea',
      hint: '',
      placeholder: 'Payment was not successful'
    }
  ],
  paymentProcessing: [
    { label: 'Title', value: 'title', type: 'textField', hint: '', placeholder: 'Payment is processing' }
  ],
  shippingAddress: [
    { label: 'Heading', value: 'heading', type: 'textField', placeholder: 'Enter Shipping Address' },
    { label: 'Name', value: 'name', type: 'textField', placeholder: 'Name' },
    { label: 'Address ', value: 'address', type: 'textField', placeholder: 'Address' },
    { label: 'City', value: 'city', type: 'textField', placeholder: 'City' },
    { label: 'State', value: 'state', type: 'textField', placeholder: 'State' },
    { label: 'Country', value: 'country', type: 'textField', placeholder: 'Country' },
    { label: 'Post Code', value: 'postCode', type: 'textField', placeholder: 'Post Code' },
    { label: 'Dial Code', value: 'dailCode', type: 'textField', placeholder: 'Dial Code' },
    { label: 'Phone Number', value: 'phoneNumber', type: 'textField', placeholder: 'Phone Number' },
    { label: 'Email', value: 'email', type: 'textField', placeholder: 'Email' },
    { label: 'Button', value: 'button', type: 'textField', placeholder: 'Continue' }
  ],
  buttonLabels: [
    { label: 'Subscribe', value: 'subscribe', type: 'textField' },
    { label: 'My Profile', value: 'myProfile', type: 'textField' },
    { label: 'Powered By', value: 'poweredBy', type: 'textField' },
    { label: 'Follow Us', value: 'followUs', type: 'textField' },
    { label: 'Try Again', value: 'tryAgain', type: 'textField' },
    { label: 'Published On', value: 'publishedOn', type: 'textField' },
    { label: 'Updated On', value: 'updatedOn', type: 'textField' },
    { label: 'Time to read', value: 'timeToRead', type: 'textField' },
    { label: 'Key Events', value: 'keyEvents', type: 'textField' },
    { label: 'Show Comments', value: 'metypeShowComments', type: 'textField' },
    { label: 'Hide Comments', value: 'metypeHideComments', type: 'textField' },
    { label: 'Author', value: 'authorLabel', type: 'textField' },
    { label: 'Connect', value: 'connect', type: 'textField' },
    { label: 'Guest Author', value: 'guestAuthorLabel', type: 'textField' },
    { label: 'Infinite Scroll Separator', value: 'infiniteScrollSeparator', type: 'textField' }
  ],
  '404Page': [
    { label: 'Title', value: 'title', type: 'textField', placeholder: 'Oops.. Page not found!' },
    {
      label: 'Description',
      value: 'description',
      type: 'textField',
      placeholder: 'The page you are looking for might have been removed, temporarily unavailable.'
    },
    { label: 'Button', value: 'button', type: 'textField', placeholder: 'Take me to home' }
  ],
  myProfile: [
    { label: 'Tab Name', value: 'tabName', type: 'textField', placeholder: 'Title' },
    { label: 'No Subsciptions yet', value: 'noSubscription', type: 'textField', placeholder: 'No Subscriptions yet' },
    { label: 'Active Plan ', value: 'activePlan', type: 'textField', placeholder: 'Active Plan' },
    { label: 'Button ', value: 'buttonText', type: 'textField', placeholder: 'Upgrade Plan' },
    { label: 'Sign In message', value: 'signInMessage', type: 'textField' }
  ],
  mySubscriptions: [
    { label: 'Tab Name', value: 'tabName', type: 'textField', placeholder: 'Subscription' },
    {
      label: 'Plan Name',
      value: 'planName',
      type: 'textField',
      placeholder: 'Plan Name'
    },
    {
      label: 'Active Subscriptions',
      value: 'activeSubscription',
      type: 'textField',
      placeholder: 'Active subscriptions..'
    },
    {
      label: 'Expired Subscriptions',
      value: 'expiredSubscription',
      type: 'textField',
      placeholder: 'Expired subscriptions..'
    },
    { label: 'No Subscriptions', value: 'noSubscription', type: 'textField', placeholder: 'No Subscriptions' },
    { label: 'Start Date', value: 'startDate', type: 'textField', placeholder: 'Start Date' },
    { label: 'End Date', value: 'endDate', type: 'textField', placeholder: 'End Date' },
    {
      label: 'Subscribe to become prime...',
      value: 'primeSubscription',
      type: 'textField',
      placeholder: 'Subscribe to become prime member and get unlimited access to our exclusive stories.'
    },
    { label: 'Button', value: 'buttonText', type: 'textField', placeholder: 'Subscribe now' }
  ],
  purchasedStories: [
    { label: 'Tab Name', value: 'tabName', type: 'textField', placeholder: 'Purchased Stories' },
    {
      label: 'Purchased Stories Subscriptions',
      value: 'purchasedStoriesSubscription',
      type: 'textField',
      placeholder: 'Purchased Stories Subscriptions..'
    },
    { label: 'Story Name', value: 'storyName', type: 'textField', placeholder: 'Story Name' },
    { label: 'Date', value: 'date', type: 'textField', placeholder: 'Date' },
    { label: 'No Purchased Stories', value: 'noStories', type: 'textField', placeholder: 'No Purchased Stories' }
  ],
  signInSignUp: [
    { label: 'Sign In', value: 'signIn', type: 'textField', placeholder: 'Sign In' },
    { label: 'Sign Up', value: 'signUp', type: 'textField', placeholder: 'Sign Up' },
    { label: 'Sign Out', value: 'signOut', type: 'textField', placeholder: 'Sign Out' },
    { label: 'Name', value: 'name', type: 'textField', placeholder: 'Name' },
    { label: 'Email', value: 'email', type: 'textField', placeholder: 'Email' },
    { label: 'Password', value: 'password', type: 'textField', placeholder: 'Password' },
    { label: 'Forgot Password', value: 'forgotPassword', type: 'textField', placeholder: 'Forgot password?' },
    { label: 'Privacy Policy', value: 'privacyPolicy', type: 'textField', placeholder: 'Privacy policy' },
    { label: 'Terms and Conditions', value: 'termsConditions', type: 'textField', placeholder: 'terms and conditions' },
    {
      label: 'Sign Up message ',
      value: 'agreementMessage',
      type: 'textArea',
      placeholder: 'I accept the Terms and Conditions and Privacy Policy',
      shortCode: ['[terms-and-conditions]', '[privacy-policy]']
    },
    { label: 'Or sign in with', value: 'socialLogin', type: 'textField', placeholder: 'Or sign in with' }
  ],
  emailVerification: [
    {
      label: 'Verification Message',
      value: 'verificationMessage',
      type: 'textField',
      placeholder:
        'Please verify your account by clicking on the verification link we sent to your email. This will sign you in instantly.'
    },
    {
      label: 'Change Email',
      value: 'emailChange',
      type: 'textField',
      placeholder: 'Change Email'
    },
    {
      label: 'Resend Link',
      value: 'resendLink',
      type: 'textField',
      placeholder: 'Resend Link'
    },
    {
      label: 'Resend Message',
      value: 'resendMessage',
      type: 'textArea',
      placeholder: 'Didn’t receive link? Please check your spam folder or [Resend Link]',
      shortCode: ['[resend-link]']
    },
    {
      label: 'Link Sent',
      value: 'sendLink',
      type: 'textField',
      placeholder: 'Verification link has been sent!'
    }
  ],
  forgotPassword: [
    {
      label: 'Submit',
      value: 'submit',
      type: 'textField',
      placeholder: 'Submit'
    },
    {
      label: 'OTP Message',
      value: 'message',
      type: 'textField',
      placeholder: 'Please enter the one time password sent via email'
    },
    {
      label: 'Enter OTP',
      value: 'enterOtp',
      type: 'textField',
      placeholder: 'Enter OTP'
    },
    {
      label: 'Enter password',
      value: 'password',
      type: 'textField',
      placeholder: 'Enter password'
    },
    {
      label: 'Re-enter password',
      value: 'reenterPassword',
      type: 'textField',
      placeholder: 'Re-enter password'
    },
    {
      label: 'Verify OTP',
      value: 'verifyOtp',
      type: 'textField',
      placeholder: 'Verify OTP'
    }
  ],
  searchLabels: [
    { label: 'Search Page Title', value: 'title', type: 'textField', placeholder: 'Search Results' },
    {
      label: 'Search Page Description',
      value: 'description',
      type: 'textField',
      placeholder: "Let's find interesting reads for you. Enter a keyword to start searching."
    },
    { label: 'Placeholder', value: 'placeholder', type: 'textField', placeholder: 'Search here...' },
    { label: 'Message', value: 'message', type: 'textField', placeholder: 'No stories found' },
    { label: 'Sort by', value: 'sortBy', type: 'textField', placeholder: 'Sort by' },
    { label: 'Relevance', value: 'relevance', type: 'textField', placeholder: 'Relevance' },
    { label: 'Newest', value: 'newest', type: 'textField', placeholder: 'Newest' }
  ],
  searchFilters: [
    { label: 'Filter', value: 'label', type: 'textField', placeholder: 'Filter' },
    { label: 'Section', value: 'section', type: 'textField', placeholder: 'Section' },
    { label: 'Author', value: 'author', type: 'textField', placeholder: 'Author' },
    { label: 'Story Type', value: 'storyType', type: 'textField', placeholder: 'Story type' },
    { label: 'Date', value: 'date', type: 'textField', placeholder: 'Date' },
    { label: 'More', value: 'more', type: 'textField', placeholder: 'More...' },
    { label: 'Clear', value: 'clear', type: 'textField', placeholder: 'Clear' },
    { label: 'To', value: 'to', type: 'textField', placeholder: 'To' }
  ],
  fieldErrors: [
    { label: 'Invalid Name', value: 'invalidName', type: 'textField', placeholder: 'Name is invalid..' },
    { label: 'Invalid Email', value: 'invalidMail', type: 'textField', placeholder: 'Email is invalid..' },
    { label: 'Invalid Password', value: 'invalidPassword', type: 'textField', placeholder: 'Password is invalid..' },
    {
      label: 'Invalid OTP message',
      value: 'invalidOtp',
      type: 'textField',
      placeholder: 'OTP you have entered does not match'
    },
    {
      label: 'Password Mismatch',
      value: 'verifyPassword',
      type: 'textField',
      placeholder: 'Password does not match'
    },
    { label: 'User Already exists', value: 'userExists', type: 'textField', placeholder: 'User already exists!' },
    { label: 'Cannot be empty', value: 'invalidField', type: 'textField', placeholder: 'Cannot be empty' },
    { label: 'Invalid Dial Code', value: 'invalidDialCode', type: 'textField', placeholder: 'Dial code is invalid' },
    {
      label: 'Invalid Phone Number',
      value: 'invalidPhoneNumber',
      type: 'textField',
      placeholder: 'Phone number is invalid'
    }
  ]
};
