import { Checkbox } from '@quintype/em/components/checkbox';
import Select from '@quintype/em/components/select';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updateSearchPageSettings } from '../../actions/builder-action';
import { ColorPicker } from '../color-picker';
import { EnableAdvancedSearch } from '../enable-advanced-search';
import styles from './search-filter-options.module.css';

export const SearchFilterOptions = ({ filterSettings }: any) => {
  const search = useSelector((state: any) => get(state, ['builder', 'config', 'search'], {}), shallowEqual);
  const { filterOptions, enableAdvancedSearch = false } = get(search, ['pageSetting'], {});
  const options = generateOptionsArray(filterSettings, filterOptions);
  const dispatch = useDispatch();
  return (
    <>
      <div className={styles.checkboxWrapper}>
        <EnableAdvancedSearch />
      </div>
      {enableAdvancedSearch &&
        options.map((filterOption: any) => {
          const { keyName, label } = filterOption;
          return (
            <div className={styles.checkboxWrapper} key={keyName}>
              <Checkbox
                key={keyName}
                id={keyName}
                label={label}
                checked={get(filterOptions, [keyName], false)}
                onChange={(value: boolean) => {
                  dispatch(updateSearchPageSettings(keyName, value, 'filter'));
                  dispatch(updateHaveUnsavedChanges(true));
                }}
              />
            </div>
          );
        })}
    </>
  );
};

function generateOptionsArray(filterSettings: any, filterOptions: any) {
  filterOptions &&
    filterSettings.forEach((filterOption: any) => {
      if (filterOption.keyName in filterOptions) {
        filterOption.enable = filterOptions[filterOption.keyName];
      }
    });

  return filterSettings;
}

export const ShowHideOptions = ({ showHideSettings }: any) => {
  const searchResultsConfig = useSelector(
    (state: any) => get(state, ['builder', 'config', 'search', 'pageSetting', 'searchResultsConfig'], {}),
    shallowEqual
  );
  const options = generateOptionsArray(showHideSettings, searchResultsConfig);
  const dispatch = useDispatch();
  return (
    <>{options && options.map((filterOption: any) => selectInputType(filterOption, searchResultsConfig, dispatch))}</>
  );
};

export const ButtonOptions = ({ buttonSettings }: any) => {
  const searchResultsConfig = useSelector(
    (state: any) => get(state, ['builder', 'config', 'search', 'pageSetting', 'searchResultsConfig'], {}),
    shallowEqual
  );
  const options = generateOptionsArray(buttonSettings, searchResultsConfig);
  const dispatch = useDispatch();
  return (
    <>{options && options.map((filterOption: any) => selectInputType(filterOption, searchResultsConfig, dispatch))}</>
  );
};

export const selectInputType = (
  filterOption: { label: any; keyName: any; type: any; placeholder: any; dropDownOptions: any },
  searchResultsConfig: any,
  dispatch: any
) => {
  const { label, keyName, type, placeholder, dropDownOptions } = filterOption;
  switch (type) {
    case 'checkbox':
      return (
        <div className={styles.checkboxWrapper}>
          <Checkbox
            key={keyName}
            id={keyName}
            label={label}
            checked={get(searchResultsConfig, [keyName], true)}
            onChange={(value: boolean) => {
              dispatch(updateSearchPageSettings(keyName, value, 'other'));
              dispatch(updateHaveUnsavedChanges(true));
            }}
          />
        </div>
      );
    case 'text':
      return (
        <TextField
          label={label}
          value={get(searchResultsConfig, [keyName])}
          onChange={(value: any) => {
            dispatch(updateSearchPageSettings(keyName, value, 'other'));
            dispatch(updateHaveUnsavedChanges(true));
          }}
          placeholder={placeholder}
        />
      );
    case 'dropDown':
      return (
        <Select
          label={label}
          options={dropDownOptions}
          onChange={(option: any) => {
            dispatch(updateSearchPageSettings(keyName, option.value, 'other'));
            dispatch(updateHaveUnsavedChanges(true));
          }}
        />
      );
    case 'colorPicker':
      return (
        <ColorPicker
          label={label}
          colorExtract={get(searchResultsConfig, [keyName])}
          updateStoreKey={keyName}
          updateTheStore={(key, value: any) => {
            dispatch(updateSearchPageSettings(key, value, 'other'));
            dispatch(updateHaveUnsavedChanges(true));
          }}
        />
      );
  }
};
