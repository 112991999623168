import { Button } from '@quintype/em/components/button';
import { Dialog } from '@quintype/em/components/dialog';
import React from 'react';
import styles from './simulatneous-action-dialog.module.css';

export function SimultanoeusEditDialog({ onClose, isOpen }: { onClose: () => void; isOpen: boolean }) {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <h3 className={styles.title}>Alert!</h3>
      <p className={styles.content}>
        You can add/edit one redirection at a time. Please finish or discard the redirection that you were editing
      </p>
      <div className={styles['button_container']}>
        <Button type="primary" onClick={onClose}>
          Ok
        </Button>
      </div>
    </Dialog>
  );
}
