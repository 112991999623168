import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { ThemeSetter } from '../theme-setter';
import styles from './logo-picker.module.css';

interface IProps {
  logos: string[];
  label?: string;
  description?: string;
  updateTheStore: (key: string, value: string) => any;
  updateLogoKey?: string;
  enableDarkModePreview?: boolean;
  logoStored?: string;
  darkLogoStored?: string;
}
interface IState {
  selectedLogoItem: any;
  selectedDarkLogoItem: any;
}
class LogoPickerBase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedLogoItem: props.logoStored,
      selectedDarkLogoItem: props.darkLogoStored
    };
  }

  updateLogo = (item: string, key: string) => {
    this.props.enableDarkModePreview
      ? this.setState({ selectedDarkLogoItem: item })
      : this.setState({ selectedLogoItem: item });
    this.props.updateTheStore(key, item);
  };
  render() {
    return (
      <>
        {this.props.logos.length > 0 && (
          <ThemeSetter label="Choose Logo" enableDarkModePreview={this.props.enableDarkModePreview}>
            <Logo
              description={this.props.description}
              selectedLogoItem={this.state.selectedLogoItem}
              selectedDarkLogoItem={this.state.selectedDarkLogoItem}
              updateLogo={this.updateLogo}
              logos={this.props.logos}
              enableDarkModePreview={this.props.enableDarkModePreview}
              logoStored={this.props.logoStored}
              darkLogoStored={this.props.darkLogoStored}
            />
          </ThemeSetter>
        )}
      </>
    );
  }
}
const mapStateToProps = (state: {}) => ({
  logos: get(state, ['builder', 'config', 'general', 'logos'], []),
  enableDarkModePreview: get(state, ['builder', 'config', 'enableDarkModePreview'], false)
});

export const LogoPicker = connect(mapStateToProps)(LogoPickerBase);

const Logo = (props: {
  selectedLogoItem: string;
  logos: string[];
  updateLogo: (item: string, key: string) => void;
  description?: string;
  label?: string;
  enableDarkModePreview?: boolean;
  logoStored?: string;
  darkLogoStored?: string;
  selectedDarkLogoItem: string;
}) => {
  const key = !props.enableDarkModePreview ? 'logo' : 'darkLogo';
  const selectedItem = !props.enableDarkModePreview ? props.selectedLogoItem : props.selectedDarkLogoItem;
  return (
    <div>
      {props.logos.map((item: any, index: any) => (
        <div
          className={selectedItem === item ? styles.selectedLogoWrapper : styles.logoWrapper}
          onClick={() => props.updateLogo(item, key)}
          key={index}
        >
          <img src={item} className={styles.imageWrapper} alt="logo" />
        </div>
      ))}
      {props.description && <p className={styles.description}>{props.description}</p>}
    </div>
  );
};
