import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { getUserDetails, updateCurrentEditor } from '../../utils/utils';

interface InactiveScreenLogout {
  time: number;
  aheadPreviewContainer: HTMLElement | null;
}

interface IProps {
  publisherId: string;
  currentEditorEmail: string;
}

interface IState {
  userEmail: string;
}

class InactiveScreenLogout extends React.Component<IProps, IState> {
  timeInMinutes = (minutes: number) => 1000 * 60 * minutes;
  timeOutMinutes = this.timeInMinutes(10);
  debounceMinutes = this.timeInMinutes(1);
  debounceMaxWaitMinutes = this.timeInMinutes(2);

  setTimer = () => {
    this.time = window.setTimeout(this.logout, this.timeOutMinutes);
  };

  resetTimer = () => {
    clearTimeout(this.time);
    this.setTimer();
    // clear server timeout
    if (this.state.userEmail === this.props.currentEditorEmail) {
      updateCurrentEditor(this.props.publisherId, this.state.userEmail);
    }
  };
  debounceResetTimer = debounce(this.resetTimer, this.debounceMinutes, {
    leading: true,
    trailing: true,
    maxWait: this.debounceMaxWaitMinutes
  });

  onScroll = () => {
    setTimeout(() => {
      this.aheadPreviewContainer = document.getElementById('ahead-preview');
      this.aheadPreviewContainer && this.aheadPreviewContainer.addEventListener('scroll', this.debounceResetTimer);
    }, 3000);
  };

  logout = async () => {
    await fetch('/logout');
    window.location.href = '/login';
  };

  addEventListners = () => {
    window.addEventListener('mousemove', this.debounceResetTimer);
    window.addEventListener('mousedown', this.debounceResetTimer);
    window.addEventListener('keypress', this.debounceResetTimer);
    window.addEventListener('load', this.debounceResetTimer);
    this.onScroll();
  };

  removeEventListeners = () => {
    window.removeEventListener('mousemove', this.debounceResetTimer);
    window.removeEventListener('mousedown', this.debounceResetTimer);
    window.removeEventListener('keypress', this.debounceResetTimer);
    window.removeEventListener('load', this.debounceResetTimer);
    this.aheadPreviewContainer && this.aheadPreviewContainer.removeEventListener('scroll', this.debounceResetTimer);
  };

  async componentDidMount() {
    const userDetails = await getUserDetails(this.props.publisherId);
    this.setState({ userEmail: userDetails.email });
    this.setTimer();
    this.addEventListners();
  }

  componentWillUnmount() {
    clearTimeout(this.time);
    this.removeEventListeners();
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: {}) => ({
  currentEditorEmail: get(state, ['builder', 'editControl', 'currentEditor'], null)
});

export default connect(mapStateToProps)(InactiveScreenLogout);
