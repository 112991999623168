export const Stories = [
  {
    'author-name': 'Shitesh Jain',
    headline: 'Green party: how are festivals upping their environmental credentials?',
    slug: 'automated/news-elsewhere-test',
    'last-published-at': 1589292999289,
    subheadline: 'test story',
    alternative: {},
    sections: [
      {
        'domain-slug': null,
        slug: 'automated',
        name: 'Automated',
        'section-url': 'https://ace-web.qtstage.io/automated',
        id: 41990,
        'parent-id': null,
        'display-name': 'Automated',
        collection: {
          slug: 'automated',
          name: 'Automated',
          id: 91819
        },
        data: null
      }
    ],
    'hero-image-metadata': {
      width: 296,
      height: 170,
      'mime-type': 'image/jpeg'
    },
    'published-at': 1589292999289,
    id: '6c98ba9e-0224-4cba-97d0-83b44e551080',
    'hero-image-s3-key': 'ace/2019-01/decc73ae-dfbf-45e6-9737-3e5eff6c1f4f/images__7_.jpeg',
    url: 'https://ace-web.qtstage.io/automated/news-elsewhere-test',
    'author-id': 234915,
    'first-published-at': 1548611181842,
    'story-template': 'news-elsewhere',
    metadata: {
      'reference-url': 'https://ace-web.qtstage.io/firstsection/2019/01/26/new-test-story',
      'story-attributes': {
        ampstory: ['true']
      },
      'card-share': {
        shareable: false
      }
    }
  },
  {
    'author-name': 'Sourabh Rathore',
    headline: 'Plastic-free paradise: The Greek island eliminating plastic waste in three years',
    slug: 'sports/test-section/animals-life',
    'last-published-at': 1537283040000,
    subheadline: 'New way of living',
    alternative: {},
    sections: [
      {
        id: 9633,
        name: 'test section',
        slug: 'test-section'
      }
    ],
    'hero-image-metadata': {
      width: 300,
      height: 168,
      'mime-type': 'image/jpeg',
      'focus-point': [184, 43]
    },
    'published-at': 1537283040000,
    id: '1aeb748a-c6d4-430d-9c54-da75f7563a22',
    'hero-image-s3-key': 'ace/2018-09/148a4993-86e6-4ce8-8993-f62cebc29b8f/download.jpeg',
    url: 'https://ace-web.qtstage.io/sports/test-section/animals-life',
    'author-id': 234914,
    'first-published-at': 1536068040177,
    'story-template': null,
    metadata: {
      'card-share': {
        shareable: false
      }
    }
  },
  {
    'author-name': 'Aswin Ambady',
    headline: 'Social enterprises outperform traditional business, says new research',
    slug: 'a1-1a',
    'last-published-at': 1533725530073,
    subheadline: 'A1 : 1a',
    alternative: {},
    sections: [
      {
        id: 10053,
        name: 'Automotive Section',
        slug: 'automotive'
      }
    ],
    'hero-image-metadata': {
      width: 3000,
      height: 2000
    },
    'published-at': 1533725530073,
    id: '0ac5ab8d-f8f7-46ae-9b66-ccdeb9eab324',
    'hero-image-s3-key': 'ace/2017-11/db64c4a1-fc67-4176-b5dd-23287ed48cab/mars.jpg',
    url: 'https://ace-web.qtstage.io/a1-1a',
    'author-id': 234218,
    'first-published-at': 1533725167262,
    'story-template': null,
    metadata: {
      'card-share': {
        shareable: false
      }
    }
  }
];
