export const LOAD_DUMMY_MEMBER = 'LOAD_DUMMY_MEMBER';
export const LOAD_DUMMY_MEMBER_SUBSCRIPTIONS = 'LOAD_DUMMY_MEMBER_SUBSCRIPTIONS';
export const DUMMY_MEMBER_SUBSCRIPTIONS_LOADED = 'DUMMY_MEMBER_SUBSCRIPTIONS_LOADED';
export const DUMMY_MEMBER_LOADED = 'DUMMY_MEMBER_LOADED';

export const loadDummyMemberReducer = (state: any = {}, action: { type: string }) => {
  switch (action.type) {
    case LOAD_DUMMY_MEMBER:
      return { ...state, ...getDummyMember() };
    default:
      return state;
  }
};
export const loadDummyMemberSubscriptionsReducer = (state: any = [], action: { type: string }) => {
  switch (action.type) {
    case LOAD_DUMMY_MEMBER_SUBSCRIPTIONS:
      return getDummyMemberSubscriptions();
    default:
      return state;
  }
};

export const memberSubscriptionsLoadedReducer = (state: any = [], action: { type: string }) => {
  switch (action.type) {
    case DUMMY_MEMBER_SUBSCRIPTIONS_LOADED:
      return true;
    default:
      return state;
  }
};

export const dummyMemberLoadedReducer = (state: any = [], action: { type: string }) => {
  switch (action.type) {
    case DUMMY_MEMBER_LOADED:
      return true;
    default:
      return state;
  }
};

function getDummyMember() {
  return {
    id: 2702224,
    name: 'Manjunath',
    email: 'iammanjunath@gmail.com',
    'created-at': 1680686306357,
    'avatar-url': 'https://lh3.googleusercontent.com/a/AGNjksfdhg843fddNunJ1qBmRrGsvf=s96-c',
    'first-name': 'The',
    'last-name': 'Manjunath',
    'verification-status': 'social-google',
    metadata: null,
    'avatar-s3-key': null,
    slug: null,
    'communication-email': null,
    'phone-number': null,
    'updated-at': 1680686306373,
    'is-tfa-enabled': false,
    'verified-mediums': ['social-google'],
    'login-phone-number': null,
    username: null
  };
}

function getDummyMemberSubscriptions() {
  return [
    {
      id: 333772,
      subscriber_id: 310480,
      subscription_plan_id: 68767,
      created_at: '2024-03-05T10:22:33.041Z',
      updated_at: '2024-03-11T11:40:27.147Z',
      assets: [
        {
          title: 'full-site',
          metadata: {},
          type: 'site',
          published_at: {}
        }
      ],
      start_timestamp: '2024-03-05T10:22:31.852Z',
      end_timestamp: '2024-05-11T11:40:27.127Z',
      deleted_at: null,
      payment_id: 335061,
      metadata: {
        mobile_number: '1234'
      },
      external_id: null,
      trial_period_length: null,
      trial_period_unit: null,
      campaign_id: null,
      plan_amount_cents: 200000,
      plan_amount_currency: 'INR',
      duration_unit: 'months',
      duration_length: 2,
      plan_name: 'Student/Educators',
      plan_description:
        "<ul>Bi monthly plan with GOLD privileges\n<li>you get 2 months of gold subscription</li>\n<li>you get to show off your gold subscription to others</li>\n<li>everyone thinks you're rich 🤑🤑🤑</li>\n</ul>\n",
      group_name: 'Upgrade Downgrade test group - Bi monthly onetime plans',
      group_description: 'Upgrade Downgrade test group containing bi monthly one time plans. Please do not edit',
      subscription_type: 'standard',
      plan_occurrence: 'One Time',
      subscription_attempt_id: 449050,
      renewal_reminder_sent_date: null,
      dynamic_assets: [
        {
          id: '',
          title: '',
          slug: ''
        }
      ],
      coupon_discount_id: null,
      notes: null,
      account_id: 9,
      old_assets: null,
      user_limit: null,
      allowed_ips: null,
      access_type: null,
      paid_trial_amount_cents: null,
      bundle_subscription_id: null,
      subscription_group_id: 39356,
      preferred_identity: {
        provider: 'email',
        value: 'amogh@quintype.com'
      },
      active: true,
      payment_amount: '1500.00',
      payment_amount_cents: 150000,
      payment_amount_currency: 'INR',
      payment_type: 'razorpay',
      payment_token: 'pay_Nl2Dy6VNVnmDKa',
      payment_metadata: {},
      renewable: true,
      cancellable: true,
      status: 'active',
      expired: false,
      coupon_code: null,
      recurring: false,
      cancelled_at: null,
      next_payment_due_date: null,
      cancelled: false,
      in_grace_period: false,
      invoices: [
        {
          id: 581065,
          sequenced_invoice_number: '923',
          base_price: '548.76',
          amount_cents: 64754,
          amount_currency: 'INR',
          created_at: '2024-03-11T11:40:27.288Z',
          amount_after_discount_before_tax: '548.76',
          discount_details: {},
          invoice_taxes: {
            CGST: {
              percentage: '9.0',
              amount: '49.39',
              currency: 'INR'
            },
            SGST: {
              percentage: '9.0',
              amount: '49.39',
              currency: 'INR'
            }
          },
          round_off: '0',
          external_payment_id: 'pay_Nl2Dy6VNVnmDKa',
          downloadable: true,
          international_payment: null,
          payment_gateway_fee_cents: null,
          payment_gateway_fee_currency: 'INR',
          payment_gateway_fee: '0',
          payment_gateway_net_amount: '647.54'
        },
        {
          id: 579316,
          sequenced_invoice_number: '913',
          base_price: '548.76',
          amount_cents: 150000,
          amount_currency: 'INR',
          created_at: '2024-03-05T10:22:33.158Z',
          amount_after_discount_before_tax: '548.76',
          discount_details: {},
          invoice_taxes: {
            SGST: {
              percentage: '9.0',
              amount: '114.41',
              currency: 'INR'
            },
            CGST: {
              percentage: '9.0',
              amount: '114.41',
              currency: 'INR'
            }
          },
          round_off: '722.42',
          external_payment_id: 'pay_Nidh04NLN8JjlE',
          downloadable: true,
          international_payment: false,
          payment_gateway_fee_cents: 354000,
          payment_gateway_fee_currency: 'INR',
          payment_gateway_fee: '3540',
          payment_gateway_net_amount: '-2040'
        }
      ],
      subscriber_name: 'Amogh Sahasrabhojanee',
      discount_detail: {},
      referrer: null,
      gifter: null,
      created_by: null,
      subscriber: {
        id: 310480,
        name: 'Amogh Sahasrabhojanee',
        created_at: '2023-04-05T09:54:20.806Z',
        updated_at: '2023-04-05T09:54:20.806Z',
        metadata: null,
        cumulative_end_timestamps: {
          standard_subscriptions_cumulative_end_timestamp: '2024-06-29T04:27:51.695Z',
          campaign_subscriptions_cumulative_end_timestamp: null,
          group_access_subscriptions_cumulative_end_timestamp: null,
          pay_per_asset_subscriptions_cumulative_end_timestamp: null
        },
        active: true,
        country_code: 'IN',
        subscriber_identities: [
          {
            id: 452209,
            account_id: 9,
            subscriber_id: 310480,
            provider: 'quintype',
            value: '2702224',
            created_at: '2023-04-05T09:54:20.833Z',
            updated_at: '2023-04-05T09:54:20.833Z'
          },
          {
            id: 452208,
            account_id: 9,
            subscriber_id: 310480,
            provider: 'email',
            value: 'amogh@quintype.com',
            created_at: '2023-04-05T09:54:20.818Z',
            updated_at: '2023-04-05T09:54:20.818Z'
          }
        ]
      },
      expires_in_days: 58,
      in_trial_period: false,
      is_upgradable: true,
      is_downgradable: true,
      is_crossgradable: true
    },
    {
      id: 333203,
      subscriber_id: 310480,
      subscription_plan_id: 68762,
      created_at: '2024-02-28T04:27:52.851Z',
      updated_at: '2024-02-28T04:27:52.851Z',
      assets: [
        {
          title: 'full-site',
          metadata: {},
          type: 'site',
          published_at: {}
        }
      ],
      start_timestamp: '2024-02-28T04:27:51.695Z',
      end_timestamp: '2024-03-28T04:27:51.695Z',
      deleted_at: null,
      payment_id: 334471,
      metadata: {
        mobile_number: '1234'
      },
      external_id: null,
      trial_period_length: null,
      trial_period_unit: null,
      campaign_id: null,
      plan_amount_cents: 75000,
      plan_amount_currency: 'INR',
      duration_unit: 'months',
      duration_length: 1,
      plan_name: 'Standard Digital Plus',
      plan_description: 'Standard Digital plan',
      group_name: 'Upgrade Downgrade test group',
      group_description: 'Upgrade Downgrade test group without proration please do not edit',
      subscription_type: 'standard',
      plan_occurrence: 'One Time',
      subscription_attempt_id: 447068,
      renewal_reminder_sent_date: null,
      dynamic_assets: [
        {
          id: '',
          title: '',
          slug: ''
        }
      ],
      coupon_discount_id: null,
      notes: null,
      account_id: 9,
      old_assets: null,
      user_limit: null,
      allowed_ips: null,
      access_type: null,
      paid_trial_amount_cents: null,
      bundle_subscription_id: null,
      subscription_group_id: 39355,
      preferred_identity: {
        provider: 'email',
        value: 'amogh@quintype.com'
      },
      active: true,
      payment_amount: '750.00',
      payment_amount_cents: 75000,
      payment_amount_currency: 'INR',
      payment_type: 'razorpay',
      payment_token: 'pay_NgARd2T6l4K20n',
      payment_metadata: {},
      renewable: true,
      cancellable: true,
      status: 'active',
      expired: false,
      coupon_code: null,
      recurring: false,
      cancelled_at: null,
      next_payment_due_date: null,
      cancelled: false,
      in_grace_period: false,
      invoices: [
        {
          id: 577773,
          sequenced_invoice_number: '905',
          base_price: '635.59',
          amount_cents: 75000,
          amount_currency: 'INR',
          created_at: '2024-02-28T04:27:52.942Z',
          amount_after_discount_before_tax: '635.59',
          discount_details: {},
          invoice_taxes: {
            SGST: {
              percentage: '9.0',
              amount: '57.20',
              currency: 'INR'
            },
            CGST: {
              percentage: '9.0',
              amount: '57.20',
              currency: 'INR'
            }
          },
          round_off: '0.01',
          external_payment_id: 'pay_NgARd2T6l4K20n',
          downloadable: true,
          international_payment: false,
          payment_gateway_fee_cents: 177000,
          payment_gateway_fee_currency: 'INR',
          payment_gateway_fee: '1770',
          payment_gateway_net_amount: '-1020'
        }
      ],
      subscriber_name: 'Amogh Sahasrabhojanee',
      discount_detail: {},
      referrer: null,
      gifter: null,
      created_by: null,
      subscriber: {
        id: 310480,
        name: 'Amogh Sahasrabhojanee',
        created_at: '2023-04-05T09:54:20.806Z',
        updated_at: '2023-04-05T09:54:20.806Z',
        metadata: null,
        cumulative_end_timestamps: {
          standard_subscriptions_cumulative_end_timestamp: '2024-06-29T04:27:51.695Z',
          campaign_subscriptions_cumulative_end_timestamp: null,
          group_access_subscriptions_cumulative_end_timestamp: null,
          pay_per_asset_subscriptions_cumulative_end_timestamp: null
        },
        active: true,
        country_code: 'IN',
        subscriber_identities: [
          {
            id: 452209,
            account_id: 9,
            subscriber_id: 310480,
            provider: 'quintype',
            value: '2702224',
            created_at: '2023-04-05T09:54:20.833Z',
            updated_at: '2023-04-05T09:54:20.833Z'
          },
          {
            id: 452208,
            account_id: 9,
            subscriber_id: 310480,
            provider: 'email',
            value: 'amogh@quintype.com',
            created_at: '2023-04-05T09:54:20.818Z',
            updated_at: '2023-04-05T09:54:20.818Z'
          }
        ]
      },
      expires_in_days: 14,
      in_trial_period: false,
      is_upgradable: true,
      is_downgradable: true,
      is_crossgradable: true
    },
    {
      id: 332823,
      subscriber_id: 310480,
      subscription_plan_id: 68756,
      created_at: '2024-02-22T11:13:54.557Z',
      updated_at: '2024-02-22T11:13:54.557Z',
      assets: [
        {
          title: 'full-site',
          metadata: {},
          type: 'site',
          published_at: {}
        }
      ],
      start_timestamp: '2021-09-29T11:13:54.461Z',
      end_timestamp: '2023-09-29T11:13:54.461Z',
      deleted_at: null,
      payment_id: 334075,
      metadata: {},
      external_id: null,
      trial_period_length: null,
      trial_period_unit: null,
      campaign_id: null,
      plan_amount_cents: 10000,
      plan_amount_currency: 'INR',
      duration_unit: 'years',
      duration_length: 2,
      plan_name: 'Disruptor',
      plan_description: 'One day non recurring plan with SILVER privileges ',
      group_name: 'One Day NR ',
      group_description: 'Subscription group to test upgrade/downgrade (Please do not edit 🙏)',
      subscription_type: 'standard',
      plan_occurrence: 'One Time',
      subscription_attempt_id: 446546,
      renewal_reminder_sent_date: null,
      dynamic_assets: {},
      coupon_discount_id: null,
      notes: '',
      account_id: 9,
      old_assets: null,
      user_limit: null,
      allowed_ips: null,
      access_type: null,
      paid_trial_amount_cents: null,
      bundle_subscription_id: null,
      subscription_group_id: 39353,
      preferred_identity: {
        provider: 'email',
        value: 'amogh@quintype.com'
      },
      active: false,
      payment_amount: '100.00',
      payment_amount_cents: 10000,
      payment_amount_currency: 'INR',
      payment_type: 'manual',
      payment_token: null,
      payment_metadata: {},
      renewable: true,
      cancellable: false,
      status: 'expired',
      expired: true,
      coupon_code: null,
      recurring: false,
      cancelled_at: null,
      next_payment_due_date: null,
      cancelled: false,
      in_grace_period: false,
      invoices: [
        {
          id: 576336,
          sequenced_invoice_number: '899',
          base_price: '84.75',
          amount_cents: 10000,
          amount_currency: 'INR',
          created_at: '2024-02-22T11:13:54.711Z',
          amount_after_discount_before_tax: '84.75',
          discount_details: {},
          invoice_taxes: {
            SGST: {
              percentage: '9.0',
              amount: '7.63',
              currency: 'INR'
            },
            CGST: {
              percentage: '9.0',
              amount: '7.63',
              currency: 'INR'
            }
          },
          round_off: '0.01',
          external_payment_id: null,
          downloadable: true,
          international_payment: null,
          payment_gateway_fee_cents: null,
          payment_gateway_fee_currency: 'INR',
          payment_gateway_fee: '0',
          payment_gateway_net_amount: '100'
        }
      ],
      subscriber_name: 'Amogh Sahasrabhojanee',
      discount_detail: {},
      referrer: null,
      gifter: null,
      created_by: 'Amogh Sahasrabhojanee',
      subscriber: {
        id: 310480,
        name: 'Amogh Sahasrabhojanee',
        created_at: '2023-04-05T09:54:20.806Z',
        updated_at: '2023-04-05T09:54:20.806Z',
        metadata: null,
        cumulative_end_timestamps: {
          standard_subscriptions_cumulative_end_timestamp: '2024-06-29T04:27:51.695Z',
          campaign_subscriptions_cumulative_end_timestamp: null,
          group_access_subscriptions_cumulative_end_timestamp: null,
          pay_per_asset_subscriptions_cumulative_end_timestamp: null
        },
        active: true,
        country_code: 'IN',
        subscriber_identities: [
          {
            id: 452209,
            account_id: 9,
            subscriber_id: 310480,
            provider: 'quintype',
            value: '2702224',
            created_at: '2023-04-05T09:54:20.833Z',
            updated_at: '2023-04-05T09:54:20.833Z'
          },
          {
            id: 452208,
            account_id: 9,
            subscriber_id: 310480,
            provider: 'email',
            value: 'amogh@quintype.com',
            created_at: '2023-04-05T09:54:20.818Z',
            updated_at: '2023-04-05T09:54:20.818Z'
          }
        ]
      },
      expires_in_days: null,
      in_trial_period: false,
      is_upgradable: true,
      is_downgradable: true,
      is_crossgradable: false
    },
    {
      id: 332822,
      subscriber_id: 310480,
      subscription_plan_id: 68755,
      created_at: '2021-09-29T11:10:19.339Z',
      updated_at: '2023-09-29T11:10:19.339Z',
      assets: [
        {
          title: 'full-site',
          metadata: {},
          type: 'site',
          published_at: {}
        }
      ],
      start_timestamp: '2024-02-22T11:10:19.260Z',
      end_timestamp: '2024-02-23T11:10:19.260Z',
      deleted_at: null,
      payment_id: 334074,
      metadata: {},
      external_id: null,
      trial_period_length: null,
      trial_period_unit: null,
      campaign_id: null,
      plan_amount_cents: 384300,
      plan_amount_currency: 'INR',
      duration_unit: 'years',
      duration_length: 2,
      plan_name: 'Game Changer',
      plan_description: 'One day non recurring plan with BRONZE privileges ',
      group_name: 'One Day NR ',
      group_description: 'Subscription group to test upgrade/downgrade (Please do not edit 🙏)',
      subscription_type: 'standard',
      plan_occurrence: 'One Time',
      subscription_attempt_id: 446545,
      renewal_reminder_sent_date: null,
      dynamic_assets: {},
      coupon_discount_id: null,
      notes: '',
      account_id: 9,
      old_assets: null,
      user_limit: null,
      allowed_ips: null,
      access_type: null,
      paid_trial_amount_cents: null,
      bundle_subscription_id: null,
      subscription_group_id: 39353,
      preferred_identity: {
        provider: 'email',
        value: 'amogh@quintype.com'
      },
      active: false,
      payment_amount: '3843.00',
      payment_amount_cents: 384300,
      payment_amount_currency: 'INR',
      payment_type: 'manual',
      payment_token: null,
      payment_metadata: {},
      renewable: true,
      cancellable: false,
      status: 'expired',
      expired: true,
      coupon_code: null,
      recurring: false,
      cancelled_at: null,
      next_payment_due_date: null,
      cancelled: false,
      in_grace_period: false,
      invoices: [
        {
          id: 576334,
          sequenced_invoice_number: '898',
          base_price: '42.37',
          amount_cents: 384300,
          amount_currency: 'INR',
          created_at: '2024-02-22T11:10:19.500Z',
          amount_after_discount_before_tax: '42.37',
          discount_details: {},
          invoice_taxes: {
            CGST: {
              percentage: '9.0',
              amount: '3.81',
              currency: 'INR'
            },
            SGST: {
              percentage: '9.0',
              amount: '3.81',
              currency: 'INR'
            }
          },
          round_off: '0.01',
          external_payment_id: null,
          downloadable: true,
          international_payment: null,
          payment_gateway_fee_cents: null,
          payment_gateway_fee_currency: 'INR',
          payment_gateway_fee: '0',
          payment_gateway_net_amount: '50'
        }
      ],
      subscriber_name: 'Amogh Sahasrabhojanee',
      discount_detail: {},
      referrer: null,
      gifter: null,
      created_by: 'Amogh Sahasrabhojanee',
      subscriber: {
        id: 310480,
        name: 'Amogh Sahasrabhojanee',
        created_at: '2023-04-05T09:54:20.806Z',
        updated_at: '2023-04-05T09:54:20.806Z',
        metadata: null,
        cumulative_end_timestamps: {
          standard_subscriptions_cumulative_end_timestamp: '2024-06-29T04:27:51.695Z',
          campaign_subscriptions_cumulative_end_timestamp: null,
          group_access_subscriptions_cumulative_end_timestamp: null,
          pay_per_asset_subscriptions_cumulative_end_timestamp: null
        },
        active: true,
        country_code: 'IN',
        subscriber_identities: [
          {
            id: 452209,
            account_id: 9,
            subscriber_id: 310480,
            provider: 'quintype',
            value: '2702224',
            created_at: '2023-04-05T09:54:20.833Z',
            updated_at: '2023-04-05T09:54:20.833Z'
          },
          {
            id: 452208,
            account_id: 9,
            subscriber_id: 310480,
            provider: 'email',
            value: 'amogh@quintype.com',
            created_at: '2023-04-05T09:54:20.818Z',
            updated_at: '2023-04-05T09:54:20.818Z'
          }
        ]
      },
      expires_in_days: null,
      in_trial_period: false,
      is_upgradable: true,
      is_downgradable: false,
      is_crossgradable: false
    }
  ];
}
