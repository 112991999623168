export enum FontLanguage {
  Latin = 'English(Latin)',
  Devanagiri = 'Devanagiri(Hindi)',
  Tamil = 'Tamil',
  Gujarati = 'Gujarati',
  Bengali = 'Bengali',
  Arabic = 'Arabic',
  Cyrillic = 'Cyrillic',
  Greek = 'Greek',
  Gurmukhi = 'Gurmukhi',
  Hebrew = 'Hebrew',
  Kannada = 'Kannada',
  Malayalam = 'Malayalam',
  Oriya = 'Oriya',
  Sinhala = 'Sinhala',
  Telugu = 'Telugu',
  Thai = 'Thai',
  Vietnamese = 'Vietnamese'
}

export enum FontFamilies {
  Roboto = 'Roboto',
  Lato = 'Lato',
  Montserrat = 'Montserrat',
  RobotoCondensed = 'Roboto Condensed',
  Oswald = 'Oswald',
  Merriweather = 'Merriweather',
  PTSerif = 'PT Serif',
  Lora = 'Lora',
  PlayfairDisplay = 'Playfair Display',
  Muli = 'Muli',
  Teko = 'Teko',
  LibreBaskerville = 'Libre Baskerville',
  NotoSans = 'Noto Sans',
  NotoSansDevanagari = 'Noto Sans Devanagari',
  NotoSansTamil = 'Noto Sans Tamil',
  NotoSansGujarati = 'Noto Sans Gujarati',
  NotoSansBengali = 'Noto Sans Bengali',
  NotoSansGurmukhi = 'Noto Sans Gurmukhi',
  NotoSansHebrew = 'Noto Sans Hebrew',
  NotoSansKannada = 'Noto Sans Kannada',
  NotoSansMalayalam = 'Noto Sans Malayalam',
  NotoSansOriya = 'Noto Sans Oriya',
  NotoSansSinhala = 'Noto Sans Sinhala',
  NotoSansTelugu = 'Noto Sans Telugu',
  Poppins = 'Poppins',
  Mukta = 'Mukta',
  Khand = 'Khand',
  Catamaran = 'Catamaran',
  MuktaMalar = 'Mukta Malar',
  HindVadodara = 'Hind Vadodara',
  MuktaVaani = 'Mukta Vaani',
  HindSiliguri = 'Hind Siliguri',
  Cairo = 'Cairo',
  Amiri = 'Amiri',
  Lalezar = 'Lalezar',
  ElMessiri = 'El Messiri',
  ReemKufi = 'Reem Kufi',
  OpenSans = 'Open Sans',
  NotoSerif = 'Noto Serif',
  MuktaMahee = 'Mukta Mahee',
  FrankRuhlLibre = 'Frank Ruhl Libre',
  AbhayaLibre = 'Abhaya Libre',
  Kanit = 'Kanit',
  Prompt = 'Prompt',
  Sarabun = 'Sarabun',
  Tajawal = 'Tajawal',
  CormorantGaramond = 'Cormorant Garamond',
  EBGaramond = 'EB Garamond',
  Raleway = 'Raleway',
  BalooTamma2 = 'Baloo Tamma 2',
  NotoSerifBengali = 'Noto Serif Bengali'
}

export const fontsList = {
  [FontLanguage.Latin as string]: [
    {
      label: FontFamilies.Roboto,
      value: FontFamilies.Roboto
    },
    {
      label: FontFamilies.Lato,
      value: FontFamilies.Lato
    },
    {
      label: FontFamilies.OpenSans,
      value: FontFamilies.OpenSans
    },
    {
      label: FontFamilies.Montserrat,
      value: FontFamilies.Montserrat
    },
    {
      label: FontFamilies.RobotoCondensed,
      value: FontFamilies.RobotoCondensed
    },
    {
      label: FontFamilies.Oswald,
      value: FontFamilies.Oswald
    },
    {
      label: FontFamilies.Merriweather,
      value: FontFamilies.Merriweather
    },
    {
      label: FontFamilies.PTSerif,
      value: FontFamilies.PTSerif
    },
    {
      label: FontFamilies.Lora,
      value: FontFamilies.Lora
    },
    {
      label: FontFamilies.PlayfairDisplay,
      value: FontFamilies.PlayfairDisplay
    },
    {
      label: FontFamilies.Muli,
      value: FontFamilies.Muli
    },
    {
      label: FontFamilies.Teko,
      value: FontFamilies.Teko
    },
    {
      label: FontFamilies.LibreBaskerville,
      value: FontFamilies.LibreBaskerville
    },
    {
      label: FontFamilies.Poppins,
      value: FontFamilies.Poppins
    },
    {
      label: FontFamilies.CormorantGaramond,
      value: FontFamilies.CormorantGaramond
    },
    {
      label: FontFamilies.EBGaramond,
      value: FontFamilies.EBGaramond
    },
    {
      label: FontFamilies.Raleway,
      value: FontFamilies.Raleway
    }
  ],
  [FontLanguage.Devanagiri as string]: [
    {
      label: FontFamilies.NotoSansDevanagari,
      value: FontFamilies.NotoSansDevanagari
    },
    {
      label: FontFamilies.Poppins,
      value: FontFamilies.Poppins
    },
    {
      label: FontFamilies.Teko,
      value: FontFamilies.Teko
    },
    {
      label: FontFamilies.Mukta,
      value: FontFamilies.Mukta
    },
    {
      label: FontFamilies.Khand,
      value: FontFamilies.Khand
    }
  ],
  [FontLanguage.Tamil as string]: [
    {
      label: FontFamilies.Catamaran,
      value: FontFamilies.Catamaran
    },
    {
      label: FontFamilies.MuktaMalar,
      value: FontFamilies.MuktaMalar
    },
    {
      label: FontFamilies.NotoSansTamil,
      value: FontFamilies.NotoSansTamil
    }
  ],
  [FontLanguage.Gujarati as string]: [
    {
      label: FontFamilies.HindVadodara,
      value: FontFamilies.HindVadodara
    },
    {
      label: FontFamilies.MuktaVaani,
      value: FontFamilies.MuktaVaani
    },
    {
      label: FontFamilies.NotoSansGujarati,
      value: FontFamilies.NotoSansGujarati
    }
  ],
  [FontLanguage.Bengali as string]: [
    {
      label: FontFamilies.HindSiliguri,
      value: FontFamilies.HindSiliguri
    },
    {
      label: FontFamilies.NotoSansBengali,
      value: FontFamilies.NotoSansBengali
    },
    {
      label: FontFamilies.NotoSerifBengali,
      value: FontFamilies.NotoSerifBengali
    }
  ],
  [FontLanguage.Arabic as string]: [
    {
      label: FontFamilies.Cairo,
      value: FontFamilies.Cairo
    },
    {
      label: FontFamilies.Amiri,
      value: FontFamilies.Amiri
    },
    {
      label: FontFamilies.Lalezar,
      value: FontFamilies.Lalezar
    },
    {
      label: FontFamilies.ElMessiri,
      value: FontFamilies.ElMessiri
    },
    {
      label: FontFamilies.ReemKufi,
      value: FontFamilies.ReemKufi
    },
    {
      label: FontFamilies.Tajawal,
      value: FontFamilies.Tajawal
    }
  ],
  [FontLanguage.Cyrillic as string]: [
    {
      label: FontFamilies.Roboto,
      value: FontFamilies.Roboto
    },
    {
      label: FontFamilies.Lato,
      value: FontFamilies.Lato
    },
    {
      label: FontFamilies.OpenSans,
      value: FontFamilies.OpenSans
    },
    {
      label: FontFamilies.Montserrat,
      value: FontFamilies.Montserrat
    },
    {
      label: FontFamilies.RobotoCondensed,
      value: FontFamilies.RobotoCondensed
    },
    {
      label: FontFamilies.Merriweather,
      value: FontFamilies.Merriweather
    },
    {
      label: FontFamilies.PTSerif,
      value: FontFamilies.PTSerif
    },
    {
      label: FontFamilies.Lora,
      value: FontFamilies.Lora
    },
    {
      label: FontFamilies.PlayfairDisplay,
      value: FontFamilies.PlayfairDisplay
    },
    {
      label: FontFamilies.NotoSerif,
      value: FontFamilies.NotoSerif
    }
  ],
  [FontLanguage.Greek as string]: [
    {
      label: FontFamilies.Roboto,
      value: FontFamilies.Roboto
    },
    {
      label: FontFamilies.OpenSans,
      value: FontFamilies.OpenSans
    },
    {
      label: FontFamilies.RobotoCondensed,
      value: FontFamilies.RobotoCondensed
    },
    {
      label: FontFamilies.NotoSans,
      value: FontFamilies.NotoSans
    },
    {
      label: FontFamilies.NotoSerif,
      value: FontFamilies.NotoSerif
    }
  ],
  [FontLanguage.Gurmukhi as string]: [
    {
      label: FontFamilies.NotoSansGurmukhi,
      value: FontFamilies.NotoSansGurmukhi
    },
    {
      label: FontFamilies.MuktaMahee,
      value: FontFamilies.MuktaMahee
    }
  ],
  [FontLanguage.Hebrew as string]: [
    {
      label: FontFamilies.FrankRuhlLibre,
      value: FontFamilies.FrankRuhlLibre
    },
    {
      label: FontFamilies.NotoSansHebrew,
      value: FontFamilies.NotoSansHebrew
    }
  ],
  [FontLanguage.Kannada as string]: [
    {
      label: FontFamilies.NotoSansKannada,
      value: FontFamilies.NotoSansKannada
    },
    {
      label: FontFamilies.BalooTamma2,
      value: FontFamilies.BalooTamma2
    }
  ],
  [FontLanguage.Malayalam as string]: [
    {
      label: FontFamilies.NotoSansMalayalam,
      value: FontFamilies.NotoSansMalayalam
    }
  ],
  [FontLanguage.Oriya as string]: [
    {
      label: FontFamilies.NotoSansOriya,
      value: FontFamilies.NotoSansOriya
    }
  ],
  [FontLanguage.Sinhala as string]: [
    {
      label: FontFamilies.NotoSansSinhala,
      value: FontFamilies.NotoSansSinhala
    },
    {
      label: FontFamilies.AbhayaLibre,
      value: FontFamilies.AbhayaLibre
    }
  ],
  [FontLanguage.Telugu as string]: [
    {
      label: FontFamilies.NotoSansTelugu,
      value: FontFamilies.NotoSansTelugu
    }
  ],
  [FontLanguage.Thai as string]: [
    {
      label: FontFamilies.Kanit,
      value: FontFamilies.Kanit
    },
    {
      label: FontFamilies.Prompt,
      value: FontFamilies.Prompt
    },
    {
      label: FontFamilies.Sarabun,
      value: FontFamilies.Sarabun
    }
  ],
  [FontLanguage.Vietnamese as string]: [
    {
      label: FontFamilies.Roboto,
      value: FontFamilies.Roboto
    },
    {
      label: FontFamilies.OpenSans,
      value: FontFamilies.OpenSans
    },
    {
      label: FontFamilies.Montserrat,
      value: FontFamilies.Montserrat
    },
    {
      label: FontFamilies.RobotoCondensed,
      value: FontFamilies.RobotoCondensed
    },
    {
      label: FontFamilies.Oswald,
      value: FontFamilies.Oswald
    },
    {
      label: FontFamilies.Merriweather,
      value: FontFamilies.Merriweather
    }
  ]
};
