import leaderboard from './low-fidelity-ad-svgs/728-x-90.svg';
import billboard from './low-fidelity-ad-svgs/970-x-250.svg';
import superLeaderboard from './low-fidelity-ad-svgs/970-x-90.svg';
import custom from './low-fidelity-ad-svgs/custom.svg';

export const adMap = [
  {
    componentName: 'Leaderboard',
    componentLabel: 'Leaderboard',
    image: leaderboard
  },
  {
    componentName: 'SuperLeaderboard',
    componentLabel: 'Super Leaderboard',
    image: superLeaderboard
  },
  {
    componentName: 'Billboard',
    componentLabel: 'Billboard',
    image: billboard
  },
  {
    componentName: 'Custom',
    componentLabel: 'Custom',
    image: custom
  }
];
