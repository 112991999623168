import get from 'lodash/get';
import { IRowDetails, ISelectedRow } from '../../../utils/interfaces';

interface IAddRow {
  rowsList: ISelectedRow[];
  rowDetails: IRowDetails;
  down?: boolean;
}

export const getRowIndexToSlice = ({ rowsList = [], rowDetails, down }: IAddRow) => {
  let updateIndex = 0;
  rowsList.map((rowData: ISelectedRow, index: number) => {
    if (rowData.rowId === rowDetails.selectedRowId) {
      updateIndex = index;
    }
  });
  if (down) {
    updateIndex += 1;
  }
  return updateIndex;
};

interface IConfigObj {
  rows: ISelectedRow[];
}
export const getRowsList = (configObj: IConfigObj, page: string, action: any) => {
  // If the page is landingPage, return the correct rows from state:
  switch (page) {
    case 'landingPage':
      return get(configObj, ['layouts', get(action, ['payload', 'pageId']), 'rows']);
    case 'story':
      return get(configObj, [action.payload.subPageType, 'rows']);
    default:
      return configObj.rows;
  }
};

export const getModuleType = (action: any) => {
  return action.payload.moduleType;
};
