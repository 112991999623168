import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBackupConfig, updateHaveLayoutUpdates, updateHaveUnsavedChanges } from '../../../actions/builder-action';
import { updateStoryElementAttribute, updateStoryElementSettings } from '../../../actions/story-action';
import FullScreenModalWrapper from '../../../atoms/full-screen-modal-wrapper';
import ActionButtons from '../../../molecules/action-buttons';
import PBInspector from '../../../molecules/inspector';
import LowFidelityLayouts from '../../../molecules/low-fidelity-layouts';
import { StoryElement } from '../../../molecules/story-element';
import { IConfig } from '../../../utils/interfaces';
import { storyElementsList } from './story-elements-list';
import { TextField } from '@quintype/em/components/text-field';
import { Checkbox } from '@quintype/em/components/checkbox';
import { ThemeSetter } from '../../../molecules/theme-setter';

import styles from './story-elements-page.module.css';

interface IPBState {
  ahead: {
    storyPage: (args: any) => any;
  };
  builder: {
    config: IConfig;
    haveUnsavedChanges: boolean;
  };
  isAccordionActive: false;
}

interface Iopions {
  name: string;
  svg: string;
}
interface ISettingDetials {
  type: string;
  label: string;
  key: string;
  settingsType: string;
  options?: any;
}

interface IComponent {
  componentName: string;
  componentLabel: string;
  image: string;
}

interface IElementDetails {
  title: string;
  description: string;
  key: string;
  componentName: string;
  templatesList?: IComponent[];
  settings?: {
    css?: ISettingDetials[];
    opts?: ISettingDetials[];
  };
}

interface IButtonStatus {
  showLayoutBtn?: boolean;
  showSettingsBtn?: boolean;
}

function getLightDefaultColor(key: string, settingDetails: any): string {
  if (settingDetails.key === 'hyperlinkColor') return '#2f81cd';
  if (key === 'text') return '#333333';
  return '#4860BC';
}

function getDarkDefaultColor(key: string, settingDetails: any): string {
  if (settingDetails.key === 'hyperlinkColor') return '#2f81cd';
  if (key === 'text') return '#fff';
  return '#4860BC';
}

export const StoryElementsPage = () => {
  const [showLayoutSelector, toggleLayoutSelector] = useState(false);
  const [activeIndex, handleActiveIndex] = useState(-1);
  const [selectedIndexIcon, setSelectedIndexIcon] = useState(-1);
  const [showSettingsInspector, toggleSettingsInspector] = useState(-1);
  const dispatch = useDispatch();
  const { config, haveUnsavedChanges } = useSelector((state: IPBState) => get(state, ['builder']));
  const enableDarkModePreview = get(config, ['enableDarkModePreview'], false);
  const settingsHandler = (index: number) => {
    dispatch(updateBackupConfig({ config }));
    toggleSettingsInspector(index + 1);
  };

  const handleLayoutSelect = (componentMeta: IComponent, elementType: string) => {
    toggleLayoutSelector(false);

    dispatch(
      updateStoryElementAttribute({
        type: elementType,
        data: { template: componentMeta.componentName || '' }
      })
    );
    dispatch(updateHaveLayoutUpdates(true));
  };

  const handleLayout = (index: number) => {
    toggleLayoutSelector(true);
    handleActiveIndex(index);
  };

  const getActionList = (buttonStatus: IButtonStatus, index: number) => {
    const { showLayoutBtn = true, showSettingsBtn = true } = buttonStatus;
    const layoutBtn = {
      action: 'replace',
      handler: () => handleLayout(index)
    };
    const settingsBtn = {
      action: 'setting',
      handler: () => settingsHandler(index)
    };
    return [...(showLayoutBtn ? [layoutBtn] : []), ...(showSettingsBtn ? [settingsBtn] : [])];
  };

  const updateSettings = (settingKey: string, value: any, elementType: string, settingsType: string, index: number) => {
    setSelectedIndexIcon(index);
    dispatch(updateStoryElementSettings({ type: elementType, settingsType, data: { [settingKey]: value } }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  return (
    <div className={styles.wrapper}>
      {storyElementsList.map((elementDetails: IElementDetails, index: number) => {
        const { title, description, key, componentName, templatesList = [], settings = {} } = elementDetails;
        const cssSettings = get(settings, ['css'], []);
        const optsSettings = get(settings, ['opts'], []);
        const mergedSettings = [...cssSettings, ...optsSettings];
        const actionList = getActionList(
          {
            showLayoutBtn: !!templatesList.length,
            showSettingsBtn: !isEmpty(mergedSettings)
          },
          index
        );
        const elementConfig = get(config, ['story', 'globalStoryElements', key], {});
        return (
          // tslint:disable-next-line:jsx-key
          <div className={styles.elementDetails}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            <div className={styles.templateWrapper}>
              <div className={styles.hoverHeading}>{title}</div>
              <div className={styles.template}>
                <StoryElement
                  key={index}
                  componentName={componentName}
                  subtype={key}
                  enableDarkModePreview={get(config, ['enableDarkModePreview'], false)}
                  {...elementConfig}
                />
              </div>
              <div className={styles.buttonGroup}>
                <ActionButtons actionList={actionList} />
              </div>

              {showLayoutSelector && activeIndex === index ? (
                <FullScreenModalWrapper onClose={() => toggleLayoutSelector(true)}>
                  <LowFidelityLayouts
                    handleLayoutSelect={(componentMeta: IComponent) => handleLayoutSelect(componentMeta, key)}
                    layoutsList={templatesList}
                  />
                </FullScreenModalWrapper>
              ) : null}
              {showSettingsInspector - 1 === index && (
                <PBInspector
                  title={`${title} Element Settings`}
                  onActionButtonClick={() => toggleSettingsInspector(-1)}
                  isActive={showSettingsInspector}
                  isActionButtonDisabled={!haveUnsavedChanges}
                  onClose={() => toggleSettingsInspector(-1)}
                >
                  {mergedSettings.map((settingDetails: ISettingDetials) => {
                    const { type, label, key: settingKey, settingsType, options } = settingDetails;
                    const darkThemeKeyName = `dark${startCase(settingKey)}`.replaceAll(' ', '');
                    const storedValue = get(elementConfig, [settingsType, settingKey]);
                    const darkStoredValue = get(elementConfig, [settingsType, darkThemeKeyName]);

                    switch (type) {
                      case 'colorPicker':
                        return (
                          <ThemeSetter
                            label={label}
                            updateTheStore={(settingKey: string, value: string) =>
                              updateSettings(settingKey, value, key, settingsType, index)
                            }
                            lightColorExtract={storedValue}
                            lightUpdateStoreKey={settingKey}
                            darkColorExtract={darkStoredValue}
                            darkUpdateStoreKey={darkThemeKeyName}
                            lightDefaultColor={getLightDefaultColor(key, settingDetails)}
                            darkDefaultColor={getDarkDefaultColor(key, settingDetails)}
                            enableDarkModePreview={enableDarkModePreview}
                          />
                        );
                      case 'selectDesign':
                        return (
                          <>
                            <div className={styles.label}>{label}</div>
                            <div className={styles.svgWrapper}>
                              {options.map((item: { name: string; svg: string }, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className={selectedIndexIcon === index ? styles.selectedIcon : styles.svgIcon}
                                    onClick={() => updateSettings(settingKey, item.name, key, settingsType, index)}
                                  >
                                    <img src={item.svg} alt="svg icon" />
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        );
                      case 'text':
                        return (
                          <TextField
                            label={label}
                            placeholder="Enter title"
                            value={storedValue}
                            onChange={(value: string) => updateSettings(settingKey, value, key, settingsType, index)}
                          />
                        );
                      case 'checkbox':
                        return (
                          <div className={styles.checkboxWrapper}>
                            <Checkbox
                              label={label}
                              id={key}
                              checked={storedValue}
                              onChange={(value: boolean) => updateSettings(settingKey, value, key, settingsType, index)}
                            />
                          </div>
                        );
                    }
                  })}
                </PBInspector>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
