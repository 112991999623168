import { PAGEBUILDER_CLOSE_POPUP, PAGEBUILDER_NAVIGATION_CLOSE_POPUP, PAGEBUILDER_NAVIGATION_SHOW_POPUP, PAGEBUILDER_SHOW_POPUP } from './../reducers/builder/showPopupReducer/actions';

interface IArgs {
  type: string;
  payload?: any;
}

const showPopup = (payload: any) => {
  return {
    type: PAGEBUILDER_SHOW_POPUP,
    payload
  };
};

const closePopup = () => {
  return { type: PAGEBUILDER_CLOSE_POPUP };
};


const showNavigationPopup = (payload: any) => {
  return {
    type: PAGEBUILDER_NAVIGATION_SHOW_POPUP,
    payload
  };
};

const closeNavigationPopup = () => {
  return { type: PAGEBUILDER_NAVIGATION_CLOSE_POPUP };
};

export { showPopup, closePopup, showNavigationPopup, closeNavigationPopup };
