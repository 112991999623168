import React, { useState } from 'react';
import styles from './action-dropdown.module.css';

// alignTo = "left" | "right" | "top" | "down"

const ActionDropdownMenu = ({ alignTo = 'bottom', children }: any) => {
  const [activeActionDropdown, setActiveActionDropdown] = useState(false);
  const handleDropdownMenuState = (event: React.MouseEvent) => {
    event.stopPropagation();
    setActiveActionDropdown(prevState => !prevState);
  };
  return (
    <div className={styles.wrapper}>
      <button className={styles.actionDropdownButton} onClick={handleDropdownMenuState}>
        {children[0]}
      </button>
      <div className={`${styles[alignTo]}`}>{activeActionDropdown && children[1]}</div>
    </div>
  );
};

const ActionDropdownList = ({ children }: any) => {
  return <ul className={styles.items}>{children}</ul>;
};

const ActionDropdownItem = ({ actionHandler, actionName, children }: any) => {
  const handleAction = (event: React.MouseEvent) => {
    event.stopPropagation();
    actionHandler();
  };
  return (
    <li onClick={handleAction}>
      <div className={styles.actionItem}>
        <span className={styles.icons}> {children} </span>
        {actionName}
      </div>
    </li>
  );
};

export { ActionDropdownMenu, ActionDropdownList, ActionDropdownItem };
