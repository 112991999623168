import { Checkbox } from '@quintype/em/components/checkbox';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFooterThemeAttributes } from '../../../actions/footer-action';
import { IPBState } from '../../../utils/interfaces';
import styles from './footer-subscription.module.css';

export const FooterSubscription = () => {
  const dispatch = useDispatch();
  const updateSubscription = (keyName: string, keyValue: boolean) =>
    dispatch(updateFooterThemeAttributes({ [keyName]: keyValue }));
  const enableSubscriptionButton = useSelector((state: IPBState) =>
    get(state, ['builder', 'config', 'footer', 'enableSubscriptionButton'], false)
  );

  return (
    <div className={styles.checkboxWrapper} data-test-id="footer-enable-subscription">
      <Checkbox
        label="Subscribe Button"
        id="footerAccesstype"
        checked={enableSubscriptionButton}
        onChange={(value: any) => updateSubscription('enableSubscriptionButton', value)}
      />
    </div>
  );
};
