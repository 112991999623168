import { ISelectRowInspector } from '../../../utils/interfaces';
export const PAGEBUILDER_OPEN_SELECT_ROW_INSPECTOR = 'PAGEBUILDER.OPEN_SELECT_ROW_INSPECTOR';
export const PAGEBUILDER_CLOSE_SELECT_ROW_INSPECTOR = 'PAGEBUILDER.CLOSE_SELECT_ROW_INSPECTOR';
const selectRowInspectorReducer = (
  state: ISelectRowInspector = {
    isOpen: false,
    type: 'Collections',
    action: 'add_row_top',
    rowId: 0,
    moduleType: ''
  },

  action: {
    type: string;
    payload: { type: string; action: string; rowId: number; moduleType: string; isHeaderRow?: boolean };
  }
) => {
  switch (action.type) {
    case PAGEBUILDER_OPEN_SELECT_ROW_INSPECTOR:
      return { isOpen: true, ...action.payload };
    case PAGEBUILDER_CLOSE_SELECT_ROW_INSPECTOR:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export { selectRowInspectorReducer };
