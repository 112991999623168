import { Checkbox } from '@quintype/em/components/checkbox';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import Select from '@quintype/em/components/select';
import { TextField } from '@quintype/em/components/text-field';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../../actions/builder-action';
import {
  updateAuthorcard,
  updateAuthorcardOpts,
  updateDateTime,
  updateStorySettings
} from '../../../actions/story-action';
import { Separator } from '../../../atoms/separator';
import { defaultDarkTheme, defaultTheme } from '../../../constant';
import { SocialFollowStyle } from '../../social-link-style';
import { ThemeSetter } from '../../theme-setter';
import styles from '../arrow-row-setting/setting.module.css';

interface IOption {
  label: string;
  value: string;
}

interface IProps {
  config: any;
  data: IStoryTemplate[];
  storyType: string;
  customStoryTypeBaseName?: string;
  enableDarkModePreview?: boolean;
}

interface IRadioOptions {
  name: string;
  value: string;
}

interface IStoryTemplate {
  label: string;
  keyName: string;
  type: string | boolean;
  dropDownOptions: any;
  description: string;
  radioOptions: IRadioOptions[];
}

interface IDynamicObject<type> {
  [key: string]: type;
}

const authorDropDownHiddenKeys = ['showLabels', 'showGuestAuthorImage', 'showGuestAuthorName', 'separator'];

const dropDownOptionHideSettings: any = {
  template: {
    default: ['showBio'],
    leftAligned: authorDropDownHiddenKeys,
    centerAligned: authorDropDownHiddenKeys
  }
};

const keyNameMapping: IDynamicObject<string> = {
  template: 'authorTemplate'
};

export const StorySettings = ({
  config,
  data,
  storyType = '',
  customStoryTypeBaseName,
  enableDarkModePreview = false
}: IProps) => {
  const dispatch = useDispatch();
  const storyPageType = `${storyType}-story`;

  const updateTemplateSettings = (keyName: string, value: string | boolean) => {
    switch (keyName) {
      case 'enablePublishedTime':
      case 'enableUpdatedTime':
      case 'showReadTime':
        dispatch(
          updateDateTime({
            type: storyPageType,
            [keyName]: value
          })
        );
        break;
      case 'template':
        dispatch(
          updateAuthorcard({
            type: storyPageType,
            [keyName]: value
          })
        );
        break;
      case 'showBio':
      case 'showImage':
      case 'showGuestAuthorImage':
      case 'showLabels':
      case 'showName':
      case 'showGuestAuthorName':
        dispatch(
          updateAuthorcardOpts({
            type: storyPageType,
            [keyName]: value
          })
        );
        break;
      default:
        break;
    }

    dispatch(updateStorySettings({ type: storyPageType, [keyName]: value }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const templateOptionsMap = () => {
    const isDropDownPresent = data.find(({ type }) => {
      return type === 'dropDown';
    });

    const hiddenKeyNames: IDynamicObject<boolean> = {};
    if (isDropDownPresent) {
      const { keyName } = isDropDownPresent;
      const configKeyname = keyNameMapping[keyName] || keyName;
      if (dropDownOptionHideSettings[keyName] && config[configKeyname]) {
        const dropDownSelectedValue = config[configKeyname];
        const hiddenItems = (dropDownOptionHideSettings[keyName][dropDownSelectedValue] || []) as string[];
        hiddenItems.forEach(item => {
          hiddenKeyNames[item] = true;
        });
      }
    }
    return (
      data &&
      data.map((storyTemplate: any, index: number) => {
        const { keyName } = storyTemplate;

        if (hiddenKeyNames[keyName]) return null;

        return <div key={index}>{selectInputType(storyTemplate, config)}</div>;
      })
    );
  };

  const selectInputType = (storyTemplate: IStoryTemplate, config: any) => {
    const { label, keyName, type, dropDownOptions, description, radioOptions } = storyTemplate;
    const dataTestId = camelCase(label);
    const darkThemeKeyName = `dark${startCase(keyName)}`.replaceAll(' ', '');

    switch (type) {
      case 'checkbox':
        return (
          <div className={styles.checkboxWrapper} data-test-id={dataTestId}>
            <Checkbox
              onChange={(value: boolean) => updateTemplateSettings(keyName, value)}
              label={label}
              id={keyName}
              checked={get(config, [keyName], true)}
            />
          </div>
        );
      case 'text':
        const keyValue = config && get(config, [keyName], '');
        return (
          <div data-test-id={dataTestId}>
            <TextField
              label={label}
              value={keyValue}
              onChange={(value: string) => updateTemplateSettings(keyName, value)}
            />
          </div>
        );
      case 'colorPicker':
        return (
          <div data-test-id={dataTestId}>
            <ThemeSetter
              label={label}
              updateTheStore={updateTemplateSettings}
              lightColorExtract={config && get(config, [keyName])}
              lightUpdateStoreKey={keyName}
              darkColorExtract={config && get(config, [darkThemeKeyName])}
              darkUpdateStoreKey={darkThemeKeyName}
              lightDefaultColor={defaultTheme}
              darkDefaultColor={defaultDarkTheme}
              enableDarkModePreview={enableDarkModePreview}
            />
          </div>
        );
      case 'dropDown':
        const actualKeyName = keyNameMapping[keyName] || keyName;
        const selectedValue = get(config, [actualKeyName], null);
        const selectedOption = dropDownOptions.find((option: IOption) => option.value === selectedValue) || null;
        return (
          <div data-test-id={dataTestId}>
            <Select
              label={label}
              options={dropDownOptions}
              onChange={(option: any) => updateTemplateSettings(keyName, option.value)}
              data-test-id={dataTestId}
              defaultValue={selectedOption}
            />
          </div>
        );
      case 'socialShareStyle':
        return (
          <SocialFollowStyle
            layout={customStoryTypeBaseName || storyType}
            socialLinkDescription={description}
            updateStyles={(option: any) => updateTemplateSettings(keyName, option)}
            supportPlainSvg
          />
        );

      case 'separator':
        return <Separator />;
      case 'radioBtn':
        return (
          <div className={styles.radioWrapper}>
            <Radio
              label={label}
              name={keyName}
              align="vertical"
              selected={(config && get(config, [keyName])) || ''}
              onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) =>
                updateTemplateSettings(keyName, (e.target as HTMLInputElement).value)
              }
            >
              {radioOptions.map(({ name, value, index }: any) => (
                <RadioOption id={index} value={value} key={index}>
                  {name}
                </RadioOption>
              ))}
            </Radio>
          </div>
        );
    }
  };
  return <>{templateOptionsMap()}</>;
};

export default StorySettings;
