import { COLLECTION_DATA } from './collection-data';

export const COLLECTION_OF_COLLECTIONS_DATA = {
  'associated-metadata': {
    initial_stories_load_count: 7,
    show_author_name: true,
    layout: 'SliderCollectionOfCollections',
    subsequent_stories_load_count: 8,
    show_section_tag: true,
    show_time_of_publish: true,
    enable_load_more_button: true,
    show_collection_name: true,
    enableDarkTheme: false,
    theme: 'light',
    button_text: 'Read more'
  },
  metadata: {
    button_text: 'Read more'
  },
  'updated-at': 1591863325792,
  'collection-cache-keys': ['c/97/34426'],
  slug: 'home',
  fallback: false,
  name: 'Sports',
  'data-source': 'manual',
  automated: false,
  template: 'default',
  rules: {},
  summary: 'Collection C5 for testing full simple slider',
  id: 34426,
  'total-count': 12,
  'collection-date': null,
  'created-at': 1549629885806,
  items: [
    {
      id: 84770,
      'associated-metadata': {},
      type: 'collection',
      name: 'Sports 1',
      slug: '',
      template: 'section',
      metadata: {
        section: [
          {
            id: 37555,
            name: 'Always bored'
          }
        ]
      },
      'collection-date': null
    },
    {
      id: 84770,
      'associated-metadata': {},
      type: 'collection',
      name: 'Sports 2',
      slug: '',
      template: 'section',
      metadata: {
        section: [
          {
            id: 37555,
            name: 'Always bored'
          }
        ]
      },
      'collection-date': null
    },
    {
      id: 84770,
      'associated-metadata': {},
      type: 'collection',
      name: 'Sports 3',
      slug: '',
      template: 'section',
      metadata: {
        section: [
          {
            id: 37555,
            name: 'Always bored'
          }
        ]
      },
      'collection-date': null
    },
    {
      id: 84770,
      'associated-metadata': {},
      type: 'collection',
      name: 'Sports 4',
      slug: '',
      template: 'section',
      metadata: {
        section: [
          {
            id: 37555,
            name: 'Always bored'
          }
        ]
      },
      'collection-date': null
    }
  ]
};

export const COLLECTION_OF_COLLECTIONS_OF_STORIES_DATA = {
  "slug": "forbes-12",
  "name": "The Elephant",
  "template": "default",
  "id": 153449,
  "collection-date": 1626825600000,
  "items": Array(4).fill(COLLECTION_DATA),
  "created-at": 1626418835412,
}
