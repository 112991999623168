import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalWrapper } from './modal-wrapper';
// A component which will render different popups based on the store value.
const SharedPopupGenerator = () => {
  const popup = useSelector(state => get(state, ['builder', 'popup'], {}));
  const dispatch = useDispatch();
  return popup.showPopup ? (
    <ModalWrapper title={popup.popupHeader} dispatch={dispatch}>
      <div>{React.createElement(popup.popupComponent, { meta: popup.meta })}</div>
    </ModalWrapper>
  ) : null;
};

export default SharedPopupGenerator;
