import { Chevron } from '@quintype/em/icons/chevron';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import React, { FunctionComponent, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IsvgData } from '../../utils/interfaces';
import backArrow from './backArrow.svg';
import styles from './dropdown.module.css';
import { ISelectedRow, IPBState } from '../../utils/interfaces';
import { Chip } from '@quintype/em/components/chip';

interface IBuilder {
  selectedRows: ISelectedRow[];
}
interface IState {
  builder: IBuilder;
}
interface DropdownProps {
  config: IDropdownConfig;
}
interface IOptionConfig {
  title?: string;
  label: string;
  options?: IOptionConfig[];
  link?: string;
  shouldRender?: boolean;
  customIcon?: FunctionComponent<IsvgData>;
}
interface IDropdownConfig {
  title?: string;
  label?: any;
  isDropdownOpen: boolean;
  options?: IOptionConfig[];
  toggleDropdown: (shouldOpen?: boolean) => void;
  active?: string;
  setActive: (option: IOptionConfig, e: MouseEvent) => void;
  pageType?: string | undefined;
}

export const Dropdown: React.FunctionComponent<DropdownProps> = ({
  config = {
    toggleDropdown: () => null,
    setActive: () => null
  }
}) => {
  const [shouldSlide, setShouldSlide] = useState('');
  const [shouldPopUp, setPopUp] = useState(false);
  const [subMenu, setSubmenu] = useState({
    title: '',
    options: []
  });
  const dispatch = useDispatch();

  const componentMeta = useSelector((state: IState) => {
    return get(state, ['ahead', 'componentMetaData']);
  });

  const onMenuItemClick = (option: any) => {
    setSubmenu({
      title: option.title,
      options: option.options
    });
    if (option.label === 'Sign in Page' || option.label === 'Subscriptions') {
      setPopUp(true);
    } else {
      setShouldSlide('right');
    }
  };

  const renderSubmenu = (option: any, index: number, displayImage: boolean = false) => {
    const component = componentMeta.find((item: any) => item.componentLabel === option.label);
    const selectedPage = config?.pageType === kebabCase(component?.componentName) ? 'currentLayout' : '';

    return (
      <>
        {option.link ? (
          <div className={`${styles[selectedPage]}`}>
            <Link
              to={option.link}
              onClick={e => {
                setShouldSlide('left');
                config.setActive(option, e);
                setPopUp(false);
              }}
              data-test-id={camelCase(option.label)}
            >
              {option.label}
            </Link>
            {displayImage && (
              <>
                {selectedPage && <Chip classname="custom-chip" value="CURRENT" />}
                <div
                  className={`${styles.imageWrapper}`}
                  onClick={e => {
                    setShouldSlide('left');
                    config.setActive(option, e);
                    setPopUp(false);
                  }}
                  dangerouslySetInnerHTML={{ __html: component?.image }}
                />
              </>
            )}
          </div>
        ) : (
          <span
            onClick={() => {
              config.toggleDropdown();
              option.onClick(dispatch);
            }}
            className={styles.optionValue}
            data-test-id={camelCase(option.label)}
          >
            {option.label}
            {option.customIcon ? React.createElement(option.customIcon) : null}
          </span>
        )}
      </>
    );
  };

  const stickyElement = get(subMenu, ['options'], []).find(
    (option: IOptionConfig) => option.label === 'Create New Landing Page' || option.label === 'Create New Story'
  );

  const renderMenuItemOption = (option: IOptionConfig, index: number) => (
    <>
      {option.shouldRender && (
        <li className={styles.menuItem} key={index}>
          {option.link ? (
            <Link
              to={option.link}
              onClick={e => {
                setShouldSlide('left');
                config.setActive(option, e);
              }}
              data-test-id={camelCase(option.label)}
            >
              {option.label}
            </Link>
          ) : (
            <span onClick={() => onMenuItemClick(option)} data-test-id={camelCase(option.label)}>
              {option.label}{' '}
              <div className={styles.chevronIcon}>
                <Chevron variant="right" width={22} height={16} />
              </div>
            </span>
          )}
        </li>
      )}
    </>
  );

  const slideStyles = shouldSlide === 'right' ? 'menuTrack--slideLeft' : 'menuTrack--slideRight';

  // click behaviour for the list item
  const handleListItemClick = (option: IOptionConfig) => {
    if (option?.link) {
      window.location.href = option.link;
    }
  };

  return (
    <div className={styles.container} data-test-id={camelCase(`${config.title}-dropdown`)}>
      <h3
        className={styles.title}
        onClick={() => {
          config.toggleDropdown();
          setPopUp(false);
        }}
      >
        {config.label && <span className={styles.titleLabel}>{config.label}:</span>}
        {get(config, ['active'], '')}
        <div className={styles.chevronIcon}>
          <Chevron variant="down" width={22} height={18} />
        </div>
      </h3>
      <div
        className={`${shouldPopUp ? styles.popUpMenuWrapper : styles.menuWrapper} ${
          config.isDropdownOpen ? styles['menuWrapper--open'] : ''
        }`}
      >
        {/* this part of code will be removed once we move to new design for navigating to page */}
        {!shouldPopUp ? (
          <div className={`${styles.menuTrack} ${styles[slideStyles]}`}>
            <div className={styles.menuLeft}>
              <p className={styles.label}>{config.title}</p>
              <ul className={styles['menuItem--wrapper']}>{get(config, ['options'], []).map(renderMenuItemOption)}</ul>
            </div>
            {/* child menu */}
            <div className={styles.menuRight}>
              <div className={`${styles['submenu--collapse']} ${config.isDropdownOpen ? styles.submenu : ''}`}>
                <p className={styles.label}>
                  <img src={backArrow} className={styles.backArrow} alt="back" onClick={() => setShouldSlide('left')} />
                  {subMenu.title}
                </p>
                <ul className={`${styles['menuItem--wrapper']} ${stickyElement ? styles.stickyElement : ''}`}>
                  {get(subMenu, ['options'], []).map(
                    (option: IOptionConfig, index: number) =>
                      option.shouldRender && (
                        <li
                          id="submenu"
                          className={styles.menuItem}
                          key={index}
                          onClick={() => handleListItemClick(option)}
                        >
                          {renderSubmenu(option, index)}
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <aside className={`${styles['asideWrapper']}`}>
            <div className={`${styles['titleContainer']}`}>
              <div className={`${styles['titleWrapper']}`}>{get(subMenu, ['title'], '')}</div>
            </div>
            <div className={`${styles['submenuContainer']}`}>
              {get(subMenu, ['options'], []).map(
                (option: IOptionConfig, index: number) =>
                  option.shouldRender && (
                    <div id="submenu" className={`${styles['submenuWrapper']}`} key={index}>
                      {renderSubmenu(option, index, true)}
                    </div>
                  )
              )}
            </div>
          </aside>
        )}
      </div>
    </div>
  );
};
