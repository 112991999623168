import React from 'react';
import closeSvg from './close.svg';
interface IProps {
  onCloseClick?: IOnCloseFn;
  className?: string;
}
type IOnCloseFn = (args: any) => void;
const CloseIcon = ({ onCloseClick, className }: IProps) => {
  return (
    <button aria-label="close modal" onClick={onCloseClick} className={className}>
      <img src={closeSvg} alt="close icon" />
    </button>
  );
};
export default CloseIcon;
