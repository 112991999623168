import { IDomainList } from '../../../utils/interfaces';
import { PAGEBUILDER_SET_DOMAIN_LIST } from './../configReducer/actions';
const domainListReducer = (
  state: IDomainList[] = [],
  action: {
    payload: IDomainList[];
    type: string;
  }
) => {
  switch (action.type) {
    case PAGEBUILDER_SET_DOMAIN_LIST:
      return action.payload;
    default:
      return state;
  }
};

export { domainListReducer };
