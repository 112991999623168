import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updatePageSettings } from '../../actions/builder-action';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FieldLabel } from '@quintype/em/components/field-label';
import { TextField } from '@quintype/em/components/text-field';
import { Separator } from '../../atoms/separator';
import styles from './styles.module.css';

interface IDataItems {
  type: string;
  label: string;
  key: string;
  parentKey?: string;
  defaultValue?: string;
  placeHolder?: string;
}

export const ManageSubscriptionSettings = (): any => {
  const dispatch = useDispatch();

  const onChangeHandler = ({ key, value }: any) => {
    dispatch(updatePageSettings({ [key]: value }, 'manage-subscriptions'));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const manageSubscriptionsSettings = useSelector(state =>
    get(state, ['builder', 'config', 'manage-subscriptions'], {})
  );
  const { pageSetting = {} } = manageSubscriptionsSettings;

  const settings = [
    {
      label: 'Dropdown Menu Text',
      type: 'label',
      key: ''
    },
    {
      label: 'Manage Subscriptions',
      type: 'textField',
      key: 'manageSubscriptionsDropdownText',
      placeHolder: 'Manage Subscriptions'
    },
    {
      label: 'Separator',
      key: 'separator',
      type: 'separator'
    },
    {
      label: 'Error Handling',
      type: 'label',
      key: ''
    },
    {
      label: 'Something went wrong, please try after sometime.',
      type: 'textField',
      key: 'errorMessage',
      placeHolder: 'Something went wrong, please try after sometime.'
    }
  ];

  return settings.map((item: IDataItems) => {
    const { type, label, key, defaultValue, placeHolder } = item || {};

    switch (type) {
      case 'quillEditor':
        const modules = {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }]
          ]
        };

        const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet'];
        return (
          <div className={styles.quillWrapper}>
            <FieldLabel label={label} />
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={get(pageSetting, [key]) || defaultValue}
              onChange={(value: any) => onChangeHandler({ key, value })}
            />
          </div>
        );
      case 'separator':
        return <Separator />;
      case 'label':
        return (
          <div className={styles.quillWrapper}>
            <FieldLabel label={label} />
          </div>
        );
      case 'textField':
        return (
          <TextField
            label={label}
            placeholder={placeHolder}
            value={get(pageSetting, [key]) || defaultValue}
            onChange={(value: any) => onChangeHandler({ key, value })}
          />
        );
    }
  });
};
