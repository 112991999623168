export const subscriptionsWithSwitchablePlansData = {
  subscription: {
    id: 333203,
    subscriber_id: 310480,
    subscription_plan_id: 68762,
    created_at: '2024-02-28T04:27:52.851Z',
    updated_at: '2024-02-28T04:27:52.851Z',
    assets: [
      {
        title: 'full-site',
        metadata: {},
        type: 'site',
        published_at: {}
      }
    ],
    start_timestamp: '2024-02-28T04:27:51.695Z',
    end_timestamp: '2024-03-28T04:27:51.695Z',
    deleted_at: null,
    payment_id: 334471,
    metadata: {
      mobile_number: '1234'
    },
    external_id: null,
    trial_period_length: null,
    trial_period_unit: null,
    campaign_id: null,
    plan_amount_cents: 75000,
    plan_amount_currency: 'INR',
    duration_unit: 'months',
    duration_length: 1,
    plan_name: 'Monthly SILVER plan',
    plan_description: 'Monthly plan with SILVER privileges',
    group_name: 'Upgrade Downgrade test group',
    group_description: 'Upgrade Downgrade test group',
    subscription_type: 'standard',
    plan_occurrence: 'One Time',
    subscription_attempt_id: 447068,
    renewal_reminder_sent_date: null,
    dynamic_assets: [
      {
        id: '',
        title: '',
        slug: ''
      }
    ],
    coupon_discount_id: null,
    notes: null,
    account_id: 9,
    old_assets: null,
    user_limit: null,
    allowed_ips: null,
    access_type: null,
    paid_trial_amount_cents: null,
    subscriber_name: 'Amogh Sahasrabhojanee',
    preferred_identity: {
      provider: 'email',
      value: 'amogh@quintype.com'
    },
    status: 'active',
    original_attempt_token: '3qBw8qSpuZaHoAMsPt3EFhWS',
    upgrade_plan_details: [
      {
        id: 68767,
        title: 'Student',
        duration_length: 1,
        duration_unit: 'month',
        price_cents: 42500,
        price_currency: 'INR',
        prices: {
          INR: {
            amount_cents: 42500
          }
        },
        recurring: false,
        supported_payment_providers: ['razorpay', 'paypal', 'stripe'],
        metadata: {},
        payment_provider: 'razorpay',
        prorated_amount_cents: 42500,
        proration_enabled: false
      },
      {
        id: 68761,
        title: 'Digital All Access',
        duration_length: 1,
        duration_unit: 'month',
        price_cents: 69600,
        price_currency: 'INR',
        prices: {
          INR: {
            amount_cents: 69600
          }
        },
        recurring: false,
        supported_payment_providers: ['razorpay', 'paypal', 'stripe'],
        metadata: {},
        payment_provider: 'razorpay',
        prorated_amount_cents: 30000,
        proration_enabled: true
      }
    ],
    downgrade_plan_details: [],
    crossgrade_plan_details: []
  }
};

export const getSubscriptionData = [
  {
    id: 39356,
    account_id: 9,
    name: 'Upgrade Downgrade test group - Bi monthly onetime plans',
    description: 'Upgrade Downgrade test group containing bi monthly one time plans. Please do not edit',
    subscription_type: 'standard',
    public: true,
    created_at: '2024-03-05T07:20:54.358Z',
    updated_at: '2024-03-06T18:25:36.392Z',
    deleted_at: null,
    preferred_identity_provider: 'email',
    metadata_fields: [],
    old_assets: [],
    currency: 'USD',
    target_amount: null,
    target_amount_validation: false,
    target_amount_validation_enabled: false,
    api_ordering: null,
    campaign_active: false,
    custom_attributes: [],
    assets: [],
    display_assets: [],
    image_url: null,
    // image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1709749518450/sticker-mule-g2GU6MR0pR0-unsplash.jpg',
    subscription_plans: [
      {
        id: 68767,
        subscription_group_id: 39356,
        duration_length: 1,
        price_cents: 42500,
        price_currency: 'INR',
        created_at: '2024-03-05T07:31:29.827Z',
        updated_at: '2024-03-09T09:31:57.671Z',
        duration_unit: 'month',
        description:
          '<ul><li>Funny interviews with entertaining personalities.</li>\n<li>Receive a daily or weekly digest of off beat news stories</li>\n<li>Humorous commentary on current events.</li>\n</ul>\n',
        title: 'Student',
        max_trial_period_length: null,
        max_trial_period_unit: null,
        recurring: false,
        metadata: {},
        deleted_at: null,
        enabled: true,
        trial_period_enabled: false,
        supported_payment_providers: ['razorpay', 'paypal', 'stripe'],
        user_limit: null,
        trial_period_type: null,
        custom_attributes: [],
        bundle_id: null,
        metadata_backup: {},
        assets: [
          {
            title: 'full-site',
            metadata: {},
            type: 'site',
            published_at: {}
          }
        ],
        display_assets: [
          {
            title: 'full-site',
            metadata: {},
            type: 'site',
            published_at: {}
          }
        ],
        paid_trial_price_cents: null,
        image_url: null
        // image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1709748381431/jingming-pan-iYsrkq5qq0Q-unsplash.jpg'
      }
    ]
  },
  {
    id: 39355,
    account_id: 9,
    name: 'Upgrade Downgrade test group',
    description: 'Upgrade Downgrade test group without proration please do not edit',
    subscription_type: 'standard',
    public: true,
    created_at: '2024-02-27T11:59:45.915Z',
    updated_at: '2024-03-06T09:27:08.280Z',
    deleted_at: null,
    preferred_identity_provider: 'email',
    metadata_fields: [],
    old_assets: [],
    currency: 'USD',
    target_amount: null,
    target_amount_validation: false,
    target_amount_validation_enabled: false,
    api_ordering: null,
    campaign_active: false,
    custom_attributes: [],
    assets: [],
    display_assets: [],
    image_url: null,
    subscription_plans: [
      {
        id: 68761,
        subscription_group_id: 39355,
        duration_length: 1,
        price_cents: 69600,
        price_currency: 'INR',
        created_at: '2024-02-27T12:03:11.318Z',
        updated_at: '2024-03-06T09:14:10.215Z',
        duration_unit: 'month',
        description:
          '<ul><li>Funny interviews with entertaining personalities.</li>\n<li>Receive a daily or weekly digest of off beat news stories</li>\n<li>Humorous commentary on current events.</li>\n</ul>\n',
        title: 'Digital All Access',
        max_trial_period_length: null,
        max_trial_period_unit: null,
        recurring: false,
        metadata: {},
        deleted_at: null,
        enabled: true,
        trial_period_enabled: false,
        supported_payment_providers: ['razorpay', 'paypal', 'stripe'],
        user_limit: null,
        trial_period_type: null,
        custom_attributes: [
          {
            name: 'Tag',
            type: 'rich_text',
            value:
              '\u003cp\u003e\u003cstrong\u003e\u003cem\u003eRecommended\u003c/em\u003e\u003c/strong\u003e\u003c/p\u003e'
          },
          {
            name: 'Actual Price',
            type: 'rich_text',
            value: '<p><s>INR 1200</s></p>'
          },
          {
            name: 'Discount',
            type: 'plain_text',
            value: '42% Off'
          }
        ],
        bundle_id: null,
        metadata_backup: {},
        assets: [
          {
            title: 'full-site',
            metadata: {},
            type: 'site',
            published_at: {}
          }
        ],
        display_assets: [
          {
            title: 'full-site',
            metadata: {},
            type: 'site',
            published_at: {}
          }
        ],
        paid_trial_price_cents: null,
        image_url: null
      }
    ]
  }
];
