import { IAppDownloadLinksPayload } from '../utils/interfaces';
import { PAGEBUILDER_GENERAL_UPDATE_APP_DOWNLOAD_LINKS_SETTINGS } from '../reducers/builder/configReducer/actions';

interface IArgs {
  type: string;
  payload: IAppDownloadLinksPayload;
}
type IDispatch = (args: IArgs) => void;

const updateAppDownloadLinksGeneralSettings = (dispatch: IDispatch, payload: IAppDownloadLinksPayload) => {
  dispatch({ type: PAGEBUILDER_GENERAL_UPDATE_APP_DOWNLOAD_LINKS_SETTINGS, payload });
};

export default updateAppDownloadLinksGeneralSettings;
