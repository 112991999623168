export const AHEAD_COMPONENT_LIBRARY_LOADED = 'AHEAD.COMPONENT_LIBRARY_LOADED';
const libraryLoaderReducer = (state: boolean = false, action: { type: string; loaded: boolean }) => {
  switch (action.type) {
    case AHEAD_COMPONENT_LIBRARY_LOADED:
      return action.loaded || false;
    default:
      return state;
  }
};

export { libraryLoaderReducer };
