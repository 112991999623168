import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updatePageSettings } from '../../actions/builder-action';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';
import './pageLayout.m.css';

const SelectPageLayoutType = () => {
  const dispatch = useDispatch();
  const configPath = useConfigPathFromStore();
  const layoutDataType = useSelector(state => {
    return get(state, ['builder', 'config', ...configPath, 'pageSetting', 'layoutDataType'], 'storiesAndCollections');
  });
  const updatePageLayout = (layoutDataTypeValue: string) => {
    dispatch(updatePageSettings({ layoutDataType: layoutDataTypeValue }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  return (
    <Radio
      name="pageType"
      selected={layoutDataType}
      align="vertical"
      onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) =>
        updatePageLayout((e.target as HTMLInputElement).value)
      }
    >
      <RadioOption id="1" value="collections">
        Collection of Collections
      </RadioOption>
      <p className="text">Each row picks stories from different child-collections.</p>
      <RadioOption id="2" value="storiesAndCollections">
        Collection of Stories
      </RadioOption>
      <p className="text">
        All rows pick stories from a single collection. Individual rows can also be separately mapped to other
        collections.
      </p>
    </Radio>
  );
};

export default SelectPageLayoutType;
