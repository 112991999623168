import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { TextField } from '@quintype/em/components/text-field';
import { ThemeSetter } from '../theme-setter';
import { Separator } from '../../atoms/separator';
import { sponsoredStoryStyle } from '../../actions/builder-action';

export function SponsoredStory() {
  const dispatch = useDispatch();
  const {
    sponsoredStoryRowLabel = '',
    sponsoredStoryPageLabel = '',
    sponsoredLabelColor = '',
    sponsoredLabelDarkColor = ''
  } = useSelector((state: any) => get(state, ['builder', 'config', 'general', 'sponsoredStoryConfig'], {}));

  const { enableDarkModePreview = false } = useSelector((state: any) => get(state, ['builder', 'config']));

  return (
    <>
      <TextField
        placeholder="Sponsored"
        label="Label On Story Cards"
        onChange={(value: any) => dispatch(sponsoredStoryStyle({ key: 'sponsoredStoryRowLabel', value }))}
        value={sponsoredStoryRowLabel}
      />
      <TextField
        placeholder="Sponsored by:"
        label="Label On Story Pages"
        onChange={(value: any) => dispatch(sponsoredStoryStyle({ key: 'sponsoredStoryPageLabel', value }))}
        value={sponsoredStoryPageLabel}
      />
      <ThemeSetter
        label="Label color"
        updateTheStore={(key: string, value: string) => dispatch(sponsoredStoryStyle({ key, value }))}
        lightColorExtract={sponsoredLabelColor}
        lightUpdateStoreKey="sponsoredLabelColor"
        lightDefaultColor="#E6E6E6"
        darkColorExtract={sponsoredLabelDarkColor}
        darkDefaultColor="#404040"
        darkUpdateStoreKey="sponsoredLabelDarkColor"
        enableDarkModePreview={enableDarkModePreview}
      />
      <Separator />
    </>
  );
}
