interface ICompMeta {
  componentName: string;
  image: string;
}
type IComponentMeta = [ICompMeta] | [];
export const AHEAD_UPDATE_COMPONENT_LIBRARY_META = 'AHEAD.UPDATE_COMPONENT_LIBRARY_META';
const componentMetaDataReducer = (state: IComponentMeta = [], action: { type: string; payload: IComponentMeta }) => {
  switch (action.type) {
    case AHEAD_UPDATE_COMPONENT_LIBRARY_META:
      return action.payload;
    default:
      return state;
  }
};

export { componentMetaDataReducer };
