import get from 'lodash/get';
export const AHEAD_MERGE_PAGEBUILDER_CONFIG_TO_QT_CONFIG = 'AHEAD.MERGE_PAGEBUILDER_CONFIG_TO_QT_CONFIG';
export const BOLD_UPDATE_LANGUAGE = 'BOLD.UPDATE_LANGUAGE';

const boldReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case AHEAD_MERGE_PAGEBUILDER_CONFIG_TO_QT_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          'pagebuilder-config': {
            ...get(state, ['config', 'pagebuilder-config']),
            ...(action.payload as object)
          }
        }
      };

    case BOLD_UPDATE_LANGUAGE:
      return {
        ...state,
        config: {
          ...state.config,
          language: action.payload
        }
      };
    default:
      return state;
  }
};

export default boldReducer;
