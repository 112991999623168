import { Checkbox } from '@quintype/em/components/checkbox';
import { FieldLabel } from '@quintype/em/components/field-label';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateInfiniteScroll } from '../../actions/infinite-scroll-action';
import styles from './infinite-scroll-settings.module.css';

const InfiniteScrollSettings = () => {
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const defaultCollectionSlug = 'curated-story-list';
  const dispatch = useDispatch();
  const {
    infiniteScrollType,
    infiniteScrollStyle,
    collectionSlug = defaultCollectionSlug,
    buttonText,
  } = useSelector((state) => get(state, ['builder', 'config', 'infiniteScroll'], {}));

  useEffect(() => {
    if (infiniteScrollType === 'disabled') {
      setIsScrollEnabled(false);
    } else {
      setIsScrollEnabled(true);
    }
  }, [infiniteScrollType]);

  const handleScrollPermission = (value: boolean) => {
    if (!value) {
      dispatch(updateInfiniteScroll({ infiniteScrollType: 'disabled', infiniteScrollStyle: 'default' }));
    } else {
      //  reset to initial state
      dispatch(
        updateInfiniteScroll({
          infiniteScrollType: 'section-based',
          infiniteScrollStyle: 'default',
          collectionSlug: ''
        })
      );
    }
  };

  const handleScrollTypeBySelection = (value: string) => dispatch(updateInfiniteScroll({ infiniteScrollType: value }));

  const handleCollectionSlug = (value: string) => dispatch(updateInfiniteScroll({ collectionSlug: value.trim() }));

  const handleScrollStyle = (value: string) => dispatch(updateInfiniteScroll({ infiniteScrollStyle: value }));

  const handleButtonText = (value: string) => dispatch(updateInfiniteScroll({ buttonText: value }));

  return (
    <>
      <div className={styles['checkbox-wrapper']}>
        <Checkbox
          label="Infinite scroll"
          id="infiniteScroll"
          checked={isScrollEnabled}
          onChange={handleScrollPermission}
        />
      </div>
      {isScrollEnabled && (
        <>
          <FieldLabel label="Infinite Scroll Type" />
          <Radio
            name="infiniteScrollType"
            selected={infiniteScrollType}
            onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) =>
              handleScrollTypeBySelection((e.target as HTMLInputElement).value)
            }
          >
            <RadioOption id="1" value="section-based">
              Section Based
            </RadioOption>
            <RadioOption id="2" value="collection-based">
              Collection Based
            </RadioOption>
          </Radio>

          {infiniteScrollType === 'collection-based' && (
            <>
              <TextField
                classname={styles['wide-textfield']}
                label="Collection Slug"
                value={collectionSlug}
                placeholder={defaultCollectionSlug}
                onChange={handleCollectionSlug}
              />
              <p className={styles.text}>
                The curated list of stories following the main story will be replaced with stories from the "
                {collectionSlug || defaultCollectionSlug}" collection.
              </p>
            </>
          )}

          {infiniteScrollType === 'section-based' && (
            <p className={styles.text}>
              The curated list of stories following the main story will be replaced with stories from the respective
              mapped section.
            </p>
          )}

          <FieldLabel classname={styles['field-lable']} label="Loaded Story Style" />
          <Radio
            name="infiniteScrollStyle"
            align="vertical"
            selected={infiniteScrollStyle}
            onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) =>
              handleScrollStyle((e.target as HTMLInputElement).value)
            }
          >
            <RadioOption id="1" value="default">
              Full Story
            </RadioOption>
            <p className={styles.text}>The whole story will be loaded one after other.</p>
            <RadioOption id="2" value="read-more">
              Story Cards
            </RadioOption>
            <p className={styles.text}>
              Stories will be loaded as cards with image, title and a portion of content with load more button that
              takes to story.
            </p>
          </Radio>
          {infiniteScrollStyle === 'read-more' && (
            <div className={styles['button-text-wrapper']}>
              <TextField
                label="Button Text"
                placeholder="Read More"
                value={buttonText}
                onChange={(value: any) => handleButtonText(value)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InfiniteScrollSettings;
