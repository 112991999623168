import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Plus: React.FunctionComponent<IsvgData> = ({ width = '24px', height = '24px', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={color} fillRule="evenodd" d="M12.5 11.25h6.75v1H12.5V19h-1v-6.75H4.75v-1h6.75V4.5h1v6.75z" />
    </svg>
  );
};

export { Plus };
