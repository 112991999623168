import { Checkbox } from '@quintype/em/components/checkbox';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateGeneralBreakingNews } from '../../actions/general-settings-action';
import { IPBState } from '../../utils/interfaces';

const BreakingNewsVisibilitySettings = () => {
  const isBreakingNewsEnabled = useSelector((state: IPBState) =>
    get(state, ['builder', 'config', 'general', 'breakingNews', 'isEnabled'], false)
  );
  const dispatch = useDispatch();
  const handleVisibilityChange = (value: boolean) => dispatch(updateGeneralBreakingNews({ isEnabled: value }));
  return (
    <div data-test-id="enable-breaking-news">
      <Checkbox
        label="Enable Breaking News"
        id="enableBreakingNews"
        checked={isBreakingNewsEnabled}
        onChange={handleVisibilityChange}
      />
    </div>
  );
};

export default BreakingNewsVisibilitySettings;
