import { ToolTip } from '@quintype/em/components/tooltip';
import { InfoCircle } from '@quintype/em/icons/info-circle';
import React from 'react';
import styles from './info-tooltip.module.css';

interface IProps {
  width: number;
  height: number;
  tooltipDescription: string;
  tooltipId: string;
  tooltipPlace: 'top' | 'right' | 'bottom' | 'left' | undefined;
}

export const InfoTooltip = ({ width, height, tooltipDescription, tooltipId, tooltipPlace }: IProps) => {
  return (
    <div className={styles.wrapper} style={{ height }} data-for={tooltipId} data-tip={true}>
      <InfoCircle width={width} height={height} />
      <ToolTip
        value={tooltipDescription}
        id={tooltipId}
        place={tooltipPlace}
        effect={'solid'}
        type={'dark'}
        classname={'wrapToolTip'}
      />
    </div>
  );
};
