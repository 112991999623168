import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Reset: React.FunctionComponent<IsvgData> = ({ width = '24px', height = '24px', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fill-rule="evenodd"
        d="M5.82 11.75a.316.316 0 00.233-.096.287.287 0 00.095-.205c.055-1.02.351-1.955.89-2.802a5.895 5.895 0 012.118-2.01A5.704 5.704 0 0112 5.898c1.112 0 2.133.288 3.062.862a5.722 5.722 0 012.133 2.31h-3.117a.316.316 0 00-.232.096.316.316 0 00-.096.232v.274c0 .091.032.169.096.232a.316.316 0 00.232.096h4.375a.316.316 0 00.233-.096.316.316 0 00.095-.232V5.297a.316.316 0 00-.095-.233.316.316 0 00-.233-.095h-.273a.316.316 0 00-.233.095.316.316 0 00-.095.233v3.035a6.812 6.812 0 00-2.461-2.447 6.524 6.524 0 00-3.364-.916 6.69 6.69 0 00-3.336.861 6.693 6.693 0 00-2.447 2.338 6.836 6.836 0 00-1.025 3.227c0 .109.032.196.095.26a.316.316 0 00.233.095h.273zm0 6.781a.316.316 0 00.233-.095.316.316 0 00.095-.233v-3.035a6.812 6.812 0 002.461 2.447c1.04.61 2.16.916 3.364.916a6.69 6.69 0 003.336-.861 6.693 6.693 0 002.447-2.338c.61-.984.952-2.06 1.025-3.227a.351.351 0 00-.095-.26.316.316 0 00-.233-.095h-.273a.316.316 0 00-.233.096.287.287 0 00-.095.205 5.715 5.715 0 01-.89 2.803 5.895 5.895 0 01-2.118 2.01 5.704 5.704 0 01-2.844.738 5.716 5.716 0 01-3.063-.862 5.722 5.722 0 01-2.132-2.31h3.117a.316.316 0 00.232-.096.316.316 0 00.096-.232v-.274a.316.316 0 00-.096-.232.316.316 0 00-.232-.096H5.547a.316.316 0 00-.233.096.316.316 0 00-.095.232v4.375c0 .091.032.169.095.233a.316.316 0 00.233.095h.273z"
      />
    </svg>
  );
};

export default Reset;
