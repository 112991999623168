import Select from '@quintype/em/components/select';
import axios from 'axios';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateHaveUnsavedChanges, updatePageSettings } from '../../actions/builder-action';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';
import styles from '../../molecules/row-inspector/arrow-row-setting/setting.module.css';
import { DropDown } from '../../utils/interfaces';

const SelectSectionTheme = () => {
  const configPath = useConfigPathFromStore();
  const pageConfig = useSelector(state => get(state, ['builder', 'config', ...configPath, 'pageSetting']));
  const pageType = useSelector(state => get(state, ['builder', 'pageType'], ''));
  const [sectionData, handleSectionData] = useState([]);
  const [sectionDataLoading, setSectionDataLoading] = useState('');
  const [shouldAssignToAllSections, handleAllSelected] = useState(get(pageConfig, ['shouldAssignToAllSections'], true));
  const { publisherId = '' } = useParams();
  const dispatch = useDispatch();

  const sectionDropdown = () => {
    if (pageType === 'section') return [];
    return sectionData.map((section: { [x: string]: any }) => {
      return {
        value: section['display-name'],
        label: section['display-name'],
        slug: section.slug,
        sectionId: section.id
      };
    });
  };
  const changeSelectedSections = (selectedOptions: DropDown) => {
    dispatch(updatePageSettings({ selectedOptions, shouldAssignToAllSections }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const getPublisherDomain = async () => {
    try {
      setSectionDataLoading('loading');
      const config = await axios.get(`/api/v1/publisher/${publisherId}/bold-config`);
      const { sections } = config.data;
      handleSectionData(sections);
      setSectionDataLoading('loaded');
      return sectionData;
    } catch (error) {
      console.log('Error', error);
    }
  };

  useEffect(() => {
    sectionData.length <= 0 && sectionDataLoading !== 'loading' && getPublisherDomain();
  }, [getPublisherDomain, sectionData.length, sectionDataLoading]);

  const getSectionData = pageType === 'section' ? [] : get(pageConfig, ['selectedOptions']);
  const placeholder = pageType === 'section' ? 'All sections' : 'Choose sections';
  return (
    <>
      <Select
        defaultValue={getSectionData}
        placeholder={placeholder}
        options={sectionDropdown()}
        isMulti
        onChange={changeSelectedSections}
        isDisabled={pageType === 'section'}
      />
      {pageType === 'section' && (
        <p className={styles.text}>This layout will be applied to all section pages by default.</p>
      )}
    </>
  );
};

export default SelectSectionTheme;
