import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { Checkbox } from '@quintype/em/components/checkbox';
import { FieldLabel } from '@quintype/em/components/field-label';
import styles from './story-demarcation.module.css';
import { videoStoryIconsData } from './icons';
import { storyDemarcationStyle } from '../../actions/builder-action';

export function VideoStoryDemarcation() {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dispatch = useDispatch();
  const demarcationType = 'videoDemarcationIcon';

  const { enableVideoDemarcationIcon } = useSelector((state: any) =>
    get(state, ['builder', 'config', 'general', 'rows', 'videoDemarcationIcon'], {})
  );

  const updateIconHandler = (name: string, index: number) => {
    setSelectedIndex(index);
    dispatch(storyDemarcationStyle({ demarcationType, iconStyle: name }));
  };

  return (
    <>
      <div className={styles.checkBoxWrapper} data-test-id="video-story-demarcation-checkbox">
        <Checkbox
          label="Video Story Demarcation"
          id="video-story-demarcation-Icon"
          checked={enableVideoDemarcationIcon}
          onChange={(value: any) =>
            dispatch(storyDemarcationStyle({ demarcationType, enableVideoDemarcationIcon: value }))
          }
        />
      </div>
      <FieldLabel label="Choose Icon style" />
      <div className={styles.iconWrapper}>
        {videoStoryIconsData.map((item: { name: string; svg: string }, index: number) => (
          <div
            onClick={() => updateIconHandler(item.name, index)}
            className={selectedIndex === index ? styles.selectedImgWrapper : styles.imageWrapper}
            data-test-id={item.name}
            key={`video-icon-${item.name}`}
          >
            <img src={item.svg} className={styles.image} alt="Video Story Demarcation Icon style" />
          </div>
        ))}
      </div>
    </>
  );
}
