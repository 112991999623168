import { TextField } from '@quintype/em/components/text-field';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updatePageSettings } from '../../actions/builder-action';
import { PAGEBUILDER_GENERAL_UPDATE_LOCALIZATION } from '../../reducers/builder/configReducer/actions';
import { Separator } from '../../atoms/separator';

interface IOptions {
  label: string;
  value: string;
  countryIso: string;
}

interface IDataItems {
  type: string;
  label: string;
  key: string;
  defaultValue?: string | boolean | IOptions;
  placeHolder?: string;
  darkKey?: string;
  options?: IOptions[];
}

export const PurchasedStorySettings = () => {
  const dispatch = useDispatch();
  const { purchasedStoryPage = {} } = useSelector(state => get(state, ['builder', 'config'], {}));
  const updateField = (key: string, value: string | boolean) => {
    dispatch(updatePageSettings({ [key]: value }, 'purchasedStoryPage'));
    if (key === 'dropdownPurchasedText') {
      const payload = { buttonLabels: { [key]: value } };
      dispatch({ type: PAGEBUILDER_GENERAL_UPDATE_LOCALIZATION, payload });
    }
    dispatch(updateHaveUnsavedChanges(true));
  };
  const setting = [
    {
      label: 'Purchased Stories Page',
      type: 'textField',
      key: 'dropdownPurchasedText',
      placeHolder: 'Purchased Stories',
      defaultValue: 'Purchased Stories'
    },
    {
      label: '',
      type: 'dashedSeparator',
      key: '',
      placeHolder: '',
      defaultValue: ''
    },
    {
      label: 'You’ve not purchased any stories yet.',
      type: 'textField',
      key: 'emptyStoriesLabel',
      placeHolder: 'You’ve not purchased any stories yet.',
      defaultValue: 'You’ve not purchased any stories yet.'
    },
    {
      label: 'Go to Home',
      type: 'textField',
      key: 'redirectHomePage',
      placeHolder: 'Go to Home',
      defaultValue: 'Go to Home'
    }
  ];

  return (
    <>
      <h4>Dropdown Menu Text</h4>
      {setting.map((item: IDataItems) => {
        const { pageSetting = {} } = purchasedStoryPage;
        const { type, label, key, placeHolder } = item || {};
        switch (type) {
          case 'dashedSeparator':
            return <Separator />;
          case 'textField':
            return (
              <TextField
                label={label}
                placeholder={placeHolder}
                value={get(pageSetting, [key])}
                onChange={(value: any) => updateField(key, value)}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
};
