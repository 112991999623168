export const MAGAZINE_PAGE_DATA = [
  {
    collection: {
      "id": 155000,
      "name": "Contest 2021",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-08/e95aa857-e5d6-46c1-b2bc-8fae55cc8457/Group_14.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 441781,
            "file-name": "Group 14.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-08/e95aa857-e5d6-46c1-b2bc-8fae55cc8457/Group_14.png",
          "cover-image-s3-key": "ace/2021-08/e95aa857-e5d6-46c1-b2bc-8fae55cc8457/Group_14.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "description": "Forbes is an American business magazine owned by Integrated Whale Media Investments and the Forbes family. Published eight times a year, it features original articles on finance, industry, investing, and marketing topics",
                "updated-at": 1626177800100,
                "slug": "forbes-2",
                "publisher-id": 97,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382,
                "deleted-at": null,
                "created-by": 425675,
                "id": 209270,
                "last-updated-by": 425675,
                "created-at": 1624955696398
              }
            ]
          }
        }
      },
      "collection-date": 1627948800000,
      "slug": "contest-2021",
      "template": "default",
      "data-source": "automated",
      "created-at": 1627902390454
    },
  },
  {
    collection: {
      "id": 154999,
      "name": "Western Ghats",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-08/4b043c5f-999e-40e4-b7ba-a2953b73d609/Group_16.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 427408,
            "file-name": "Group 16.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-08/4b043c5f-999e-40e4-b7ba-a2953b73d609/Group_16.png",
          "cover-image-s3-key": "ace/2021-08/4b043c5f-999e-40e4-b7ba-a2953b73d609/Group_16.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "description": "Forbes is an American business magazine owned by Integrated Whale Media Investments and the Forbes family. Published eight times a year, it features original articles on finance, industry, investing, and marketing topics",
                "updated-at": 1626177800100,
                "slug": "forbes-2",
                "publisher-id": 97,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382,
                "deleted-at": null,
                "created-by": 425675,
                "id": 209270,
                "last-updated-by": 425675,
                "created-at": 1624955696398
              }
            ]
          }
        }
      },
      "collection-date": 1627862400000,
      "slug": "western-ghats",
      "template": "default",
      "data-source": "automated",
      "created-at": 1627902330851
    },
  },
  {
    collection: {
      "id": 153449,
      "name": "The Elephant",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/130ae1fc-fd63-422a-9688-964464334f50/Group_11.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 411839,
            "file-name": "Group 11.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/130ae1fc-fd63-422a-9688-964464334f50/Group_11.png",
          "cover-image-s3-key": "ace/2021-07/130ae1fc-fd63-422a-9688-964464334f50/Group_11.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "description": "Forbes is an American business magazine owned by Integrated Whale Media Investments and the Forbes family. Published eight times a year, it features original articles on finance, industry, investing, and marketing topics",
                "updated-at": 1626177800100,
                "slug": "forbes-2",
                "publisher-id": 97,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382,
                "deleted-at": null,
                "created-by": 425675,
                "id": 209270,
                "last-updated-by": 425675,
                "created-at": 1624955696398
              }
            ]
          }
        }
      },
      "collection-date": 1626825600000,
      "slug": "forbes-12",
      "template": "default",
      "data-source": "manual",
      "created-at": 1626418835412
    }
  },
  {
    collection: {
      "id": 153451,
      "name": "Billionaires",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/c2b0cd41-ac91-455b-a39e-6ebeff7fa304/Group_12.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 469784,
            "file-name": "Group 12.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/c2b0cd41-ac91-455b-a39e-6ebeff7fa304/Group_12.png",
          "cover-image-s3-key": "ace/2021-07/c2b0cd41-ac91-455b-a39e-6ebeff7fa304/Group_12.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "id": 209270,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382
              }
            ]
          }
        }
      },
      "collection-date": 1626998400000,
      "slug": "forbes-14",
      "template": "default",
      "data-source": "manual",
      "created-at": 1626419116499
    },
  },
  {
    collection: {
      "id": 153450,
      "name": "Auto Trends",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/79458055-d2c9-4542-ba9c-3c51ee96530e/Group_6.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 482510,
            "file-name": "Group 6.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/79458055-d2c9-4542-ba9c-3c51ee96530e/Group_6.png",
          "cover-image-s3-key": "ace/2021-07/79458055-d2c9-4542-ba9c-3c51ee96530e/Group_6.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "id": 209270,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382
              }
            ]
          }
        }
      },
      "collection-date": 1626912000000,
      "slug": "forbes-13",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626419065510
    },
  },
  {
    collection: {
      "id": 153447,
      "name": "Financial Dreams",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/ab1b2d84-eff6-4694-8e4d-53eadfb006d0/Group_9.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 643853,
            "file-name": "Group 9.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/ab1b2d84-eff6-4694-8e4d-53eadfb006d0/Group_9.png",
          "cover-image-s3-key": "ace/2021-07/ab1b2d84-eff6-4694-8e4d-53eadfb006d0/Group_9.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "description": "Forbes is an American business magazine owned by Integrated Whale Media Investments and the Forbes family. Published eight times a year, it features original articles on finance, industry, investing, and marketing topics",
                "updated-at": 1626177800100,
                "slug": "forbes-2",
                "publisher-id": 97,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382,
                "deleted-at": null,
                "created-by": 425675,
                "id": 209270,
                "last-updated-by": 425675,
                "created-at": 1624955696398
              }
            ]
          }
        }
      },
      "collection-date": 1626652800000,
      "slug": "forbes-10",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626418628250
    },
  },
  {
    collection: {
      "id": 153448,
      "name": "Greater America",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 505274,
            "file-name": "Group 10.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png",
          "cover-image-s3-key": "ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "description": "Forbes is an American business magazine owned by Integrated Whale Media Investments and the Forbes family. Published eight times a year, it features original articles on finance, industry, investing, and marketing topics",
                "updated-at": 1626177800100,
                "slug": "forbes-2",
                "publisher-id": 97,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382,
                "deleted-at": null,
                "created-by": 425675,
                "id": 209270,
                "last-updated-by": 425675,
                "created-at": 1624955696398
              }
            ]
          }
        }
      },
      "collection-date": 1626739200000,
      "slug": "forbes-11",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626418731548
    },
  },
  {
    collection: {
      "id": 153141,
      "name": "Unearthed Axis",
      "metadata": {
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "id": 209270,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382
              }
            ]
          }
        },
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 569846,
            "file-name": "Group 3.png",
            "focus-point": [
              263,
              385
            ]
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png",
          "cover-image-s3-key": "ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png"
        }
      },
      "collection-date": 1626393600000,
      "slug": "forbes-9",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626151330341
    },
  },
  {
    "collection": {
      "id": 106137,
      "name": "High Times",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 682261,
            "file-name": "Group 4.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png",
          "cover-image-s3-key": "ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'high-times',
      template: 'collection',
      'data-source': 'manual',
      'created-at': 1599639711992
    }
  },
  {
    "collection": {
      "id": 106131,
      "name": "The Week",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 476925,
            "file-name": "Group 5.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png",
          "cover-image-s3-key": "ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'the-week',
      template: 'collection',
      'data-source': 'manual',
      'created-at': 1599616208142
    }
  },
  {
    "collection": {
      "id": 106131,
      "name": "Forbes",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/74157ce2-dd94-4e56-8702-432ebf1765ea/Group_7.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 502909,
            "file-name": "Group 7.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/74157ce2-dd94-4e56-8702-432ebf1765ea/Group_7.png",
          "cover-image-s3-key": "ace/2021-07/74157ce2-dd94-4e56-8702-432ebf1765ea/Group_7.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      "collection-date": null,
      "slug": "the-week",
      "template": "collection",
      "data-source": "manual",
      "created-at": 1599616208142
    }
  },
  {
    collection: {
      id: 35865,
      name: 'The Count',
      metadata: {
        section: [
          {
            id: 24565,
            name: 'The Count'
          }
        ],
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/f34b4cc7-ec60-4d86-ae37-2e364a2fc634/Group_8.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 536236,
            "file-name": "Group 8.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/f34b4cc7-ec60-4d86-ae37-2e364a2fc634/Group_8.png",
          "cover-image-s3-key": "ace/2021-07/f34b4cc7-ec60-4d86-ae37-2e364a2fc634/Group_8.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'the-count',
      template: 'section',
      'data-source': 'automated',
      'created-at': 1550146188863
    }
  },
  {
    collection: {
      "id": 153448,
      "name": "Greater America",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 505274,
            "file-name": "Group 10.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png",
          "cover-image-s3-key": "ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "description": "Forbes is an American business magazine owned by Integrated Whale Media Investments and the Forbes family. Published eight times a year, it features original articles on finance, industry, investing, and marketing topics",
                "updated-at": 1626177800100,
                "slug": "forbes-2",
                "publisher-id": 97,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382,
                "deleted-at": null,
                "created-by": 425675,
                "id": 209270,
                "last-updated-by": 425675,
                "created-at": 1624955696398
              }
            ]
          }
        }
      },
      "collection-date": 1626739200000,
      "slug": "forbes-11",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626418731548
    },
  },
  {
    collection: {
      "id": 153141,
      "name": "Unearthed Axis",
      "metadata": {
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "id": 209270,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382
              }
            ]
          }
        },
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 569846,
            "file-name": "Group 3.png",
            "focus-point": [
              263,
              385
            ]
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png",
          "cover-image-s3-key": "ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png"
        }
      },
      "collection-date": 1626393600000,
      "slug": "forbes-9",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626151330341
    },
  },
  {
    "collection": {
      "id": 106137,
      "name": "High Times",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 682261,
            "file-name": "Group 4.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png",
          "cover-image-s3-key": "ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'high-times',
      template: 'collection',
      'data-source': 'manual',
      'created-at': 1599639711992
    }
  },
  {
    "collection": {
      "id": 106131,
      "name": "The Week",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 476925,
            "file-name": "Group 5.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png",
          "cover-image-s3-key": "ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'the-week',
      template: 'collection',
      'data-source': 'manual',
      'created-at': 1599616208142
    }
  },
  {
    "collection": {
      "id": 106131,
      "name": "Forbes",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/74157ce2-dd94-4e56-8702-432ebf1765ea/Group_7.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 502909,
            "file-name": "Group 7.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/74157ce2-dd94-4e56-8702-432ebf1765ea/Group_7.png",
          "cover-image-s3-key": "ace/2021-07/74157ce2-dd94-4e56-8702-432ebf1765ea/Group_7.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      "collection-date": null,
      "slug": "the-week",
      "template": "collection",
      "data-source": "manual",
      "created-at": 1599616208142
    }
  },
  {
    collection: {
      id: 35865,
      name: 'The Count',
      metadata: {
        section: [
          {
            id: 24565,
            name: 'The Count'
          }
        ],
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/f34b4cc7-ec60-4d86-ae37-2e364a2fc634/Group_8.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 536236,
            "file-name": "Group 8.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/f34b4cc7-ec60-4d86-ae37-2e364a2fc634/Group_8.png",
          "cover-image-s3-key": "ace/2021-07/f34b4cc7-ec60-4d86-ae37-2e364a2fc634/Group_8.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'the-count',
      template: 'section',
      'data-source': 'automated',
      'created-at': 1550146188863
    }
  },
  {
    collection: {
      "id": 153448,
      "name": "Greater America",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 505274,
            "file-name": "Group 10.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png",
          "cover-image-s3-key": "ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "description": "Forbes is an American business magazine owned by Integrated Whale Media Investments and the Forbes family. Published eight times a year, it features original articles on finance, industry, investing, and marketing topics",
                "updated-at": 1626177800100,
                "slug": "forbes-2",
                "publisher-id": 97,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382,
                "deleted-at": null,
                "created-by": 425675,
                "id": 209270,
                "last-updated-by": 425675,
                "created-at": 1624955696398
              }
            ]
          }
        }
      },
      "collection-date": 1626739200000,
      "slug": "forbes-11",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626418731548
    },
  },
  {
    collection: {
      "id": 153141,
      "name": "Unearthed Axis",
      "metadata": {
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "id": 209270,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382
              }
            ]
          }
        },
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 569846,
            "file-name": "Group 3.png",
            "focus-point": [
              263,
              385
            ]
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png",
          "cover-image-s3-key": "ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png"
        }
      },
      "collection-date": 1626393600000,
      "slug": "forbes-9",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626151330341
    },
  },
  {
    "collection": {
      "id": 106137,
      "name": "High Times",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 682261,
            "file-name": "Group 4.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png",
          "cover-image-s3-key": "ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'high-times',
      template: 'collection',
      'data-source': 'manual',
      'created-at': 1599639711992
    }
  },
  {
    "collection": {
      "id": 106131,
      "name": "The Week",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 476925,
            "file-name": "Group 5.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png",
          "cover-image-s3-key": "ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'the-week',
      template: 'collection',
      'data-source': 'manual',
      'created-at': 1599616208142
    }
  },
  {
    "collection": {
      "id": 106131,
      "name": "Forbes",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/74157ce2-dd94-4e56-8702-432ebf1765ea/Group_7.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 502909,
            "file-name": "Group 7.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/74157ce2-dd94-4e56-8702-432ebf1765ea/Group_7.png",
          "cover-image-s3-key": "ace/2021-07/74157ce2-dd94-4e56-8702-432ebf1765ea/Group_7.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      "collection-date": null,
      "slug": "the-week",
      "template": "collection",
      "data-source": "manual",
      "created-at": 1599616208142
    }
  },
  {
    collection: {
      id: 35865,
      name: 'The Count',
      metadata: {
        section: [
          {
            id: 24565,
            name: 'The Count'
          }
        ],
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/f34b4cc7-ec60-4d86-ae37-2e364a2fc634/Group_8.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 536236,
            "file-name": "Group 8.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/f34b4cc7-ec60-4d86-ae37-2e364a2fc634/Group_8.png",
          "cover-image-s3-key": "ace/2021-07/f34b4cc7-ec60-4d86-ae37-2e364a2fc634/Group_8.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'the-count',
      template: 'section',
      'data-source': 'automated',
      'created-at': 1550146188863
    }
  },
  {
    collection: {
      "id": 153448,
      "name": "Greater America",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 505274,
            "file-name": "Group 10.png"
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png",
          "cover-image-s3-key": "ace/2021-07/4ea31aad-1e70-4538-89f8-cd7458689875/Group_10.png"
        },
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "description": "Forbes is an American business magazine owned by Integrated Whale Media Investments and the Forbes family. Published eight times a year, it features original articles on finance, industry, investing, and marketing topics",
                "updated-at": 1626177800100,
                "slug": "forbes-2",
                "publisher-id": 97,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382,
                "deleted-at": null,
                "created-by": 425675,
                "id": 209270,
                "last-updated-by": 425675,
                "created-at": 1624955696398
              }
            ]
          }
        }
      },
      "collection-date": 1626739200000,
      "slug": "forbes-11",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626418731548
    },
  },
  {
    collection: {
      "id": 153141,
      "name": "Unearthed Axis",
      "metadata": {
        "entities": {
          "collectionEntities": {
            "magazine": [
              {
                "id": 209270,
                "name": "Forbes",
                "type": "magazine",
                "entity-type-id": 382
              }
            ]
          }
        },
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 569846,
            "file-name": "Group 3.png",
            "focus-point": [
              263,
              385
            ]
          },
          "extracted-data": {
            "artist": ""
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png",
          "cover-image-s3-key": "ace/2021-07/921d4c6d-9355-4baa-8251-e0e2298ed1ec/Group_3.png"
        }
      },
      "collection-date": 1626393600000,
      "slug": "forbes-9",
      "template": "default",
      "data-source": "automated",
      "created-at": 1626151330341
    },
  },
  {
    "collection": {
      "id": 106137,
      "name": "High Times",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png",
          "metadata": {
            "width": 474,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 682261,
            "file-name": "Group 4.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png",
          "cover-image-s3-key": "ace/2021-07/0a56c8b8-b403-4b41-9427-4fb7d2f450ef/Group_4.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'high-times',
      template: 'collection',
      'data-source': 'manual',
      'created-at': 1599639711992
    }
  },
  {
    "collection": {
      "id": 106131,
      "name": "The Week",
      "metadata": {
        "cover-image": {
          "success": true,
          "url": "https://qtstage-01.gumlet.com/ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png",
          "metadata": {
            "width": 475,
            "height": 654,
            "mime-type": "image/png",
            "file-size": 476925,
            "file-name": "Group 5.png"
          },
          'extracted-data': {
            artist: ''
          },
          "attribution": "",
          "cover-image-url": "https://qtstage-01.gumlet.com/ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png",
          "cover-image-s3-key": "ace/2021-07/56c0ebae-f9bf-48d3-af8a-1f34056f5ca6/Group_5.png"
        },
        entities: {
          collectionEntities: {
            magazine: [
              {
                id: 199563,
                name: 'New Magazine',
                type: 'magazine',
                'entity-type-id': 382
              }
            ]
          }
        }
      },
      'collection-date': null,
      slug: 'the-week',
      template: 'collection',
      'data-source': 'manual',
      'created-at': 1599616208142
    }
  }
];
