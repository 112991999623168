import { TextField } from '@quintype/em/components/text-field';
import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updatePageSettings } from '../../actions/builder-action';
import { ToggleButton } from '../toggle-button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FieldLabel } from '@quintype/em/components/field-label';
import { ThemeSetter } from '../theme-setter';
import { Separator } from '../../atoms/separator';
import { allCountries } from 'country-telephone-data';
import styles from '../subscription/subscription.module.css';
import Select from '@quintype/em/components/select';
import { updateMeterSettings } from '../../actions/builder-action';

interface IOptions {
  label: string;
  value: string;
  countryIso: string;
}

interface IDataItems {
  type: string;
  label: string;
  key: string;
  defaultValue?: string | boolean | IOptions;
  placeHolder?: string;
  darkKey?: string;
  options?: IOptions[];
}

export const LoginSettings = () => {
  const dispatch = useDispatch();
  const { login = {}, enableDarkModePreview = false } = useSelector(state => get(state, ['builder', 'config'], {}));
  const { pageSetting = {}, rows = [] } = login;
  const updateLogin = (key: string, value: string | boolean) => {
    dispatch(updatePageSettings({ [key]: value }, 'login'));

    switch (key) {
      case 'buttonColor':
        dispatch(updateMeterSettings({ key: 'loginButtonColor', value }));
        break;
      case 'darkButtonColor':
        dispatch(updateMeterSettings({ key: 'loginDarkButtonColor', value }));
        break;
    }

    dispatch(updateHaveUnsavedChanges(true));
  };

  const countryCodeOptions = allCountries.map((country: any, index: number) => {
    return {
      label: `${country?.name} (+${country?.dialCode})`,
      value: `+${country?.dialCode}`,
      countryIso: country?.iso2
    };
  });

  const setting = [
    {
      label: 'Authentication Methods',
      type: 'label',
      key: 'authMethods'
    },
    {
      label: 'Mobile & OTP',
      type: 'toggle',
      key: 'mobileOtpAuth'
    },
    {
      label: 'Google',
      type: 'toggle',
      key: 'googleAuth',
      defaultValue: true
    },
    {
      label: 'Facebook',
      type: 'toggle',
      key: 'facebookAuth',
      defaultValue: true
    },
    {
      label: 'LinkedIn',
      type: 'toggle',
      key: 'linkedInAuth',
      defaultValue: true
    },
    {
      label: 'Default Country Code',
      key: 'defaultCountryCode',
      type: 'select',
      options: countryCodeOptions
    },
    {
      type: 'colorPicker',
      label: 'Button Color',
      key: 'buttonColor',
      darkKey: 'darkButtonColor'
    },
    {
      label: 'Success Messages',
      type: 'label',
      key: 'successLabel'
    },
    {
      label: 'Sign in successful.',
      type: 'textField',
      key: 'loginSuccess',
      placeHolder: ''
    },
    {
      label: 'Sign up successful.',
      type: 'textField',
      key: 'signupSuccess',
      placeHolder: ''
    },
    {
      label: 'Password reset successful.',
      type: 'textField',
      key: 'passwordResetSuccess',
      placeHolder: ''
    },
    {
      label: 'Error Handling',
      type: 'label',
      key: 'errorLabel'
    },
    {
      label: 'Please enter a valid email address.',
      type: 'textField',
      key: 'emailError',
      placeHolder: ''
    },
    {
      label: 'Please enter a valid phone number.',
      type: 'textField',
      key: 'phoneError',
      placeHolder: ''
    },
    {
      label: 'Password entered is invalid.',
      type: 'textField',
      key: 'passwordError',
      placeHolder: ''
    },
    {
      label: 'OTP entered is invalid.',
      type: 'textField',
      key: 'otpError',
      placeHolder: ''
    },
    {
      label: 'Email already exists.',
      type: 'textField',
      key: 'existsError',
      placeHolder: ''
    },
    {
      label: 'Something went wrong, please try after sometime.',
      type: 'textField',
      key: 'genericError',
      placeHolder: ''
    }
  ];

  return (
    <>
      {setting.map((item: IDataItems) => {
        const { type, label, key, defaultValue, placeHolder, darkKey = '', options } = item || {};

        switch (type) {
          case 'quillEditor':
            const modules = {
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }]
              ]
            };
            const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet'];
            return (
              <div className={styles.quillWrapper}>
                <FieldLabel label={label} />
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={get(pageSetting, [key]) || defaultValue}
                  onChange={(value: any) => updateLogin(key, value)}
                  placeholder=" "
                />
              </div>
            );
          case 'textField':
            return (
              <TextField
                label={label}
                placeholder={placeHolder}
                value={get(pageSetting, [key]) || defaultValue}
                onChange={(value: any) => updateLogin(key, value)}
              />
            );
          case 'toggle':
            return (
              <div className={styles.toggleContainer}>
                <ToggleButton
                  label={label}
                  toggleName={key}
                  id={key}
                  updateToggleState={get(pageSetting, [key], defaultValue)}
                  updateToggleButton={(name: string, value: boolean) => updateLogin(name, value)}
                />
              </div>
            );
          case 'colorPicker':
            return (
              <div className={styles.colorPickerWrapper}>
                <ThemeSetter
                  label={label}
                  updateTheStore={updateLogin}
                  lightColorExtract={get(pageSetting, [key], defaultValue)}
                  lightUpdateStoreKey={key}
                  darkColorExtract={get(pageSetting, [darkKey], defaultValue)}
                  darkUpdateStoreKey={darkKey}
                  lightDefaultColor="#4860BC"
                  darkDefaultColor="#4860BC"
                  enableDarkModePreview={enableDarkModePreview}
                />
              </div>
            );
          case 'separator':
            return <Separator />;
          case 'select':
            return (
              <div className={styles.selectWrapper}>
                <FieldLabel label={label} />
                <Select
                  onChange={(option: { label: string; value: string | boolean; countryIso: string }) => {
                    updateLogin(key, option.value);
                    updateLogin('defaultCountryName', option.label);
                    updateLogin('defaultCountryIso', option.countryIso);
                  }}
                  id={key}
                  options={options}
                  defaultValue={options?.find(item => item.label === get(pageSetting, ['defaultCountryName'], ''))}
                />
              </div>
            );
          case 'label':
            return (
              <div className={styles.labelWrapper}>
                <FieldLabel label={label} />
              </div>
            );
        }
      })}
    </>
  );
};
