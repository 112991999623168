import React from 'react';
import { Header } from '../../row/header';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AheadPreview from '../../ahead/ahead-preview';
import { SelectRowInspector } from '../../molecules/select-row-inspector';
import { setPageType } from '../../actions/builder-action';

const Story = () => {
  const { publisherId = '' } = useParams();
  const dispatch = useDispatch();
  dispatch(setPageType('story'));
  return (
    <div>
      <Header publisherId={publisherId} />
      <AheadPreview hideHeader hideBreakingNews />
      <SelectRowInspector publisherId={publisherId} />
    </div>
  );
};

export default Story;
