import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';
import { ISelectedRow } from '../../utils/interfaces';
import RowTypeSetting from '../row-type-setting';
import { ButtonOptions, SearchFilterOptions, ShowHideOptions } from '../search-filter-options/search-filter-options';
import { RowSetting } from './arrow-row-setting/index';
import SlotSetting from './select-wrapper';

const RowInspector = ({ rowId, config = {}, layout = '', componentMeta = [] }: ISelectedRow) => {
  const getConfig = useSelector(state => get(state, ['builder'], {}));
  const { pageType = '', config: pbConfig = {} } = getConfig;
  const configPath = useConfigPathFromStore();
  const getLayoutDataPath = (pageType: string) => {
    switch (pageType) {
      case 'section':
        return ['section'];
      case 'landingPage':
        return configPath;
      default:
        return [];
    }
  };
  const layoutDataPath = getLayoutDataPath(pageType);
  const layoutDataType =
    get(getConfig, ['config', ...layoutDataPath, 'pageSetting', 'layoutDataType'], '') === 'storiesAndCollections';
  const showButton = get(config, ['showButton'], true);

  const renderButtonForRows = () => {
    if (pageType === 'issue-landing') {
      const rows = get(getConfig, ['config', 'magazine', 'issue-landing', 'rows'], []);
      const topStoriesRow = rows.find((row: any) => row.name === 'topStories' && row.rowId === rowId);
      if (topStoriesRow) return false;
    }
    return true;
  };

  const generateSettings = (item: any, index: number) => {
    let { buttonSettings = [], showHideSettings, settings } = item;
    const {
      slotSettings,
      styleSettings,
      sliderSettings,
      showHideButtonSettings,
      filterSettings,
      assignRowSetting,
      aheadNextRowMappingSettings,
      initialLoadCountSettings
    } = item;
    const isSubsequentLoadMore = get(config, ['footerButton'], 'NavigateToPage') === 'SubsequentLoadCount';

    buttonSettings = !isSubsequentLoadMore
      ? buttonSettings.filter((item: any) => item.keyName !== 'subsequentLoadCount')
      : buttonSettings;

    if (settings) {
      switch (pageType) {
        case 'archive':
          settings = settings.filter((item: any) => item.keyName !== 'pageDescription' && item.keyName !== 'pageTitle');
          break;
        case 'magazine-landing':
          settings = settings.filter(
            (item: any) =>
              item.keyName !== 'pageDescription' &&
              item.keyName !== 'pageTitle' &&
              item.keyName !== 'sortBy' &&
              item.keyName !== 'initialLoadCount' &&
              item.keyName !== 'subsequentLoadCount'
          );
          break;
        case 'issue-landing':
          settings = settings.filter(
            (item: any) => item.keyName !== 'initialLoadCount' && item.keyName !== 'subsequentLoadCount'
          );
          break;
        case 'authors':
          settings = settings.filter((item: any) => item.keyName !== 'showButton' && item.keyName !== 'buttonText');
        case 'sign-in':
          const { mobileOtpAuth = false, googleAuth = true, facebookAuth = true, linkedInAuth = true } = get(
            pbConfig,
            ['login', 'pageSetting'],
            {}
          );
          const conditions = [
            { condition: mobileOtpAuth, keyName: 'emailLabel' },
            { condition: !googleAuth, keyName: 'googleLoginLabel' },
            { condition: !facebookAuth, keyName: 'facebookLoginLabel' },
            { condition: !linkedInAuth, keyName: 'linkedInLoginLabel' },
            { condition: !mobileOtpAuth, keyName: 'phoneEmailLabel' }
          ];
          settings = settings.filter(
            (item: any) => !conditions.some(cond => cond.condition && item.keyName === cond.keyName)
          );
      }
    }

    if (buttonSettings && pageType === 'issue-landing') {
      buttonSettings = buttonSettings.filter(
        (item: any) => item.keyName !== 'footerButton' && item.keyName !== 'initialLoadCount'
      );
    }

    const supportedPages = ['author', 'tag'];
    const hideProperties = supportedPages.includes(pageType);

    // Hiding Row title and Button properties for the purchased story page
    const hidePurchasedStoryProperties = ['purchased-story-page'];
    const hideRowTitleProperty = hidePurchasedStoryProperties.includes(pageType);
    if (showHideSettings && hideRowTitleProperty) {
      showHideSettings = showHideSettings.filter(
        (item: any) => item.keyName !== 'customCollectionName' && item.keyName !== 'showRowTitle'
      );
      buttonSettings = buttonSettings.filter((item: any) => item.keyName !== 'footerButton');
    }

    if (hideProperties) {
      buttonSettings = buttonSettings.filter((item: any) => item.keyName !== 'footerButton');
    }
    const showHideLayoutSettings = hideProperties ? showHideSettings.slice(0, -1) : showHideSettings; // update this condition when new settings are added.

    if (pageType === 'search' && item) {
      return (
        <Accordion hasSingleActivePanel onToggle={() => {}}>
          <AccordionSection label="Filter Options" isLabelUpperCase index={0}>
            <SearchFilterOptions filterSettings={filterSettings} />
          </AccordionSection>
          <AccordionSection label="Layout Settings" isLabelUpperCase index={1}>
            <ShowHideOptions showHideSettings={showHideSettings} />
          </AccordionSection>
          <AccordionSection label="Button Settings" isLabelUpperCase index={2}>
            <ButtonOptions buttonSettings={showHideButtonSettings} />
            {showButton && <ButtonOptions buttonSettings={buttonSettings} />}
          </AccordionSection>
        </Accordion>
      );
    } else {
      const initialLoadCountLayouts = ['ArrowSixColSixStories'];
      return (
        <>
          {settings && <RowSetting rowId={rowId} config={config} layout={layout} data={settings} />}
          <Accordion hasSingleActivePanel onToggle={() => {}}>
            {layoutDataType ? (
              <AccordionSection label="Row Type" isLabelUpperCase index={index}>
                <RowTypeSetting rowId={rowId} />
              </AccordionSection>
            ) : (
              <> </>
            )}
            {pageType === 'issue-landing' && assignRowSetting && (
              <AccordionSection label="Assign row to collection" isLabelUpperCase index={index}>
                <RowSetting rowId={rowId} config={config} layout={layout} data={assignRowSetting} />
              </AccordionSection>
            )}
            {slotSettings && (
              <AccordionSection label="Slot settings" isLabelUpperCase index={index}>
                <SlotSetting rowId={rowId} config={config} layout={layout} />
              </AccordionSection>
            )}
            {showHideLayoutSettings && (
              <AccordionSection label="Layout Settings" isLabelUpperCase index={index}>
                <RowSetting rowId={rowId} config={config} layout={layout} data={showHideLayoutSettings} />
              </AccordionSection>
            )}
            {styleSettings && (
              <AccordionSection label="Style Settings" isLabelUpperCase index={index}>
                <RowSetting rowId={rowId} config={config} layout={layout} data={styleSettings} />
              </AccordionSection>
            )}
            {sliderSettings && (
              <AccordionSection label="Slider Settings" isLabelUpperCase index={index}>
                <RowSetting rowId={rowId} config={config} layout={layout} data={sliderSettings} />
              </AccordionSection>
            )}
            {renderButtonForRows() && showHideButtonSettings && (
              <AccordionSection label="Button Settings" isLabelUpperCase index={index}>
                {initialLoadCountLayouts.includes(layout) && (
                  <RowSetting rowId={rowId} config={config} layout={layout} data={initialLoadCountSettings} />
                )}
                <RowSetting rowId={rowId} config={config} layout={layout} data={showHideButtonSettings} />
                {showButton && <RowSetting rowId={rowId} config={config} layout={layout} data={buttonSettings} />}
              </AccordionSection>
            )}
            {aheadNextRowMappingSettings && aheadNextRowMappingSettings[0]?.display && (
              <AccordionSection label="Ahead Next Row Mapping" isLabelUpperCase index={index}>
                <RowSetting rowId={rowId} config={config} layout={layout} data={aheadNextRowMappingSettings} />
              </AccordionSection>
            )}
          </Accordion>
        </>
      );
    }
  };

  return (
    <>
      {componentMeta &&
        componentMeta.map((item: any, index: number) => {
          return item.componentName === layout && item.settings && generateSettings(item.settings, index);
        })}
    </>
  );
};

export default RowInspector;
