import React from 'react';
import { Chip } from '@quintype/em/components/chip';
import { ColorPicker } from '../color-picker';

import styles from './theme-setter.module.css';

interface IProps {
  description?: string;
  label?: string;
  updateTheStore?: (key: string, value: string, pageType: string) => void;
  lightColorExtract?: string;
  darkColorExtract?: string;
  lightUpdateStoreKey?: string;
  darkUpdateStoreKey?: string;
  lightDefaultColor?: string;
  darkDefaultColor?: string;
  children?: any;
  pageType?: string;
  enableDarkModePreview?: boolean;
}

export const ThemeSetter = (props: IProps) => {
  const setDefaultColor = () => {
    if (!props.enableDarkModePreview) {
      props.updateTheStore!(props.lightUpdateStoreKey!, props.lightDefaultColor!, props.pageType!);
    } else {
      props.updateTheStore!(props.darkUpdateStoreKey!, props.darkDefaultColor!, props.pageType!);
    }
  };

  const getComponent = () => {
    if (props.children) {
      return React.cloneElement(props.children, {});
    }
    return (
      <>
        <ColorPicker
          label={''}
          colorExtract={!props.enableDarkModePreview ? props.lightColorExtract! : props.darkColorExtract!}
          updateStoreKey={!props.enableDarkModePreview ? props.lightUpdateStoreKey : props.darkUpdateStoreKey}
          updateTheStore={props.updateTheStore!}
        />
        {(props.lightDefaultColor || props.darkDefaultColor) && (
          <div onClick={() => setDefaultColor()} className={styles.setDefault} title="Reset to default">
            &#8634;
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.colorPaletteWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.label}>{props.label} </div>
        <Chip value={props.enableDarkModePreview ? 'Dark Mode' : 'Light Mode'} />
      </div>
      <div className={`${styles.content}`}>{getComponent()}</div>
    </div>
  );
};
