import {
  PAGEBUILDER_EDIT_CONTROL_SET_CURRENT_EDITOR,
  PAGEBUILDER_EDIT_CONTROL_START_EDITING,
  PAGEBUILDER_EDIT_CONTROL_STOP_EDITING,
  PAGEBUILDER_EDIT_CONTROL_RESET_EDITING
} from '../reducers/builder/editControlReducer/actions';

import get from 'lodash/get';
import { updateCurrentEditor, getCurrentEditor } from '../utils/utils';

export const initEditControl = (publisherId: string, email: string, isEditAccessLocked: boolean) => {
  return async (dispatch: any) => {
    const result = await getCurrentEditor(publisherId);
    const existingEditorEmail = get(result, ['currentEditor'], null);
    if (!existingEditorEmail) {
      !isEditAccessLocked && dispatch(startEditing(publisherId, email));
    } else {
      const isSameUser = existingEditorEmail === email;
      const action = isSameUser ? PAGEBUILDER_EDIT_CONTROL_START_EDITING : PAGEBUILDER_EDIT_CONTROL_SET_CURRENT_EDITOR;
      dispatch({
        type: action,
        payload: existingEditorEmail
      });
    }
  };
};

export const startEditing = (publisherId: string, email: string) => {
  return async (dispatch: any) => {
    const result = await updateCurrentEditor(publisherId, email);
    if (result) {
      dispatch({
        type: PAGEBUILDER_EDIT_CONTROL_START_EDITING,
        payload: email
      });
    }
  };
};

export const stopEditing = (publisherId: string) => {
  return async (dispatch: any) => {
    const lockedAccessTime = Date.now() + 60000 * 2; // 2 minutes from now
    //TODO: locking editing access logic should be moved to server side
    localStorage.setItem('lockedAccessTime', String(lockedAccessTime));
    dispatch(resetEditing(publisherId));
    dispatch({
      type: PAGEBUILDER_EDIT_CONTROL_STOP_EDITING
    });
  };
};

export const resetEditing = (publisherId: string) => {
  return async (dispatch: any) => {
    await updateCurrentEditor(publisherId, null);
    dispatch({
      type: PAGEBUILDER_EDIT_CONTROL_RESET_EDITING,
      payload: null
    });
  };
};
