import { Checkbox } from '@quintype/em/components/checkbox';
import { FieldLabel } from '@quintype/em/components/field-label';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { premiumIconStyle } from '../../actions/builder-action';
import { defaultPremiumIconColor } from '../../constant';
import { IPBState } from '../../utils/interfaces';
import { ThemeSetter } from '../theme-setter';
import premiumStoryIconsData from './premium-story-icon-data';
import styles from './premium-story-icon.module.css';

export const PremiumStoryIcons = () => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { enablePremiumStoryIcon, iconColor = '', darkIconColor = '' } = useSelector((state: any) =>
    get(state, ['builder', 'config', 'general', 'rows', 'premiumStoryIcon'], {})
  );
  const { enableDarkModePreview = false } = useSelector((state: IPBState) => get(state, ['builder', 'config']));

  const updateIconHandler = (name: string, index: number) => {
    setSelectedIndex(index);
    dispatch(premiumIconStyle({ iconStyle: name }));
  };
  const updatePremiumIconColor = (key: string, value: string) => dispatch(premiumIconStyle({ [key]: value }));

  return (
    <>
      <div className={styles.checkBoxWrapper} data-test-id="premium-story-checkbox">
        <Checkbox
          label="Show Premium Content Badge "
          id="premiumIcon"
          checked={enablePremiumStoryIcon}
          onChange={(value: any) => dispatch(premiumIconStyle({ enablePremiumStoryIcon: value }))}
        />
      </div>
      <FieldLabel label="Choose Icon Style" />
      <div className={styles.iconWrapper}>
        {premiumStoryIconsData.map((item: { name: string; svg: string }, index: number) => (
          <div
            onClick={() => updateIconHandler(item.name, index)}
            className={selectedIndex === index ? styles.selectedImgWrapper : styles.imageWrapper}
            data-test-id={item.name}
            key={`premium-icon-${item.name}`}
          >
            <img src={item.svg} className={styles.image} alt="Premium Icon style" />
          </div>
        ))}
      </div>
      <ThemeSetter
        label="Choose Icon color"
        updateTheStore={updatePremiumIconColor}
        lightColorExtract={iconColor}
        lightUpdateStoreKey="iconColor"
        darkColorExtract={darkIconColor}
        darkUpdateStoreKey="darkIconColor"
        lightDefaultColor={defaultPremiumIconColor}
        darkDefaultColor={defaultPremiumIconColor}
        enableDarkModePreview={enableDarkModePreview}
      />
    </>
  );
};
