import React, { useState } from 'react';
import { FieldLabel } from '@quintype/em/components/field-label';
import styles from './icon-selector.module.css';

interface IOption {
  value: string;
  svg: string;
}

interface IProps {
  label: string;
  options?: IOption[];
  onChange: (key: string, value: any) => void;
  defaultValue: string;
  keyName: string;
}

export const IconSelector = ({ label, options, onChange, keyName, defaultValue }: IProps) => {
  const defaultValueIndex = options?.findIndex(item => item?.value === defaultValue);
  const [selectedIndex, setSelectedIndex] = useState(defaultValueIndex || -1);

  const updateSelectedIcon = (item: { svg: string; value: string }, index: number) => {
    setSelectedIndex(index);
    onChange(keyName, item.value);
  };

  return (
    <>
      <FieldLabel label={label} />
      <div className={styles.iconWrapper}>
        {options?.map((item: { svg: string; value: string }, index: number) => (
          <div
            onClick={() => updateSelectedIcon(item, index)}
            className={
              selectedIndex === index || item.value === defaultValue ? styles.selectedImgWrapper : styles.imageWrapper
            }
            data-test-id={item.value}
            key={`${item.value}-icon`}
          >
            <img src={item.svg} className={styles.image} alt={item.value} />
          </div>
        ))}
      </div>
    </>
  );
};
