import React from 'react';
import IconButton from '../../atoms/iconButton';
import { Replace } from '../../atoms/Replace-svg';
import { Setting } from '../../atoms/setting-svg';
import { Trash } from '../../atoms/trash';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';

interface IProps {
  actionList: IActionList[];
}

interface IActionList {
  action: string;
  handler: () => void;
}

const ActionButtons = ({ actionList }: IProps) => {
  const hasEditAccess = useSelector(state => get(state, ['builder', 'editControl', 'access']));
  const listComponents = actionList.map((item: IActionList) => {
    const dataTestId = camelCase(`${item.action}-button`);
    switch (item.action) {
      case 'replace':
        return (
          <IconButton data-test-id={dataTestId} key={item.action} onClick={item.handler}>
            <Replace />
          </IconButton>
        );
      case 'setting':
        return (
          <IconButton data-test-id={dataTestId} key={item.action} onClick={item.handler}>
            <Setting />
          </IconButton>
        );

      case 'trash':
        return (
          <IconButton data-test-id={dataTestId} key={item.action} onClick={item.handler}>
            <Trash />
          </IconButton>
        );
      default:
        return null;
    }
  });
  if (!hasEditAccess) return null;
  return <>{listComponents}</>;
};

export default ActionButtons;
