import { addFontLinkTag } from '../../utils/utils';
import { useEffect } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

const AheadFontLoader = () => {
  const fonts = useSelector(state => get(state, ['builder', 'config', 'general', 'fonts'], {}));
  const primaryFont = get(fonts, ['primaryFont']);
  const secondaryFont = get(fonts, ['secondaryFont']);

  useEffect(() => {
    downloadFonts(primaryFont);
  }, [primaryFont]);
  useEffect(() => {
    downloadFonts(secondaryFont);
  }, [secondaryFont]);
  return null;
};

const downloadFonts = async (fontName = '') => {
  if (fontName.length) await addFontLinkTag(fontName);
};

export default AheadFontLoader;
