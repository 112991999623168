import React from 'react';
import returnNull from '../../atoms/null';
import styles from './login.module.css';

interface IProps {
  pageType?: string | undefined;
  enableDarkModePreview?: boolean;
}

export const Login = ({ pageType, enableDarkModePreview }: IProps) => {
  let Component;
  let displayData = '';
  switch (pageType) {
    case 'sign-up':
      Component = window?.QT_COMPONENTS?.SignUp || returnNull;
      break;
    case 'forgot-password':
      Component = window?.QT_COMPONENTS?.ForgotPassword || returnNull;
      break;
    case 'email-otp':
      Component = window?.QT_COMPONENTS?.EmailOtp || returnNull;
      displayData = 'abc@gmail.com';
      break;
    case 'mobile-otp':
      Component = window?.QT_COMPONENTS?.MobileOtp || returnNull;
      displayData = '+919876543210';
      break;
    case 'reset-password':
      Component = window?.QT_COMPONENTS?.ResetPassword || returnNull;
      break;
    case 'enter-password':
      Component = window?.QT_COMPONENTS?.EnterPassword || returnNull;
      displayData = 'abc@gmail.com';
      break;
    default:
    case 'sign-in':
      Component = window?.QT_COMPONENTS?.SignIn || returnNull;
      break;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.overlay} />
      <Component mode={enableDarkModePreview ? 'dark' : 'light'} displayData={displayData} />
    </div>
  );
};
