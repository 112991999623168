import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import './global-styles.css';
import styles from './styles.module.css';
import CloseIcon from '../close-icon';
const componentRoot = document.getElementById('component-root');
interface IProps {
  children: ReactNode;
  onClose?: () => any;
}

class FullScreenModalWrapper extends React.Component<IProps> {
  el: HTMLDivElement;
  constructor(props: IProps) {
    super(props);
    this.el = document.createElement('div');
    this.el.classList.add('pb-fullscreen-modal');
    this.el.classList.add('pb-fullscreen-modal--hidden');
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    if (componentRoot) {
      componentRoot.innerHTML = '';
      componentRoot.appendChild(this.el);
      setTimeout(() => {
        this.el.classList.remove('pb-fullscreen-modal--hidden');
      }, 100);
    }
  }
  componentWillUnmount() {
    if (componentRoot) {
      componentRoot.removeChild(this.el);
    }
  }
  closeModal() {
    this.el.classList.add('pb-fullscreen-modal--hidden');
    setTimeout(() => {
      this.props.onClose && this.props.onClose();
    }, 500);
  }
  render() {
    return ReactDOM.createPortal(
      <div className="pb-modal-content">
        <CloseIcon onCloseClick={this.closeModal} className={styles.closeBtn} />
        {this.props.children}
      </div>,
      this.el
    );
  }
}
export default FullScreenModalWrapper;
