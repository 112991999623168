import React from 'react';
import styles from './low-fidelity-layouts.module.css';
import { IComponentMeta } from '../../utils/interfaces';
import SmallTitle from '../../atoms/smallTitle';

interface ILowFidelityLayoutsProps {
  handleLayoutSelect: (e: any) => void;
  layoutsList: IComponentMeta[];
}

const LowFidelityLayouts = ({ handleLayoutSelect, layoutsList = [] }: ILowFidelityLayoutsProps) => (
  <div className={styles.headersWrapper}>
    <div className={styles.headersComponents}>
      {layoutsList.map((comp: IComponentMeta, index) => (
        <div className={styles.headersComponent} key={index} onClick={() => handleLayoutSelect(comp)}>
          <SmallTitle className={styles.componentName}>{comp.componentLabel}</SmallTitle>
          <div className={styles.headersImgWrapper}>
            <img src={comp.image} alt={comp.componentLabel} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default LowFidelityLayouts;
