export const AHEAD_DARK_MODE_PREVIEW = 'AHEAD.DARK_MODE_PREVIEW';

export const headerState = {
  isDarkModeEnabled: false
};
const headerReducer = (state: any = headerState, action: any) => {
  switch (action.type) {
    case AHEAD_DARK_MODE_PREVIEW:
      return {
        ...state,
        isDarkModeEnabled: action.payload as boolean
      };
    default:
      return state;
  }
};

export default headerReducer;
