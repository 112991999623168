import { PAGEBUILDER_HAVE_LAYOUT_UPDATES } from '../configReducer/actions';

const haveLayoutUpdatesReducer = (
  state: boolean = false,
  action: {
    type: string;
    payload: boolean;
  }
) => {
  switch (action.type) {
    case PAGEBUILDER_HAVE_LAYOUT_UPDATES:
      return action.payload;
    default:
      return state;
  }
};

export { haveLayoutUpdatesReducer };
