import get from 'lodash/get';

interface Slot {
  [id: string]: {
    before?: object;
    after?: object;
  };
}

export const addSlotsToStory = (storyCards: any, slots: Slot, storyData: object) => {
  const storyCardsWithSlotConfig = JSON.parse(JSON.stringify(storyCards));
  let customSlotAfterStory = {};
  for (const slotKey of Object.keys(slots)) {
    const slotConfig = slots[slotKey] as { before: object[]; after: object[] };
    if (!slotKey) continue;
    if (slotKey === 'story') {
      if (slotConfig.after) {
        customSlotAfterStory = {
          ...customSlotAfterStory,
          ...slotConfig.after[0]
        };
      }
    } else {
      if (slotConfig.before) {
        storyCardsWithSlotConfig[Number(slotKey) - 1]['story-elements'] = [
          ...slotConfig.before,
          ...storyCardsWithSlotConfig[Number(slotKey) - 1]['story-elements']
        ];
      }
      if (slotConfig.after) {
        storyCardsWithSlotConfig[Number(slotKey) - 1]['story-elements'] = [
          ...storyCardsWithSlotConfig[Number(slotKey) - 1]['story-elements'],
          ...slotConfig.after
        ];
      }
    }
  }
  return { ...storyData, story: { ...get(storyData, ['story'], {}), cards: storyCardsWithSlotConfig, customSlotAfterStory } };

};
