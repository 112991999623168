import { OutsideClickHandler } from '@quintype/em/components/outside-click-handler';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDomainList } from '../../../actions/builder-action';
import { Dropdown } from '../../../molecules/dropdown';
import { IParams } from '../../../utils/interfaces';
import { getBoldConfig, getConfiguredDomains } from '../../../utils/utils';

interface IDomain {
  name: string;
  slug: string;
}

const generateDomainList = async (publisherId: string, subdomains: IDomain[], publisherName: string) => {
  const mainDomain = {
    name: startCase(publisherName),
    slug: publisherName,
    type: 'main',
    configured: true
  };

  try {
    const { data } = await getConfiguredDomains(publisherId);
    const configuredDomains = data.domains;
    const subdomainList = subdomains.map((item: IDomain) => {
      const isDomainConfigured = configuredDomains.some((domain: { key: string }) => domain.key === item.slug);
      return {
        name: startCase(item.name),
        slug: item.slug,
        type: 'sub',
        configured: isDomainConfigured
      };
    });
    return [mainDomain, ...subdomainList];
  } catch (error) {
    console.log('Could not fetch the configured domains list', error.message);
  }
};

const generateMenuItems = (items: IDomain[], publisherId: string) => {
  if (!items) return [];
  return items.map((item: any) => {
    return {
      label: item.name,
      title: item.name,
      link: `/accounts/${publisherId}/${item.slug}`,
      shouldRender: true
    };
  });
};

const DomainMenu = ({ publisherId, domain }: IParams) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeDomain, setActiveDomain] = useState('');
  const domains = useSelector(state => get(state, ['builder', 'domainList']));
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const boldConfig = await getBoldConfig(publisherId);
      if (boldConfig) {
        const { domains: subdomains = [], 'publisher-name': publisherName } = boldConfig;
        const allDomainList = (await generateDomainList(publisherId, subdomains, publisherName)) || [];
        dispatch(setDomainList(allDomainList));
      }
    })();
  }, [dispatch, domain, publisherId]);

  useEffect(() => {
    const result = domains.find((item: { slug: string }) => item.slug === domain);
    result && setActiveDomain(startCase(result.name));
  }, [domain, domains]);

  const handleOnClick = (option: { label: string }) => {
    setActiveDomain(option.label);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  if (domains.length <= 1) return null;
  const dropdownConfig = {
    title: 'Domains',
    label: 'Domain',
    options: generateMenuItems(domains, publisherId),
    active: activeDomain,
    setActive: handleOnClick,
    isDropdownOpen,
    toggleDropdown
  };

  return (
    <OutsideClickHandler onOutsideClick={() => isDropdownOpen && toggleDropdown()}>
      <Dropdown config={dropdownConfig} />
    </OutsideClickHandler>
  );
};

export default DomainMenu;
