import { Checkbox } from '@quintype/em/components/checkbox';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import { updateRowsList } from '../../actions/row-action';
import '../page-layout/pageLayout.m.css';
import { ISelectedRow } from '../../utils/interfaces';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';

interface IProps {
  rowId: number;
}

const RowTypeSetting = ({ rowId }: IProps) => {
  const [rowType, updateRowtype] = useState(false);
  const configPath = useConfigPathFromStore();
  const rows = useSelector((state: any) => get(state, ['builder', 'config', ...configPath, 'rows'], []));
  const dispatch = useDispatch();

  const currentRow = rows.find((row: ISelectedRow) => row.rowId === rowId);
  const { dataType: currentDataType, slug: currentSlug } = get(currentRow, ['config'], {});
  const currentCheckboxStatus = currentDataType === 'collection' || currentDataType === 'collectionWithSlug';

  const updateRowTypeSetting = (key: string, value: string | boolean) => {
    const dataType = value ? 'collection' : 'stories';
    updateRowtype(!rowType);
    dispatch(
      updateRowsList({
        rowAction: 'update_settings',
        rowActionPayload: { selectedRowId: rowId, row: { [key]: dataType } }
      })
    );
    dispatch(updateHaveUnsavedChanges(true));
  };

  const setDefaultCollection = (key: string, value: string | boolean) => {
    const dataType = value ? 'collectionWithSlug' : 'collection';
    dispatch(
      updateRowsList({
        rowAction: 'update_settings',
        rowActionPayload: { selectedRowId: rowId, row: { [key]: dataType, slug: value } }
      })
    );
    dispatch(updateHaveUnsavedChanges(true));
  };
  return (
    <>
      <Checkbox
        id="rowType"
        label="Make this a separate collection"
        checked={currentCheckboxStatus}
        onChange={(value: boolean) => updateRowTypeSetting('dataType', value)}
      />
      <div className="text">This row will have a separate story collection from all other rows.</div>
      {currentCheckboxStatus && (
        <TextField
          label="Collection Slug"
          value={currentSlug}
          placeholder="e.g. politics"
          onChange={(value: string) => setDefaultCollection('dataType', value)}
          hint="Defaults to the first child collection"
        />
      )}
    </>
  );
};

export default RowTypeSetting;
