import React from 'react';
import { Header } from '../../row/header';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AheadPreview from '../../ahead/ahead-preview';
import { setPageType } from '../../actions/builder-action';

const Login = () => {
  const { publisherId = '' } = useParams();
  const dispatch = useDispatch();
  const url = window.location.pathname;
  const params = url.split('/');
  const pageType = params?.[params.length - 1];

  dispatch(setPageType(pageType));
  return (
    <>
      <Header publisherId={publisherId} />
      <AheadPreview />
    </>
  );
};

export default Login;
