import { useEffect } from 'react';
import { chameleonId, initChameleon } from './chameleon';
import useUserDetails from '../../hooks/user';
const Chameleon = () => {
  const userDetails = useUserDetails();
  useEffect(() => {
    // Run chameleon initialization script
    initChameleon(document, window, chameleonId);
    if (userDetails.email) {
      // Start chameleon
      // @ts-ignore
      chmln.identify(userDetails.userId, {
        email: userDetails.email
      });
    }
  }, [userDetails.email, userDetails.userId]);

  return null;
};

export default Chameleon;
