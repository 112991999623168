import { TextField } from '@quintype/em/components/text-field';
import { TextArea } from '@quintype/em/components/text-area';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { updateRowsList } from '../../actions/row-action';
import { ISelectedRow } from '../../utils/interfaces';
import styles from './ad-inspector.module.css';
import { updateHeaderRowsList } from '../../actions/header-action';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import { checkValidEncodedCustomCode, encodeCustomCode } from '../../utils/utils';
import { Callout } from '@quintype/em/components/callout';

const AdInspector = ({
  rowId,
  layoutLabel,
  config: { adType = layoutLabel, targetingId = '', customCode = '' } = {},
  isHeaderRow = false
}: ISelectedRow) => {
  const dispatch = useDispatch();
  const { pageType } = useSelector(state => get(state, 'builder'));
  const updateAdRowSettings = (key: string, value: string) => {
    const updateRowsListFunc = isHeaderRow ? updateHeaderRowsList : updateRowsList;
    dispatch(
      updateRowsListFunc({
        rowAction: 'update_settings',
        rowActionPayload: { selectedRowId: rowId, row: { [key]: value } }
      })
    );
    dispatch(updateHaveUnsavedChanges(true));
  };

  if (layoutLabel === 'Custom') {
    return (
      <>
        <div className={styles.warningMessage}>
          <Callout
            variant="warning"
            title="Warning"
            content="The code may contain malicious script or may negatively affect the performance. Be cautious before pasting your code."
          />
        </div>
        <TextArea
          value={checkValidEncodedCustomCode(customCode)}
          onChange={(value: string) => updateAdRowSettings('customCode', encodeCustomCode(value))}
          hint="Paste any valid HTML code here. The code will be inserted to the row."
          label="Custom Code"
          placeholder="Paste custom code"
        />
      </>
    );
  }

  return (
    <div className={styles.adInspectorWrapper}>
      <TextField label="Ad type" value={adType} readOnly onChange={() => null} />
      <TextField label="Page type" value={pageType} readOnly onChange={() => null} />
      <TextField
        label="Targeting Id"
        value={targetingId}
        onChange={(value: string) => updateAdRowSettings('targetingId', value)}
        placeholder="No value set"
      />
    </div>
  );
};

export default AdInspector;
