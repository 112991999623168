import { combineReducers } from 'redux';
import aheadReducer from './ahead';
import boldReducer from './bold';
import builderReducer from './builder';
import headerReducer from './header';
import {
  loadDummyMemberReducer,
  loadDummyMemberSubscriptionsReducer,
  memberSubscriptionsLoadedReducer,
  dummyMemberLoadedReducer
} from './member-and-subscriptions';

export default combineReducers({
  ahead: aheadReducer,
  qt: boldReducer,
  builder: builderReducer,
  header: headerReducer,
  member: loadDummyMemberReducer,
  memberLoaded: dummyMemberLoadedReducer,
  memberSubscriptions: loadDummyMemberSubscriptionsReducer,
  memberSubscriptionsLoaded: memberSubscriptionsLoadedReducer
});
