import get from 'lodash/get';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { updateConfigVersion, updateHaveLayoutUpdates, updateHistoryActionStatus } from '../../actions/builder-action';
import { IParams } from '../../utils/interfaces';
import { saveConfig } from '../../utils/utils';

const AutoSave = () => {
  const { publisherId = '', domain } = useParams<IParams>();
  const { haveLayoutUpdates, config } = useSelector(state => get(state, ['builder'], {}));
  const dispatch = useDispatch();
  // added to avoid extra save calls
  let activeVersionValue = useRef(0);
  useEffect(() => {
    if (haveLayoutUpdates) {
      (async () => {
        const nextVersion = config.version + 1;
        const updatedConfig = { ...config, version: nextVersion };
        const response = await saveConfig(publisherId, updatedConfig, domain);

        if (response?.activeVersion && response?.actionButtonStatus) {
          const { activeVersion = 0, actionButtonStatus } = response;
          activeVersionValue.current = activeVersion;
          dispatch(updateHistoryActionStatus(actionButtonStatus));
        }
      })().then(() => {
        dispatch(updateHaveLayoutUpdates(false));
        dispatch(updateConfigVersion(activeVersionValue.current));
      });
    }
  }, [config, dispatch, publisherId, haveLayoutUpdates, domain]);
  // TODO: Show loading when saving
  return null;
};
export default AutoSave;
