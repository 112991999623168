import get from 'lodash/get';
import {
  PAGEBUILDER_CLOSE_SELECT_ROW_INSPECTOR,
  PAGEBUILDER_OPEN_SELECT_ROW_INSPECTOR
} from '../reducers/builder/selectRow';
interface IOpenPayload {
  type: string;
  action: string;
  rowId: number;
  moduleType?: string;
  isHeaderRow?: boolean;
}

interface IOpenArg {
  type: string;
  payload: IOpenPayload;
}
type IOpen = (arg: IOpenArg) => void;

export const openSelectRowInspector = (payload: IOpenPayload) => {
  return (dispatch: IOpen, getState: any) => {
    const pageType = get(getState(), ['builder', 'pageType'], '');
    dispatch({
      type: PAGEBUILDER_OPEN_SELECT_ROW_INSPECTOR,
      payload: { ...payload, moduleType: pageType }
    });
  };
};

export const closeSelectRowInspector = () => ({ type: PAGEBUILDER_CLOSE_SELECT_ROW_INSPECTOR });
