import { FixedTabList } from '@quintype/em/components/tabs';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { updateSubPageType } from '../../../actions/builder-action';
import { IMatch } from '../../../utils/interfaces';
import { splitCamelCase } from '../../../utils/utils';
import { RowLoader } from '../../component-loader';
import { StoryElementsPage } from '../story-elements-page';
import styles from './section-tab.module.css';

interface StorypageTabState {
  selectedTab: string;
}
interface StorypageTabProps {
  match: IMatch;
  updateStoryType: (storyType: string) => void;
}

const getTabList = (storyType: string) => {
  return [
    {
      value: storyType,
      label: `${splitCamelCase(storyType)} Story Layout`
    },
    {
      value: 'globalStoryElements',
      label: 'Global Story Elements'
    }
  ];
};

class StorypageTabBase extends React.Component<StorypageTabProps, StorypageTabState> {
  private storyType: string;
  constructor(props: StorypageTabProps) {
    super(props);
    this.storyType = camelCase(get(this.props, ['match', 'params', 'storyType'], ''));
    this.state = {
      selectedTab: this.storyType
    };
  }

  setStoryType = (value: string) => {
    const { updateStoryType } = this.props;
    updateStoryType(value);
  };

  setSelectedTab = (value: string) => {
    this.setState({
      selectedTab: value
    });
  };

  componentDidMount() {
    this.setStoryType(this.storyType);
  }

  render() {
    return (
      <div className={styles.tabwrapper}>
        <div className={styles.tabs}>
          <FixedTabList
            tabs={getTabList(this.storyType)}
            value={this.state.selectedTab}
            onChange={this.setSelectedTab}
          />
        </div>
        {this.state.selectedTab === 'globalStoryElements' ? <StoryElementsPage /> : <RowLoader />}
      </div>
    );
  }
}

interface IActionType {
  type: string;
  payload: string;
}

const mapDispatchToProps = (dispatch: React.Dispatch<IActionType>) => ({
  updateStoryType: (storyType: string) => {
    dispatch(updateSubPageType(`${storyType}-story`));
  }
});

export const StorypageTab = connect(null, mapDispatchToProps)(StorypageTabBase);
