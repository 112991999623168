import React from 'react';
import styles from './social-icon-group.module.css';

interface IProps {
  icons: string[];
}
export class SocialIconGroup extends React.Component<IProps> {
  render() {
    return (
      <div className={styles.socialIconGroupWrapper}>
        {this.props.icons.map((item, index) => (
          <img className={styles['socialIconImage']} src={item} key={`social-icon-${index}`} alt="social-icon" />
        ))}
      </div>
    );
  }
}
