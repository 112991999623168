import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { defaultDarkTheme, defaultTheme } from '../constant';

const getRootVariables = (props: {}): any => {
  const {
    footer = {},
    breakingNews = {},
    header = {},
    general = {},
    enableDarkModePreview = false,
    story = {},
    login = {}
  } = get(props, ['pageBuilderConfig'], {});
  const { darkPrimaryColor, primaryColor = '#4860BC' } = general;
  const getkDarkPrimaryColor = darkPrimaryColor ? darkPrimaryColor : primaryColor;
  const pbPrimaryColor = enableDarkModePreview ? getkDarkPrimaryColor : primaryColor;

  const themeVariables = {
    '--headlineLineHeight': 1.3,
    '--bodyLineHeight': 1.5,
    '--bleedCarouseltitleLineHeight': 1.2,
    '--bleedCarouselSectionColor': '#fff',
    '--bleedCarouselSectionBackgroundColor': 'rgba(216,37,28,.7)',
    '--bleedCarouselTitleColor': '#fff',
    '--bleedCarouselTitleBackgroundColor': 'transparent',
    '--bleedCarouselAuthorColor': '#D4D4D4',
    '--bleedCarouselAuthorBackgroundColor': 'transparent',
    '--primaryColorRgba': 'rgba(255, 0, 4)',
    '--halfWidthCarouselHeadingColor': '#333333',
    '--halfWidthCarouselHeadingBackgroundColor': 'transparent',
    '--halfWidthCarouselDescriptionColor': '#4c4c4c',
    '--halfWidthCarouselDescriptionBackgroundColor': 'transparent',
    '--halfWidthCarouselAuthorColor': '#515151',
    '--halfWidthCarouselAuthorBackgroundColor': 'transparent',
    '--halfWidthCarouselTimeColor': '#D4D4D4',
    '--halfWidthCarouselTimeBackgroundColor': 'transparent',
    '--halfWidthCarouselheadlineLineHeight': '1.2',
    '--primaryHeaderBackgroundColor': enableDarkModePreview
      ? get(header, ['darkPrimaryBackgroundColor'], '#292929')
      : get(header, ['primaryBackgroundColor'], '#fff'),
    '--primaryHeaderTextColor': enableDarkModePreview
      ? get(header, ['darkPrimaryTextColor'], defaultTheme)
      : get(header, ['primaryTextColor'], '#000'),
    '--secondaryHeaderTextColor': enableDarkModePreview
      ? get(header, ['darkSecondaryTextColor'], defaultTheme)
      : get(header, ['secondaryTextColor'], '#000'),
    '--secondaryHeaderBackgroundColor': enableDarkModePreview
      ? get(header, ['darkSecondaryBackgroundColor'], '#303030')
      : get(header, ['secondaryBackgroundColor'], '#fff'),
    '--hamburgerAndMegaMenuBgColor': enableDarkModePreview
      ? get(header, ['hamburgerAndMegamenuDarkBackgroundColor'], defaultDarkTheme)
      : get(header, ['hamburgerAndMegamenuBackgroundColor'], '#fff'),
    '--hamburgerAndMegaMenuTextColor': enableDarkModePreview
      ? get(header, ['hamburgerAndMegamenuDarkTextColor'], defaultTheme)
      : get(header, ['hamburgerAndMegamenuTextColor'], '#000'),
    '--footerTextColor': enableDarkModePreview
      ? get(footer, ['darkTextColor'], defaultTheme)
      : get(footer, ['textColor'], '#fff'),
    '--footerBackgroundColor': enableDarkModePreview
      ? get(footer, ['darkBackgroundColor'], '#2d2d2d')
      : get(footer, ['backgroundColor'], '#000'),
    '--breakingNewsBgColor': enableDarkModePreview
      ? get(breakingNews, ['darkBackgroundColor'], '#2d2d2d')
      : get(breakingNews, ['backgroundColor'], '#EFEFEF'),
    '--breakingNewsTextColor': enableDarkModePreview
      ? get(breakingNews, ['darkTextColor'], defaultTheme)
      : get(breakingNews, ['textColor'], '#333'),
    '--textElementHyperlinkColor': enableDarkModePreview
      ? get(story, ['globalStoryElements', 'text', 'css', 'darkHyperlinkColor'], '#2f81cd')
      : get(story, ['globalStoryElements', 'text', 'css', 'hyperlinkColor'], '#2f81cd'),
    '--primaryColor': pbPrimaryColor || '#000',
    '--primaryFont': get(general, ['fonts', 'primaryFont'], 'sans-serif'),
    '--secondaryFont': get(general, ['fonts', 'secondaryFont'], 'sans-serif'),
    '--arrow-c-brand1': `${get(general, ['primaryColor'], '#4860BC')}!important`,
    '--textColor': enableDarkModePreview ? defaultTheme : '#333',
    '--defaultBgColor': enableDarkModePreview ? '#3b3b3b' : defaultTheme,
    '--loginButtonColor': enableDarkModePreview
      ? get(login, ['pageSetting', 'darkButtonColor'], darkPrimaryColor || '#4860BC')
      : get(login, ['pageSetting', 'buttonColor'], primaryColor)
  };
  return themeVariables;
};

const getRootVariablesString = (props: {}): any => {
  const rootVariables = getRootVariables(props);
  let rootVariablesString = '';
  Object.keys(rootVariables).forEach((key: any) => (rootVariablesString += `${key}: ${rootVariables[key]};\n`));
  return rootVariablesString;
};

const ThemeBase = (props: {}): any => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
 .qt-theme__color {
  color: #ff0004 !important;
 }
 .qt-theme__color--bg {
  background-color: #a3a3a3 !important;
 }
 .qt-theme__color--border {
  border-color: #ff0004 !important;
 }
 .qt-theme__color--header {
  color: #a72723 !important;
 }
 .qt-theme__color--headerbg {
  background-color: #ffffff !important;
 }
 .blank-story .story-element-text a:after {
  background-color: #ff0004 !important;
 }

 .card--align-left .story-element-text-quote,
 .card--align-left .story-element-text-blockquote,
 .card--align-left .story-element-text-blurb,
 .card--align-right .story-element-text-quote,
 .card--align-right .story-element-text-blockquote,
 .card--align-right .story-element-text-blurb {
  border-color: #ff0004 !important;
 }
 .active-tabs {
  background-color: #4860BC;
  color: #fff;
 }
 ::-moz-selection {
  background-color: #ff0004;
  color: #fff;
  opacity: 0.5;
 }
 ::selection {
  background-color: #ff0004;
  color: #fff;
  opacity: 0.5;
 }
 button:focus  {
  outline: none;
 }
 .rtl-twoColSixStories .arr--content-wrapper {
   order: -1;
 }
 .rtl-threeColTwelveStories {
   order: 2;
 }

 :root {
  ${getRootVariablesString(props)},

 }
 `
      }}
    />
  );
};

const mapStateToProps = (state: {}) => {
  return {
    pageBuilderConfig: get(state, ['builder', 'config'], {})
  };
};

export const Theme = connect(mapStateToProps)(ThemeBase);
