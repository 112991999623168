import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers/root-reducer';
import { STATIC_DATA } from './static-data/static-data';
import { logger } from './middleware/logger';
import { mergePBConfigtoQT } from './middleware/merge-pbconfig-qt';
import thunk from 'redux-thunk';

const INIT_DATA = {
  qt: STATIC_DATA
};

export default function configureStore(initialState) {
  const replaceInitState = initialState || INIT_DATA;
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    rootReducer,
    replaceInitState,
    composeEnhancers(applyMiddleware(thunk, mergePBConfigtoQT, logger))
  );
}
