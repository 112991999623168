import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PBInspector from '../inspector';
import MeteringOff from './metering-off';
import MeteringOn from './metering-on';
import { FixedTabList } from '@quintype/em/components/tabs';

enum TabElements {
  meteringOn = 'metering on',
  meteringOnLabel = 'METERING ON',
  meteringOff = 'metering off',
  meteringOffLabel = 'METERING OFF'
}

const tabs = [
  {
    value: TabElements.meteringOff,
    label: TabElements.meteringOffLabel
  },
  {
    value: TabElements.meteringOn,
    label: TabElements.meteringOnLabel
  }
];

interface meterProps {
  isActive: boolean;
  title: string;
  onCloseHandler: () => void;
  haveUnsavedChanges?: boolean;
}

interface meterState {
  tabName: string;
  isSave: boolean;
  showConfirmationPopup: boolean;
}

class PaywallSettingsBase extends React.Component<meterProps, meterState> {
  constructor(props: meterProps) {
    super(props);
    this.state = {
      tabName: TabElements.meteringOff,
      isSave: false,
      showConfirmationPopup: false
    };
  }

  toggleConfirmationPopup = (value: boolean) => {
    this.setState({
      showConfirmationPopup: value
    });
  };

  onChangeHandler = (value: string) => {
    this.setState({
      tabName: value
    });
  };

  actionButtonHandler = () => {
    this.setState({
      isSave: true
    });
    this.props.onCloseHandler();
  };

  updatedCloseHandler = () => {
    this.props.onCloseHandler();
    this.setState({
      isSave: false
    });
  };

  tabListItems = () => {
    if (this.state.tabName === TabElements.meteringOff) return <MeteringOff />;
    return <MeteringOn />;
  };

  render() {
    const { title, isActive, haveUnsavedChanges } = this.props;
    return (
      <PBInspector
        title={title}
        isActive={isActive}
        onActionButtonClick={this.actionButtonHandler}
        isActionButtonDisabled={!haveUnsavedChanges} //
        onClose={this.updatedCloseHandler}
        toggleConfirmationPopup={this.toggleConfirmationPopup}
        showConfirmationPopup={this.state.showConfirmationPopup}
      >
        <FixedTabList tabs={tabs} value={this.state.tabName} onChange={this.onChangeHandler} />
        {this.tabListItems()}
      </PBInspector>
    );
  }
}

interface IState {
  builder: {
    haveUnsavedChanges: boolean;
  };
}

const mapStateToProps = (state: IState) => ({
  haveUnsavedChanges: get(state, ['builder', 'haveUnsavedChanges'], false)
});

interface IActionType {
  type: string;
  payload: object | boolean;
}

const PaywallSettings = connect(mapStateToProps)(PaywallSettingsBase);

export { PaywallSettings };
