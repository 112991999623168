import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import { Dispatch } from 'redux';
import { showPopup } from '../../actions/popup-action';
import { Plus } from '../../atoms/plus-svg';
import CreateNewLayoutPagePopup from '../../molecules/newLayoutPagePopup';
import { getLandingPage } from '../../utils/getLandingPageName';
import { ICustomStory, IFeatureToggle, ILandingPageLayout, ILandingPageLayouts, IMatch } from '../../utils/interfaces';
import { getStoryName } from '../../utils/utils';

const generateStoryOptions = (
  {
    storyPage = false,
    videoStoryPage = false,
    photoStoryPage = false,
    liveBlogStoryPage = false,
    listicleStoryPage = false
  },
  customStoryTemplates: ICustomStory,
  baseURL: string
) => {
  const storyBaseURL = `${baseURL}/story`;

  const customStoryOptions = generateCustomStoryOptions(customStoryTemplates, storyBaseURL);
  const defaultStoryOptions = [
    {
      label: 'Text Story',
      title: 'Text Story',
      link: `${storyBaseURL}/text`,
      shouldRender: storyPage
    },
    {
      label: 'Video Story',
      title: 'Video Story',
      link: `${storyBaseURL}/video`,
      shouldRender: videoStoryPage
    },
    {
      label: 'Photo Story',
      title: 'Photo Story',
      link: `${storyBaseURL}/photo`,
      shouldRender: photoStoryPage
    },
    {
      label: 'Live Blog Story',
      title: 'Live Blog Story',
      link: `${storyBaseURL}/live-blog`,
      shouldRender: liveBlogStoryPage
    },
    {
      label: 'Listicle Story',
      title: 'Listicle Story',
      link: `${storyBaseURL}/listicle`,
      shouldRender: listicleStoryPage
    }
  ];

  return [
    ...defaultStoryOptions,
    ...customStoryOptions,
    {
      label: 'Create New Story',
      title: 'Create New Story',
      shouldRender: storyPage,
      customIcon: Plus,
      onClick: createNewStoryPage
    }
  ];
};

const generateCustomStoryOptions = (customStoryTemplates: ICustomStory, baseURL: string) => {
  const options: any = [];

  Object.entries(customStoryTemplates).forEach(([name]) => {
    if (name !== 'newsElsewhere-story') {
      const customStoryName = kebabCase(getStoryName(name));
      options.push({
        label: `${startCase(customStoryName)} Story`,
        title: `${startCase(customStoryName)} Story`,
        link: `${baseURL}/${encodeURIComponent(customStoryName)}`,
        shouldRender: true
      });
    }
  });

  return options;
};

const generateSectionPageOptions = (
  { sectionPage = false, landingPage = false },
  landingPagesLayouts: ILandingPageLayouts,
  baseURL: string
) => {
  const options = [
    {
      label: 'Section page',
      title: 'Section page',
      link: `${baseURL}/section`,
      shouldRender: sectionPage
    }
  ];
  if (landingPagesLayouts && landingPage) {
    Object.entries(landingPagesLayouts).forEach(([layoutId, layoutObj]: [string, ILandingPageLayout]) => {
      !layoutObj.isDeleted &&
        layoutObj.layoutName &&
        options.push({
          label: layoutObj.layoutName as string,
          title: layoutObj.layoutName as string,
          link: `${baseURL}/landing-page?layoutId=${layoutId}`,
          shouldRender: true
        });
    });
  }
  return options;
};

const generateLandingOptions = (
  { sectionPage = false, landingPage = true },
  landingPagesLayouts: ILandingPageLayouts,
  baseURL: string
) => {
  let options = [];
  options.push({
    label: 'Home page',
    title: 'Home page',
    link: baseURL,
    shouldRender: true
  });
  options = [
    ...options,
    ...generateSectionPageOptions({ sectionPage, landingPage }, landingPagesLayouts, baseURL),
    ...generateLandingPageCTAs({ landingPage })
  ];
  return options;
};

const generateLandingPageCTAs = ({ landingPage = false }) => [
  {
    label: 'Create New Landing Page',
    title: 'Create New Landing Page',
    shouldRender: landingPage,
    customIcon: Plus,
    onClick: createNewLandingPage
  }
];

const createNewLandingPage = (dispatch: Dispatch) => {
  dispatch(
    showPopup({
      popupComponent: CreateNewLayoutPagePopup,
      meta: { layoutType: 'landing' },
      popupHeader: 'Create Section Layout'
    })
  );
};

const createNewStoryPage = (dispatch: Dispatch) => {
  dispatch(
    showPopup({
      popupComponent: CreateNewLayoutPagePopup,
      meta: { layoutType: 'story' },
      popupHeader: 'Create Story Template'
    })
  );
};

const generateOptions = (
  publisherId = '',
  featureToggle: IFeatureToggle = {},
  landingPagesLayouts: ILandingPageLayouts,
  customStoryTemplates: ICustomStory = {},
  domain: string
) => {
  const {
    tagPage = false,
    authorPage = false,
    searchPage = false,
    landingPages = false,
    storyPage = false,
    authorsPage = false,
    magazinePage = false,
    isMeteredPaywallEnabled = false,
    subscriptions = false,
    loginPages = false,
    profilePage = false,
    purchasedStoryPage = false,
    manageSubscriptions = false
  } = featureToggle;

  const baseURL = `/accounts/${publisherId}/${domain}`;

  const options = [
    {
      label: 'Landing Page',
      title: 'Landing Pages',
      pageType: 'home',
      shouldRender: landingPages,
      options: generateLandingOptions(featureToggle, landingPagesLayouts, baseURL)
    },
    {
      label: 'Magazine',
      title: 'Magazine',
      shouldRender: magazinePage,
      options: [
        {
          label: 'Magazine Landing',
          link: `${baseURL}/magazine-landing`,
          shouldRender: magazinePage
        },
        {
          label: 'Magazine Issue',
          link: `${baseURL}/issue-landing`,
          shouldRender: magazinePage
        },
        {
          label: 'Magazine Archive',
          link: `${baseURL}/magazine-archive`,
          shouldRender: magazinePage
        }
      ]
    },
    {
      label: 'Search Page',
      title: 'Search Page',
      link: `${baseURL}/search`,
      shouldRender: searchPage
    },
    {
      label: 'Tag Page',
      title: 'Tag Page',
      link: `${baseURL}/tag`,
      shouldRender: tagPage
    },
    {
      label: 'Author Page',
      title: 'Author Page',
      link: `${baseURL}/author`,
      shouldRender: authorPage
    },
    {
      label: 'Authors Listing Page',
      title: 'Authors Listing Page',
      link: `${baseURL}/authors`,
      shouldRender: authorsPage
    },
    {
      label: 'Profile Page',
      title: 'Profile Page',
      link: `${baseURL}/user-details`,
      shouldRender: profilePage
    },
    {
      label: 'Purchased Stories Page',
      title: 'Purchased Stories Page',
      link: `${baseURL}/purchased-stories`,
      shouldRender: purchasedStoryPage
    },
    {
      label: 'Subscriptions',
      title: 'Subscriptions',
      shouldRender: subscriptions,
      options: [
        { label: 'Subscription Page', link: `${baseURL}/subscription`, shouldRender: subscriptions },
        {
          label: 'Manage Subscriptions Overview',
          link: `${baseURL}/manage-subscriptions`,
          shouldRender: manageSubscriptions
        },
        {
          label: 'Available Plans',
          link: `${baseURL}/available-plans`,
          shouldRender: manageSubscriptions
        },
        {
          label: 'Plan Change Overview',
          link: `${baseURL}/plan-change-overview`,
          shouldRender: manageSubscriptions
        }
      ]
    },
    {
      label: 'Sign in Page',
      title: 'Sign in Page',
      shouldRender: loginPages,
      options: [
        {
          label: 'Sign in/ Sign up',
          link: `${baseURL}/sign-in`,
          shouldRender: loginPages
        },
        {
          label: 'New Account Creation',
          link: `${baseURL}/sign-up`,
          shouldRender: loginPages
        },
        {
          label: 'Enter Password',
          link: `${baseURL}/enter-password`,
          shouldRender: loginPages
        },
        {
          label: 'Enter OTP (Mobile)',
          link: `${baseURL}/mobile-otp`,
          shouldRender: loginPages
        },
        {
          label: 'Enter OTP (Email Verification)',
          link: `${baseURL}/email-otp`,
          shouldRender: loginPages
        },
        {
          label: 'Forgot Password',
          link: `${baseURL}/forgot-password`,
          shouldRender: loginPages
        },
        {
          label: 'Reset Password',
          link: `${baseURL}/reset-password`,
          shouldRender: loginPages
        }
      ]
    },
    {
      label: 'Metered Paywall/Registration',
      title: 'Metered Paywall/Registration',
      link: `${baseURL}/metering`,
      shouldRender: isMeteredPaywallEnabled
    }
  ];
  if (storyPage) {
    options.splice(1, 0, {
      label: 'Story Page',
      title: 'Story Types',
      pageType: 'story',
      shouldRender: storyPage,
      options: generateStoryOptions(featureToggle, customStoryTemplates, baseURL)
    });
  }
  return options;
};

const getCurrentRouteLabel = (match: IMatch, layouts?: ILandingPageLayouts, pageId?: string) => {
  const landingPageObj = layouts && pageId && getLandingPage(layouts, pageId);
  switch (match.path) {
    case '/accounts/:publisherId/:domain/story/:storyType':
      return `${startCase(match.params.storyType)} Story`;
    case '/accounts/:publisherId/:domain':
      return 'Home page';
    case '/accounts/:publisherId/:domain/section':
      return 'Section Page';
    case '/accounts/:publisherId/:domain/tag':
      return 'Tag Page';
    case '/accounts/:publisherId/:domain/author':
      return 'Author Page';
    case '/accounts/:publisherId/:domain/authors':
      return 'Authors Listing Page';
    case '/accounts/:publisherId/:domain/search':
      return 'Search Page';
    case '/accounts/:publisherId/:domain/issue-landing':
      return 'Magazine Issue Page';
    case '/accounts/:publisherId/:domain/magazine-landing':
      return 'Magazine Landing Page';
    case '/accounts/:publisherId/:domain/landing-page':
      return landingPageObj ? `${get(landingPageObj, [1, 'layoutName'])} Settings` : '';
    case '/accounts/:publisherId/:domain/magazine-archive':
      return 'Magazine Archive Page';
    case '/accounts/:publisherId/:domain/subscription':
      return 'Subscription Page';
    case '/accounts/:publisherId/:domain/manage-subscriptions':
      return 'Subscriptions Overview';
    case '/accounts/:publisherId/:domain/available-plans':
      return 'Available Plans';
    case '/accounts/:publisherId/:domain/plan-change-overview':
      return 'Plan Change Overview';
    case '/accounts/:publisherId/:domain/sign-in':
      return 'Sign in/ Sign up Page';
    case '/accounts/:publisherId/:domain/sign-up':
      return 'New Account Creation Page';
    case '/accounts/:publisherId/:domain/forgot-password':
      return 'Forgot Password Page';
    case '/accounts/:publisherId/:domain/reset-password':
      return 'Reset Password Page';
    case '/accounts/:publisherId/:domain/mobile-otp':
      return 'Enter OTP (Mobile) Page';
    case '/accounts/:publisherId/:domain/email-otp':
      return 'Enter OTP (Email Verification) Page';
    case '/accounts/:publisherId/:domain/enter-password':
      return 'Enter Password Page';
    case '/accounts/:publisherId/:domain/metering':
      return 'Metered Paywall/Registration';
    case '/accounts/:publisherId/:domain/user-details':
      return 'Profile Page';
    case '/accounts/:publisherId/:domain/purchased-stories':
      return 'Purchased Stories Page';
    default:
      return 'Home page';
  }
};

export { generateOptions, getCurrentRouteLabel };
