import photoIcon1 from './svg/photo-icons/photo-icon1.svg';
import photoIcon2 from './svg/photo-icons/photo-icon2.svg';
import photoIcon3 from './svg/photo-icons/photo-icon3.svg';
import photoIcon4 from './svg/photo-icons/photo-icon4.svg';

import videoIcon1 from './svg/video-icons/video-icon1.svg';
import videoIcon2 from './svg/video-icons/video-icon2.svg';
import videoIcon3 from './svg/video-icons/video-icon3.svg';
import videoIcon4 from './svg/video-icons/video-icon4.svg';
import videoIcon5 from './svg/video-icons/video-icon5.svg';

import visualStoryIcon1 from './svg/visual-story-icons/visual-story-icon1.svg';
import visualStoryIcon2 from './svg/visual-story-icons/visual-story-icon2.svg';
import visualStoryIcon3 from './svg/visual-story-icons/visual-story-icon3.svg';
import visualStoryIcon4 from './svg/visual-story-icons/visual-story-icon4.svg';

export const photoStoryIconsData = [
  { name: 'photoIcon1', svg: photoIcon1 },
  { name: 'photoIcon2', svg: photoIcon2 },
  { name: 'photoIcon3', svg: photoIcon3 },
  { name: 'photoIcon4', svg: photoIcon4 }
];

export const videoStoryIconsData = [
  { name: 'videoIcon1', svg: videoIcon1 },
  { name: 'videoIcon2', svg: videoIcon2 },
  { name: 'videoIcon3', svg: videoIcon3 },
  { name: 'videoIcon4', svg: videoIcon4 },
  { name: 'videoIcon5', svg: videoIcon5 }
];

export const visualStoryIconsData = [
  { name: 'visualStoryIcon1', svg: visualStoryIcon1 },
  { name: 'visualStoryIcon2', svg: visualStoryIcon2 },
  { name: 'visualStoryIcon3', svg: visualStoryIcon3 },
  { name: 'visualStoryIcon4', svg: visualStoryIcon4 }
];
