import { TextField } from '@quintype/em/components/text-field';
import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updatePageSettings } from '../../actions/builder-action';
import { PAGEBUILDER_GENERAL_UPDATE_LOCALIZATION } from '../../reducers/builder/configReducer/actions';

interface IOptions {
  label: string;
  value: string;
  countryIso: string;
}

interface IDataItems {
  type: string;
  label: string;
  key: string;
  defaultValue?: string | boolean | IOptions;
  placeHolder?: string;
  darkKey?: string;
  options?: IOptions[];
}

export const ProfilePageSettings = () => {
  const dispatch = useDispatch();
  const { profilePage = {} } = useSelector(state => get(state, ['builder', 'config'], {}));
  const updateField = (key: string, value: string | boolean) => {
    dispatch(updatePageSettings({ [key]: value }, 'profilePage'));
    const payload = { buttonLabels: { [key]: value } };
    dispatch({ type: PAGEBUILDER_GENERAL_UPDATE_LOCALIZATION, payload });
    dispatch(updateHaveUnsavedChanges(true));
  };
  const setting = [
    {
      label: 'My Profile',
      type: 'textField',
      key: 'dropdownProfileText',
      placeHolder: 'My Profile',
      defaultValue: 'My Profile'
    }
  ];

  return (
    <>
      <h4>Dropdown Menu Text</h4>
      {setting.map((item: IDataItems) => {
        const { pageSetting = {} } = profilePage;
        const { type, label, key, placeHolder } = item || {};
        switch (type) {
          case 'textField':
            return (
              <TextField
                label={label}
                placeholder={placeHolder}
                value={get(pageSetting, [key])}
                onChange={(value: any) => updateField(key, value)}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
};
