import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { setPageType } from '../../actions/builder-action';
import { AheadPreview } from '../../ahead/ahead-preview';
import { SelectRowInspector } from '../../molecules/select-row-inspector/index';
import { Header } from '../../row/header';

const LandingPage = () => {
  const { publisherId = '' } = useParams();
  const location = useLocation();
  // Based on the URL we set the correct pageType
  const pageType = location.pathname.includes('landing-page') ? 'landingPage' : 'section';
  const dispatch = useDispatch();
  dispatch(setPageType(pageType));
  return (
    <>
      <Header publisherId={publisherId} />
      <AheadPreview />
      <SelectRowInspector publisherId={publisherId} />
    </>
  );
};

// Older name: SectionPage. Currently we are handling all landing pages as one.
export default LandingPage;
