import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import { FieldLabel } from '@quintype/em/components/field-label';
import Select from '@quintype/em/components/select';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updatePBConfig } from '../../actions/builder-action';
import { IDomainList, IDropdownOption } from '../../utils/interfaces';
import { getPublishedConfig } from '../../utils/utils';
import PBInspector from '../inspector';
import styles from './importer-inspector.module.css';

const getDomainDropdownList = (domainList: IDomainList[], domain: string) => {
  return domainList
    .filter((item: IDomainList) => item.configured && item.slug !== domain)
    .map((item: IDomainList) => ({ label: item.name, value: item.slug }));
};

const settingOptions = [
  { sectionLabel: 'IMPORT GENERAL SETTINGS', configKey: 'general' },
  { sectionLabel: 'IMPORT HEADER SETTINGS', configKey: 'header' },
  { sectionLabel: 'IMPORT FOOTER SETTINGS', configKey: 'footer' }
];

interface IProps {
  title: string;
  isActive: boolean;
  onCloseHandler: () => void;
  domain: string;
  publisherId: string;
}

const Importer = (props: IProps) => {
  const { title, isActive, onCloseHandler, domain: currentDomain, publisherId } = props;
  const { domainList = [], haveUnsavedChanges = false, config = {} } = useSelector(state =>
    get(state, ['builder'], {})
  );
  const dispatch = useDispatch();

  const handleChange = async (domain: string, configKey: string) => {
    try {
      const { data } = await getPublishedConfig(publisherId, domain);
      const importedSettings = get(data, ['config', configKey], {});
      const updatedConfig = {
        ...config,
        [configKey]: importedSettings
      };
      dispatch(updatePBConfig(updatedConfig));
      dispatch(updateHaveUnsavedChanges(true));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <PBInspector
      title={title}
      isActive={isActive}
      onActionButtonClick={onCloseHandler}
      isActionButtonDisabled={!haveUnsavedChanges}
      onClose={onCloseHandler}
    >
      <Accordion hasSingleActivePanel onToggle={() => {}}>
        {settingOptions.map((item, index) => (
          <AccordionSection label={item.sectionLabel} isLabelUpperCase index={index} key={index}>
            <div className={`${styles.formRow} ${styles.dropdown}`}>
              <FieldLabel label="Import From Domain" />
              <Select
                options={getDomainDropdownList(domainList, currentDomain)}
                onChange={(event: IDropdownOption) => handleChange(event.value, item.configKey)}
              />
            </div>
          </AccordionSection>
        ))}
      </Accordion>
    </PBInspector>
  );
};

export default Importer;
