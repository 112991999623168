export const ampLocalization = [
  { label: 'By', value: 'by', type: 'textField', placeholder: 'By' },
  { label: 'Published', value: 'published', type: 'textField', placeholder: 'Published' },
  { label: 'Live Blog Updated at', value: 'updatedAt', type: 'textField', placeholder: 'Updated at' },
  {
    label: 'Also Read Story Element',
    value: 'alsoReadStoryElement',
    type: 'textField',
    placeholder: 'Also Read Story Element'
  },
  {
    label: 'Scroll For Next',
    value: 'infiniteScrollSeparatorText',
    type: 'textField',
    placeholder: 'Scroll for next'
  },
  { label: 'Preview', value: 'attachmentElementPreview', type: 'textField', placeholder: 'Preview' },
  {
    label: 'Related Stories Header Text',
    value: 'relatedStoriesHeaderText',
    type: 'textField',
    placeholder: 'Related Stories Header Text'
  }
];
