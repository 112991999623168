import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Globe: React.FunctionComponent<IsvgData> = ({ width = '16', height = '16', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="{width}" height="{height}" viewBox="0 0 16 16">
      <defs>
        <path
          id="prefix__a"
          d="M7 .333c1.2 0 2.312.3 3.333.9a6.736 6.736 0 012.433 2.434c.6 1.021.9 2.132.9 3.333 0 1.2-.3 2.312-.9 3.333a6.736 6.736 0 01-2.433 2.433c-1.021.6-2.132.9-3.333.9-1.2 0-2.312-.3-3.333-.9a6.736 6.736 0 01-2.433-2.433A6.462 6.462 0 01.334 7c0-1.2.3-2.312.9-3.333a6.736 6.736 0 012.433-2.433c1.021-.6 2.132-.9 3.333-.9zm5.188 4.086a5.723 5.723 0 00-1.384-1.814A5.442 5.442 0 008.8 1.489c.538.735.932 1.712 1.183 2.93h2.204zM9.366 7c0-.573-.036-1.147-.108-1.72H4.742a13.858 13.858 0 000 3.44h4.516c.072-.573.108-1.147.108-1.72zM7 1.194c-.376 0-.762.286-1.156.86-.412.591-.726 1.38-.94 2.365h4.193c-.215-.985-.529-1.774-.941-2.365-.394-.574-.78-.86-1.156-.86zm-1.801.295a5.442 5.442 0 00-2.003 1.116 5.723 5.723 0 00-1.384 1.814h2.204c.251-1.2.645-2.177 1.183-2.93zM1.194 7c0 .591.09 1.165.268 1.72h2.42a13.75 13.75 0 010-3.44h-2.42A5.57 5.57 0 001.194 7zm.618 2.58c.34.7.802 1.304 1.384 1.815.583.51 1.25.883 2.003 1.116-.538-.735-.932-1.712-1.183-2.93H1.812zM7 12.807c.376 0 .762-.286 1.156-.86.412-.591.726-1.38.94-2.365H4.904c.215.985.529 1.774.941 2.365.394.574.78.86 1.156.86zm1.828-.295a5.477 5.477 0 001.976-1.116 5.723 5.723 0 001.384-1.814H9.984c-.251 1.2-.636 2.177-1.156 2.93zm1.29-3.79h2.42A5.57 5.57 0 0012.806 7a5.57 5.57 0 00-.268-1.72h-2.42a13.75 13.75 0 010 3.44z"
        />
      </defs>
      <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
        <mask id="prefix__b" fill="#fff">
          <use href="#prefix__a" />
        </mask>
        <use fill="#0C0033" href="#prefix__a" />
        <g fill="#D5D5D5" mask="url(#prefix__b)">
          <path d="M0 0H16V16H0z" transform="translate(-1 -1)" />
        </g>
        <path d="M-1 -1H23V23H-1z" mask="url(#prefix__b)" />
      </g>
    </svg>
  );
};

export { Globe };
