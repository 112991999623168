import React from 'react';
import styles from './widget.module.css';

const WidgetComponent = () => {
  return (
    <div data-test-id="widget" className={styles.wrapper}>
      <div className={styles.customWidget}>Custom Code</div>
    </div>
  );
};

export default WidgetComponent;
