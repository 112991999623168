import React from 'react';
import styles from './styles.module.css';

interface IProps {
  children: React.ReactNode;
  className: string;
}
const SmallTitle = ({ children, className, ...props }: IProps) => {
  return (
    <h3 className={`${className} ${styles.title}`} {...props}>
      {children}
    </h3>
  );
};

export default SmallTitle;
