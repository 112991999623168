import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMagazineConfig, updateHaveUnsavedChanges } from '../../actions/builder-action';
import ViewEditor from '../../atoms/inspector-accordion-button/button';
import { AccesstypeIntegration } from '../accesstype-integration';
import AdsGeneralSettings from '../ads-general-settings';
import AmpSettings from '../amp-setting';
import AppDownloadLinksGeneralSettings from '../app-download-links';
import CommentPlugin from '../commentPlugin';
import InfiniteScrollSettings from '../infinite-scroll-settings';
import NotificationGeneralSettings from '../notification-general-settings';
import { Others } from '../others';
import PerformanceSettings from '../performance-settings';
import { SingleSignOn } from '../single-sign-on';
import { ThirdPartyIntegrations } from '../third-party-integrations';
import TimeFormatter from '../time-formatter';
import { ToggleButton } from '../toggle-button';
import { UsersAndRoles } from '../users-and-roles';
import styles from './manage.module.css';
import BotsSettings from '../bots-settings';

const Manage = ({ toggleConfirmationPopup }: any) => {
  const [openAdsTab, toggleAdsTab] = useState(false);
  const { magazine } = useSelector((state: any) => get(state, ['builder', 'config'], {}));
  const enableMagazine = get(magazine, ['enableMagazine'], false);
  const dispatch = useDispatch();
  return (
    <div className={styles.manageWrapper}>
      <Accordion hasSingleActivePanel onToggle={() => toggleAdsTab(!openAdsTab)}>
        <AccordionSection label="Ads Integration" isLabelUpperCase index={0}>
          <AdsGeneralSettings />
        </AccordionSection>
        <AccordionSection label="Notifications" isLabelUpperCase index={1}>
          <NotificationGeneralSettings />
        </AccordionSection>
        <AccordionSection label="Magazine" isLabelUpperCase index={2}>
          <ToggleButton
            label="Enable Magazine"
            toggleName="enableMagazine"
            id="enableMagazine"
            updateToggleState={enableMagazine}
            updateToggleButton={(name: string, value: boolean) => {
              dispatch(addMagazineConfig({ [name]: value }));
              dispatch(updateHaveUnsavedChanges(true));
            }}
          />
        </AccordionSection>
        <AccordionSection label="Comment Plugin" isLabelUpperCase index={3}>
          <CommentPlugin />
        </AccordionSection>
        <AccordionSection label="Infinite Scroll" isLabelUpperCase index={4}>
          <InfiniteScrollSettings />
        </AccordionSection>
        <AccordionSection label="Accesstype Integration" isLabelUpperCase index={5}>
          <AccesstypeIntegration />
        </AccordionSection>
        <AccordionSection label="Third Party Integrations" isLabelUpperCase index={6}>
          <ThirdPartyIntegrations />
        </AccordionSection>
        <AccordionSection label="App Download Links" isLabelUpperCase index={7}>
          <AppDownloadLinksGeneralSettings />
        </AccordionSection>
        <AccordionSection label="AMP Configuration" isLabelUpperCase index={8}>
          <AmpSettings toggleConfirmationPopup={toggleConfirmationPopup} />
        </AccordionSection>
        <AccordionSection label="Insert Custom Code" isLabelUpperCase index={9}>
          <ViewEditor
            toggleConfirmationPopup={toggleConfirmationPopup}
            navigationPath="custom-code"
            text="Click here to insert"
          />
        </AccordionSection>
        <AccordionSection label="Performance" isLabelUpperCase index={10}>
          <PerformanceSettings />
        </AccordionSection>
        <AccordionSection label="Hide below custom scripts from Bots" isLabelUpperCase index={11}>
          <BotsSettings />
        </AccordionSection>
        <AccordionSection label="Time Settings" isLabelUpperCase index={12}>
          <TimeFormatter />
        </AccordionSection>
        <AccordionSection label="Users and Roles" isLabelUpperCase index={13}>
          <UsersAndRoles />
        </AccordionSection>
        <AccordionSection label="Others" isLabelUpperCase index={14}>
          <Others />
        </AccordionSection>
        <AccordionSection label="Single Sign-On" isLabelUpperCase index={15}>
          <SingleSignOn />
        </AccordionSection>
        <AccordionSection label="Manage Redirections" isLabelUpperCase index={16}>
          <ViewEditor
            toggleConfirmationPopup={toggleConfirmationPopup}
            navigationPath="redirects"
            text="Click here to add"
          />
        </AccordionSection>
      </Accordion>
    </div>
  );
};

export default Manage;
