import { Checkbox } from '@quintype/em/components/checkbox';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import Select from '@quintype/em/components/select';
import { TextArea } from '@quintype/em/components/text-area';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../../actions/builder-action';
import { updateRowsList } from '../../../actions/row-action';
import { defaultDarkTheme, defaultTheme } from '../../../constant';
import { ThemeSetter } from '../../theme-setter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './setting.module.css';
import { FieldLabel } from '@quintype/em/components/field-label';
import { copyTextToClipboard } from '../utils';
import { Separator } from '../../../atoms/separator';
import { ToggleButton } from '../../toggle-button';

interface IProps {
  rowId: number;
  config: {
    showSection?: boolean;
  };
  layout: string;
  data: string[];
}

interface IShortCode {
  name: string;
  value: string;
}

interface IRowComponent {
  label: string;
  keyName: string;
  type: string;
  defaultValue: boolean;
  dropDownOptions: string[];
  placeholder: string | undefined;
  hint?: string | undefined;
  radioOptions?: Object[];
  handleDisable: boolean;
  shortCodeOptions?: IShortCode[] | undefined;
}

interface IOptions {
  label: string;
  value: string | boolean;
  disabled: boolean;
}

export const RowSetting = ({ rowId, config = {}, layout, data }: IProps) => {
  const dispatch = useDispatch();
  const { enableDarkModePreview = false } = useSelector(state => get(state, ['builder', 'config'], {}));
  const updateRowOptions = (key: string, value: string | boolean) => {
    dispatch(
      updateRowsList({
        rowAction: 'update_settings',
        rowActionPayload: { selectedRowId: rowId, row: { [key]: value } }
      })
    );
    dispatch(updateHaveUnsavedChanges(true));
  };

  const templateOptionsMap = () => {
    return (
      data && data.map((rowComponent: any, index: number) => <div key={index}>{selectInputType(rowComponent)}</div>)
    );
  };

  const getCardColorPicker = (label = '', keyName = '', darkThemeKeyName = '') => {
    const showCardBgColor = get(config, ['showCardBgColor'], false);
    const border = get(config, ['border'], false);
    if (showCardBgColor && border === 'aroundBorder') {
      return (
        <ThemeSetter
          label={label}
          updateTheStore={updateRowOptions}
          lightColorExtract={config && get(config, [keyName])}
          lightUpdateStoreKey={keyName}
          darkColorExtract={config && get(config, [darkThemeKeyName])}
          darkUpdateStoreKey={darkThemeKeyName}
          lightDefaultColor={keyName === 'customBulletColor' ? defaultDarkTheme : defaultTheme}
          darkDefaultColor={keyName === 'customBulletColor' ? defaultTheme : defaultDarkTheme}
          enableDarkModePreview={enableDarkModePreview}
        />
      );
    }
  };

  const getCardBgToggle = (label = '', keyName = '', inputValue = false) => {
    const border = get(config, ['border'], false);
    if (border === 'aroundBorder') {
      return (
        <div className={styles.toggleContainer}>
          <ToggleButton
            label={label}
            toggleName={label}
            id={rowId}
            updateToggleState={inputValue}
            updateToggleButton={(name: string, value: boolean) => {
              updateRowOptions(keyName, value);
            }}
          />
          {inputValue && <br />}
        </div>
      );
    }
  };

  const selectInputType = (rowComponent: IRowComponent) => {
    const {
      label,
      keyName,
      type,
      defaultValue = '',
      placeholder,
      dropDownOptions,
      hint = '',
      radioOptions = [],
      handleDisable = false,
      shortCodeOptions = []
    } = rowComponent;
    const darkThemeKeyName = `dark${startCase(keyName)}`.replaceAll(' ', '');
    const inputValue = get(config, [keyName], defaultValue);
    switch (type) {
      case 'checkbox':
        return (
          <div className={styles.checkboxWrapper}>
            <Checkbox
              onChange={(value: boolean) => updateRowOptions(keyName, value)}
              label={label}
              id={keyName}
              checked={inputValue}
            />
          </div>
        );
      case 'text':
        return (
          <div>
            <TextField
              label={label}
              value={inputValue}
              onChange={(value: string) => updateRowOptions(keyName, value)}
              placeholder={placeholder}
              hint={hint}
            />
          </div>
        );
      case 'textArea':
        return (
          <div>
            <TextArea
              value={inputValue}
              onChange={(value: string) => updateRowOptions(keyName, value)}
              label={label}
              placeholder={placeholder}
            />
          </div>
        );
      case 'dropDown':
        const inputValueForSelect = typeof inputValue === 'number' ? inputValue.toString() : inputValue;
        const selectedOption: any = dropDownOptions.find((option: any) => option.value === inputValueForSelect);
        return (
          <Select
            label={label}
            options={dropDownOptions}
            onChange={(option: IOptions) => updateRowOptions(keyName, option.value)}
            defaultInputValue={selectedOption?.label || inputValueForSelect}
          />
        );
      case 'colorPicker':
        return (
          <ThemeSetter
            label={label}
            updateTheStore={updateRowOptions}
            lightColorExtract={config && get(config, [keyName])}
            lightUpdateStoreKey={keyName}
            darkColorExtract={config && get(config, [darkThemeKeyName])}
            darkUpdateStoreKey={darkThemeKeyName}
            lightDefaultColor={keyName === 'customBulletColor' ? defaultDarkTheme : defaultTheme}
            darkDefaultColor={keyName === 'customBulletColor' ? defaultTheme : defaultDarkTheme}
            enableDarkModePreview={enableDarkModePreview}
          />
        );
      case 'radioButton':
        return (
          <div className={styles.radioButtonWrapper}>
            <Radio
              label={label}
              name={keyName}
              align="vertical"
              selected={inputValue}
              onChange={(e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) =>
                updateRowOptions(keyName, (e.target as HTMLInputElement).value)
              }
            >
              {radioOptions.map(({ name, value, index, isEnabled }: any, ind) => {
                if (handleDisable) {
                  return (
                    <RadioOption value={value} key={value} isDisabled={!isEnabled}>
                      {name}
                    </RadioOption>
                  );
                } else {
                  return (
                    <RadioOption value={value} key={value}>
                      {name}
                    </RadioOption>
                  );
                }
              })}
            </Radio>
          </div>
        );
      case 'quillEditor':
        const modules = {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }]
          ]
        };
        const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet'];
        return (
          <div className={styles.quillWrapper}>
            <FieldLabel label={label} />
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={(config && get(config, [keyName])) || placeholder}
              onChange={(value: any) => updateRowOptions(keyName, value)}
              placeholder=" "
            />
            {hint ? <div className={styles.hintWrapper}>{hint}</div> : <></>}
          </div>
        );
      case 'label':
        return (
          <div className={styles.labelWrapper}>
            <FieldLabel label={label} />
          </div>
        );
      case 'shortCodes':
        return (
          <div>
            <FieldLabel label={label} />
            <div className={styles.contentWrapper}>
              Click on any of the shortcodes below to copy, and then paste it wherever it is needed.
            </div>
            {shortCodeOptions.map((shortCode, index) => {
              return (
                <span
                  key={`${shortCode?.name}-${index}`}
                  className={styles.shortCodeWrapper}
                  onClick={() => copyTextToClipboard(shortCode?.value)}
                >
                  {shortCode?.name}
                </span>
              );
            })}
          </div>
        );
      case 'separator':
        return <Separator />;
      case 'toggle':
        return getCardBgToggle(label, keyName, inputValue);
      case 'cardColorPicker':
        return getCardColorPicker(label, keyName, darkThemeKeyName);
    }
  };
  return <>{templateOptionsMap()}</>;
};
