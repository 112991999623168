import React, { useState } from 'react';

import { ContextMenu, ContextMenuItem, ContextMenuList } from '@quintype/em/components/context-menu';
import { EditRedirect } from './edit-redirect';

import { IRedirectItem } from '../../utils/interfaces';
import { EditType, IErrorMessageData } from './index';
import styles from './redirect-list.module.css';
import { SimultanoeusEditDialog } from './simulatneous-action-dialog';

interface IRedirect {
  onItemChange: (item: IRedirectItem) => void;
  onItemAdd: (type: EditType, id: string) => boolean;
  onItemCancel: () => void;
  onItemDelete: (id: string) => void;
  error: IErrorMessageData;
  onItemEditStart: (id: string) => void;
  editItem: string | null;
  hasEditAccess: boolean;
}

interface IRedirectList extends IRedirect {
  items: IRedirectItem[];
}

interface IRedirectItemComp extends IRedirect {
  redirectData: IRedirectItem;
}

function RedirectReadItem({
  redirectData,
  onEditClick,
  onDeleteClick,
  hasEditAccess
}: {
  redirectData: IRedirectItem;
  onEditClick: () => void;
  onDeleteClick: (a: string) => void;
  hasEditAccess: boolean;
}) {
  const { sourceUrl, destinationUrl, statusCode, id } = redirectData;
  return (
    <div className={styles['read_item_wrapper']}>
      <div className={styles['read_item_contents_wrapper']}>
        <div className={styles['read_item_content_wrapper']}>
          <span className={styles['read_item_title']}>Source Path</span>
          <span className={styles['read_item_value']}>{sourceUrl}</span>
        </div>
        <div className={styles['read_item_content_wrapper']}>
          <span className={styles['read_item_title']}>Destination Path</span>
          <span className={styles['read_item_value']}>{destinationUrl}</span>
        </div>
        <div className={styles['read_item_content_wrapper']}>
          <span className={styles['read_item_title']}>Status Code</span>
          <span className={styles['read_item_value']}>{statusCode}</span>
        </div>
      </div>
      {hasEditAccess && (
        <div className={styles['read_item_context_menu_container']}>
          <ContextMenu position="left">
            <ContextMenuList>
              <ContextMenuItem label="Edit" onClick={onEditClick} />
              <ContextMenuItem label="Delete" onClick={() => onDeleteClick(id)} />
            </ContextMenuList>
          </ContextMenu>
        </div>
      )}
    </div>
  );
}

function RedirectItem({
  redirectData,
  onItemChange,
  onItemAdd,
  onItemCancel,
  onItemDelete,
  onItemEditStart,
  editItem,
  error,
  hasEditAccess
}: IRedirectItemComp) {
  const [mode, updateMode] = useState('read');
  const [showSimultaneousDialog, updateDialogVisibility] = useState(false);

  function onAdd(type: EditType, id: string) {
    const result = onItemAdd(type, id);
    if (!result) return;
    updateMode('read');
  }

  function onCancel() {
    onItemCancel();
    updateMode('read');
  }

  function localOnItemDeleteClick(id: string) {
    if (!editItem) {
      onItemDelete(id);
    } else {
      updateDialogVisibility(true);
    }
  }

  function onEditClick() {
    if (!editItem) {
      onItemEditStart(redirectData.id);
      updateMode('edit');
    } else {
      updateDialogVisibility(true);
    }
  }

  return (
    <div className={styles['redirect_item_wrapper']}>
      <SimultanoeusEditDialog isOpen={showSimultaneousDialog} onClose={() => updateDialogVisibility(false)} />
      {mode === 'read' ? (
        <>
          <RedirectReadItem
            redirectData={redirectData}
            onEditClick={onEditClick}
            onDeleteClick={localOnItemDeleteClick}
            hasEditAccess={hasEditAccess}
          />
        </>
      ) : (
        <>
          <EditRedirect
            title="Update redirection"
            type="edit"
            redirectData={redirectData}
            onCancel={onCancel}
            onAdd={onAdd}
            onChange={onItemChange}
            error={error}
          />
        </>
      )}
    </div>
  );
}

export function RedirectList({
  items,
  onItemChange,
  onItemAdd,
  onItemCancel,
  onItemDelete,
  onItemEditStart,
  editItem,
  error,
  hasEditAccess
}: IRedirectList) {
  return (
    <div className={styles['redirect_list_wrapper']}>
      {items.map(item => {
        const { sourceUrl, destinationUrl, statusCode, id } = item;
        return (
          <RedirectItem
            key={id}
            redirectData={{ sourceUrl, destinationUrl, statusCode, id }}
            onItemChange={onItemChange}
            onItemAdd={onItemAdd}
            onItemCancel={onItemCancel}
            onItemDelete={onItemDelete}
            onItemEditStart={onItemEditStart}
            error={error}
            editItem={editItem}
            hasEditAccess={hasEditAccess}
          />
        );
      })}
    </div>
  );
}
