import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import { renderComponent } from '@quintype/framework/client/start';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { updateBackupConfig, updateHaveLayoutUpdates, updateHaveUnsavedChanges } from '../../actions/builder-action';
import { changeFooterLayout, updateFooterThemeAttributes } from '../../actions/footer-action';
import FullScreenModalWrapper from '../../atoms/full-screen-modal-wrapper';
import returnNull from '../../atoms/null';
import ActionButtons from '../../molecules/action-buttons';
import PBInspector from '../../molecules/inspector';
import { LogoNavigator } from '../../molecules/logo-navigator';
import { LogoPicker } from '../../molecules/logo-picker';
import LowFidelityLayouts from '../../molecules/low-fidelity-layouts';
import { SocialFollowStyle } from '../../molecules/social-link-style';
import { PAGEBUILDER_FOOTER_UPDATE_SOCIAL_LINK_STYLE } from '../../reducers/builder/configReducer/actions';
import { IComponentMeta, IConfig, IPBState, Window } from '../../utils/interfaces';
import { aheadFooterMap } from '../footer-layouts/aheadFooterMap';
import styles from './ahead-footer.module.css';
import { ColorPickerFooter } from './color-picker-footer-panel';
import { FooterAppDownloadLinks } from './footer-app-download-links';
import { FooterSubscription } from './footer-subscription';

declare var window: Window;

interface IProps {
  logoStored: string | undefined;
  darkLogoStored: string | undefined;
  config: IConfig;
  haveUnsavedChanges: boolean;
  footerType: string;
  qt: any;
  enableAppDownloadLinksButton?: boolean;
}

const getFooterStyle = (state: IPBState): string => {
  return get(state, ['builder', 'config', 'footer', 'footerStyle'], 'footer_1');
};

const AheadFooterBase = (props: IProps) => {
  const [showLayoutSelector, toggleLayoutSelector] = useState(false);
  const [showSettingsInspector, toggleSettingsInspector] = useState(false);
  const [isAccordionActive, setAccordionActive] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    const aheadState = { getState: () => props, subscribe: () => null, state: props.qt };

    if (window.QT_COMPONENTS && document.getElementsByTagName('footer').length > 0) {
      renderComponent(window.QT_COMPONENTS.FooterLink, 'footer-link', aheadState);
      if (props.footerType && props.footerType !== 'footer_1') {
        renderComponent(window.QT_COMPONENTS.Menu, 'footer-menu', aheadState, { hydrate: true });
        renderComponent(window.QT_COMPONENTS.SocialFollowWrapper, 'social-follow-container', aheadState, {
          hydrate: true
        });
        if (document.getElementById('footer-mobile-download-links')) {
          if (props.enableAppDownloadLinksButton) {
            renderComponent(window.QT_COMPONENTS.MobileDownloadLinks, 'footer-mobile-download-links', aheadState, {
              hydrate: true
            });
          } else {
            renderComponent(() => <div />, 'footer-mobile-download-links', aheadState, { hydrate: true });
          }
        }
      }
    }
  }, [props.footerType, props.enableAppDownloadLinksButton, props, props.qt]);

  const updateFooterStyles = (socialFollowFooterStyle: string) => {
    dispatch({
      type: PAGEBUILDER_FOOTER_UPDATE_SOCIAL_LINK_STYLE,
      payload: socialFollowFooterStyle
    });
    dispatch(updateHaveUnsavedChanges(true));
  };
  const updateFooterData = (key: string, value: string) => dispatch(updateFooterThemeAttributes({ [key]: value }));

  const pickFooterFn = window.QT_COMPONENTS && window.QT_COMPONENTS.pickFooter;

  const ComponentName = (pickFooterFn && pickFooterFn(props.footerType)) || returnNull;

  const handleLayoutSelect = (footerLayout: IComponentMeta) => {
    toggleLayoutSelector(false);
    dispatch(changeFooterLayout(footerLayout.componentName));
    dispatch(updateHaveLayoutUpdates(true));
  };
  const closeFooterSettings = () => {
    // TODO: Alert user for losing unsaved changes before closing the inspector
    toggleSettingsInspector(false);
  };

  const footerSettingsHandler = () => {
    dispatch(updateBackupConfig({ config: props.config }));
    toggleSettingsInspector(true);
  };
  const onClickHandler = () => {
    return setAccordionActive(!isAccordionActive);
  };
  const socialLinkDescription = 'Choose a style for the social links visible on the footer.';
  return (
    <div className={styles.footer}>
      <div className={styles.buttonGroup}>
        <ActionButtons
          actionList={[
            {
              action: 'replace',
              handler: () => toggleLayoutSelector(true)
            },
            {
              action: 'setting',
              handler: footerSettingsHandler
            }
          ]}
        />
      </div>
      {showLayoutSelector ? (
        <FullScreenModalWrapper onClose={() => toggleLayoutSelector(false)}>
          <LowFidelityLayouts handleLayoutSelect={handleLayoutSelect} layoutsList={aheadFooterMap} />
        </FullScreenModalWrapper>
      ) : null}
      <PBInspector
        title="Footer Settings"
        onActionButtonClick={closeFooterSettings}
        isActive={showSettingsInspector}
        isActionButtonDisabled={!props.haveUnsavedChanges}
        onClose={closeFooterSettings}
      >
        <Accordion hasSingleActivePanel onToggle={() => onClickHandler}>
          <AccordionSection label="Logo" isLabelUpperCase index={0}>
            <LogoPicker
              updateTheStore={updateFooterData}
              updateLogoKey="logo"
              logoStored={props.logoStored}
              darkLogoStored={props.darkLogoStored}
            />
            <LogoNavigator layout="footer" updateStore={updateFooterData} />
          </AccordionSection>
          <AccordionSection label="Social Icons" isLabelUpperCase index={1}>
            <SocialFollowStyle
              layout="Footer"
              socialLinkDescription={socialLinkDescription}
              updateStyles={updateFooterStyles}
            />
          </AccordionSection>
          <AccordionSection label="Footer Color" isLabelUpperCase index={2}>
            <ColorPickerFooter />
          </AccordionSection>
          <AccordionSection label="Buttons and Links" isLabelUpperCase index={3}>
            <FooterSubscription />
            <FooterAppDownloadLinks />
          </AccordionSection>
        </Accordion>
      </PBInspector>
      <ComponentName />
    </div>
  );
};

const mapStateToProps = (state: IPBState) => {
  return {
    config: get(state, ['builder', 'config']),
    haveUnsavedChanges: get(state, ['builder', 'haveUnsavedChanges']),
    footerType: getFooterStyle(state),
    qt: get(state, ['qt']),
    enableAppDownloadLinksButton: get(state, [
      'qt',
      'config',
      'pagebuilder-config',
      'footer',
      'enableAppDownloadLinksButton'
    ]),
    logoStored: get(state, ['builder', 'config', 'footer', 'logo']),
    darkLogoStored: get(state, ['builder', 'config', 'footer', 'darkLogo'])
  };
};

export const AheadFooter = connect(mapStateToProps)(AheadFooterBase);
export default AheadFooter;
