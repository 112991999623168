import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Desktop: React.FunctionComponent<IsvgData> = ({ width = '24px', height = '24px', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fill-rule="evenodd"
        d="M18.562 4.75c.365 0 .675.128.93.383s.383.565.383.93v7.875c0 .364-.128.674-.383.93a1.266 1.266 0 0 1-.93.382H5.437c-.364 0-.674-.128-.93-.383a1.266 1.266 0 0 1-.382-.93V6.064c0-.365.128-.675.383-.93s.565-.383.93-.383h13.124zm-.164 9.188c.037 0 .073-.019.11-.055.036-.037.054-.073.054-.11V6.227c0-.037-.018-.073-.054-.11-.037-.036-.073-.054-.11-.054H5.602c-.037 0-.073.018-.11.054-.036.037-.055.073-.055.11v7.546c0 .037.019.073.055.11.037.036.073.055.11.055h12.796zm-1.148 4.156a.633.633 0 0 1-.191.465.633.633 0 0 1-.465.191H7.406a.633.633 0 0 1-.465-.191.633.633 0 0 1-.191-.465c0-.183.064-.337.191-.465a.633.633 0 0 1 .465-.192h2.707l.492-1.53c.055-.147.165-.22.329-.22h2.132c.164 0 .274.073.329.22l.492 1.53h2.707c.182 0 .337.064.465.192a.633.633 0 0 1 .191.465z"
      />
    </svg>
  );
};

export { Desktop };
