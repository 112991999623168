import React from 'react';
import { RowLoader } from '../component-loader';
import { SelectRowInspector } from '../../molecules/select-row-inspector';
import { useParams } from 'react-router-dom';

const SubscriptionPage = () => {
  const { publisherId = '' } = useParams();
  return (
    <>
      <RowLoader />
      <SelectRowInspector publisherId={publisherId} />
    </>
  );
};

export default SubscriptionPage;
