import { Button } from '@quintype/em/components/button';
import { Inspector } from '@quintype/em/components/inspector';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  updateBackupConfig,
  updateConfigVersion,
  updateHaveUnsavedChanges,
  updateHistoryActionStatus,
  updatePBConfig
} from '../../actions/builder-action';
import { closeNavigationPopup } from '../../actions/popup-action';
import { Close } from '../../atoms/close-svg';
import { IParams } from '../../utils/interfaces';
import { saveConfig, updateBodyScroll } from '../../utils/utils';
import styles from './inspector.module.css';

const PBInspector = (props: any) => {
  const { config, backupConfig, haveUnsavedChanges, navigationPopup = {} } = useSelector(state =>
    get(state, ['builder'])
  );

  const { saveButton, discardButton, navigationPath } = navigationPopup;

  const [showSaveMsg, toggleSaveMsg] = useState(false);
  const [showConfirmationPopup, toggleConfirmationPopup] = useState(false);

  const { publisherId = '', domain } = useParams<IParams>();
  const dispatch = useDispatch();
  const history = useHistory();

  const closePopup = (value: boolean) => {
    dispatch(closeNavigationPopup());
    props.toggleConfirmationPopup && props.toggleConfirmationPopup(value);
  };

  // props.toggleConfirmationPopup is used since the component is rerendered multiple times
  const popupToggle = (value: boolean) => {
    saveButton
      ? closePopup(value)
      : props.toggleConfirmationPopup
      ? props.toggleConfirmationPopup(value)
      : toggleConfirmationPopup(value);
  };

  const onActionButtonClick = async () => {
    const nextVersion = config.version + 1;
    const updatedConfig = { ...config, version: nextVersion };
    const response = await saveConfig(publisherId, updatedConfig, domain);
    if (response?.activeVersion && response?.actionButtonStatus) {
      const { activeVersion = 0, actionButtonStatus } = response;
      dispatch(updateConfigVersion(activeVersion));
      dispatch(updateHistoryActionStatus(actionButtonStatus));
      dispatch(updateBackupConfig({ config }));
      dispatch(updateHaveUnsavedChanges(false));
      popupToggle(false);
      toggleSaveMsg(true);
      props.onActionButtonClick && props.onActionButtonClick();
      setTimeout(() => {
        toggleSaveMsg(false);
        updateBodyScroll(true, 'removeScroll');
      }, 1500);
    } else {
      props.onClose && props.onClose();
    }
    if (navigationPath) navigateToPage();
  };

  const onClose = () => {
    if (haveUnsavedChanges) {
      popupToggle(true);
      updateBodyScroll(false, 'removeScroll');
    } else {
      props.onClose && props.onClose();
    }
  };

  const onDiscard = () => {
    dispatch(updatePBConfig(backupConfig));
    popupToggle(false);
    updateBodyScroll(true, 'removeScroll');
    props.onClose && props.onClose();
    dispatch(updateHaveUnsavedChanges(false));
    setTimeout(() => {
      if (navigationPath) navigateToPage();
    }, 300);
  };

  const onCancel = () => popupToggle(false);

  const navigateToPage = () => {
    history.push(`/accounts/${publisherId}/${domain}/${navigationPath}`);
  };

  return (
    <div className={styles.inspectorWrapper}>
      <Inspector
        {...props}
        onActionButtonClick={onActionButtonClick}
        onClose={onClose}
        actionButtonLabel={props.actionButtonLabel || 'Save'}
        title={props.title || 'Settings'}
      >
        {props.children}
      </Inspector>
      {(props.showConfirmationPopup || showConfirmationPopup) && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupWrapper}>
            <div className={styles.closeButton} onClick={onCancel}>
              <Close />
            </div>
            <div className={styles.heading}>Do you want to discard your changes?</div>
            <div className={styles.subheading}>
              You've made changes that haven't been saved yet. Please confirm if you'd like to discard your changes.
            </div>
            <div className={styles.btnWrapper}>
              <Button onClick={onDiscard}>{discardButton || 'Discard'}</Button>
              <Button type="primary" onClick={onActionButtonClick}>
                {saveButton || 'Save'}
              </Button>
            </div>
          </div>
        </div>
      )}
      {showSaveMsg && (
        <div className={styles.saveMsgWrapper}>
          <p>Your changes are successfully saved.</p>
        </div>
      )}
    </div>
  );
};
export default PBInspector;
