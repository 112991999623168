import get from 'lodash/get';
import { IStory } from '../../../utils/interfaces';

export const authors = [
  {
    id: 232776,
    name: 'Garima Kamboj',
    social: {
      twitter: {
        url: 'https://www.twitter.com/sabqorg',
        handle: 'elonmusk'
      }
    },
    slug: 'garima-kamboj',
    'avatar-url':
      'https://lh6.googleusercontent.com/-Wj0AnJnn4_s/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3repjdA6tbPSjwM_GCu5_7DM20R2Kw/photo.jpg',
    'avatar-s3-key': 'ace/2019-05/ab1c7dfe-2089-4a70-8b02-07158dba63a4/574882_PLQEPZ_308.jpg',
    'twitter-handle': null,
    bio:
      'William Shakespeare was an English poet, playwright, and actor, widely regarded as the greatest writer in the English language and the world’s greatest dramatist. He is often called England’s national poet and the “Bard of Avon”',
    'contributor-role': {
      id: 873,
      name: 'Author'
    }
  },
  {
    id: 949805,
    name: 'Harry Potter',
    social: {
      twitter: {
        url: 'https://www.twitter.com/sabqorg',
        handle: 'elonmusk'
      }
    },
    slug: 'stephen-wertheim',
    'avatar-url': 'https://thumbor-stg.assettype.com/ace/2019-08/145ab200-429f-44ac-8618-00c6e4643e31/cat.jpeg',
    'avatar-s3-key': 'ace/2019-08/145ab200-429f-44ac-8618-00c6e4643e31/cat.jpeg',
    'twitter-handle': null,
    bio: null,
    'contributor-role': {
      id: 879,
      name: 'Guest Author'
    }
  }
];

const dummyTextStory: IStory = {
  'updated-at': 1580192896500,
  seo: {
    'claim-reviews': {
      story: null
    }
  },
  'assignee-id': 232776,
  'author-name': 'Garima Kamboj',
  tags: [],
  headline: 'Employee-owned businesses are doing well by doing good',
  'storyline-id': null,
  votes: {},
  'story-content-id': '3bff1af0-c3c5-477c-9e5b-31ee9e8a31e0',
  slug: 'recent-stories/employee-owned-businesses-are-doing-well-by-doing-good',
  'last-published-at': 1580192897958,
  subheadline: null,
  alternative: {},
  sections: [
    {
      'domain-slug': null,
      slug: 'recent-stories',
      name: 'Recent stories',
      'section-url': 'https://ace-web.qtstage.io/recent-stories',
      id: 5773,
      'parent-id': null,
      'display-name': 'Recent stories',
      collection: {
        slug: 'recent',
        name: 'Recent stories',
        id: 2760
      },
      data: null
    }
  ],
  'story-audio': {
    's3-key': 'ace/story-audio/2020-01/3bff1af0-c3c5-477c-9e5b-31ee9e8a31e0/.4e4e06aa-be2b-4657-b801-5b85ecb17cdc.mp3'
  },
  'read-time': 3,
  'access-level-value': null,
  'content-created-at': 1580192273786,
  'owner-name': 'Garima Kamboj',
  'custom-slug': null,
  'push-notification': null,
  'publisher-id': 97,
  'hero-image-metadata': {
    width: 4855,
    height: 3237,
    'mime-type': 'image/jpeg',
    'file-size': 3175028,
    'file-name': 'employee-owned-businesses.jpg'
  },
  comments: null,
  'word-count': 655,
  entities: {},
  'published-at': 1580192897958,
  'is-live-blog': false,
  'breaking-news-linked-story-id': null,
  'storyline-title': null,
  summary: null,
  'push-notification-title': null,
  'external-id': null,
  'canonical-url': null,
  'is-amp-supported': true,
  autotags: [],
  'linked-entities': [],
  status: 'published',
  'hero-image-attribution': null,
  'bullet-type': '123',
  id: '3bff1af0-c3c5-477c-9e5b-31ee9e8a31e0',
  'hero-image-s3-key': 'ace/2020-01/d2f718f5-e95c-4b3d-ba88-03b03d99e5bf/employee_owned_businesses.jpg',
  contributors: null,
  'associated-series-collection-ids': [],
  cards: [
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/3bff1af0-c3c5-477c-9e5b-31ee9e8a31e0/element/d5f723ae-3c7a-40b8-ab09-2b6daef4a782',
          type: 'text',
          'family-id': '6702fbc4-bac7-4d28-b2a1-9c6b09e6a8d8',
          title: '',
          id: 'd5f723ae-3c7a-40b8-ab09-2b6daef4a782',
          metadata: {},
          subtype: null,
          text:
            '<p>The need for an alternative approach to business has never been greater,” says Guy Singh-Watson. He is foraging along the banks of the River Erme at low tide, searching for marsh samphire – the crunchy, salty plant with vibrant green stalks – to go in some of the 50,000 veg boxes that will be dispatched tomorrow. Regularly joining half  dozen pickers to cut samphire during the few weeks it is in season, he is clearly happy to get his feet wet and muddy on the frontline.</p><p>The alternative approach Singh-Watson has in mind may once have sounded radical, but it is growing in popularity among UK businesses. In June 2018, he sold three-quarters of the shares in Riverford, the Devon-based organic farm and veg box company that he founded in 1987, to its 741 employees for a quarter of their market value.</p>'
        }
      ],
      'card-updated-at': 1580192896540,
      'content-version-id': 'e79126ca-47d1-4bbc-a591-fef61d67ca5e',
      'card-added-at': 1580192473284,
      status: 'draft',
      id: 'adad135b-a8cb-4f5e-a85e-20850b4c92bd',
      'content-id': 'adad135b-a8cb-4f5e-a85e-20850b4c92bd',
      version: 13,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Employee-owned businesses are doing well by doing good',
          message: null,
          image: {
            key: 'ace/2020-01/d2f718f5-e95c-4b3d-ba88-03b03d99e5bf/employee_owned_businesses.jpg',
            url: null,
            attribution: null,
            caption: 'Words by\nAnna Turns, photography by Martin Ellis',
            metadata: {
              width: 4855,
              height: 3237,
              'mime-type': 'image/jpeg',
              'file-size': 3175028,
              'file-name': 'employee-owned-businesses.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/3bff1af0-c3c5-477c-9e5b-31ee9e8a31e0/element/4fd2f6d6-3187-42d3-aed1-33fd53ce8e32',
          type: 'text',
          'family-id': 'a811f618-4cf7-48b4-aecb-bdc25141316b',
          title: '',
          id: '4fd2f6d6-3187-42d3-aed1-33fd53ce8e32',
          metadata: {},
          subtype: null,
          text:
            '<p>But switching the business to an employee ownership (EO) model wasn’t done simply out of generosity. Singh-Watson considers it a shrewd business move too. “We need to encourage staff to have more control over their own jobs as well as the direction of the company,” he tells Positive News. “That feeds their motivation and wellbeing and, as a result, creates a more productive workforce with a greater sense of unity. Employee owners are more invested, and the business will be more profitable in the long-term.”</p><p>Kirsty Hale, head of recipes, has worked at Riverford for 11 years and says the workforce is now more cohesive. “Day to day, my job hasn’t really changed, but there is more shared decision-making now and people are empowered to make positive change,” she notes. It is key, Hale suggests, to balance giving people autonomy with consolidating the processes and structures that are in place. “Making sure everyone feels connected, whether they’re in the barn packing veg boxes or in the office, is essential. It’s a work in progress but it’s quite enlightening to realise that it’s commercially beneficial.”</p>'
        }
      ],
      'card-updated-at': 1580192896540,
      'content-version-id': '54632a25-c5a3-45ea-b6bf-12e46a63d902',
      'card-added-at': 1580192531550,
      status: 'draft',
      id: '35e3eec9-aae0-4c98-b8b9-23f62f982b8d',
      'content-id': '35e3eec9-aae0-4c98-b8b9-23f62f982b8d',
      version: 11,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Employee-owned businesses are doing well by doing good',
          message: null,
          image: {
            key: 'ace/2020-01/d2f718f5-e95c-4b3d-ba88-03b03d99e5bf/employee_owned_businesses.jpg',
            url: null,
            attribution: null,
            caption: 'Words by\nAnna Turns, photography by Martin Ellis',
            metadata: {
              width: 4855,
              height: 3237,
              'mime-type': 'image/jpeg',
              'file-size': 3175028,
              'file-name': 'employee-owned-businesses.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'image-metadata': {
            width: 1500,
            height: 1000,
            'mime-type': 'image/jpeg',
            'file-size': 287215,
            'file-name': 'Singh-Watson.jpg'
          },
          'page-url': '/story/3bff1af0-c3c5-477c-9e5b-31ee9e8a31e0/element/c93fc364-1ebe-4752-980f-382754bb464e',
          type: 'image',
          'family-id': '0a21deff-5596-4af5-9d7f-1ed090fa9cf6',
          'image-attribution': '',
          title: 'Singh-Watson says he is still happiest when in the fields with his crops',
          id: 'c93fc364-1ebe-4752-980f-382754bb464e',
          'image-s3-key': 'ace/2020-01/7fa9fa27-a3fe-46ee-9ef4-5187e860078c/Singh_Watson.jpg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/3bff1af0-c3c5-477c-9e5b-31ee9e8a31e0/element/8cc7c25f-3e13-41b8-b4ca-2dec3174cf4e',
          type: 'text',
          'family-id': '618db438-43ef-48a7-9f85-8524a9b1c6b7',
          title: '',
          id: '8cc7c25f-3e13-41b8-b4ca-2dec3174cf4e',
          metadata: {},
          subtype: null,
          text:
            '<p>Because by many measures, the move has proved a success for Riverford. One year on, Singh-Watson has noticed a greater sense of pride among his fellow workers. The company reported its strongest financial growth in 30 years – thanks partly to favourable weather and bumper harvests – with a turnover of £65.9m for the year to April 2019, a 10 per cent increase in sales and 24 per cent rise in new customers. It is now on track to exceed a £70m turnover by 2020.</p><p>Most crucially for Singh-Watson, staff turnover has reduced by 15 per cent since the transition. Could this be due to the staff council consulting on issues such as wellbeing and work culture, and the recent company-wide 3 per cent pay rise? “So far, it’s working fantastically well,” he says. “We are making better decisions, although maybe it takes slightly longer to make them.”</p>'
        }
      ],
      'card-updated-at': 1580192896540,
      'content-version-id': '4a83c234-ca60-44ff-8e2f-c9f3f1185656',
      'card-added-at': 1580192612573,
      status: 'draft',
      id: '61b4e286-f29e-4eb4-ac20-405a2a1cda18',
      'content-id': '61b4e286-f29e-4eb4-ac20-405a2a1cda18',
      version: 9,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Employee-owned businesses are doing well by doing good',
          message: null,
          image: {
            key: 'ace/2020-01/7fa9fa27-a3fe-46ee-9ef4-5187e860078c/Singh_Watson.jpg',
            url: null,
            attribution: '',
            caption: null,
            metadata: {
              width: 1500,
              height: 1000,
              'mime-type': 'image/jpeg',
              'file-size': 287215,
              'file-name': 'Singh-Watson.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/3bff1af0-c3c5-477c-9e5b-31ee9e8a31e0/element/d798fc41-e5dc-43e3-9593-72c13b70d1f4',
          type: 'text',
          'family-id': '1e2b4cae-7452-4928-9e88-fbc03063fa14',
          title: '',
          id: 'd798fc41-e5dc-43e3-9593-72c13b70d1f4',
          metadata: {
            content: 'There is more shared decision-making now and people are empowered to make positive change',
            attribution: ''
          },
          subtype: 'blockquote',
          text:
            '<div><blockquote>There is more shared decision-making now and people are empowered to make positive change</blockquote><span class="attribution"></span></div>'
        }
      ],
      'card-updated-at': 1580192896540,
      'content-version-id': '7b5bc2da-f7e5-4191-b579-d12d4c483771',
      'card-added-at': 1580192671668,
      status: 'draft',
      id: '8f5df895-620a-427d-aa12-49fd0aef8f5f',
      'content-id': '8f5df895-620a-427d-aa12-49fd0aef8f5f',
      version: 6,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Employee-owned businesses are doing well by doing good',
          message: null,
          image: {
            key: 'ace/2020-01/d2f718f5-e95c-4b3d-ba88-03b03d99e5bf/employee_owned_businesses.jpg',
            url: null,
            attribution: null,
            caption: 'Words by\nAnna Turns, photography by Martin Ellis',
            metadata: {
              width: 4855,
              height: 3237,
              'mime-type': 'image/jpeg',
              'file-size': 3175028,
              'file-name': 'employee-owned-businesses.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/3bff1af0-c3c5-477c-9e5b-31ee9e8a31e0/element/6208d701-15ff-4ab2-bdc3-23235df01ea8',
          type: 'text',
          'family-id': '3f60f60e-b2f1-4241-9de4-96665b596100',
          title: '',
          id: '6208d701-15ff-4ab2-bdc3-23235df01ea8',
          metadata: {},
          subtype: null,
          text:
            '<p>Employee ownership is nothing new, of course. The John Lewis Partnership has been owned by staff since 1929, and now has 83,900 partners. With gross sales of more than £11.7bn for the 12 months to January 2019, it’s the largest EO business in the UK according to the Employee Ownership Association. Riverford is the 30th largest by number of workers and joins the likes of food wholesaler Suma, which has its headquarters in West Yorkshire, Brighton-based Mooncup, the 15,000-strong international engineering company Arup, and a more recent convert, electronics retailer Richer Sounds.</p><p>In November 2018, Bristol-based studio Aardman Animations followed suit in order to ‘secure its creative legacy’. “I believe that those creating the value should benefit, rather than external third parties,” says studio co-founder David Sproxton, who handed over a 75 per cent stake to 140 employees. “The EO model fits well with our collaborative, collegiate culture. It gives people a voice, and once people understand that they can make a difference, it revolutionises the way that business works.”</p>'
        },
        {
          id: '00000000-0000-0000-0000-000000000000',
          description: '',
          title: '',
          subtype: null,
          type: 'text',
          text: '<p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
          metadata: {
            'promotional-message': true
          }
        }
      ],
      'card-updated-at': 1580192896540,
      'content-version-id': '8637b676-f23f-4967-a850-e41d46b1902f',
      'card-added-at': 1580192766389,
      status: 'draft',
      id: '18ae5779-77b2-4ed5-9056-55e306b9d9f6',
      'content-id': '18ae5779-77b2-4ed5-9056-55e306b9d9f6',
      version: 4,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Employee-owned businesses are doing well by doing good',
          message: null,
          image: {
            key: 'ace/2020-01/d2f718f5-e95c-4b3d-ba88-03b03d99e5bf/employee_owned_businesses.jpg',
            url: null,
            attribution: null,
            caption: 'Words by\nAnna Turns, photography by Martin Ellis',
            metadata: {
              width: 4855,
              height: 3237,
              'mime-type': 'image/jpeg',
              'file-size': 3175028,
              'file-name': 'employee-owned-businesses.jpg'
            }
          }
        },
        attributes: {}
      }
    }
  ],
  url: 'https://ace-web.qtstage.io/recent-stories/employee-owned-businesses-are-doing-well-by-doing-good',
  'story-version-id': '6b2cc591-7a8a-4e66-ab2c-ac4c6a5a47ec',
  'content-type': 'story',
  'content-updated-at': 1580192898316,
  'author-id': 232776,
  'owner-id': 232776,
  'linked-story-ids': [],
  access: 'subscription',
  'promotional-message': '<p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
  'asana-project-id': null,
  'first-published-at': 1580192897958,
  'hero-image-caption': 'Words by\nAnna Turns, photography by Martin Ellis',
  version: 17,
  'story-template': 'text',
  'sequence-no': null,
  'created-at': 1580192896500,
  authors,
  metadata: {
    'card-share': {
      shareable: false
    },
    'sponsored-by': 'quint'
  },
  'publish-at': null,
  'assignee-name': 'Garima Kamboj'
};

const dummyPhotoStory: IStory = {
  'updated-at': 1594116285438,
  seo: { 'claim-reviews': { story: null } },
  'assignee-id': 927927,
  'author-name': 'Pravin Atigre',
  tags: [],
  headline: 'History of Munnar',
  'storyline-id': null,
  votes: {},
  'story-content-id': 'b54b3a36-ff14-448e-a149-8e6033f4ea87',
  slug: 'automated/history-of-munnar',
  'last-published-at': 1594116289300,
  subheadline: 'The tourism industry redefined Munnar in the latter part of the 20th century.',
  alternative: {},
  sections: [
    {
      'domain-slug': null,
      slug: 'automated',
      name: 'Automated',
      'section-url': 'https://ace-web.qtstage.io/automated',
      id: 41990,
      'parent-id': null,
      'display-name': 'Automated',
      collection: { slug: 'automated', name: 'Automated', id: 91819 },
      data: null
    }
  ],
  'story-audio': {
    's3-key': 'ace/story-audio/2020-07/b54b3a36-ff14-448e-a149-8e6033f4ea87/.01187740-ecfe-4d21-b6c7-874c92db2fa0.mp3'
  },
  'read-time': 2,
  'access-level-value': null,
  'content-created-at': 1594113197731,
  'owner-name': 'Pravin Atigre',
  'custom-slug': null,
  'push-notification': null,
  'publisher-id': 97,
  'hero-image-metadata': { width: 1500, height: 1000 },
  comments: null,
  'word-count': 172,
  entities: {},
  'published-at': 1594116289300,
  'is-live-blog': false,
  'breaking-news-linked-story-id': null,
  'storyline-title': null,
  summary: null,
  'push-notification-title': null,
  'external-id': null,
  'canonical-url': null,
  'is-amp-supported': true,
  autotags: [],
  'linked-entities': [],
  status: 'published',
  'hero-image-attribution': 'Munnar',
  'bullet-type': '123',
  id: 'b54b3a36-ff14-448e-a149-8e6033f4ea87',
  'hero-image-s3-key': 'ace/2017-09/f60ff92c-92f3-4d98-b09c-3982a3de4503/munnar.jpg',
  contributors: [],
  'associated-series-collection-ids': [],
  cards: [
    {
      'story-elements': [
        {
          description: '',
          'image-metadata': {
            width: 700,
            height: 423,
            'mime-type': 'image/jpeg',
            'file-size': 68614,
            'file-name': 'cover5.jpg'
          },
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/05761a8f-29f0-4492-bfb9-60cbeda80daf',
          type: 'image',
          'family-id': '4de2dbf5-0b55-4756-937b-3587cac0fbd1',
          'image-attribution': 'Modern day Munnar was carved out after a sequence of historic events. ',
          title:
            'One of the biggest tea plantation areas in India, Munnar was once a summer resort for the British Government in South India.',
          id: '05761a8f-29f0-4492-bfb9-60cbeda80daf',
          'image-s3-key': 'ace/2020-07/825bf7ac-3078-48d9-a58d-60c230ca81c4/cover5.jpg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/657bad3a-d33f-42d0-abf8-e929efc9add5',
          type: 'text',
          'family-id': 'c6614f54-e035-4caa-a458-3dbdede1e9c9',
          title: '',
          id: '657bad3a-d33f-42d0-abf8-e929efc9add5',
          metadata: {},
          subtype: null,
          text:
            '<p>Modern day Munnar was carved out after a sequence of historic events. It is said that the Muthuvan tribal community were the earliest residents of modern day Munnar. The area was left untouched and unexplored until the latter part of the 19th century when a flurry of plantations came up in the area.</p>'
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/283ed5c3-7bdb-4603-8a13-61e1167d3836',
          type: 'text',
          'family-id': '0dd983ec-d42c-4cd5-ab5e-678ad8319838',
          title: '',
          id: '283ed5c3-7bdb-4603-8a13-61e1167d3836',
          metadata: {},
          subtype: 'summary',
          text:
            "<p>The tradition that Col Arthur Wellesley, later to be the Duke of Wellington, leading a British detachment from Vandiperiyar to Bodinayakanur, then over the High Range and into the Coimbatore plains to cut off Tippu Sultan's retreat from Travancore, was the first Englishman in the High Range appears to be belied by the dates involved.</p>"
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/f61ebe55-5187-46fe-ac43-6a54d76b293d',
          type: 'text',
          'family-id': '7de6a3e4-e1b6-42e3-b107-feb77f12a0a4',
          title: '',
          id: 'f61ebe55-5187-46fe-ac43-6a54d76b293d',
          metadata: {
            content:
              'One of the biggest tea plantation areas in India, Munnar was once a summer resort for the British Government in South India.',
            attribution: 'Skymet Weather Team'
          },
          subtype: 'quote',
          text:
            '<div><blockquote>One of the biggest tea plantation areas in India, Munnar was once a summer resort for the British Government in South India.</blockquote><span class="attribution">Skymet Weather Team</span></div>'
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/f2c11289-c629-4e66-85ef-80f77b5e2e4c',
          type: 'text',
          'family-id': '4e6b6ef7-953b-4f86-b7c4-b0e6ecb1cad2',
          title: '',
          id: 'f2c11289-c629-4e66-85ef-80f77b5e2e4c',
          metadata: {
            content: 'The tourism industry redefined Munnar in the latter part of the 20th century. ',
            attribution: 'Kerala Tourism'
          },
          subtype: 'bigfact',
          text:
            '<div><div class="bigfact-title">The tourism industry redefined Munnar in the latter part of the 20th century. </div><div class="bigfact-description">Kerala Tourism</div></div>'
        }
      ],
      'card-updated-at': 1594113702731,
      'content-version-id': '43edfb2e-5d65-4541-87d2-d4ec75009be7',
      'card-added-at': 1594113255623,
      status: 'draft',
      id: '3946be89-1623-4354-bf48-c07786cb3dc5',
      'content-id': '3946be89-1623-4354-bf48-c07786cb3dc5',
      version: 16,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'History of Munnar',
          message: null,
          image: {
            key: 'ace/2020-07/825bf7ac-3078-48d9-a58d-60c230ca81c4/cover5.jpg',
            url: null,
            attribution: 'Modern day Munnar was carved out after a sequence of historic events. ',
            caption: null,
            metadata: {
              width: 700,
              height: 423,
              'mime-type': 'image/jpeg',
              'file-size': 68614,
              'file-name': 'cover5.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'image-metadata': {
            width: 1024,
            height: 683,
            'mime-type': 'image/jpeg',
            'file-size': 63545,
            'file-name': 'munnar.jpg'
          },
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          type: 'image',
          'family-id': 'ed7a8053-e782-4807-98e4-c91ae0a457cd',
          'image-attribution': 'One of the romantic place in Munnar.',
          title: 'Camp At Meesapulimala',
          id: 'f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          'image-s3-key': 'ace/2020-07/9c3a8170-ad22-40dd-82ae-e1c4b12c3551/munnar.jpg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          type: 'text',
          'family-id': 'cc8883db-6a89-48f9-84c3-ca6bf9ce437b',
          title: '',
          id: '2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          metadata: {},
          subtype: null,
          text:
            '<p>Meesapulimala, the second highest peak along the Western Ghats of Kerala beckons you to trek up its peak and feel on top of the world.</p>'
        }
      ],
      'card-updated-at': 1594115026000,
      'content-version-id': '7b8fee10-78c9-43aa-80fb-ac4f5b6e2578',
      'card-added-at': 1594113776027,
      status: 'draft',
      id: '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      'content-id': '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      version: 8,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'History of Munnar',
          message: null,
          image: {
            key: 'ace/2020-07/9c3a8170-ad22-40dd-82ae-e1c4b12c3551/munnar.jpg',
            url: null,
            attribution: 'One of the romantic place in Munnar.',
            caption: null,
            metadata: {
              width: 1024,
              height: 683,
              'mime-type': 'image/jpeg',
              'file-size': 63545,
              'file-name': 'munnar.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'image-metadata': {
            width: 1024,
            height: 683,
            'mime-type': 'image/jpeg',
            'file-size': 63545,
            'file-name': 'munnar.jpg'
          },
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          type: 'image',
          'family-id': 'ed7a8053-e782-4807-98e4-c91ae0a457cd',
          'image-attribution': 'One of the romantic place in Munnar.',
          title: 'Camp At Meesapulimala',
          id: 'f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          'image-s3-key':
            'ace/story-audio/2023-04/57bfffc8-4051-4fde-b718-54d1b9649ee8/.92e4394f-fe6e-4300-8c7d-163794406214.mp3',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          type: 'text',
          'family-id': 'cc8883db-6a89-48f9-84c3-ca6bf9ce437b',
          title: '',
          id: '2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          metadata: {},
          subtype: null,
          text:
            '<p>Meesapulimala, the second highest peak along the Western Ghats of Kerala beckons you to trek up its peak and feel on top of the world.</p>'
        }
      ],
      'card-updated-at': 1594115026000,
      'content-version-id': '7b8fee10-78c9-43aa-80fb-ac4f5b6e2578',
      'card-added-at': 1594113776027,
      status: 'draft',
      id: '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      'content-id': '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      version: 8,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'History of Munnar',
          message: null,
          image: {
            key: 'ace/2020-07/9c3a8170-ad22-40dd-82ae-e1c4b12c3551/munnar.jpg',
            url: null,
            attribution: 'One of the romantic place in Munnar.',
            caption: null,
            metadata: {
              width: 1024,
              height: 683,
              'mime-type': 'image/jpeg',
              'file-size': 63545,
              'file-name': 'munnar.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'image-metadata': {
            width: 1024,
            height: 683,
            'mime-type': 'image/jpeg',
            'file-size': 63545,
            'file-name': 'munnar.jpg'
          },
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          type: 'image',
          'family-id': 'ed7a8053-e782-4807-98e4-c91ae0a457cd',
          'image-attribution': 'One of the romantic place in Munnar.',
          title: 'Camp At Meesapulimala',
          id: 'f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          'image-s3-key': 'ace/2019-01/72017920-bcef-468a-82d0-43142e04d5fc/images__4_.jpeg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          type: 'text',
          'family-id': 'cc8883db-6a89-48f9-84c3-ca6bf9ce437b',
          title: '',
          id: '2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          metadata: {},
          subtype: null,
          text:
            '<p>Meesapulimala, the second highest peak along the Western Ghats of Kerala beckons you to trek up its peak and feel on top of the world.</p>'
        }
      ],
      'card-updated-at': 1594115026000,
      'content-version-id': '7b8fee10-78c9-43aa-80fb-ac4f5b6e2578',
      'card-added-at': 1594113776027,
      status: 'draft',
      id: '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      'content-id': '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      version: 8,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'History of Munnar',
          message: null,
          image: {
            key: 'ace/2020-07/9c3a8170-ad22-40dd-82ae-e1c4b12c3551/munnar.jpg',
            url: null,
            attribution: 'One of the romantic place in Munnar.',
            caption: null,
            metadata: {
              width: 1024,
              height: 683,
              'mime-type': 'image/jpeg',
              'file-size': 63545,
              'file-name': 'munnar.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'image-metadata': {
            width: 1024,
            height: 683,
            'mime-type': 'image/jpeg',
            'file-size': 63545,
            'file-name': 'munnar.jpg'
          },
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          type: 'image',
          'family-id': 'ed7a8053-e782-4807-98e4-c91ae0a457cd',
          'image-attribution': 'One of the romantic place in Munnar.',
          title: 'Camp At Meesapulimala',
          id: 'f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          'image-s3-key': 'ace/2019-05/dcfb8b43-44a2-49cc-ae71-5fbc3cc99dca/jpeg_43.jpg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          type: 'text',
          'family-id': 'cc8883db-6a89-48f9-84c3-ca6bf9ce437b',
          title: '',
          id: '2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          metadata: {},
          subtype: null,
          text:
            '<p>Meesapulimala, the second highest peak along the Western Ghats of Kerala beckons you to trek up its peak and feel on top of the world.</p>'
        },
        {
          id: '00000000-0000-0000-0000-000000000000',
          description: '',
          title: '',
          subtype: null,
          type: 'text',
          text: ' <p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
          metadata: { 'promotional-message': true }
        }
      ],
      'card-updated-at': 1594115026000,
      'content-version-id': '7b8fee10-78c9-43aa-80fb-ac4f5b6e2578',
      'card-added-at': 1594113776027,
      status: 'draft',
      id: '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      'content-id': '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      version: 8,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'History of Munnar',
          message: null,
          image: {
            key: 'ace/2020-07/9c3a8170-ad22-40dd-82ae-e1c4b12c3551/munnar.jpg',
            url: null,
            attribution: 'One of the romantic place in Munnar.',
            caption: null,
            metadata: {
              width: 1024,
              height: 683,
              'mime-type': 'image/jpeg',
              'file-size': 63545,
              'file-name': 'munnar.jpg'
            }
          }
        },
        attributes: {}
      }
    }
  ],
  url: 'https://ace-web.qtstage.io/automated/history-of-munnar',
  'story-version-id': 'a35820ce-0c9f-49d3-a9f1-3148231c6f3f',
  'content-type': 'story',
  'content-updated-at': 1594116289442,
  'author-id': 927927,
  'owner-id': 927927,
  'linked-story-ids': [],
  access: 'subscription',
  'promotional-message': ' <p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
  'asana-project-id': null,
  'first-published-at': 1594113703547,
  'hero-image-caption': 'History of Munnar',
  version: 29,
  'story-template': 'photo',
  'sequence-no': null,
  'created-at': 1594116281094,
  authors,
  metadata: { 'card-share': { shareable: false }, 'sponsored-by': 'quint' },
  'publish-at': null,
  'assignee-name': 'Pravin Atigre'
};

const dummyLiveBlogStory: IStory = {
  'updated-at': 1602044472114,
  seo: { 'meta-keywords': [], 'claim-reviews': { story: null } },
  'assignee-id': 425675,
  'author-name': 'Bindiya H',
  tags: [
    {
      id: 163463,
      name: 'entertainment',
      'meta-description': null,
      'meta-title': null,
      slug: 'entertainment',
      'tag-type': 'Tag'
    },
    {
      id: 1209598,
      name: 'government',
      'meta-description': null,
      'meta-title': null,
      slug: 'government',
      'tag-type': 'Tag'
    },
    {
      id: 1409326,
      name: 'liveblog',
      'meta-description': null,
      'meta-title': null,
      slug: 'liveblog',
      'tag-type': 'Tag'
    }
  ],
  headline: 'Live Updates',
  'storyline-id': null,
  votes: {},
  'story-content-id': '4270e6db-f238-46d4-8b6d-788ede41760e',
  slug: 'entertainment/2020/09/28/live-blog-story-3',
  'last-published-at': 1602044474567,
  subheadline: 'At vero eos et accusamus iusto odio dignissimos ducimus qui blanditiis pre.',
  alternative: {},
  sections: [
    {
      'domain-slug': null,
      slug: 'entertainment',
      name: 'Entertainment',
      'section-url': 'https://ace-web.qtstage.io/news/entertainment',
      id: 6447,
      'parent-id': 38586,
      'display-name': 'Entertainment',
      collection: { slug: 'entertainment', name: 'Entertainment', id: 3946 },
      data: null
    }
  ],
  'story-audio': { 's3-key': null },
  'read-time': 1,
  'access-level-value': null,
  'content-created-at': 1601276754377,
  'owner-name': 'Bindiya H',
  'custom-slug': null,
  'push-notification': null,
  'publisher-id': 97,
  'hero-image-metadata': { 'mime-type': 'image/jpeg', 'focus-point': [975, 375], width: 1682, height: 820 },
  comments: null,
  'word-count': 96,
  entities: {},
  'published-at': 1602044474567,
  'is-live-blog': true,
  'breaking-news-linked-story-id': null,
  'storyline-title': null,
  summary: null,
  'push-notification-title': null,
  'external-id': null,
  'canonical-url': null,
  'is-amp-supported': true,
  autotags: [],
  'linked-entities': [],
  status: 'published',
  'hero-image-attribution': 'Google',
  'bullet-type': '123',
  id: '4270e6db-f238-46d4-8b6d-788ede41760e',
  'hero-image-s3-key': 'ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
  contributors: [],
  'associated-series-collection-ids': [],
  cards: [
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/4270e6db-f238-46d4-8b6d-788ede41760e/element/9ffc9b92-bc10-4e11-a710-f3794c2643ca',
          type: 'title',
          'family-id': '126c4327-5e75-40c6-b63d-c5cce31254c5',
          title: '',
          id: '9ffc9b92-bc10-4e11-a710-f3794c2643ca',
          metadata: {},
          subtype: null,
          text: 'Saque ipsa quae ab illo inventore veritatis et quip'
        }
      ],
      'card-updated-at': 1602044472159,
      'content-version-id': '1979f5ff-1737-47a1-a3d5-8ae335bd5a58',
      'card-added-at': 1602044472159,
      status: 'draft',
      id: 'd46d9a50-4516-4796-937a-dc672fe766b3',
      'content-id': 'd46d9a50-4516-4796-937a-dc672fe766b3',
      version: 1,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Live Updates',
          message: null,
          image: {
            key: 'ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
            url: 'https://thumbor-stg.assettype.com/ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
            attribution: 'Google',
            caption: 'Saque ipsa quae ab illo inventore veritatis et quip',
            metadata: { 'mime-type': 'image/jpeg', 'focus-point': [975, 375], width: 1682, height: 820 }
          }
        },
        attributes: { 'key-event': true }
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/4270e6db-f238-46d4-8b6d-788ede41760e/element/d038d156-d98d-477b-abc8-4d25c6e47595',
          type: 'title',
          'family-id': '7e622322-1e56-4cf2-8b02-0420b31c3834',
          title: '',
          id: 'd038d156-d98d-477b-abc8-4d25c6e47595',
          metadata: {},
          subtype: null,
          text: 'Saque ipsa quae ab illo inventore veritatis et quip'
        }
      ],
      'card-updated-at': 1602044472159,
      'content-version-id': '20a64eea-ac82-4e76-b87e-34174a399c89',
      'card-added-at': 1602044472159,
      status: 'draft',
      id: '3d94dbf6-b4e7-4425-b00c-da377c8cc6ae',
      'content-id': '3d94dbf6-b4e7-4425-b00c-da377c8cc6ae',
      version: 1,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Live Updates',
          message: null,
          image: {
            key: 'ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
            url: 'https://thumbor-stg.assettype.com/ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
            attribution: 'Google',
            caption: 'Saque ipsa quae ab illo inventore veritatis et quip',
            metadata: { 'mime-type': 'image/jpeg', 'focus-point': [975, 375], width: 1682, height: 820 }
          }
        },
        attributes: { 'key-event': true }
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/4270e6db-f238-46d4-8b6d-788ede41760e/element/3a569347-2bed-4763-bf3b-b58df01ad6cc',
          type: 'title',
          'family-id': '844074a7-2b25-423a-b3c8-88461db91a26',
          title: '',
          id: '3a569347-2bed-4763-bf3b-b58df01ad6cc',
          metadata: {},
          subtype: null,
          text: 'Sed ut perspiciatis unde omnis iste natus error'
        },
        {
          description: '',
          'page-url': '/story/4270e6db-f238-46d4-8b6d-788ede41760e/element/739cf8a1-26d8-48fb-8cbf-771b1161ed68',
          type: 'text',
          'family-id': '6b3e107b-02d4-4d72-b523-64bbf809246b',
          title: '',
          id: '739cf8a1-26d8-48fb-8cbf-771b1161ed68',
          metadata: {},
          subtype: null,
          text:
            '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est</p>'
        }
      ],
      'card-updated-at': 1601439658426,
      'content-version-id': 'f48c6725-4dbf-45c4-8d46-56c5fe4489f1',
      'card-added-at': 1601431063377,
      status: 'draft',
      id: '5c8900ed-4676-45f4-a7b6-aa28746370fc',
      'content-id': '5c8900ed-4676-45f4-a7b6-aa28746370fc',
      version: 3,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Live Updates',
          message: null,
          image: {
            key: 'ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
            url: 'https://thumbor-stg.assettype.com/ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
            attribution: 'Google',
            caption: 'Saque ipsa quae ab illo inventore veritatis et quip',
            metadata: { 'mime-type': 'image/jpeg', 'focus-point': [975, 375], width: 1682, height: 820 }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/4270e6db-f238-46d4-8b6d-788ede41760e/element/8ee1c6ed-57d8-4891-8a77-e789b2d33c29',
          type: 'title',
          'family-id': 'fab4dbe9-acce-43cb-8d3e-3b7d83afe04a',
          title: '',
          id: '8ee1c6ed-57d8-4891-8a77-e789b2d33c29',
          metadata: {},
          subtype: null,
          text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium'
        },
        {
          description: '',
          'page-url': '/story/4270e6db-f238-46d4-8b6d-788ede41760e/element/78142993-d358-4c17-b610-9c80101f32b1',
          type: 'text',
          'family-id': 'ec1e356a-d274-4f71-9ef9-4f070c907894',
          title: '',
          id: '78142993-d358-4c17-b610-9c80101f32b1',
          metadata: {},
          subtype: null,
          text:
            '<p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem.</p>'
        },
        {
          description: '',
          'image-url':
            'https://thumbor-stg.assettype.com/ace/2020-09/55df83b2-3181-49f3-b1d9-31cc91dda1be/3aae2ba50b3667e9f02e3674a37581fae1ebaf74_3.jpg',
          'member-id': 934395,
          'image-metadata': {
            'original-url':
              'https://s.rfi.fr/media/display/a1b6b938-01c2-11eb-ba87-005056a964fe/w:1280/p:16x9/3aae2ba50b3667e9f02e3674a37581fae1ebaf74_3.jpg',
            width: 1280,
            height: 720
          },
          'uploaded-at': 1601327722596,
          'page-url': '/story/4270e6db-f238-46d4-8b6d-788ede41760e/element/bf739a18-6878-485a-adc7-d608d767f123',
          type: 'image',
          'family-id': '98f289da-3a92-48e0-ac66-2c0381b9fbc8',
          'image-attribution': '',
          title: 'Premier League',
          id: 'bf739a18-6878-485a-adc7-d608d767f123',
          'image-s3-key':
            'ace/2020-09/55df83b2-3181-49f3-b1d9-31cc91dda1be/3aae2ba50b3667e9f02e3674a37581fae1ebaf74_3.jpg',
          metadata: {},
          subtype: null
        }
      ],
      'card-updated-at': 1601439658426,
      'content-version-id': '75762f78-06b6-4aa4-aa24-17089ac03ae6',
      'card-added-at': 1601372749401,
      status: 'draft',
      id: '42c89975-2f45-472e-ae09-73fec6f020b7',
      'content-id': '42c89975-2f45-472e-ae09-73fec6f020b7',
      version: 4,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Live Updates',
          message: null,
          image: {
            key: 'ace/2020-09/55df83b2-3181-49f3-b1d9-31cc91dda1be/3aae2ba50b3667e9f02e3674a37581fae1ebaf74_3.jpg',
            url:
              'https://thumbor-stg.assettype.com/ace/2020-09/55df83b2-3181-49f3-b1d9-31cc91dda1be/3aae2ba50b3667e9f02e3674a37581fae1ebaf74_3.jpg',
            attribution: '',
            caption: null,
            metadata: {
              'original-url':
                'https://s.rfi.fr/media/display/a1b6b938-01c2-11eb-ba87-005056a964fe/w:1280/p:16x9/3aae2ba50b3667e9f02e3674a37581fae1ebaf74_3.jpg',
              width: 1280,
              height: 720
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/4270e6db-f238-46d4-8b6d-788ede41760e/element/fb5a9e9d-1741-4125-b307-20dcc374b6f0',
          type: 'title',
          'family-id': 'f73118f0-74b8-4b9f-b2f6-c3b26852e110',
          title: '',
          id: 'fb5a9e9d-1741-4125-b307-20dcc374b6f0',
          metadata: {},
          subtype: null,
          text: 'Sed ut perspiciatis unde omnis iste natus error'
        }
      ],
      'card-updated-at': 1601439658426,
      'content-version-id': '9a24c082-b57e-41b4-b6d7-21683d9d1c2a',
      'card-added-at': 1601289122939,
      status: 'draft',
      id: '69fb8bd4-77e8-4b17-bca4-c229b92ce57f',
      'content-id': '69fb8bd4-77e8-4b17-bca4-c229b92ce57f',
      version: 7,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Live Updates',
          message: null,
          image: {
            key: 'ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
            url: 'https://thumbor-stg.assettype.com/ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
            attribution: 'Google',
            caption: 'Saque ipsa quae ab illo inventore veritatis et quip',
            metadata: { 'mime-type': 'image/jpeg', 'focus-point': [975, 375], width: 1682, height: 820 }
          }
        },
        attributes: { 'key-event': true }
      }
    }
  ],
  url: 'https://ace-web.qtstage.io/entertainment/2020/09/28/live-blog-story-3',
  'story-version-id': '6d22a6c6-0649-468d-8020-2957663a33fd',
  'content-type': 'story',
  'content-updated-at': 1602044475048,
  'author-id': 425675,
  'owner-id': 425675,
  'linked-story-ids': [],
  access: 'subscription',
  'promotional-message': ' <p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
  'asana-project-id': null,
  'first-published-at': 1601276764461,
  'hero-image-caption': 'Saque ipsa quae ab illo inventore veritatis et quip',
  version: 12,
  'story-template': 'live-blog',
  'sequence-no': null,
  'created-at': 1602044472114,
  authors,
  metadata: { 'card-share': { shareable: false }, 'sponsored-by': 'quint' },
  'hero-image-url': 'https://thumbor-stg.assettype.com/ace/2019-03/f25cc9c1-917c-4a19-9c9a-fa498f84f17b/test.jpg',
  'publish-at': null,
  'assignee-name': 'Bindiya H'
};

const dummyListicleStory: IStory = {
  'updated-at': 1602566965523,
  seo: {
    'claim-reviews': {
      story: null
    }
  },
  'assignee-id': 927927,
  'author-name': 'Pravin Atigre',
  tags: [
    {
      id: 1421524,
      name: 'health',
      'meta-description': null,
      'meta-title': null,
      slug: 'health',
      'tag-type': 'Tag'
    },
    {
      id: 1421525,
      name: 'culture',
      'meta-description': null,
      'meta-title': null,
      slug: 'culture',
      'tag-type': 'Tag'
    },
    {
      id: 1421526,
      name: 'workplace',
      'meta-description': null,
      'meta-title': null,
      slug: 'workplace',
      'tag-type': 'Tag'
    }
  ],
  headline: 'It pays to be kind: improving workplace culture through kindness',
  'storyline-id': null,
  votes: {},
  'story-content-id': '307e5a1b-5cf0-4321-8193-827b2add174f',
  slug: 'health/2020/10/08/it-pays-to-be-kind-improving-workplace-culture-through-kindness',
  'last-published-at': 1602566970066,
  subheadline: 'Neque porro quisquam est, qui dolorem ipsum quia dolor sit.',
  alternative: {},
  sections: [
    {
      'domain-slug': null,
      slug: 'health',
      name: 'Health',
      'section-url': 'https://ace-web.qtstage.io/news/health',
      id: 11181,
      'parent-id': 38586,
      'display-name': 'Health',
      collection: {
        slug: 'health',
        name: 'Health',
        id: 15603
      },
      data: null
    }
  ],
  'story-audio': {
    's3-key': 'ace/story-audio/2020-10/307e5a1b-5cf0-4321-8193-827b2add174f/.11ec7b75-5e91-489c-bc54-5413419c1f32.mp3'
  },
  'read-time': 2,
  'access-level-value': null,
  'content-created-at': 1602154991378,
  'owner-name': 'Pravin Atigre',
  'custom-slug': null,
  'push-notification': null,
  'publisher-id': 97,
  'hero-image-metadata': {
    width: 1600,
    height: 900,
    'mime-type': 'image/jpeg',
    'file-size': 228062,
    'file-name': 'seo.jpg'
  },
  comments: null,
  'word-count': 232,
  entities: {},
  'published-at': 1602566970066,
  'is-live-blog': false,
  'breaking-news-linked-story-id': null,
  'storyline-title': null,
  summary: null,
  'push-notification-title': null,
  'external-id': null,
  'canonical-url': null,
  'is-amp-supported': true,
  autotags: [],
  'linked-entities': [],
  status: 'published',
  'hero-image-attribution': 'Credits: Asif Asharaf',
  'bullet-type': 'bullets',
  id: '307e5a1b-5cf0-4321-8193-827b2add174f',
  'hero-image-s3-key': 'ace/2020-10/ec88fad0-ed4d-44ec-9930-72e9d897bdd0/seo.jpg',
  contributors: [],
  'associated-series-collection-ids': [],
  cards: [
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/307e5a1b-5cf0-4321-8193-827b2add174f/element/5ffad09b-7db1-489a-8308-f7d63c745c70',
          type: 'title',
          'family-id': 'd3bf6239-6723-4291-a7d1-6164c808d07d',
          title: '',
          id: '5ffad09b-7db1-489a-8308-f7d63c745c70',
          metadata: {},
          subtype: null,
          text: 'Nemo enim ipsam voluptatem quia volup tas sit aspernatur aut odit aut fugit'
        },
        {
          description: '',
          'page-url': '/story/307e5a1b-5cf0-4321-8193-827b2add174f/element/f24c83a4-dd3d-47fe-8d05-90f6ada10632',
          type: 'text',
          'family-id': '0a4af70b-2434-4038-8134-87cdc60fc379',
          title: '',
          id: 'f24c83a4-dd3d-47fe-8d05-90f6ada10632',
          metadata: {},
          subtype: null,
          text:
            '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est</p>'
        }
      ],
      'card-updated-at': 1602566959937,
      'content-version-id': 'a3dd22c9-b9da-4ac9-9a82-ec3a96ca71be',
      'card-added-at': 1602155475842,
      status: 'draft',
      id: 'aeb377da-eb9f-4013-ae19-fb6fb821e926',
      'content-id': 'aeb377da-eb9f-4013-ae19-fb6fb821e926',
      version: 11,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'It pays to be kind: improving workplace culture through kindness',
          message: null,
          image: {
            key: 'ace/2020-10/ec88fad0-ed4d-44ec-9930-72e9d897bdd0/seo.jpg',
            url: null,
            attribution: 'Credits: Asif Asharaf',
            caption: 'Saque ipsa quae ab illo inventore veritatis et quip',
            metadata: {
              width: 1600,
              height: 900,
              'mime-type': 'image/jpeg',
              'file-size': 228062,
              'file-name': 'seo.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/307e5a1b-5cf0-4321-8193-827b2add174f/element/82244bcd-c453-41c4-a215-bb038f569375',
          type: 'title',
          'family-id': '11f060e0-a1aa-4963-9778-24f6b6eca401',
          title: '',
          id: '82244bcd-c453-41c4-a215-bb038f569375',
          metadata: {},
          subtype: null,
          text: 'Nemo enim ipsam voluptatem quia volup tas sit aspernatur aut odit aut fugit'
        },
        {
          description: '',
          'page-url': '/story/307e5a1b-5cf0-4321-8193-827b2add174f/element/ae7131f6-450a-4589-9995-812ba5832c58',
          type: 'text',
          'family-id': 'a970d458-c7d8-4512-8420-4bf3a4046caf',
          title: '',
          id: 'ae7131f6-450a-4589-9995-812ba5832c58',
          metadata: {},
          subtype: null,
          text:
            '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est</p>'
        }
      ],
      'card-updated-at': 1602155725655,
      'content-version-id': '43ff8777-4176-4294-95a3-5aaee8e34537',
      'card-added-at': 1602155520864,
      status: 'draft',
      id: '70988ea9-90a0-42ea-b2b1-1f7374de06e1',
      'content-id': '70988ea9-90a0-42ea-b2b1-1f7374de06e1',
      version: 7,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'It pays to be kind: improving workplace culture through kindness',
          message: null,
          image: {
            key: 'ace/2020-10/ec88fad0-ed4d-44ec-9930-72e9d897bdd0/seo.jpg',
            url: null,
            attribution: 'Credits: Asif Asharaf',
            caption: 'Saque ipsa quae ab illo inventore veritatis et quip',
            metadata: {
              width: 1600,
              height: 900,
              'mime-type': 'image/jpeg',
              'file-size': 228062,
              'file-name': 'seo.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/307e5a1b-5cf0-4321-8193-827b2add174f/element/d3cbfcce-a503-499d-80cd-52fce2215ef3',
          type: 'title',
          'family-id': '1b8bcf8e-19cb-4d16-ac0e-bb4f0939dbbf',
          title: '',
          id: 'd3cbfcce-a503-499d-80cd-52fce2215ef3',
          metadata: {},
          subtype: null,
          text: 'Nemo enim ipsam voluptatem quia volup tas sit aspernatur aut odit aut fugit'
        },
        {
          description: '',
          'image-metadata': {
            width: 960,
            height: 540,
            'mime-type': 'image/jpeg',
            'file-size': 26544,
            'file-name': 'quintype-website_2020-01_2f2ba42d-7a45-4a60-b4af-7d4758f27f5d_Untitled_presentation.jpg'
          },
          'page-url': '/story/307e5a1b-5cf0-4321-8193-827b2add174f/element/a7f79ffa-4be0-4207-a495-a035a020cac3',
          type: 'image',
          'family-id': '6d5d82b8-f879-476d-84a2-1f62f74acdd5',
          'image-attribution': 'Jon Doe',
          title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
          id: 'a7f79ffa-4be0-4207-a495-a035a020cac3',
          'image-s3-key':
            'ace/2020-10/920f35b9-023e-44d2-b4de-69dce8587bf7/quintype_website_2020_01_2f2ba42d_7a45_4a60_b4af_7d4758f27f5d_Untitled_presentation.jpg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/307e5a1b-5cf0-4321-8193-827b2add174f/element/2a248f86-3181-4456-9f96-f7e56668fbc1',
          type: 'text',
          'family-id': 'b9a1fdd8-5727-4e5a-86a6-467922aa155f',
          title: '',
          id: '2a248f86-3181-4456-9f96-f7e56668fbc1',
          metadata: {},
          subtype: null,
          text:
            '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est</p>'
        }
      ],
      'card-updated-at': 1602156067786,
      'content-version-id': 'dbfeb570-f9aa-4875-9c3d-f48bf551dd91',
      'card-added-at': 1602155541845,
      status: 'draft',
      id: 'd6876a49-9319-4c24-9bf9-881ea454b967',
      'content-id': 'd6876a49-9319-4c24-9bf9-881ea454b967',
      version: 10,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'It pays to be kind: improving workplace culture through kindness',
          message: null,
          image: {
            key:
              'ace/2020-10/920f35b9-023e-44d2-b4de-69dce8587bf7/quintype_website_2020_01_2f2ba42d_7a45_4a60_b4af_7d4758f27f5d_Untitled_presentation.jpg',
            url: null,
            attribution: 'Jon Doe',
            caption: null,
            metadata: {
              width: 960,
              height: 540,
              'mime-type': 'image/jpeg',
              'file-size': 26544,
              'file-name': 'quintype-website_2020-01_2f2ba42d-7a45-4a60-b4af-7d4758f27f5d_Untitled_presentation.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/307e5a1b-5cf0-4321-8193-827b2add174f/element/20db973e-4612-4933-9acd-5a6d4c3d281d',
          type: 'text',
          'family-id': 'b8f84eb7-4a91-45ba-a5d3-95d5f48c7772',
          title: '',
          id: '20db973e-4612-4933-9acd-5a6d4c3d281d',
          metadata: {},
          subtype: null,
          text:
            '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est</p>'
        }
      ],
      'card-updated-at': 1602156067786,
      'content-version-id': 'dc523cfa-dcf2-41b5-ab7a-b5c3577e11f7',
      'card-added-at': 1602156035607,
      status: 'draft',
      id: '539c1ed1-79e5-4771-a304-60b46d8376c6',
      'content-id': '539c1ed1-79e5-4771-a304-60b46d8376c6',
      version: 2,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'It pays to be kind: improving workplace culture through kindness',
          message: null,
          image: {
            key: 'ace/2020-10/ec88fad0-ed4d-44ec-9930-72e9d897bdd0/seo.jpg',
            url: null,
            attribution: 'Credits: Asif Asharaf',
            caption: 'Saque ipsa quae ab illo inventore veritatis et quip',
            metadata: {
              width: 1600,
              height: 900,
              'mime-type': 'image/jpeg',
              'file-size': 228062,
              'file-name': 'seo.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/307e5a1b-5cf0-4321-8193-827b2add174f/element/20db973e-4612-4933-9acd-5a6d4c3d281d',
          type: 'text',
          'family-id': 'b8f84eb7-4a91-45ba-a5d3-95d5f48c7772',
          title: '',
          id: '20db973e-4612-4933-9acd-5a6d4c3d281d',
          metadata: {},
          subtype: null,
          text:
            '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est</p>'
        },
        {
          id: '00000000-0000-0000-0000-000000000000',
          description: '',
          title: '',
          subtype: null,
          type: 'text',
          text: ' <p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
          metadata: {
            'promotional-message': true
          }
        }
      ],
      'card-updated-at': 1602156067786,
      'content-version-id': 'dc523cfa-dcf2-41b5-ab7a-b5c3577e11f7',
      'card-added-at': 1602156035607,
      status: 'draft',
      id: '539c1ed1-79e5-4771-a304-60b46d8376c6',
      'content-id': '539c1ed1-79e5-4771-a304-60b46d8376c6',
      version: 2,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'It pays to be kind: improving workplace culture through kindness',
          message: null,
          image: {
            key: 'ace/2020-10/ec88fad0-ed4d-44ec-9930-72e9d897bdd0/seo.jpg',
            url: null,
            attribution: 'Credits: Asif Asharaf',
            caption: 'Saque ipsa quae ab illo inventore veritatis et quip',
            metadata: {
              width: 1600,
              height: 900,
              'mime-type': 'image/jpeg',
              'file-size': 228062,
              'file-name': 'seo.jpg'
            }
          }
        },
        attributes: {}
      }
    }
  ],
  url: 'https://ace-web.qtstage.io/health/2020/10/08/it-pays-to-be-kind-improving-workplace-culture-through-kindness',
  'story-version-id': '1102e686-c8ee-40e4-be37-ae46ea248b86',
  'content-type': 'story',
  'content-updated-at': 1602566970410,
  'author-id': 927927,
  'owner-id': 927927,
  'linked-story-ids': [],
  access: 'subscription',
  'promotional-message': ' <p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
  'asana-project-id': null,
  'first-published-at': 1602155728566,
  'hero-image-caption': 'Saque ipsa quae ab illo inventore veritatis et quip',
  version: 24,
  'story-template': 'listicle',
  'sequence-no': null,
  'created-at': 1602566959904,
  authors,
  metadata: {
    'card-share': {
      shareable: false
    },
    'sponsored-by': 'quint'
  },
  'publish-at': null,
  'assignee-name': 'Pravin Atigre'
};

const dummyVideoStory: IStory = {
  'updated-at': 1597304475121,
  seo: {
    'claim-reviews': {
      story: null
    }
  },
  'assignee-id': 187245,
  'author-name': 'Shreya Shukla',
  tags: [],
  headline: 'Places To Visit In India Before You Turn 30 in 2020',
  'storyline-id': null,
  votes: {},
  'story-content-id': '7e6b6da1-9c9f-46ac-ad16-e5e379f2de57',
  slug: 'parent-sec/child-sec/places-to-visit-in-india-before-you-turn-30-in-2020',
  'last-published-at': 1597304477738,
  subheadline:
    'Here is a list of 81 places to visit in Indiabefore you turn 30. These places in India are known for their magnificence and overall beauty. They are also considered as havens for solo',
  alternative: {},
  sections: [
    {
      'domain-slug': null,
      slug: 'child-sec',
      name: 'child-sec',
      'section-url': 'https://ace-web.qtstage.io/parent-sec/child-sec',
      id: 11825,
      'parent-id': 11824,
      'display-name': 'child-sec',
      collection: {
        slug: 'child-sec-parent-sec',
        name: 'child-sec (parent-sec)',
        id: 17115
      },
      data: null
    }
  ],
  'story-audio': {
    's3-key': 'ace/story-audio/2020-08/7e6b6da1-9c9f-46ac-ad16-e5e379f2de57/.47a3109a-f0e8-4608-9b69-d9d951c41061.mp3'
  },
  'read-time': 2,
  'access-level-value': null,
  'content-created-at': 1597303825469,
  'owner-name': 'Shreya Shukla',
  'custom-slug': null,
  'push-notification': null,
  'publisher-id': 97,
  'hero-image-metadata': {
    width: 600,
    height: 600,
    'mime-type': 'image/jpeg',
    'file-size': 65362,
    'file-name': '29TRIPPEDUP-points-articleLarge.jpg',
    'focus-point': [290, 275]
  },
  comments: null,
  'word-count': 366,
  entities: {},
  'published-at': 1597304477738,
  'is-live-blog': false,
  'breaking-news-linked-story-id': null,
  'storyline-title': null,
  summary: null,
  'push-notification-title': null,
  'external-id': null,
  'canonical-url': null,
  'is-amp-supported': true,
  autotags: [],
  'linked-entities': [],
  status: 'published',
  'hero-image-attribution': 'Explore',
  'bullet-type': '123',
  id: '7e6b6da1-9c9f-46ac-ad16-e5e379f2de57',
  'hero-image-s3-key': 'ace/2020-08/03286797-a917-4c6b-a79e-2157d594d643/29TRIPPEDUP_points_articleLarge.jpg',
  contributors: null,
  'associated-series-collection-ids': [],
  cards: [
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/7e6b6da1-9c9f-46ac-ad16-e5e379f2de57/element/242c56ac-f327-4e5e-8bec-0ddb16be7eae',
          type: 'youtube-video',
          'family-id': 'd8a3a0b8-b2f7-4168-96d3-289917d68c11',
          title: '',
          id: '242c56ac-f327-4e5e-8bec-0ddb16be7eae',
          url: 'https://www.youtube.com/watch?v=Y4WHA4AH6I8',
          'embed-url': 'https://www.youtube.com/embed/Y4WHA4AH6I8',
          metadata: {},
          subtype: null
        }
      ],
      'card-updated-at': 1597304475141,
      'content-version-id': '4da828cf-7935-47a7-9be8-a338d2df1aa9',
      'card-added-at': 1597304110277,
      status: 'draft',
      id: 'c7cf4bbc-291e-40d4-b065-1bde9cf059af',
      'content-id': 'c7cf4bbc-291e-40d4-b065-1bde9cf059af',
      version: 13,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Places To Visit In India Before You Turn 30 in 2020',
          message: null,
          image: {
            key: 'ace/2020-08/03286797-a917-4c6b-a79e-2157d594d643/29TRIPPEDUP_points_articleLarge.jpg',
            url: null,
            attribution: 'Explore',
            caption: 'travel',
            metadata: {
              width: 600,
              height: 600,
              'mime-type': 'image/jpeg',
              'file-size': 65362,
              'file-name': '29TRIPPEDUP-points-articleLarge.jpg',
              'focus-point': [290, 275]
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'page-url': '/story/7e6b6da1-9c9f-46ac-ad16-e5e379f2de57/element/756e666b-b70e-4500-b05b-b939916109aa',
          type: 'text',
          'family-id': 'e28bcff8-7d50-4e22-8190-71dfcc55c3ac',
          title: '',
          id: '756e666b-b70e-4500-b05b-b939916109aa',
          metadata: {},
          subtype: null,
          text:
            '<p>The majestic mountains can call tourists to experience the best of what nature has to offer at Mcleodganj – one of the most <strong>beautiful places in India</strong>. Triund offers a spectacular view of the Dhauladhar ranges. Experience bliss while camping under billions of stars with your friends in Triund. This is among loveliest Indian destinations for camping, regardless of which season you’re coming in. No wonder it’s among the top 10 places to visit in India.</p>'
        }
      ],
      'card-updated-at': 1597304475141,
      'content-version-id': '910d24ed-0ea3-480e-b531-590db6ec9659',
      'card-added-at': 1597304170255,
      status: 'draft',
      id: '0ec27e74-6b99-4baf-873b-f6f22d0a1d79',
      'content-id': '0ec27e74-6b99-4baf-873b-f6f22d0a1d79',
      version: 12,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Places To Visit In India Before You Turn 30 in 2020',
          message: null,
          image: {
            key: 'ace/2020-08/03286797-a917-4c6b-a79e-2157d594d643/29TRIPPEDUP_points_articleLarge.jpg',
            url: null,
            attribution: 'Explore',
            caption: 'travel',
            metadata: {
              width: 600,
              height: 600,
              'mime-type': 'image/jpeg',
              'file-size': 65362,
              'file-name': '29TRIPPEDUP-points-articleLarge.jpg',
              'focus-point': [290, 275]
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'image-metadata': {
            'original-url': 'https://www.wsws.org/asset/611ccd56-55be-4eae-9817-e924da95710e/image.jpg',
            width: 300,
            height: 168
          },
          'page-url': '/story/7e6b6da1-9c9f-46ac-ad16-e5e379f2de57/element/fe153a01-751c-42af-89d7-1012ba491c69',
          type: 'image',
          'family-id': '844833e3-2fb4-4c7b-84e6-519db2a52e0a',
          'image-attribution': 'Tourist destinations in India ',
          title: 'Building',
          id: 'fe153a01-751c-42af-89d7-1012ba491c69',
          'image-s3-key': 'ace/2020-08/894a16d2-ef88-4631-a44a-a31099cc37ef/image.jpg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/7e6b6da1-9c9f-46ac-ad16-e5e379f2de57/element/7b43043c-5a41-4d95-83c5-e581aa6c6ae9',
          type: 'text',
          'family-id': '2770c182-1ce6-415b-b051-e7e836768469',
          title: '',
          id: '7b43043c-5a41-4d95-83c5-e581aa6c6ae9',
          metadata: {},
          subtype: null,
          text:
            '<p>Here’s why every man should travel solo at least once in your life – Not only will you discover a new you and find a direction for yourself, but you’d return with experiences of a</p>'
        },
        {
          description: '',
          'page-url': '/story/7e6b6da1-9c9f-46ac-ad16-e5e379f2de57/element/2d72d139-9636-40bd-aa56-7c15c928c084',
          type: 'text',
          'family-id': '66de9e09-98f4-48ac-b0a2-99fdf5f436b1',
          title: '',
          id: '2d72d139-9636-40bd-aa56-7c15c928c084',
          metadata: {},
          subtype: null,
          text:
            '<p>Stay set to be overwhelmed by the sceneries, flora, and fauna of the Dibru Saikhowa National Park. And this wildlife expedition is not the same as any jeep safari. Instead, activities like jungle walk and boat ride are conducted during the months of November to April. Cruise along the Brahmaputra river to witness the animals in their most natural habitats. The surreal surroundings of this place makes Assam one of the <strong>top 25 tourist places in India</strong>.</p>'
        },
        {
          description: '',
          'page-url': '/story/7e6b6da1-9c9f-46ac-ad16-e5e379f2de57/element/69efa87a-0b5e-4964-9c06-3b28375c07e1',
          type: 'text',
          'family-id': '579a9470-e36d-4f31-af7c-6f5892b8b006',
          title: '',
          id: '69efa87a-0b5e-4964-9c06-3b28375c07e1',
          metadata: {
            content: 'Rishikesh – The Adventure Capital Of India',
            attribution:
              'With bursting energy and enthusiasm, twenties is the best time to enjoy thrill and adventure, especially with your friends. Travel to the adventure capital of India, Rishikesh and ride on the mystical but fierce fast flowing waters of Ganges. Rishikesh is a must visit place in India if you haven’t yet crossed the age bar.'
          },
          subtype: 'blockquote',
          text:
            '<div><blockquote>Rishikesh – The Adventure Capital Of India</blockquote><span class="attribution">With bursting energy and enthusiasm, twenties is the best time to enjoy thrill and adventure, especially with your friends. Travel to the adventure capital of India, Rishikesh and ride on the mystical but fierce fast flowing waters of Ganges. Rishikesh is a must visit place in India if you haven’t yet crossed the age bar.</span></div>'
        },
        {
          description: '',
          'page-url': '/story/7e6b6da1-9c9f-46ac-ad16-e5e379f2de57/element/d85b8c9c-6c17-47a4-9088-2569d4b51f6e',
          type: 'text',
          'family-id': 'ee399b93-c743-49f0-9f8d-121bb4857dd4',
          title: '',
          id: 'd85b8c9c-6c17-47a4-9088-2569d4b51f6e',
          metadata: {},
          subtype: 'summary',
          text:
            '<p>Revisit childhood by hopping on the colorful <a href="https://traveltriangle.com/himachal-tourism/shimla/things-to-do/toy-train" rel="noopener noreferrer">toy train from Kalka to Shimla</a> as it makes way through the pine forests and verdant valleys. Experiencing this exceptionally elating toy train experience is a must for everyone on their trip to this <strong>best tourist place in India</strong> at least once in a lifetime.</p>'
        }
      ],
      'card-updated-at': 1597304475141,
      'content-version-id': 'a856ce95-a6de-4295-9eff-aa2ab7069dd3',
      'card-added-at': 1597304278141,
      status: 'draft',
      id: 'bab4819d-9b9b-4449-8496-a21ae8878681',
      'content-id': 'bab4819d-9b9b-4449-8496-a21ae8878681',
      version: 10,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'Places To Visit In India Before You Turn 30 in 2020',
          message: null,
          image: {
            key: 'ace/2020-08/894a16d2-ef88-4631-a44a-a31099cc37ef/image.jpg',
            url: null,
            attribution: 'Tourist destinations in India ',
            caption: null,
            metadata: {
              'original-url': 'https://www.wsws.org/asset/611ccd56-55be-4eae-9817-e924da95710e/image.jpg',
              width: 300,
              height: 168
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'image-metadata': {
            width: 1024,
            height: 683,
            'mime-type': 'image/jpeg',
            'file-size': 63545,
            'file-name': 'munnar.jpg'
          },
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          type: 'image',
          'family-id': 'ed7a8053-e782-4807-98e4-c91ae0a457cd',
          'image-attribution': 'One of the romantic place in Munnar.',
          title: 'Camp At Meesapulimala',
          id: 'f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          'image-s3-key': 'ace/2019-01/72017920-bcef-468a-82d0-43142e04d5fc/images__4_.jpeg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          type: 'text',
          'family-id': 'cc8883db-6a89-48f9-84c3-ca6bf9ce437b',
          title: '',
          id: '2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          metadata: {},
          subtype: null,
          text:
            '<p>Meesapulimala, the second highest peak along the Western Ghats of Kerala beckons you to trek up its peak and feel on top of the world.</p>'
        }
      ],
      'card-updated-at': 1594115026000,
      'content-version-id': '7b8fee10-78c9-43aa-80fb-ac4f5b6e2578',
      'card-added-at': 1594113776027,
      status: 'draft',
      id: '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      'content-id': '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      version: 8,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'History of Munnar',
          message: null,
          image: {
            key: 'ace/2020-07/9c3a8170-ad22-40dd-82ae-e1c4b12c3551/munnar.jpg',
            url: null,
            attribution: 'One of the romantic place in Munnar.',
            caption: null,
            metadata: {
              width: 1024,
              height: 683,
              'mime-type': 'image/jpeg',
              'file-size': 63545,
              'file-name': 'munnar.jpg'
            }
          }
        },
        attributes: {}
      }
    },
    {
      'story-elements': [
        {
          description: '',
          'image-metadata': {
            width: 1024,
            height: 683,
            'mime-type': 'image/jpeg',
            'file-size': 63545,
            'file-name': 'munnar.jpg'
          },
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          type: 'image',
          'family-id': 'ed7a8053-e782-4807-98e4-c91ae0a457cd',
          'image-attribution': 'One of the romantic place in Munnar.',
          title: 'Camp At Meesapulimala',
          id: 'f95679f1-46a0-41f5-9d3b-430a6a96cccd',
          'image-s3-key': 'ace/2019-05/dcfb8b43-44a2-49cc-ae71-5fbc3cc99dca/jpeg_43.jpg',
          metadata: {},
          subtype: null
        },
        {
          description: '',
          'page-url': '/story/b54b3a36-ff14-448e-a149-8e6033f4ea87/element/2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          type: 'text',
          'family-id': 'cc8883db-6a89-48f9-84c3-ca6bf9ce437b',
          title: '',
          id: '2eb2a35a-a125-4b4b-9601-fccf50cbbadf',
          metadata: {},
          subtype: null,
          text:
            '<p>Meesapulimala, the second highest peak along the Western Ghats of Kerala beckons you to trek up its peak and feel on top of the world.</p>'
        },
        {
          id: '00000000-0000-0000-0000-000000000000',
          description: '',
          title: '',
          subtype: null,
          type: 'text',
          text: ' <p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
          metadata: { 'promotional-message': true }
        }
      ],
      'card-updated-at': 1594115026000,
      'content-version-id': '7b8fee10-78c9-43aa-80fb-ac4f5b6e2578',
      'card-added-at': 1594113776027,
      status: 'draft',
      id: '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      'content-id': '9f9ad1b2-07c8-41b2-b792-c116aa6ec459',
      version: 8,
      metadata: {
        'social-share': {
          shareable: false,
          title: 'History of Munnar',
          message: null,
          image: {
            key: 'ace/2020-07/9c3a8170-ad22-40dd-82ae-e1c4b12c3551/munnar.jpg',
            url: null,
            attribution: 'One of the romantic place in Munnar.',
            caption: null,
            metadata: {
              width: 1024,
              height: 683,
              'mime-type': 'image/jpeg',
              'file-size': 63545,
              'file-name': 'munnar.jpg'
            }
          }
        },
        attributes: {}
      }
    }
  ],
  url: 'https://ace-web.qtstage.io/parent-sec/child-sec/places-to-visit-in-india-before-you-turn-30-in-2020',
  'story-version-id': 'abe421d1-933e-4aa8-90c0-20137ad7fe7b',
  'content-type': 'story',
  'content-updated-at': 1597304477982,
  'author-id': 187245,
  'owner-id': 187245,
  'linked-story-ids': [],
  access: 'subscription',
  'promotional-message': ' <p>text <a href="https://ace-web.qtstage.io/">promotional</a> message</p>',
  'asana-project-id': null,
  'first-published-at': 1597304477738,
  'hero-image-caption': 'travel',
  version: 16,
  'story-template': 'video',
  'sequence-no': null,
  'created-at': 1597304475121,
  authors,
  metadata: {
    'card-share': {
      shareable: false
    },
    'sponsored-by': 'quint'
  },
  'publish-at': null,
  'assignee-name': 'Shreya Shukla'
};

const updatedStory = (story: IStory, storyType: string, config: object) => {
  const authorDetails = get(config, ['story', `${storyType}-story`, 'settings', 'authorDetails']);
  const authorStyle = get(authorDetails, ['template'], 'default');
  if (authorStyle === 'centerAligned' || authorStyle === 'leftAligned') {
    story = {
      ...story,
      authors: [authors[0]]
    };
  }
  return story;
};

const getDummyStory = (storyType: string, config: object) => {
  let story;
  switch (storyType) {
    case 'text':
      story = dummyTextStory;
      break;
    case 'video':
      story = dummyVideoStory;
      break;
    case 'photo':
      story = dummyPhotoStory;
      break;
    case 'liveBlog':
      story = dummyLiveBlogStory;
      break;
    case 'listicle':
      story = dummyListicleStory;
      break;
  }

  return updatedStory(story as IStory, storyType, config);
};

export const getDummyStoryData = (storyType: string, config: object) => {
  return {
    story: getDummyStory(storyType, config),
    index: 0,
    staticRelatedStories: [
      {
        'author-name': 'Ravigopal Kesari',
        headline: 'Ready Player One review \u2013 Spielberg\u00A0',
        slug: 'recent-stories/news/ready-player-one-review-spielberg-spins-a-dizzying-vr-yarn',
        'last-published-at': 1580192897958,
        'hero-image-metadata': {
          width: 1920,
          height: 1080,
          'mime-type': 'image/png',
          'file-size': 587085,
          'file-name': 'ready-player-one-hd-wallpapers-70749-6537851.png',
          'focus-point': [883, 411]
        },
        id: 'a3561065-11ce-4281-9d86-325934aa2146',
        'hero-image-s3-key':
          'ace/2019-08/e3103795-ebb4-4c6c-93f0-2e829037e008/ready_player_one_hd_wallpapers_70749_6537851.png',
        'author-id': 123981,
        'story-template': 'review',
        'read-time': 3,
        authors: [
          {
            id: 94980,
            name: 'Kuruvilla',
            slug: 'kuruvilla-choolackal-2',
            'avatar-url': null,
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': {
              id: 873,
              name: 'Author'
            }
          },
          {
            id: 123981,
            name: 'Ravigopal Kesari',
            slug: 'ravigopal-kesari',
            'avatar-url':
              'https://lh5.googleusercontent.com/-NhNrHEp1w4M/AAAAAAAAAAI/AAAAAAAAAAs/lzYwVY1BQdQ/photo.jpg?sz=50',
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': {
              id: 873,
              name: 'Author'
            }
          }
        ],
        metadata: {
          'review-title': 'Ready Player One',
          'review-rating': {
            label: '1',
            value: '1'
          },
          'sponsored-by': 'quint',
          'card-share': {
            shareable: false
          }
        }
      },
      {
        'author-name': 'Shreya Shukla',
        headline: 'Steps you can take to protect yourself from Covid',
        slug: 'recent-stories/other-templates',
        'read-time': 3,
        'last-published-at': 1580192897958,
        'hero-image-metadata': {
          width: 3456,
          height: 2304,
          'mime-type': 'image/jpeg',
          'file-size': 3528507,
          'file-name': 'IMG_5342.JPG',
          'focus-point': [1867, 918]
        },
        id: 'c7dbc1d1-635d-4de1-b274-db7aa0fd1e06',
        'hero-image-s3-key': 'ace/2019-07/43ab742e-185d-4899-9735-a9bc6b5785ca/IMG_5342.JPG',
        'author-id': 187245,
        'story-template': 'other',
        authors: [
          {
            id: 187245,
            name: 'Shreya',
            slug: 'shreya-shukla-2',
            'avatar-url':
              'https://lh6.googleusercontent.com/-pBdJGAfN81c/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rfmrjS-gWdYzH3Gtmuib3KbVLdixw/photo.jpg',
            'avatar-s3-key': null,
            'twitter-handle': null,
            bio: null,
            'contributor-role': {
              id: 873,
              name: 'Author'
            }
          }
        ],
        metadata: {
          'card-share': {
            shareable: false
          }
        }
      },
      {
        'author-name': 'Rashmi J',
        headline: 'Corona Letter: Another vaccine may get approval',
        slug: 'news/health/test-fallback',
        'hero-image-metadata': {
          'focus-point': [459, 248],
          width: 960,
          height: 576
        },
        id: 'e0754cf9-9b58-40eb-ac90-754f30406ac9',
        'hero-image-s3-key':
          'ace/2018-01/ffeb5647-0c94-4541-8acd-7c7aeb0800a5/Thailand-Kra-Canal-Wikimedia-Commons-copy-960x576.jpg',
        'author-id': 181681,
        'story-template': null,
        'read-time': 3,
        'last-published-at': 1580192897958,
        authors: [
          {
            id: 181681,
            name: 'Rashmi',
            slug: 'rashmi-j',
            'avatar-url':
              'http://thumbor-stg.assettype.com/ace/2018-12/b795fdb1-2e57-44ba-95fd-10d89531aab1/author.jpeg',
            'avatar-s3-key': 'ace/2018-12/b795fdb1-2e57-44ba-95fd-10d89531aab1/author.jpeg',
            'twitter-handle': 'www.twitter.com',
            bio: '<p>Bio-test</p>',
            'contributor-role': null
          }
        ],
        metadata: {
          'card-share': {
            shareable: false
          }
        }
      },
      {
        'author-name': 'Rashmi J',
        headline: '6 new features that make using an iPhone different',
        slug: 'other/entertainment/text-story-image-test',
        'read-time': 3,
        'last-published-at': 1580192897958,
        'hero-image-metadata': {
          width: 313,
          height: 161,
          'mime-type': 'image/jpeg'
        },
        id: 'b468d9b3-d294-472a-a06e-374d35d248a2',
        'hero-image-s3-key': 'ace/2019-01/9c5166ad-958f-4716-bee7-bb7fbc55f872/1137_585_images.jpeg',
        'author-id': 181681,
        'story-template': null,
        authors: [
          {
            id: 181681,
            name: 'Rashmi',
            slug: 'rashmi-j',
            'avatar-url':
              'http://thumbor-stg.assettype.com/ace/2018-12/b795fdb1-2e57-44ba-95fd-10d89531aab1/author.jpeg',
            'avatar-s3-key': 'ace/2018-12/b795fdb1-2e57-44ba-95fd-10d89531aab1/author.jpeg',
            'twitter-handle': 'www.twitter.com',
            bio: '<p>Bio-test</p>',
            'contributor-role': null
          }
        ],
        metadata: {
          'card-share': {
            shareable: false
          }
        }
      }
    ]
  };
};

export const getStories = (id: string) => {
  const stories = [dummyTextStory, dummyPhotoStory, dummyLiveBlogStory];
  if (id) {
    return stories.find(story => story.id === id);
  }
  return stories;
};
