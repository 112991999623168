import { TextField } from '@quintype/em/components/text-field';
import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updatePageSettings } from '../../actions/builder-action';
import { ToggleButton } from '../toggle-button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './subscription.module.css';
import { FieldLabel } from '@quintype/em/components/field-label';
import { ThemeSetter } from '../theme-setter';
import { Separator } from '../../atoms/separator';
import { updateMeterSettings } from '../../actions/builder-action';

interface IDataItems {
  type: string;
  label: string;
  key: string;
  defaultValue?: string;
  placeHolder?: string;
  darkKey?: string;
}

export const SubscriptionSettings = () => {
  const dispatch = useDispatch();

  const { subscription = {}, enableDarkModePreview = false } = useSelector(state =>
    get(state, ['builder', 'config'], {})
  );
  const { pageSetting = {}, rows = [] } = subscription;
  const updateSubscription = (key: string, value: string | boolean) => {
    dispatch(updatePageSettings({ [key]: value }));

    switch (key) {
      case 'buttonColor':
        dispatch(updateMeterSettings({ key: 'subscribeButtonColor', value }));
        break;
      case 'darkButtonColor':
        dispatch(updateMeterSettings({ key: 'subscribeDarkButtonColor', value }));
        break;
    }

    dispatch(updateHaveUnsavedChanges(true));
  };

  const getSubscriptionSetting = (layout: string) => {
    const displayGroupImage = {
      label: 'Display Group Images',
      key: 'showGroupImg',
      type: 'toggle'
    };

    const displayPlanImage = {
      label: 'Display Plan Images',
      key: 'showPlanImg',
      type: 'toggle'
    };

    const loaderSetting = [
      {
        label: 'Separator',
        key: 'separator',
        type: 'separator'
      },
      {
        label: 'Loading Delay Message',
        type: 'quillEditor',
        defaultValue: 'Oops, it seems plans are taking longer than expected to load. Thank you for your patience!',
        key: 'loaderMessage',
        placeHolder: 'Oops, it seems plans are taking longer than expected to load. Thank you for your patience!'
      }
    ];

    const setting = [
      {
        label: 'Page Title',
        type: 'quillEditor',
        defaultValue: 'Discover the Power of News Subscription Plans',
        key: 'pageTitle',
        placeHolder: 'Discover the Power of News Subscription Plans'
      },
      {
        label: 'Page Description',
        type: 'quillEditor',
        defaultValue:
          'Unlock premium news content and stay informed with our exclusive subscription plan. Get access to in-depth stories and expert analysis.',
        key: 'pageDescription',
        placeHolder:
          'Unlock premium news content and stay informed with our exclusive subscription plan. Get access to in-depth stories and expert analysis.'
      },
      {
        label: 'Subscribe Button Text',
        placeHolder: 'Subscribe Now',
        key: 'subscribeButton',
        defaultValue: 'Subscribe Now',
        type: 'textField'
      },
      {
        label: "What's Included Text",
        placeHolder: "What's Included",
        key: 'descriptionTitle',
        defaultValue: "What's Included",
        type: 'textField'
      },
      {
        type: 'colorPicker',
        label: 'Button Color',
        key: 'buttonColor',
        darkKey: 'darkButtonColor'
      },
      {
        label: 'Display Original Price & Offer',
        key: 'showOfferPrice',
        type: 'toggle'
      }
    ];

    const thankYouPageSettings = [
      {
        label: 'Separator',
        key: 'separator2',
        type: 'separator'
      },
      {
        label: 'Thank you page',
        type: 'fieldLabel',
        key: 'fieldLabel2'
      },
      {
        label: 'Title',
        type: 'quillEditor',
        defaultValue: 'Thank you for placing your order!',
        key: 'thankYouPageTitle',
        placeHolder: ''
      },
      {
        label: 'Description',
        type: 'quillEditor',
        defaultValue:
          'We kindly ask for your patience as we process it (approx. 15min). Please check your email for further details and updates.',
        key: 'thankYouPageDescription',
        placeHolder: ''
      },
      {
        label: 'Continue Reading button',
        placeHolder: 'Continue Reading',
        key: 'continueReading',
        defaultValue: 'Continue Reading',
        type: 'textField'
      },
      {
        label: 'Go to Profile button',
        placeHolder: '',
        key: 'goToProfile',
        defaultValue: 'Go to Profile',
        type: 'textField'
      },
      {
        label: 'Go to Home button',
        placeHolder: '',
        key: 'goToHome',
        defaultValue: 'Go to Home',
        type: 'textField'
      }
    ];

    switch (layout) {
      case 'Lavish':
        return [...setting, displayGroupImage, ...loaderSetting, ...thankYouPageSettings];
      case 'Concrete':
        return [...setting, displayPlanImage, ...loaderSetting, ...thankYouPageSettings];
      default:
        return [...setting, ...loaderSetting, ...thankYouPageSettings];
    }
  };

  const layout = get(rows, [0, 'layout'], 'Lavish');
  const setting = getSubscriptionSetting(layout);

  return (
    <>
      {setting.map((item: IDataItems) => {
        const { type, label, key, defaultValue = '', placeHolder, darkKey = '' } = item || {};

        switch (type) {
          case 'quillEditor':
            const modules = {
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }]
              ]
            };

            const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet'];
            return (
              <div className={styles.quillWrapper}>
                <FieldLabel label={label} />
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={get(pageSetting, [key]) || defaultValue}
                  onChange={(value: any) => updateSubscription(key, value)}
                  placeholder=" "
                />
              </div>
            );
          case 'textField':
            return (
              <TextField
                label={label}
                placeholder={placeHolder}
                value={get(pageSetting, [key]) || defaultValue}
                onChange={(value: any) => updateSubscription(key, value)}
              />
            );
          case 'toggle':
            return (
              <div className={styles.toggleContainer}>
                <ToggleButton
                  label={label}
                  toggleName={key}
                  id={key}
                  updateToggleState={get(pageSetting, [key], defaultValue)}
                  updateToggleButton={(name: string, value: boolean) => updateSubscription(name, value)}
                />
              </div>
            );
          case 'colorPicker':
            return (
              <div className={styles.colorPickerContainer}>
                <ThemeSetter
                  label={label}
                  updateTheStore={updateSubscription}
                  lightColorExtract={get(pageSetting, [key], defaultValue)}
                  lightUpdateStoreKey={key}
                  darkColorExtract={get(pageSetting, [darkKey], defaultValue)}
                  darkUpdateStoreKey={darkKey}
                  lightDefaultColor="#4860BC"
                  darkDefaultColor="#4860BC"
                  enableDarkModePreview={enableDarkModePreview}
                />
              </div>
            );
          case 'separator':
            return <Separator />;
          case 'fieldLabel':
            return (
              <div className={styles.fieldLabel}>
                <FieldLabel label={label} />
              </div>
            );
        }
      })}
    </>
  );
};
