import React from 'react';
import styles from './display-message.module.css';

export function DisplayMessage({ customMessage = '' }) {
  return (
    <div className={styles.saveMsgWrapper}>
      <p>{customMessage || 'Your changes are successfully saved.'}</p>
    </div>
  );
}
