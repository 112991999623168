import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import Masonry from 'react-masonry-css';
import { useSelector } from 'react-redux';
import SmallTitle from '../../atoms/smallTitle';
import useConfigPathFromStore from '../../hooks/useConfigPathFromStore';
import { IComponentMeta } from '../../utils/interfaces';
import { generateComponentLabel, generateComponentList } from '../../utils/utils';
import camelCase from 'lodash/camelCase';
import styles from './masonry-layouts.module.css';

import { OptimisedIcon } from '../../atoms/optimsed-icon';

interface IMasonryLayoutsProps {
  handleLayoutSelect: (
    type: string,
    componentName: string,
    label: string,
    settings: object,
    componentContentType: string,
    storiesCount: number
  ) => void;
  layoutsList: IComponentMeta[];
  type: string;
  optimisedOnly?: boolean;
  customStoryTypeName?: string;
  customStoryTypeBaseName?: string;
}

const MasonryLayouts = ({
  handleLayoutSelect,
  layoutsList = [],
  type,
  optimisedOnly,
  customStoryTypeName,
  customStoryTypeBaseName
}: IMasonryLayoutsProps) => {
  const { selectRowInspector, config, pageType } = useSelector(state => get(state, ['builder']));
  const filteredComponentList = generateComponentList({ pageType, layoutsList });
  const configPath = useConfigPathFromStore();
  const selectedRows = get(config, [...configPath, 'rows'], []);
  const isCurrentRow = selectedRows.find((item: any) => item.rowId === selectRowInspector.rowId);
  const activeLayout = isCurrentRow
    ? filteredComponentList.find((item: any) => item.componentName === isCurrentRow.layout)
    : {};

  const storyConfig = get(config, [customStoryTypeName ? 'customStory' : 'story'], {});
  const magazine = get(config, ['magazine']);

  const getSelectedTemplate = (type: string) => {
    if (type === 'collections') return activeLayout;
    const storyType = `${type}-story`;
    return filteredComponentList.find(
      (item: any) => storyConfig[storyType] && item.componentName === storyConfig[storyType].template
    );
  };

  return (
    <Masonry className={styles.components} columnClassName={styles.componentsColoumn} breakpointCols={3}>
      {filteredComponentList.map(
        (
          {
            componentName,
            viewType,
            componentLabel = '',
            image,
            lightRow = true,
            settings,
            componentContentType,
            storiesCount
          }: any,
          index: any
        ) => {
          const hideLayouts = [
            'SearchPage',
            'AuthorsListComponent',
            'ArrowPageIntroductionCard',
            'ArrowMagazineHeaderCard',
            'ArrowMagazineEditions',
            'Concrete',
            'Lavish',
            'Minimalist',
            'SignIn',
            'SignUp',
            'ForgotPassword',
            'ResetPassword',
            'EmailOtp',
            'MobileOtp',
            'EnterPassword',
            'ManageSubscriptions',
            'AvailablePlans',
            'PlanChangeOverview',
            'PurchasePageIntroductionCard',
            'UserProfilePage'
          ];

          const subscriptionLayout = ['Concrete', 'Lavish', 'Minimalist'];
          const hideMagazineWidget = isEmpty(magazine) && componentName === 'ArrowMagazineWidget';

          if (
            pageType !== 'subscription' &&
            (hideMagazineWidget || hideLayouts.includes(componentName) || (optimisedOnly && !lightRow))
          ) {
            return;
          }
          if (pageType === 'subscription' && !subscriptionLayout.includes(componentName)) {
            return;
          }

          const label = generateComponentLabel({ pageType, viewType, componentLabel }) || '';
          const selectedTemplate = getSelectedTemplate(customStoryTypeName || type);

          let isSelectedTemplate = selectedTemplate && selectedTemplate.componentName === componentName;
          if (isSelectedTemplate && customStoryTypeBaseName && customStoryTypeBaseName !== type) {
            isSelectedTemplate = false;
          }

          const dataTestId = camelCase(`${label}-template`);
          return (
            <div
              className={`${styles.component} select-row__component`}
              key={index}
              onClick={() =>
                handleLayoutSelect(type, componentName, label, settings, componentContentType, storiesCount)
              }
            >
              <SmallTitle className={styles.componentName}>{label}</SmallTitle>
              <div
                className={`${styles.componentImgWrapper} ${isSelectedTemplate ? `${styles.selected}` : ''}`}
                data-test-id={dataTestId}
              >
                <div dangerouslySetInnerHTML={{ __html: image }} />
                {pageType === 'home' && lightRow && (
                  <div className={styles.optimisedIcon}>
                    <OptimisedIcon />
                  </div>
                )}
              </div>
            </div>
          );
        }
      )}
    </Masonry>
  );
};

export default MasonryLayouts;
