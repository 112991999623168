import React from 'react';
import { IsvgData } from '../../utils/interfaces';

const Phone: React.FunctionComponent<IsvgData> = ({ width = '24px', height = '24px', color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        fill-rule="evenodd"
        d="M15.437 18.75c.365 0 .675-.128.93-.383s.383-.565.383-.93V6.063c0-.365-.128-.675-.383-.93a1.266 1.266 0 0 0-.93-.383H9.312c-.364 0-.674.128-.93.383a1.266 1.266 0 0 0-.382.93v11.374c0 .365.128.675.383.93s.565.383.93.383h6.124zm-.164-1.313H9.477c-.037 0-.073-.018-.11-.054-.036-.037-.055-.073-.055-.11V6.227c0-.037.019-.073.055-.11.037-.036.073-.054.11-.054h5.796c.037 0 .073.018.11.054.036.037.054.073.054.11v11.046c0 .037-.018.073-.054.11-.037.036-.073.054-.11.054zM12.375 17a.84.84 0 0 0 .615-.26.84.84 0 0 0 .26-.615.84.84 0 0 0-.26-.615.84.84 0 0 0-.615-.26.84.84 0 0 0-.615.26.84.84 0 0 0-.26.615.84.84 0 0 0 .26.615.84.84 0 0 0 .615.26z"
      />
    </svg>
  );
};

export { Phone };
