import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import updateOtherAdsScripts from '../../actions/other-ads-scripts-action';
import { checkValidEncodedCustomCode, encodeCustomCode } from '../../utils/utils';

import styles from './other-ads-scripts.module.css';

interface IFieldDetails {
  label: string;
  key: string;
  savedValue: string;
}

type IFieldDetailsList = IFieldDetails[];

const OtherAdsScripts = () => {
  const otherAdsScripts = useSelector(state => get(state, ['builder', 'config', 'general', 'otherAdsScripts'], {}));
  const dispatch = useDispatch();

  const fieldDetailsList: IFieldDetailsList = [
    {
      label: 'Horizontal Responsive',
      key: 'Horizontal-Ad',
      savedValue: get(otherAdsScripts, ['Horizontal-Ad'], '')
    },
    {
      label: 'Mrec',
      key: 'Mrec',
      savedValue: get(otherAdsScripts, ['Mrec'], '')
    },
    {
      label: 'Vertical Responsive',
      key: 'Vertical-Ad',
      savedValue: get(otherAdsScripts, ['Vertical-Ad'], '')
    },
    {
      label: 'Story Mrec',
      key: 'Story-Mrec',
      savedValue: get(otherAdsScripts, ['Story-Mrec'], '')
    },
    {
      label: 'Story Middle Responsive',
      key: 'Story-Middle-Ad',
      savedValue: get(otherAdsScripts, ['Story-Middle-Ad'], '')
    },
    {
      label: 'Story Bottom Responsive',
      key: 'Story-Bottom-Ad',
      savedValue: get(otherAdsScripts, ['Story-Bottom-Ad'], '')
    }
  ];

  return (
    <div className={styles.otherAdsScriptsWrapper}>
      {(fieldDetailsList || []).map(({ label, key, savedValue }: IFieldDetails) => {
        return (
          <TextField
            label={label}
            key={key}
            value={checkValidEncodedCustomCode(savedValue)}
            onChange={(value: string) => dispatch(updateOtherAdsScripts({ [key]: encodeCustomCode(value) }))}
          />
        );
      })}
    </div>
  );
};

export default OtherAdsScripts;
