import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion } from '@quintype/em/components/accordion';
import get from 'lodash/get';
import { richTxtFormats, richTxtModules } from '../../../constant';
import { storiesBehindLogin } from './behindLogin';
import { storiesBehindSubscription } from './behindSubscription';

function MeteringOff(): JSX.Element {
  const formats = richTxtFormats;
  const modules = richTxtModules;
  const dispatch = useDispatch();
  const { meteredPaywall = {}, enableDarkModePreview = false } = useSelector(state =>
    get(state, ['builder', 'config'], {})
  );
  const { pageSetting = {} } = meteredPaywall;

  return (
    <div>
      <Accordion hasSingleActivePanel onToggle={() => {}}>
        {storiesBehindLogin(modules, formats, pageSetting, dispatch)}
        {storiesBehindSubscription(modules, formats, pageSetting, dispatch)}
      </Accordion>
    </div>
  );
}

export default MeteringOff;
