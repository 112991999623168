export const TAG_DATA = {
  tagName: 'Story Tag',
  tagDescription:
    'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores'
};

export const AUTHOR_DATA = {
  id: 232776,
  name: 'Wonder Woman',
  slug: 'ravigopal-kesari',
  social: {
    twitter: {
      url: 'https://twitter.com/Reena00659364',
      handle: 'Twitter'
    },
    youtube: {
      url: 'https://www.facebook.com/people/Reena-Singh/100005438855189',
      handle: 'Youtube'
    },
    facebook: {
      url: 'https://www.facebook.com/people/Reena-Singh/100005438855189',
      handle: 'Facebook'
    },
    linkedin: {
      url: 'https://www.facebook.com/people/Reena-Singh/100005438855189',
      handle: 'LinkedIn'
    },
    whatsapp: {
      url: 'https://www.facebook.com/people/Reena-Singh/100005438855189',
      handle: 'Whatsapp'
    },
    instagram: {
      url: 'https://www.facebook.com/people/Reena-Singh/100005438855189',
      handle: 'Instagram'
    },
    pinterest: {
      url: 'https://www.facebook.com/people/Reena-Singh/100005438855189',
      handle: 'Pinterest'
    }
  },
  'avatar-url':
    'https://lh6.googleusercontent.com/-T9_sTsD4Qco/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rc0KarItXiZkh68r6sgS4QwAApcbg/photo.jpg',
  'avatar-s3-key': 'ace/2019-05/ab1c7dfe-2089-4a70-8b02-07158dba63a4/574882_PLQEPZ_308.jpg',
  'twitter-handle': 'quintype_inc',
  bio:
    'Wonder Woman origin story relates that she was sculpted from clay by her mother Queen Hippolyta and was given a life to live as an Amazon, along with superhuman powers as gifts by the Greek gods.',
  'contributor-role': {
    id: 873,
    name: 'Author'
  }
};

export const AUTHORS_DATA = {
  pageTitle: 'ours author data',
  pageDescription:
    'An easy and obvious description to explain the contents of the page below. Recommended to be 1-2 lines long.'
};

export const MAGAZINE_INTRO_DATA = {
  pageTitle: 'The Reader’s Digest',
  theme: '#131922',
  pageDescription:
    'The Reader’s Digest has a unique voice in its coverage of old-school stories. Reading is a valuable way of self educating yourself and an excellent way of filling in time, and it will give you a welcome break, from our sometimes repetitive lives.'
};

export const SUBSCRIPTION_DATA = {
  lavish: {
    subscriptions: [
      {
        account_id: 9,
        api_ordering: null,
        assets: [],
        campaign_active: false,
        created_at: '2023-04-07T08:27:34.714Z',
        currency: '₹',
        custom_attributes: [],
        deleted_at: null,
        display_assets: [],
        id: 33744,
        description:
          '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
        image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948181492/Asset 12x.png',
        metadata_fields: [],
        name: 'Digital All Access',
        old_assets: [],
        preferred_identity_provider: 'email',
        public: true,
        subscription_plans: [
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            custom_attributes: [
              {
                name: 'Actual Price',
                value: '<p><s>$8.99</s></p>',
                type: 'rich_text'
              },
              {
                name: 'Discount',
                value: '<p>42% Off</p>',
                type: 'rich_text'
              }
            ],
            deleted_at: null,
            description:
              '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
            display_assets: [],
            downgrade_plan_config: null,
            duration_length: 1,
            duration_unit: 'month',
            enabled: true,
            id: 65056,
            image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948181492/Asset 12x.png',
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 587,
            price_currency: '$',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Monthly',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          },
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            deleted_at: null,
            description:
              '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
            display_assets: [],
            downgrade_plan_config: null,
            duration_length: 3,
            duration_unit: 'months',
            enabled: true,
            custom_attributes: [
              {
                name: 'Tag',
                value: 'Best Value',
                type: 'plain_text'
              },
              {
                name: 'Actual Price',
                value: '<p><s>₹ 945</s></p>',
                type: 'rich_text'
              },
              {
                name: 'Discount',
                type: 'rich_text',
                value: '<p>42% Off</p>'
              }
            ],
            id: 65056,
            image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948218083/Asset 32x.png',
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 70000,
            price_currency: '₹',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Quarterly',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          },
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            deleted_at: null,
            description:
              '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
            display_assets: [],
            downgrade_plan_config: null,
            custom_attributes: [],
            duration_length: 1,
            duration_unit: 'year',
            enabled: true,
            id: 65056,
            image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948243781/Asset 12x 1.png',
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 125000,
            price_currency: '₹',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Yearly',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          }
        ],
        subscription_type: 'standard',
        target_amount: null,
        target_amount_validation: false,
        target_amount_validation_enabled: false,
        updated_at: '2023-04-07T08:27:34.714Z'
      },
      {
        account_id: 9,
        api_ordering: null,
        assets: [],
        campaign_active: false,
        created_at: '2023-04-07T08:27:34.714Z',
        currency: '₹',
        custom_attributes: [],
        deleted_at: null,
        description:
          '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
        display_assets: [],
        id: 33745,
        image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948218083/Asset 32x.png',
        metadata_fields: [],
        name: 'Standard',
        old_assets: [],
        preferred_identity_provider: 'email',
        public: true,
        subscription_plans: [
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            custom_attributes: [
              {
                name: 'Actual Price',
                value: '<p><s>$8.99</s></p>',
                type: 'rich_text'
              },
              {
                name: 'Discount',
                value: '<p>42% Off</p>',
                type: 'rich_text'
              }
            ],
            deleted_at: null,
            description: 'new usd plan',
            display_assets: [],
            downgrade_plan_config: null,
            duration_length: 1,
            duration_unit: 'month',
            enabled: true,
            id: 65056,
            image_url: null,
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 587,
            price_currency: '$',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Monthly',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          },
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            custom_attributes: [
              {
                name: 'Tag',
                value: 'Best Value',
                type: 'plain_text'
              },
              {
                name: 'Actual Price',
                value: '<p><s>₹ 945</s></p>',
                type: 'rich_text'
              },
              {
                name: 'Discount',
                type: 'rich_text',
                value: '<p>42% Off</p>'
              }
            ],
            deleted_at: null,
            description: 'new usd plan',
            display_assets: [],
            downgrade_plan_config: null,
            duration_length: 3,
            duration_unit: 'months',
            enabled: true,
            id: 65056,
            image_url: null,
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 30000,
            price_currency: 'INR',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Quarterly',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          },
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            custom_attributes: [],
            description: 'new usd plan',
            duration_length: 1,
            duration_unit: 'year',
            enabled: true,
            id: 65056,
            image_url: null,
            max_trial_period_length: null,
            max_trial_period_unit: null,
            paid_trial_price_cents: null,
            price_cents: 10000,
            price_currency: 'USD',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Yearly',
            updated_at: '2023-04-10T10:48:59.449Z'
          }
        ],
        subscription_type: 'standard',
        target_amount: null,
        target_amount_validation: false,
        target_amount_validation_enabled: false,
        updated_at: '2023-04-07T08:27:34.714Z'
      },
      {
        account_id: 9,
        api_ordering: null,
        assets: [],
        campaign_active: false,
        created_at: '2023-04-07T08:27:34.714Z',
        currency: '₹',
        custom_attributes: [],
        deleted_at: null,
        description:
          '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
        display_assets: [],
        id: 33746,
        image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948243781/Asset 12x 1.png',
        metadata_fields: [],
        name: 'Standard + Print',
        old_assets: [],
        preferred_identity_provider: 'email',
        public: true,
        subscription_plans: [
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            custom_attributes: [
              {
                name: 'Actual Price',
                value: '<p><s>$8.99</s></p>',
                type: 'rich_text'
              },
              {
                name: 'Discount',
                value: '<p>42% Off</p>',
                type: 'rich_text'
              }
            ],
            deleted_at: null,
            description: 'new usd plan',
            display_assets: [],
            downgrade_plan_config: null,
            duration_length: 1,
            duration_unit: 'month',
            enabled: true,
            id: 65056,
            image_url: null,
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 587,
            price_currency: '$',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Monthly',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          },
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            custom_attributes: [
              {
                name: 'Tag',
                value: 'Best Value',
                type: 'plain_text'
              },
              {
                name: 'Actual Price',
                value: '<p><s>₹ 945</s></p>',
                type: 'rich_text'
              },
              {
                name: 'Discount',
                type: 'rich_text',
                value: '<p>42% Off</p>'
              }
            ],
            deleted_at: null,
            description: 'new usd plan',
            display_assets: [],
            downgrade_plan_config: null,
            duration_length: 3,
            duration_unit: 'months',
            enabled: true,
            id: 65056,
            image_url: null,
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 10000,
            price_currency: 'USD',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Quarterly',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          },
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            custom_attributes: [],
            deleted_at: null,
            description: 'new usd plan',
            display_assets: [],
            downgrade_plan_config: null,
            duration_length: 1,
            duration_unit: 'year',
            enabled: true,
            id: 65056,
            image_url: null,
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 20000,
            price_currency: 'INR',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Yearly',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          }
        ],
        subscription_type: 'standard',
        target_amount: null,
        target_amount_validation: false,
        target_amount_validation_enabled: false,
        updated_at: '2023-04-07T08:27:34.714Z'
      }
    ]
  },
  concrete: {
    subscriptions: [
      {
        account_id: 9,
        api_ordering: null,
        assets: [],
        campaign_active: false,
        created_at: '2023-04-07T08:27:34.714Z',
        currency: '₹',
        custom_attributes: [],
        deleted_at: null,
        display_assets: [],
        id: 33744,
        description:
          '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
        image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948128721/image 1.png',
        metadata_fields: [],
        name: 'Digital All Access',
        old_assets: [],
        preferred_identity_provider: 'email',
        public: true,
        subscription_plans: [
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            custom_attributes: [
              {
                name: 'Actual Price',
                value: '<p><s>₹ 599</s></p>',
                type: 'rich_text'
              }
            ],
            deleted_at: null,
            description:
              '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
            display_assets: [],
            downgrade_plan_config: null,
            duration_length: 1,
            duration_unit: 'month',
            enabled: true,
            id: 65056,
            image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948181492/Asset 12x.png',
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 25000,
            price_currency: '₹',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Digital All Access',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          },
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            deleted_at: null,
            description:
              '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
            display_assets: [],
            downgrade_plan_config: null,
            duration_length: 6,
            duration_unit: 'months',
            enabled: true,
            custom_attributes: [
              {
                name: 'Tag',
                value: 'Best Value',
                type: 'plain_text'
              },
              {
                name: 'Actual Price',
                value: '<p><s>₹ 945</s></p>',
                type: 'rich_text'
              },
              {
                name: 'Discount',
                type: 'rich_text',
                value: '<p>42% Off</p>'
              }
            ],
            id: 65056,
            image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948218083/Asset 32x.png',
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 70000,
            price_currency: '₹',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Standard',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          },
          {
            assets: [],
            created_at: '2023-04-10T08:54:56.815Z',
            crossgrade_plan_config: null,
            deleted_at: null,
            description:
              '<p>✔️ Funny interviews with entertaining personalities.</p><p>✔️ Receive a daily or weekly digest of off beat news stories</p><p>✔️ Humorous commentary on current events.</p>',
            display_assets: [],
            downgrade_plan_config: null,
            custom_attributes: [],
            duration_length: 1,
            duration_unit: 'year',
            enabled: true,
            id: 65056,
            image_url: 'https://qtstage-01.gumlet.io/accesstype/assets/1690948243781/Asset 12x 1.png',
            max_trial_period_length: null,
            max_trial_period_unit: null,
            metadata: {},
            paid_trial_price_cents: null,
            price_cents: 125000,
            price_currency: '₹',
            recurring: true,
            subscription_group_id: 33744,
            supported_payment_providers: ['razorpay', 'paypal'],
            title: 'Standard + Print',
            trial_period_enabled: false,
            trial_period_type: null,
            updated_at: '2023-04-10T10:48:59.449Z',
            upgrade_plan_config: null,
            user_limit: null
          }
        ],
        subscription_type: 'standard',
        target_amount: null,
        target_amount_validation: false,
        target_amount_validation_enabled: false,
        updated_at: '2023-04-07T08:27:34.714Z'
      }
    ]
  }
};
