import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { uniqueId } from 'lodash';
import './banner.module.css';
import styles from './banner.module.css';
import { updateBannerDefaults } from './payWallUtils';

interface IPaywallBanner {
  wallTitle: string;
  wallDescription: string;
  signInTxt: string;
  buttonTxt: string;
  bottomTxt: string;
  isPbPreview: boolean;
  paywallSettings: object;
  enableDarkMode: boolean;
}

interface IBanner {
  bannerSettings: ISettings;
  PayWallBanner: React.FunctionComponent<IPaywallBanner>;
  bannerName: string;
  paywallSettings: object;
}

interface ISettings {
  wallTitle: string;
  wallDescription: string;
  signInTxt: string;
  buttonTxt: string;
  bottomTxt: string;
}

function Banner({ bannerSettings, PayWallBanner, bannerName, paywallSettings }: IBanner) {
  bannerSettings = updateBannerDefaults(bannerName, bannerSettings);
  const { wallTitle, wallDescription, signInTxt, buttonTxt, bottomTxt = '' } = bannerSettings || {};
  const { enableDarkModePreview = false } = useSelector(state => get(state, ['builder', 'config'], {}));

  return (
    <div className={styles['banner-wrapper']}>
      <span className={styles['banner-name']}>{bannerName}</span>
      <PayWallBanner
        wallTitle={wallTitle}
        wallDescription={wallDescription}
        signInTxt={signInTxt || 'Sign in'}
        buttonTxt={buttonTxt}
        bottomTxt={bottomTxt}
        isPbPreview={true}
        paywallSettings={paywallSettings}
        enableDarkMode={enableDarkModePreview}
      />
    </div>
  );
}

function PaywallBanner() {
  const PayWallBanner = window.QT_COMPONENTS.PayWall;
  const paywallSettings = useSelector(state =>
    get(state, ['qt', 'config', 'pagebuilder-config', 'meteredPaywall', 'pageSetting'], {})
  );

  const { meteringOff = {}, meteringOn = {} } = paywallSettings;
  const { login: meterOffBehindLogin = {}, subscription: meterOffBehindSubscription = {} } = meteringOff;
  const { anonymous: meterOnAnonymous = {}, loggedIn: meterOnBehindLogin = {} } = meteringOn;
  const { login: anonymousBehindLogin = {}, subscription: anonymousBehindSubscription = {} } = meterOnAnonymous;
  const {
    loginWall: anonymousBehindSubscriptionLoginWall = {},
    payWall: anonymousBehindSubscriptionPayWall = {}
  } = anonymousBehindSubscription;

  const PayWalls = [
    {
      bannerSettings: meterOffBehindLogin,
      bannerName: 'Stories behind Login'
    },
    {
      bannerSettings: meterOffBehindSubscription,
      bannerName: 'Stories behind Subscription'
    },
    {
      bannerSettings: anonymousBehindLogin,
      bannerName: 'Anonymous users - stories behind Login'
    },
    {
      bannerSettings: anonymousBehindSubscriptionPayWall,
      bannerName: 'Anonymous users - stories behind Subscription (Paywall)'
    },
    {
      bannerSettings: anonymousBehindSubscriptionLoginWall,
      bannerName: 'Anonymous users - stories behind Subscription (Registration Wall)'
    },
    {
      bannerSettings: meterOnBehindLogin,
      bannerName: 'Logged in users - stories behind Subscription'
    }
  ];

  return (
    <div className={styles['paywall-banners-container']}>
      {PayWalls.map(item => (
        <Banner
          key={uniqueId()}
          bannerSettings={item.bannerSettings}
          PayWallBanner={PayWallBanner}
          bannerName={item.bannerName}
          paywallSettings={paywallSettings}
        />
      ))}
    </div>
  );
}

export default PaywallBanner;
