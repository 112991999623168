import StarIcon from './svg/star.svg';
import CrownIcon from './svg/crown.svg';
import KeyIcon from './svg/key.svg';
import LockIcon from './svg/lock.svg';

const premiumStoryIconsData = [
  { name: 'star', svg: StarIcon },
  { name: 'crown', svg: CrownIcon },
  { name: 'key', svg: KeyIcon },
  { name: 'lock', svg: LockIcon }
];

export default premiumStoryIconsData;
