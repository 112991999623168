import React from 'react';
import styles from './styles.module.css';

export const CustomCallout = () => {
  return (
    <div className={styles.container}>
      <div className={styles.iconTitleContainer}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#4860bc">
          <path fill="none" d="M0 0h24v24H0V0z"></path>
          <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
        </svg>
        <h3 className={styles.title}>Note:</h3>
      </div>
      <div className={styles.content}>
        The validation of the code you enter will be conducted by an
        <a href="https://validator.ampproject.org" target="_blank" rel="noopener noreferrer" className={styles.link}>
          external AMP validator
        </a>
        .
        <span className={styles.spaceLeft}>
          To find more
          <a
            href="https://amp.dev/documentation/guides-and-tutorials/learn/validation-workflow/validate_amp"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            click here
          </a>
          .
        </span>
      </div>
    </div>
  );
};
