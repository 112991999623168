import { Callout } from '@quintype/em/components/callout';
import { FieldLabel } from '@quintype/em/components/field-label';
import Select from '@quintype/em/components/select';
import { TextArea } from '@quintype/em/components/text-area';
import { TextField } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges } from '../../actions/builder-action';
import { updateRowsList } from '../../actions/row-action';
import { IAdConfig } from '../../utils/interfaces';
import { checkValidEncodedCustomCode, encodeCustomCode } from '../../utils/utils';
import styles from './select-wrapper.module.css';

interface IComponentName {
  label?: string;
  value?: string;
}

interface IProps {
  rowId?: number;
  slot?: string;
  layout?: string;
  config?: {
    slotConfig?: object[];
    targetingId?: string;
    adType?: string;
    widget?: string;
  };
  selectAd?: string;
  selectTargetId?: string;
}

const adConfig: IAdConfig = {
  Mrec: ['300', '250'],
  'Vertical-Ad': ['300', '600']
};

const layoutTypesList = [
  { label: 'Story', value: 'story' },
  { label: 'Ad', value: 'ad' },
  { label: 'Widget', value: 'widget' }
];
const adTypesList = [
  { label: 'Mrec', value: 'Mrec' },
  { label: 'VerticalAd', value: 'Vertical-Ad' }
];

const SlotSetting = ({ rowId, config = {}, layout = '' }: IProps) => {
  const { targetingId = '', widget = '', slotConfig = [], adType = 'Mrec' } = config;

  const slotSetting = slotConfig.find((item: any) => {
    return item && item.type;
  });
  const slot = (slotSetting && Object.values(slotSetting)[0]) || 'story';
  const dispatch = useDispatch();
  let [selectVal, handleSelectValue] = useState(slot);
  let [selectAd, handleAd] = useState(adType);
  let [selectTargetId, handleTargetId] = useState(targetingId);

  const SlotWigetComponent = () => {
    return <div className={styles.widgetStyleWrapper}>Widget</div>;
  };

  // tslint:disable:no-shadowed-variable
  const SlotAdComponent = ({ selectAd = '', selectTargetId = '' }: IProps) => {
    const adDimensions = adConfig[selectAd] || {};
    const { pageType } = useSelector(state => get(state, 'builder'));
    return (
      <div>
        <div
          className={styles.adStyleWrapper}
          style={{ width: `${adDimensions[0]}px`, height: `${adDimensions[1]}px` }}
        >
          <p>
            adType: {selectAd}
            <br />
            pageType: {pageType}
            <br />
            targetingId: {selectTargetId}
          </p>
        </div>
      </div>
    );
  };

  const getSlotComponent = () => {
    switch (selectVal) {
      case 'ad':
        return <SlotAdComponent selectAd={selectAd} selectTargetId={selectTargetId} />;
      case 'widget':
        return <SlotWigetComponent />;
      default:
        return null;
    }
  };

  const initialUpdate = () => {
    handleSelectValue(slot);
    const selectedAdValue = selectVal === 'ad' && { adType: selectAd };
    dispatch(
      updateRowsList({
        rowAction: 'update_settings',
        rowActionPayload: {
          selectedRowId: rowId,
          row: {
            ...selectedAdValue,
            slotConfig: [{ type: selectVal, component: getSlotComponent }],
            targetingId,
            widget
          }
        }
      })
    );
    dispatch(updateHaveUnsavedChanges(true));
  };

  useEffect(() => {
    initialUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adTypeHandler = (option: IComponentName) => {
    selectAd = option.value || selectAd;
    handleAd(selectAd);
    dispatch(updateHaveUnsavedChanges(true));
    dispatch(
      updateRowsList({
        rowAction: 'update_settings',
        rowActionPayload: {
          selectedRowId: rowId,
          row: { adType: selectAd, slotConfig: [{ type: 'ad', component: getSlotComponent }] }
        }
      })
    );
  };

  const slotTypeHandler = (option: IComponentName) => {
    selectVal = option.value;
    const setAdType = option.value === 'ad' ? { adType: 'Mrec' } : {};
    handleSelectValue(selectVal);
    dispatch(updateHaveUnsavedChanges(true));
    dispatch(
      updateRowsList({
        rowAction: 'update_settings',
        rowActionPayload: {
          selectedRowId: rowId,
          row: { ...setAdType, slotConfig: [{ type: option.value, component: getSlotComponent }] }
        }
      })
    );
  };

  const updateTargetId = (key: string, value: string) => {
    selectTargetId = value;
    handleTargetId(selectTargetId);
    dispatch(updateHaveUnsavedChanges(true));
    dispatch(
      updateRowsList({
        rowAction: 'update_settings',
        rowActionPayload: {
          selectedRowId: rowId,
          row: { adType: selectAd, slotConfig: [{ type: 'ad', component: getSlotComponent }], [key]: value }
        }
      })
    );
  };

  const updateWidget = (key: string, value: string) => {
    dispatch(updateHaveUnsavedChanges(true));
    dispatch(
      updateRowsList({
        rowAction: 'update_settings',
        rowActionPayload: {
          selectedRowId: rowId,
          row: { [key]: value }
        }
      })
    );
  };

  const getDefaultAd = () => {
    switch (adType) {
      case 'Vertical-Ad':
        return { label: 'VerticalAd', value: 'Vertical-Ad' };
      case 'Mrec':
        return { label: 'Mrec', value: 'Mrec' };
    }
  };

  const getDefaultValue = () => {
    switch (slot) {
      case 'ad':
        return { label: 'Ad', value: 'ad' };
      case 'widget':
        return { label: 'Widget', value: 'widget' };
      case 'story':
        return { label: 'Story', value: 'story' };
    }
  };

  const getAdTypesList = () => {
    // Temp fix - Supporting both Mrec and Vertical ad for 3 col 12 stories

    // if (layout === 'ArrowThreeColTwelveStories') {
    //   return [{ label: 'VerticalAd', value: 'Vertical-Ad' }];
    // }
    const layoutsWithVerticalAds = ['ArrowOneColStoryList', 'ArrowElevenStories', 'ArrowThreeColTwelveStories'];
    if (!layoutsWithVerticalAds.includes(layout)) {
      return [{ label: 'Mrec', value: 'Mrec' }];
    }
    return adTypesList;
  };

  const getlayoutTypesList = () => {
    if (layout === 'ArrowCollectionFilter') {
      return [
        { label: 'Story', value: 'story' },
        { label: 'Ad', value: 'ad' }
      ];
    }
    return layoutTypesList;
  };

  const getRowTemplate = () => {
    return (
      <>
        <FieldLabel label="Slot Content" />
        <Select defaultValue={getDefaultValue()} onChange={slotTypeHandler} options={getlayoutTypesList()} />
        {selectVal === 'ad' || slot === 'ad' ? (
          <div>
            <Select defaultValue={getDefaultAd()} onChange={adTypeHandler} options={getAdTypesList()} />
            <div>
              <TextField
                label="Targeting Id"
                value={targetingId}
                onChange={(value: string) => updateTargetId('targetingId', value)}
              />
            </div>
          </div>
        ) : null}
        {selectVal === 'widget' || slot === 'widget' ? (
          <>
            <div className={styles.warningMessage}>
              <Callout
                variant="warning"
                title="Warning"
                content="The code may contain malicious script or may negatively affect the performance. Be cautious before pasting your code."
              />
            </div>
            <FieldLabel label="Custom Code" />
            <div className={styles.widgetInputBox}>
              <TextArea
                label="widget"
                value={checkValidEncodedCustomCode(widget)}
                onChange={(value: string) => updateWidget('widget', encodeCustomCode(value))}
              />
            </div>
            <p className={styles.description}>Paste any valid HTML code here</p>
          </>
        ) : null}
      </>
    );
  };

  return <>{getRowTemplate()}</>;
};

export default SlotSetting;
