import { Checkbox } from '@quintype/em/components/checkbox';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  otherConfig,
  updateHaveUnsavedChanges,
  updateNotificationsConfig,
  updateWidgetSetting
} from '../../actions/builder-action';
import updateAdsSetting from '../../actions/general-settings-action';
import { InfoTooltip } from '../../molecules/info-tooltip';
import styles from './performance-settings.module.css';

const PerformanceSettings = () => {
  const dispatch = useDispatch();
  const { notifications = {}, ads = {}, widget = {}, others = {} } = useSelector((state: any) =>
    get(state, ['builder', 'config', 'general'], {})
  );
  const { deferNotificationScript = true } = notifications;
  const { deferAds = true } = ads;
  const { lazyLoadWidget = true } = widget;
  const { lazyLoadJsEmbed = true } = others;

  const updateNotificationSettings = (key: string, value: boolean) => {
    dispatch(updateNotificationsConfig({ type: '', config: { [key]: value } }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const updateAdSettings = (key: string, value: boolean) => {
    dispatch(updateAdsSetting({ [key]: value }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const updateWidgetSettings = (key: string, value: boolean) => {
    dispatch(updateWidgetSetting({ [key]: value }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const updateOtherConfig = (key: string, value: boolean) => {
    dispatch(otherConfig({ [key]: value }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  return (
    <>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          label="Defer notification script"
          id="deferNotificationScript"
          checked={deferNotificationScript}
          onChange={(value: boolean) => updateNotificationSettings('deferNotificationScript', value)}
        />
        <Checkbox
          label="Defer ads"
          id="deferAds"
          checked={deferAds}
          onChange={(value: boolean) => updateAdSettings('deferAds', value)}
        />
        <Checkbox
          label="Lazy Load Widget"
          id="lazyLoadWidget"
          checked={lazyLoadWidget}
          onChange={(value: boolean) => updateWidgetSettings('lazyLoadWidget', value)}
        />
        <div className={styles.tooltipWrapper}>
          <Checkbox
            label="Lazy Load JavaScript Embeds"
            id="lazyLoadJsEmbeds"
            checked={lazyLoadJsEmbed}
            onChange={(value: boolean) => updateOtherConfig('lazyLoadJsEmbed', value)}
          />
          <InfoTooltip
            width={20}
            height={20}
            tooltipDescription="We recommend keeping lazy load enabled to experience a performant website."
            tooltipId="jsEmbed"
            tooltipPlace="bottom"
          />
        </div>
      </div>
    </>
  );
};

export default PerformanceSettings;
