import get from 'lodash/get';
import {
  PAGEBUILDER_ROW_ADD_ROW_BOTTOM,
  PAGEBUILDER_ROW_ADD_ROW_TOP,
  PAGEBUILDER_ROW_DELETE_ROW,
  PAGEBUILDER_ROW_MOVE,
  PAGEBUILDER_ROW_UPDATE_SELECTED_ROW,
  PAGEBUILDER_ROW_UPDATE_SELECTED_ROW_SETTINGS
} from '../reducers/builder/configReducer/actions';
import { IRowAction } from '../utils/interfaces';

const updateRowsList = ({ rowAction, rowActionPayload }: IRowAction) => {
  return (dispatch: any, getState: any) => {
    const { pageType, subPageType, pageId } = get(getState(), ['builder'], {});
    const payload = { ...rowActionPayload, moduleType: pageType, subPageType, pageId };
    const actionEventMap: { [key: string]: string } = {
      update: PAGEBUILDER_ROW_UPDATE_SELECTED_ROW,
      delete_row: PAGEBUILDER_ROW_DELETE_ROW,
      add_row_top: PAGEBUILDER_ROW_ADD_ROW_TOP,
      add_row_bottom: PAGEBUILDER_ROW_ADD_ROW_BOTTOM,
      update_settings: PAGEBUILDER_ROW_UPDATE_SELECTED_ROW_SETTINGS
    };
    dispatch({
      type: actionEventMap[rowAction],
      payload
    });
    return;
  };
};

const moveRow = (index: number, moduleType: string, pageId: string, relativePosition: number, subPageType: string) => {
  return {
    type: PAGEBUILDER_ROW_MOVE,
    payload: { index, moduleType, relativePosition, pageId, subPageType }
  };
};

export { moveRow, updateRowsList };
